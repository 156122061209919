<template>
  <ion-modal :is-open="modelValue" @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

        <ion-title>{{
          !modalHour?.id
          ? $t('Añadir tiempo')
          : $t('Editar tiempo')
          }}</ion-title>

        <ion-buttons slot="end">
          <ion-button v-if="+comportamientos?.campo_tipo_tiempo_obligatorio" :disabled="!hayTipo"
            @click="processDateAndTime">{{
            !modalHour?.id ? $t('Añadir') : $t('Guardar')
            }}</ion-button>
          <ion-button v-else @click="processDateAndTime">{{
            !modalHour?.id ? $t('Añadir') : $t('Guardar')
            }}</ion-button>
        </ion-buttons>

      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-list>
        <ion-item v-if="isTecnicosAdicionales && isMainTechnician" @click="$emit('tecnicosModal', true)">
          <ion-label>{{ $t('Técnicos') }}</ion-label>
          <ion-input class="ion-text-right" :value="techName" readonly clear-input></ion-input>
        </ion-item>

        <ion-item>
          <ion-label>{{ $t('Fecha Inicio') }}</ion-label>

          <ion-datetime-button datetime="imputacionDatetime"></ion-datetime-button>

          <ion-modal class="date-modal" :keep-contents-mounted="true">
            <ion-datetime first-day-of-week="1" v-model="modalHour.date" style="color: black" id="imputacionDatetime"
              presentation="date" :max="moment().format('YYYY-MM-DD')"></ion-datetime>
          </ion-modal>
        </ion-item>

        <ion-item>
          <ion-label>{{ $t('Fecha Fin') }}</ion-label>

          <ion-datetime-button datetime="imputacionDatetime2"></ion-datetime-button>

          <ion-modal class="date-modal" :keep-contents-mounted="true">
            <ion-datetime first-day-of-week="1" v-model="modalHour.date_fin" style="color: black" id="imputacionDatetime2"
              presentation="date" :max="moment().format('YYYY-MM-DD')"></ion-datetime>
          </ion-modal>
        </ion-item>

        <ion-item>
          <ion-label id="open-modal">{{ $t('Desde') }}</ion-label>
          <ion-input class="ion-text-right" v-model="modalHour.from" clear-input type="time">
          </ion-input>
        </ion-item>

        <ion-item>
          <ion-label>{{ $t('Hasta') }}</ion-label>
          <ion-input class="ion-text-right" v-model="modalHour.to" clear-input type="time">
          </ion-input>
        </ion-item>

        <ion-item>
          <ion-input
            field-type="type"
            :label="$t('Tipo tiempo')"
            @click="$emit('setTipoTiempos', null)"
            :value="!modalHour?.id ? modalHour.nombreTipoTiempo : modalHour.hora?.tipo_tiempo?.nombre"
            readonly
            clear-input
          />
        </ion-item>

        <ion-item v-if="comportamientos?.usar_partidas_en_obras && hasPartidas"
          @click="$emit('partidasModal', null)">
          <ion-label>{{ $t('Partida presupuestaria') }}</ion-label>
          <ion-input class="ion-text-right" :value="
                modalHour.partida
                  ? `${modalHour.partida.codigo} - ${modalHour.partida.descripcion}`
                  : ''
              " readonly clear-input></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">{{ $t('Observaciones') }}</ion-label>
          <ion-textarea v-model="modalHour.observaciones"></ion-textarea>
        </ion-item>
      </ion-list>

      <template v-if="showDeleteAction">
        <div class="delete-box">
          <ion-button
            size="small"
            style="display:flex;width:80%;margin-top: 3rem;"
            color="danger"
            :disabled='offline.status'
            @click="resolveAction('delete', modalHour)"
          >
            {{ $t('Eliminar') }}
          </ion-button>
        </div>
      </template>
    </ion-content>

  </ion-modal>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment';

import { useOfflineStore } from '@/stores/offline';

import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonInput,
  IonTextarea,
  IonDatetime,
  IonDatetimeButton,
} from '@ionic/vue';

export default {
  name: 'MSetAddHoras',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    newHora: {
      required: true,
      type: Object,
    },

    comportamientos: {
      required: false,
      type: Object,
    },

    techName: {
      required: true,
      type: String,
    },

    isTecnicosAdicionales: {
      required: true,
      type: Boolean,
    },

    isMainTechnician: {
      required: true,
      type: Boolean,
    },

    hasPartidas: {
      required: true,
      type: Boolean,
    },

    hayTipo: {
      required: true,
      type: Boolean,
    },

    deleteItemCallback: {
      type: Function,
      default: () => { }
    }
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonInput,
    IonTextarea,
    IonDatetime,
    IonDatetimeButton,
  },

  setup() {
    const offline = useOfflineStore();

    return {
      moment,

      offline,

      validTime: ref(false),
      localComponentTime: ref({}),
      modalHour: ref({}),
    };
  },

  watch: {
    modelValue: function (newValue) {
      if (newValue) {
        this.modalHour = { ...this.newHora };
      }
    },

    'newHora.tecnico': function() {
      this.modalHour = { ...this.newHora };
    }
  },

  computed: {
    showDeleteAction() {
      return this.modalHour?.id && this.$hasPermissions('horas', 'borrar');
    }
  },

  methods: {
    resolveAction(action = '', hour) {

      if (action === 'delete') {
        this.$showDeleteAlertController(
          undefined,
          undefined,
          () => this.deleteItemCallback(hour),
        );
      } else {
        this.$openToastObject(
          this.$t('Ha ocurrido un error'),
          this.$t('generic-delete-error-message')
        );
      }
    },

    async processDateAndTime() {
      let isValid = false;

      isValid = await this.$isTimeValid(
        {
          inicio: this.modalHour.from,
          fin: this.modalHour.to,
        },
        {
          desde: this.modalHour?.date,
          hasta: this.modalHour?.date_fin
        }
      );

      if (isValid) this.$emit('done', this.modalHour);
      /** NOTE: En caso contrario lo notificamos desde la función $isTimeValid(...) */
    },
  },
};
</script>

<style lang="scss" scoped>
  .date-modal {
    background-color: rgba(0, 0, 0, 0.4);
    --width: 290px;
    --height: 382px;
    --border-radius: 8px;
  }

  .date-modal ion-datetime {
    height: 382px;
    width: 100% !important;
  }

  .delete-box {
    display: flex;
    justify-content: center;
  }

  ion-input[field-type="type"] {
    text-align: right;
  }

</style>