<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>

          <ion-title>{{ $t('GENERAR PRESUPUESTO') }}</ion-title>

          <ion-buttons slot="end">
            <ion-button @click="setMakeBudgetForWo">{{ $t('Aceptar') }}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-item>
        <ion-label position="floating">{{ $t('Descripcion') }}</ion-label>
        <ion-textarea v-model="budget.descripcion"></ion-textarea>
      </ion-item>
    </ion-content>
  </ion-modal>
</template>

<script>
import { ref, getCurrentInstance } from 'vue';

// Stores
import { useGmaoStore } from '@/stores/gmao';
import { useOfflineStore } from '@/stores/offline';

import {
  IonModal,
  IonButton,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonTextarea
} from '@ionic/vue';

export default {
  name: 'MSetMakeBudget',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    workorder: {
      required: true,
      type: Object,
    },
  },

  components: {
    IonModal,
    IonButton,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonTextarea,
  },

  watch: {
    modelValue(value) {
      if (value) {
        this.budget.descripcion = this.workorder.problema;
      }
    }
  },

  computed: {},

  setup() {
    const gmao = useGmaoStore();
    const offline = useOfflineStore();

    const app = getCurrentInstance();

    return {
      budget: ref({}),

      // Stores
      gmao,
      offline,

      // OTHER
      app,
    };
  },

  methods: {
    setMakeBudgetForWo() {
      return this.$axios
        .post(
          `/v2/users/actions.php?call=setMakeBudgetForWo&token=${this.gmao.user.token}&v2=1`,
          {
            data: {
              workorder: this.workorder.id,
              tecnico: this.gmao.user.id,
              descripcion: this.budget.descripcion,
            }
          },
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          }
        )
        .then(() => {
          this.$emit('update:modelValue', false);

          this.app.appContext.config.globalProperties.$openToastObject(
            'Presupuesto creado',
            'Se ha creado el presupuesto.',
            'success'
          );
          this.setMessageForBudget = false;
          this.$emit('goBack');
        });
    },
  },
};
</script>