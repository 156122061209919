import getController from "./getQueries";

import moment from 'moment';

import { useWorkOrdersStore } from '@/stores/workorders';

export default function setController(datos:any, store: any = null) {
    const { functionName } = datos;

    switch (functionName) {
        case 'createWorkorder_new_workorder':
            createWorkorder_new_workorder(datos, store);
            break;
        case 'createIncidence_new_workorder':
            createIncidence_new_workorder(datos, store);
            break;
        case 'selectOperarioAdicional_workorder':
            setOperarioAdicional_workorder(datos, store);
            break;
        case 'deleteOperarioAdicional_workorder':
            deleteOperarioAdicional_workorder(datos, store);
            break;
        case 'updateWorkorderField_workorder':
            updateWorkorderField_workorder(datos, store);
            break;
        case 'setWorkorderUpdate_workorder':
            setWorkorderUpdate_workorder(datos, store);
            break;
        case 'selectOperario_workorder':
            selectOperario_workorder(datos, store);
            break;
        case 'selectEspecialidad_workorder':
            selectEspecialidad_workorder(datos, store);
            break;
        case 'setNewCommunication_workorder':
            setNewCommunication_workorder(datos, store);
            break;
        case 'setWorkorderActive_workorder':
            setWorkorderActive_workorder(datos, store);
            break;
        case 'setWorkorderActiveSync_workorder':
            setWorkorderActiveSync_workorder(datos, store);
            break;
        case 'setMaquina_workorder':
            setWorkorderActiveSync_workorder(datos, store);
            break;
        case 'deleteWorkorderActive_workorder':
            deleteWorkorderActive_workorder(datos, store);
            break;
        case 'uploadPhoto_workorder':
            uploadPhoto_workorder(datos, store);
            break;
        case 'uploadMultiplePhotos_workorder':
            uploadPhoto_workorder(datos, store);
            break;
        case 'deleteImage_workorder':
            deleteImage_workorder(datos, store);
            break;
        case 'storeTimes_workorder':
            storeTimes_workorder(datos, store);
            break;
        case 'deleteTimes_workorder':
            deleteTimes_workorder(datos, store);
            break;
        case 'sendFirma_workorder':
            sendFirma_workorder(datos, store);
            break;
        case 'setDuplicateWorkorder_workorder':
            setDuplicateWorkorder_workorder(datos, store);
            break;
        case 'cambiarActivo_item':
            cambiarActivo_item(datos, store);
            break;
        case 'setActiveFaq_item':
            setActiveFaq_item(datos, store);
            break;
        case 'saveBack_item':
            saveBack_item(datos, store);
            break;
        case 'setAnomaly_item':
            setAnomaly_item(datos, store);
            break;
        case 'storeTimes_item':
            storeTimes_item(datos, store);
            break;
        case 'setWorkorderMaterials_workorder':
            setWorkorderMaterials_workorder(datos, store);
            break;
        case 'deleteWorkorderMaterial_workorder':
            deleteWorkorderMaterial_workorder(datos, store);
            break;
        case 'setFaqArticles_item':
            setFaqArticles_item(datos, store);
            break;
        case 'setUpdateParteMaquinaChecklist':
            setUpdateParteMaquinaChecklist(datos, store);
            break;
        case 'setCreateJornada':
            setCreateJornada(datos, store);
            break;
        case 'setJornada_workorder':
            setJornada_workorder(datos, store);
            break;
        case 'setJornada_vehiculo_workorder':
            setJornada_vehiculo_workorder(datos, store);
            break;
        case 'setEditWorkedDay_workeddays':
            setEditWorkedDay_workeddays(datos, store);
            break;
        default:
            throw new Error(`SET SQLITE: La funcion "${functionName}" no existe`);
    }

    // TODO: Posible otra solución
    // const fun: Window = window[functionName];

    // if (typeof fun == 'function') fun(table, query, config, dbEX);
    // else throw new Error("La función no existe...");


    // FUNCIONES -------------------------------------------------------------------------------------------------------

    // function function_name(data:any, store:any) {
    //     const {table, query, config, dbEX} = data;
    //     .
    //     .
    //     .
    // }

    async function createWorkorder_new_workorder(
    datos: {
        table: string;
        query: string;
        config: any;
        dbEX: any;
    },
    store: { status: boolean; counterQueue: number, workorder: any }
    ): Promise<any> {
        const { table, query, config, dbEX } = datos;

        try {
            // SET NEW WO DATA
            const result = await dbEX.run(query);

            // Validar resultado de la creación
            if (!result || !result.changes?.lastId) {
                throw new Error("No se pudo crear el parte. Respuesta inválida.");
            }

            if (store.status) store.counterQueue++;

            // Preparar datos para obtener el parte recién creado
            const getParteData = {
                functionName: 'getParte_general',
                table,
                query: `SELECT * FROM partes WHERE rowid=${result.changes.lastId}`,
                config,
                dbEX,
            };

            // Obtener datos del nuevo parte
            const parte = await getController(getParteData, store);
            store.workorder = parte;

        } catch (e) {
            throw new Error(`createWorkorder creation failed: ${e}`);

        }

    }

    async function createIncidence_new_workorder(datos:any, store:any) {
        const { dbEX } = datos;
        let query = '';
        let queryImg = '';

        try {
            if (Array.isArray(datos.query)) {
                query = datos.query[0];
                queryImg = datos.query[1];
            }

            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.run(query);

                resolve(parte);
            });
            return promesa.then((value:any) => {
                // Incrementar contador
                store.counterQueue++;

                // SET NEW WO DATA value { changes: {changes: 1, lastId: id} }
                const getIncidence = new Promise((resolve) => {
                    resolve(dbEX.query(`SELECT * FROM incidencias WHERE rowid=${value.changes.lastId}`));
                });
                getIncidence.then((value:any) => {
                    store.incidence = value.values[0];
                });

                // Set Imagenes Incidencia
                if (Array.isArray(datos.query) && queryImg.trim().length > 1) {
                    const setIncidenciaImagen = new Promise((resolve) => {
                        resolve(dbEX.run(queryImg));
                    });
                    setIncidenciaImagen.then(() => {

                    })
                }
            });

        } catch (e) {
            throw new Error(`createIncidence creation failed: ${e}`);

        }

    }

    async function setOperarioAdicional_workorder(datos:any, store:any) {
        const { table, query, config, dbEX } = datos;

        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.run(query);
                resolve(parte);
            });
            return promesa.then((value:any) => {
                // Incrementar contador
                store.counterQueue++;

                // SET NEW WO DATA value { changes: {changes: 1, lastId: id} }
                datos = {
                    functionName: 'getParte_general',
                    table,
                    query: `SELECT * FROM partes P where P.id=(SELECT (T.id_parte) FROM parte_tecnicos T WHERE T.rowid=${value.changes.lastId})`,
                    config,
                    dbEX
                }
                // GET NEW WO DATA -> Funcion getParte
                return getController(datos, store);
            });

        } catch (e) {
            throw new Error(`setOperarioAdicional failed: ${e}`);

        }

    }

    async function deleteOperarioAdicional_workorder(datos:any, store:any) {
        const { table, query, config, dbEX } = datos;

        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.run(query);
                resolve(parte);
            });
            return promesa.then(() => {
                // Incrementar contador
                store.counterQueue--;

                // SET NEW WO DATA value { changes: {changes: 1, lastId: id} }
                const id_parte = +query.split('id_parte=')[1].split('AND')[0];
                datos = {
                    functionName: 'getParte_general',
                    table,
                    query: `SELECT * FROM partes P where P.id=${id_parte || 0}`,
                    config,
                    dbEX
                }
                // GET NEW WO DATA -> Funcion getParte
                return getController(datos, store);
            });

        } catch (e) {
            throw new Error(`deleteOperarioAdicional failed: ${e}`);

        }

    }

    async function updateWorkorderField_workorder(datos:any, store:any) {
        const { table, query, config, dbEX } = datos;

        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.query(query);
                resolve(parte);
            });
            return promesa.then(() => {
                // Incrementar contador
                store.counterQueue++;

                const parteId = +query.split('partes.id=')[1];

                if (parteId && typeof parteId == 'number') {
                    // SET NEW WO DATA value { changes: {changes: 1, lastId: id} }
                    datos = {
                        functionName: 'getParte_general',
                        table,
                        query: `SELECT * FROM partes P where P.id=${parteId}`,
                        config,
                        dbEX
                    }
                    // GET NEW WO DATA -> Funcion getParte
                    return getController(datos, store);
                } else {
                    throw new Error("updateWorkorderField: Parte ID no encontrado");
                }

            });

        } catch (e) {
            throw new Error(`updateWorkorderField failed: ${e}`);
        }

    }

    async function setWorkorderUpdate_workorder(datos:any, store:any) {
        const { table, query, config, dbEX } = datos;

        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.run(query[0]);
                resolve(parte);
            });
            return promesa.then(() => {
                // Incrementar contador
                store.counterQueue++;

                const parteId = query[1].id;

                if (parteId && typeof parteId == 'number') {
                    // SET NEW WO DATA value { changes: {changes: 1, lastId: id} }
                    datos = {
                        functionName: 'getParte_general',
                        table,
                        query: `SELECT * FROM partes P where P.id=${parteId}`,
                        config,
                        dbEX
                    }
                    // GET NEW WO DATA -> Funcion getParte
                    return getController(datos, store);
                } else {
                    throw new Error("setWorkorderUpdate: Parte ID no encontrado");
                }

            });

        } catch (e) {
            throw new Error(`setWorkorderUpdate failed: ${e}`);
        }

    }

    async function selectOperario_workorder(datos:any, store:any) {
        const { table, query, config, dbEX } = datos;

        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.run(query);
                resolve(parte);
            });
            return promesa.then(() => {
                // Incrementar contador
                store.counterQueue++;

                const parteId = +query.split('partes.id=')[1];

                if (parteId && typeof parteId == 'number') {
                    // SET NEW WO DATA value { changes: {changes: 1, lastId: id} }
                    datos = {
                        functionName: 'getParte_general',
                        table,
                        query: `SELECT * FROM partes P where P.id=${parteId}`,
                        config,
                        dbEX
                    }
                    // GET NEW WO DATA -> Funcion getParte
                    return getController(datos, store);
                } else {
                    throw new Error("selectOperario: Parte ID no encontrado");
                }

            });

        } catch (e) {
            throw new Error(`selectOperario failed: ${e}`);
        }

    }

    async function selectEspecialidad_workorder(datos:any, store:any) {
        const { table, query, config, dbEX } = datos;

        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.run(query);
                resolve(parte);
            });
            return promesa.then(() => {
                // Incrementar contador
                store.counterQueue++;
                const parteId = +query.split('partes.id=')[1];
                if (parteId && typeof parteId == 'number') {
                    // SET NEW WO DATA value { changes: {changes: 1, lastId: id} }
                    datos = {
                        functionName: 'getParte_general',
                        table,
                        query: `SELECT * FROM partes P where P.id=${parteId}`,
                        config,
                        dbEX
                    }
                    // GET NEW WO DATA -> Funcion getParte
                    return getController(datos, store);
                } else {
                    throw new Error("selectEspecialidad: Parte ID no encontrado");
                }

            });

        } catch (e) {
            throw new Error(`selectEspecialidad failed: ${e}`);
        }

    }

    async function setNewCommunication_workorder(datos:any, store:any) {
        const { table, query, config, dbEX } = datos;

        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.run(query);
                resolve(parte);
            });
            return promesa.then((value:any) => {
                // Incrementar contador
                store.counterQueue++;

                const lastID = value.changes.lastId;

                const getComunicacion = new Promise((resolve) => {
                    const comunicacion = dbEX.query(`SELECT * FROM incidencia_comunicaciones T WHERE T.rowid=${lastID}`);
                    resolve(comunicacion);
                });
                getComunicacion.then((value:any) => {
                    const comunicacion = value.values[0];
                    if (comunicacion) {
                        store.comunicacion = comunicacion;
                    }
                    // SET NEW WO DATA value { changes: {changes: 1, lastId: id} }
                    datos = {
                        functionName: 'getParte_general',
                        table,
                        query: `SELECT * FROM partes P where P.id=(SELECT (T.id_parte) FROM incidencia_comunicaciones T WHERE T.rowid=${lastID})`,
                        config,
                        dbEX
                    }
                    // GET NEW WO DATA -> Funcion getParte
                    return getController(datos, store);
                });
            });

        } catch (e) {
            throw new Error(`setNewCommunication failed: ${e}`);
        }

    }

    async function setWorkorderActive_workorder(datos:any, store:any) {
        const { query, config, dbEX } = datos;

        try {
            // SI SE LLEGA CON IMAGEN
            const querySplit = query.split(';\n');

            let query1 = querySplit[0]; // Creacion del material
            const modQuery:any = query1.split('parteID=')[1].split('|');

            query1 = modQuery[1];

            const id_parte = +modQuery[0];

            const query2 = querySplit[1]; // Su imagen

            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const maquina = dbEX.run(query1);
                resolve(maquina);
            });
            return promesa.then((value:any) => {
                store.counterQueue++;
                const lastID = value.changes?.lastId;
                if (lastID) {

                    const promesa2 = new Promise((resolve) => {
                        const maquina = dbEX.query(`SELECT * FROM maquinas WHERE rowid=${lastID}`);
                        resolve(maquina);
                    });

                    promesa2.then((value:any) => {
                        if (value.values.length) {
                            const maquina = value.values[0];

                            // Añadir la maquina nueva al parte_maquinas
                            const datos1 = {
                                functionName: 'setWorkorderActiveSync_workorder',
                                table: 'parte_maquinas',
                                query: `INSERT INTO parte_maquinas (id, id_parte, id_maquina, created_at, updated_at, last_modified) VALUES (${moment().unix()+2}, ${id_parte}, ${maquina?.id}, '${moment().format('YYYY-MM-DD H:m:s')}', '${moment().format('YYYY-MM-DD H:m:s')}', '${moment().format('YYYY-MM-DD H:m:s')}')`,
                                config,
                                dbEX
                            }
                            setWorkorderActiveSync_workorder(datos1, store);

                            if ((query2 || '' ).trim().length) { // Subida de imagen de una nueva imagen de maquina
                                datos = {
                                    functionName: 'setWorkorderActiveImage_workorder',
                                    table: 'maquina_imagenes',
                                    query: query2,
                                    config,
                                    dbEX
                                }
                                setWorkorderActiveImage_workorder(datos, store, maquina?.id);
                            }

                        }
                    });

                } else {
                    throw new Error("setWorkorderActive: no last id");
                }
            });

        } catch (e) {
            throw new Error(`setWorkorderActive failed: ${e}`);

        }

    }

    async function deleteWorkorderActive_workorder(datos:any, store:any) {
        const { query, dbEX } = datos;

        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.query(query);
                resolve(parte);
            });
            return promesa.then(() => {
                // TODO: Si es activo a borrar ya existe, ++ | Si no existe y ha sido creado en offline --
                store.counterQueue++;
                // const parteId = +query.split('id_parte=')[1];
                // if (parteId && typeof parteId == 'number') {
                //     // SET NEW WO DATA value { changes: {changes: 1, lastId: id} }
                //     datos = {
                //         functionName: 'getParte_general',
                //         table,
                //         query: `SELECT * FROM partes P where P.id=${parteId}`,
                //         config,
                //         dbEX
                //     }
                //     // GET NEW WO DATA -> Funcion getParte
                //     return getController(datos, store);
                // } else {
                //     throw new Error("deleteWorkorderActive: Parte ID no encontrado");
                // }

            });

        } catch (e) {
            throw new Error(`deleteWorkorderActive failed: ${e}`);
        }

    }

    async function uploadPhoto_workorder(datos:any, store:any) {
        const { query, dbEX } = datos;

        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.run(query);
                resolve(parte);
            });
            return promesa.then(() => {
                store.counterQueue++;
            });

        } catch (e) {
            throw new Error(`uploadPhoto failed: ${e}`);

        }

    }

    async function deleteImage_workorder(datos:any, store:any) {
        const { query, dbEX } = datos;

        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.query(query);
                resolve(parte);
            });
            return promesa.then(() => {
                store.counterQueue++;
            });

        } catch (e) {
            throw new Error(`deleteImage failed: ${e}`);

        }

    }

    async function storeTimes_workorder(datos:any, store:any) {
        const { query, dbEX } = datos;

        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.run(query);
                resolve(parte);
            });
            return promesa.then((value:any) => {
                store.counterQueue++;

                const parteHoras = new Promise((resolve) => {
                    const row = dbEX.query(`SELECT id FROM parte_horas WHERE rowid=${value.changes.lastId}`);
                    resolve(row);
                });
                parteHoras.then((value:any) => {
                    console.log(value);
                    const id_hora = value.values.at(-1).id
                    const woStore:any = useWorkOrdersStore();

                    woStore.timeRegister.id = id_hora;

                })
            });

        } catch (e) {
            throw new Error(`storeTimes failed: ${e}`);

        }

    }

    async function deleteTimes_workorder(datos:any, store:any) {
        const { query, dbEX } = datos;

        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.run(query);
                resolve(parte);
            });
            return promesa.then(() => {
                store.counterQueue++;
            });

        } catch (e) {
            throw new Error(`deleteTimes failed: ${e}`);

        }

    }

    async function sendFirma_workorder(datos:any, store:any) {
        const { dbEX } = datos;
        let { query } = datos;
        
        let onlySaveImg = false;
        if (Array.isArray(query) && query?.length) {
            onlySaveImg = query[1];
            query = query[0];
        }



        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                /**
                 * In construction 🚧 NOTE: Solo vamos a ejecutar la query
                 * cuando guardemos la imagen. Sino, se crean duplicados

                 * REVIEW: Hay que revisar este flujo
                 */
                const parte = dbEX.run(query) || null;
                resolve(parte);
            });
            return promesa.then(() => {

                // SET NEW WO DATA value { changes: {changes: 1, lastId: id} }
                store.counterQueue++;

                if (!onlySaveImg) {

                    if (Object.keys(datos?.extra)) {
                        const { extra } = datos;
                        const id_parte = extra.id_parte;

                        if (id_parte) {
                            const parteFirma = new Promise((resolve) => {
                                const firma = dbEX.query(`SELECT * FROM parte_firma WHERE id_parte=${id_parte} AND firma_tec=0`); // NOTE: Filtramos por imagen de la firma + firma cliente
                                resolve(firma);
                            });
                            parteFirma.then((value:any) => {
                                
                                if (value.values[0]?.id) {
                                    const setPartePromise = new Promise((resolve) => {
                                        // TODO: Añadir estado asociado a OT firmada/cerrada
                                        const setParte = dbEX.run(
                                        `
                                            UPDATE partes
                                            SET id_estado_actual=5
                                            WHERE id=${id_parte}
                                        `
                                        );
                                        resolve(setParte);
                                    });
                
                                    setPartePromise.then(() => {
                                        console.log('Done');
                                    });
                                } else {
                                    console.error('No hay ninguna firma guardada');
                                }
                            });
                        }

                    }
                }

            });

        } catch (e) {
            throw new Error(`sendFirma failed: ${e}`);

        }

    }

    async function setDuplicateWorkorder_workorder(datos:any, store:any) {
        const { query, dbEX } = datos;

        const {
            id_antiguo_parte,
            id_parte_actual,
            tipo_letra,
            comportamiento_duplicar_preventivos_activos,
            comportamiento_duplicar_correctivos_activos
        } = query[1];

        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.run(query[0]);
                resolve(parte);
            });
            return promesa.then(() => {
                store.counterQueue++;

                // Set Horas
                const parteHorasPromise = new Promise((resolve) => {
                    const parteHoras = dbEX.query(`
                        SELECT * FROM parte_horas WHERE id_parte=${id_antiguo_parte}
                    `);
                    resolve(parteHoras);
                });
                parteHorasPromise.then((value:any) => {
                    const horas = value.values;
                    let horasQuery = '';
                    horas.map((h:any, index:number) => {
                        delete h?.tecnico;
                        delete h?.tipo_tiempo;

                        h.id = id_parte_actual + index + 1;
                        h.id_parte = id_parte_actual;

                        Object.keys(h).forEach((k, i) => {
                            const value = Object.values(h)[i];
                            if (typeof value == 'string' || !value) {
                                h[k] = `'${value}'`;
                            }
                        })

                        horasQuery += `
                            INSERT INTO parte_horas (${Object.keys(h).toString()})
                            VALUES
                            (${Object.values(h).toString()});
                        `
                        return h;
                    });

                    const setNewHorasPromise = new Promise((resolve) => {
                        const setNewHora = dbEX.run(horasQuery);
                        resolve(setNewHora);
                    });

                    setNewHorasPromise.then((value:any) => {
                        console.log('Done: ', value);
                    }).catch(() => {
                        console.log('horas');
                    })
                });

                // Set Imagenes
                const imagenesPromise = new Promise((resolve) => {
                    const imagenes = dbEX.query(`
                        SELECT * FROM parte_imagenes WHERE id_parte=${id_antiguo_parte} AND (deleted_at IS NULL OR deleted_at='null')
                    `);
                    resolve(imagenes);
                });
                imagenesPromise.then((value:any) => {
                    const imagenes = value.values;
                    let imagenesQuery = '';
                    imagenes.map((h:any, index:number) => {
                        h.id = id_parte_actual + index + 1;
                        h.id_parte = id_parte_actual;

                        Object.keys(h).forEach((k, i) => {
                            const value = Object.values(h)[i];
                            if (typeof value == 'string' || !value) {
                                h[k] = `'${value}'`;
                            }
                        })

                        imagenesQuery += `
                            INSERT INTO parte_imagenes (${Object.keys(h).toString()})
                            VALUES
                            (${Object.values(h).toString()});
                        `
                        return h;
                    });

                    const setNewImagenesPromise = new Promise((resolve) => {
                        const setNewImagen = dbEX.run(imagenesQuery);
                        resolve(setNewImagen);
                    });

                    setNewImagenesPromise.then((value:any) => {
                        console.log('Done: ', value);
                    }).catch(() => {
                        console.log('imagenes');
                    });
                });

                if((tipo_letra == 'P' && comportamiento_duplicar_preventivos_activos) || (tipo_letra != 'P' && comportamiento_duplicar_correctivos_activos)) {
                    // Set Activos
                    const parteMaquinasPromise = new Promise((resolve) => {
                        const parteMaquinas = dbEX.query(`
                            SELECT * FROM parte_maquinas WHERE id_parte=${id_antiguo_parte} AND (deleted_at IS NULL OR deleted_at='null')
                        `);
                        resolve(parteMaquinas);
                    });
                    parteMaquinasPromise.then((value:any) => {
                        const maquinas = value.values;
                        let maquinasQuery = '';
                        maquinas.map((h:any, index:number) => {
                            h.id = id_parte_actual + index + 1;
                            h.id_parte = id_parte_actual;

                            Object.keys(h).forEach((k, i) => {
                                const value = Object.values(h)[i];
                                if (typeof value == 'string' || !value) {
                                    h[k] = `'${value}'`;
                                }
                            })

                            maquinasQuery += `
                                INSERT INTO parte_maquinas (${Object.keys(h).toString()})
                                VALUES
                                (${Object.values(h).toString()});
                            `
                            return h;
                        });

                        const setNewmaquinasPromise = new Promise((resolve) => {
                            const setNewHora = dbEX.run(maquinasQuery);
                            resolve(setNewHora);
                        });

                        setNewmaquinasPromise.then((value:any) => {
                            console.log('Done: ', value);
                        }).catch(() => {
                            console.log('maquinas');
                        })
                    });
                }
            });

        } catch (e) {
            throw new Error(`setDuplicateWorkorder failed: ${e}`);
        }

    }


    async function setWorkorderMaterials_workorder(datos:any, store:any) {
        const { query, dbEX } = datos;

        try {
            store.counterQueue++;
            let imageId:any;

            if (query[1].trim().length > 0) {

                const promesa = new Promise((resolve) => {
                    const imagen = dbEX.query(query[1]);
                    resolve(imagen);
                });
                promesa.then((value:any) => {

                    if (value.values) imageId = value.values[0]['last_insert_rowid()'];

                    const promesaMaterial = new Promise((resolve) => {
                        const parte = dbEX.query(query[0]);
                        resolve(parte);
                    });
                    promesaMaterial.then(async (value:any) => {
                        updateWorkorderMaterials(value, imageId, dbEX);
                    });
                });
            } else {
                const promesa = new Promise((resolve) => {
                    const parte = dbEX.run(query[0]);
                    resolve(parte);
                });
                return promesa.then(async() => {
                    console.log('Done');
                });
            }


        } catch (e) {
            throw new Error(`setWorkorderMaterials failed: ${e}`);
        }

    }

    async function deleteWorkorderMaterial_workorder(datos:any, store:any) {
        const { query, dbEX } = datos;

        try {

            const promesa = new Promise((resolve) => {
                const parte = dbEX.run(query);
                resolve(parte);
            });
            return promesa.then(async(value:any) => {
                store.counterQueue++;
                console.log('Done', value);
            });


        } catch (e) {
            throw new Error(`deleteWorkorderMaterial failed: ${e}`);

        }

    }

    // VIEW -> ITEM
    async function cambiarActivo_item(datos:any, store:any) {
        const { query, dbEX } = datos;


        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.query(query);
                resolve(parte);
            });
            return promesa.then((value:any) => {
                store.counterQueue++;
                store.item_asset = value.values[0];
            });

        } catch (e) {
            throw new Error(`cambiarActivo failed: ${e}`);

        }

    }

    async function setActiveFaq_item(datos:any, store:any) {
        const { query, dbEX } = datos;


        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.run(query);
                resolve(parte);
            });
            return promesa.then(() => {
                store.counterQueue++;
            });

        } catch (e) {
            throw new Error(`setActiveFaq failed: ${e}`);

        }

    }

    async function saveBack_item(datos:any, store:any) {
        const { query, dbEX } = datos;

        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.run(query);
                resolve(parte);
            });
            return promesa.then(() => {
                store.counterQueue++;
            });

        } catch (e) {
            throw new Error(`saveBack failed: ${e}`);

        }

    }

    async function setAnomaly_item(datos:any, store:any) {
        const { query, dbEX } = datos;


        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.run(query);
                resolve(parte);
            });
            return promesa.then(() => {
                store.counterQueue++;
            });

        } catch (e) {
            throw new Error(`setAnomaly failed: ${e}`);

        }

    }

    async function storeTimes_item(datos:any, store:any) {
        const { query, dbEX } = datos;


        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.query(query);
                resolve(parte);
            });
            return promesa.then((value:any) => {
                store.counterQueue++;
                store.totalTimes = value.values[0].tiempo_total;
            });

        } catch (e) {
            throw new Error(`storeTimes failed: ${e}`);

        }

    }

    async function setFaqArticles_item(datos:any, store:any) {
        const { query, dbEX } = datos;

        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.run(query);
                resolve(parte);
            });
            return promesa.then(() => {

                store.counterQueue++;
            });

        } catch (e) {
            throw new Error(`setFaqArticles failed: ${e}`);

        }

    }

    async function setUpdateParteMaquinaChecklist(datos:any, store:any) {
        const { query, dbEX } = datos;

        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.run(query);
                resolve(parte);
            });
            return promesa.then(() => {

                store.counterQueue++;
            });

        } catch (e) {
            throw new Error(`setUpdateParteMaquinaChecklist failed: ${e}`);

        }

    }

    // FUNCIONES DEPENDIENTES
    async function setWorkorderActiveImage_workorder(datos:any, store:any, maquinaID:any = 0) {
        const { dbEX } = datos;
        let { query } = datos

        try {
            if (maquinaID > 0) {
                query = query.replace('id_maquina?', maquinaID);

                // SET NEW WO DATA
                const promesa = new Promise((resolve) => {
                    const maquinaImagen = dbEX.query(query);
                    resolve(maquinaImagen);
                });
                return promesa.then(() => {
                });
            } else {
                throw new Error("setWorkorderActiveImage: No maquina ID");
            }

        } catch (e) {
            throw new Error(`setWorkorderActiveImage failed: ${e}`);

        }

    }
    async function setWorkorderActiveSync_workorder(datos:any, store:any) {
        const { dbEX } = datos;
        const { query } = datos

        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const maquinaImagen = dbEX.run(query);
                resolve(maquinaImagen);
            });
            return promesa.then(() => {
                store.counterQueue++;
            });

        } catch (e) {
            throw new Error(`setWorkorderActiveSync failed: ${e}`);

        }

    }
    async function updateWorkorderMaterials(value:any, imageId:any, dbEX:any) {
        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const parte = dbEX.run(`UPDATE parte_materiales SET id_imagen=${imageId} WHERE rowid=${value.values[0]['last_insert_rowid()'] || 0}`);
                resolve(parte);
            });
            return promesa.then(() => {
            });

        } catch (e) {
            throw new Error(`updateWorkorderMaterials failed: ${e}`);

        }

    }
    async function setCreateJornada(datos:any, store:any) {
        const { dbEX } = datos;
        const { query } = datos
        
        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const jornada = dbEX.run(query, []);
                resolve(jornada);
            });
            return promesa.then((v:any) => {
                if (store.status) store.counterQueue++;

                const jornadaHoras = new Promise((resolve) => {
                    const row = dbEX.query(`SELECT * FROM jornada_horas WHERE rowid=${v.changes.lastId}`);
                    resolve(row);
                });
                jornadaHoras.then((value:any) => {
                    const jornada = value.values.at(0)
                    store.jornada_horas = jornada;

                })
            });

        } catch (e) {
            throw new Error(`setCreateJornada failed: ${e}`);

        }

    }
    async function setJornada_workorder(datos:any, store:any) {
        const { dbEX } = datos;
        const queryT = datos.query[1];
        const id_jornada = datos.query[0];

        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const jornada = dbEX.run(queryT, [], false);
                resolve(jornada);
            });
            return promesa.then(() => {
                
                store.counterQueue++;

                const jornadaHoras = new Promise((resolve) => {
                    const row = dbEX.query(`SELECT * FROM jornada_horas WHERE id=${id_jornada}`);
                    resolve(row);
                });
                jornadaHoras.then((value:any) => {
                    const jornada = value.values.at(0)
                    store.jornada_horas = jornada;

                })
            });

        } catch (e) {
            throw new Error(`setJornada_workorder failed: ${e}`);

        }

    }
    async function setJornada_vehiculo_workorder(datos:any, store:any) {
        const { dbEX } = datos;
        const queryVehiculo = datos.query[2];
        const queryT = datos.query[1];
        const id_jornada = datos.query[0];

        try {
            // SET NEW WO DATA
            const promesa = new Promise((resolve) => {
                const jornada = dbEX.run(queryT, [], false);
                resolve(jornada);
            });
            return promesa.then(() => {
                
                store.counterQueue++;

                const jornadaHoras = new Promise((resolve) => {
                    const row = dbEX.query(`SELECT * FROM jornada_horas WHERE id=${id_jornada}`);
                    resolve(row);
                });
                jornadaHoras.then((value:any) => {
                    const jornada = value.values.at(0)
                    store.jornada_horas = jornada;

                    const vehiculoHoras = new Promise((resolve) => {
                        const row = dbEX.run(queryVehiculo, [], false);
                        resolve(row);
                    });

                    vehiculoHoras.then(() => {
                        // ...
                    });

                })
            });

        } catch (e) {
            throw new Error(`setJornada_workorder failed: ${e}`);

        }

    }
    async function setEditWorkedDay_workeddays(datos:any, store:any) {
        const { dbEX, query } = datos;
        
        try {
            const promesa = new Promise((resolve) => {
                const jornada = dbEX.run(query, [], false);
                
                resolve(jornada);
            });
            return promesa.then(() => {
                store.counterQueue++;
            });

        } catch (e) {
            console.error(e);
            throw new Error(`setJornada_workorder failed: ${e}`);
            

        }

    }
}