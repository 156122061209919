export class DataProcessingController {
  /**
   * Convierte datos para almacenarlos en la base de datos.
   */
  public processData(data: any): Record<string, string | number | null> {
    return data && Object.entries(data).reduce((acc: any, [key, value]) => {
      if (value === undefined) {
        acc[key] = null; // Convertir undefined a null
      } else if (typeof value === 'object' && value !== null) {
        acc[key] = `'${JSON.stringify(value).replace(/'/g, "''")}'`; // Convertir objeto a string JSON y escapar comillas simples
      } else if (typeof value === 'string') {
        acc[key] = `'${value.replace(/'/g, "''")}'`; // Manejar strings, escapar comillas simples
      } else if (value === null || value === '') {
        acc[key] = 'NULL'; // Convertir null y string vacío a SQL NULL
      } else {
        acc[key] = value; // Usar número tal cual
      }
      return acc;
    }, {} as Record<string, string | number | null>);
  }

  /**
   * Restaura los tipos de datos originales al recuperar datos de la base de datos.
   */
  public restoreData(data: any): Record<string, any> {
    return data && Object.entries(data).reduce((acc: any, [key, value]) => { 
      if (value === null) {
        acc[key] = null; // SQL NULL se mantiene como null
      } else if (typeof value === 'string' && (value.startsWith('{') || value.startsWith('['))) {
        try {
            acc[key] = JSON.parse(value); // Intentar parsear strings JSON
        } catch (e) {
            acc[key] = value; // Si falla, mantener como string
        }
      } else {
        acc[key] = value; // Dejar otros valores tal cual
      }
      return acc;
    }, {} as Record<string, any>);
  }

  // NOTE: Not used yet in Offline
  public async base64toBlob(b64Data: string, contentType = 'image/png', sliceSize = 512): Promise<Blob> {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});

        return blob;
    }
}
