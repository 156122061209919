import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { style: {"font-weight":"500"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createBlock(_component_ion_modal, {
    "is-open": _ctx.modelValue,
    onDidDismiss: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update:modelValue', false))),
    initialBreakpoint: 0.95
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', false)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('Cancelar')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(_ctx.content.title)), 1)
                ]),
                _: 1
              }),
              (_ctx.content.multiple)
                ? (_openBlock(), _createBlock(_component_ion_buttons, {
                    key: 0,
                    slot: "end"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, { onClick: _ctx.setOption }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('Seleccionar')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, {
        class: "ion-padding",
        style: {"height":"calc(100% - 80px)"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_searchbar, {
            class: "defaultSearchBar",
            placeholder: _ctx.$t('Buscar elemento...'),
            modelValue: _ctx.search,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
            onIonInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('search', _ctx.search))),
            debounce: 500
          }, null, 8, ["placeholder", "modelValue"]),
          (_ctx.content?.sections?.length)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.content.sections, (section, i) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: `section-${i}`
                }, [
                  _createElementVNode("h5", null, _toDisplayString(_ctx.$t(section.title)), 1),
                  _createVNode(_component_ion_list, { style: {"border-radius":"15px"} }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data[section.key], (item, key) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: `section-${key}`,
                          onClick: ($event: any) => (_ctx.setOption(item))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { style: {"white-space":"normal"} }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.structure, (s, i) => {
                                  return (_openBlock(), _createBlock(_resolveDynamicComponent(s.tag), _mergeProps({
                                    key: `tag-${i}`,
                                    ref_for: true
                                  }, s.props), {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(+s['key']?.length ?
                            item[s['key']]
                            : '-'), 1)
                                    ]),
                                    _: 2
                                  }, 1040))
                                }), 128))
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024)
                ], 64))
              }), 128))
            : (!_ctx.content?.sections?.length)
              ? (_openBlock(), _createBlock(_component_ion_list, {
                  key: 1,
                  style: {"border-radius":"15px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, {
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('done', null)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createElementVNode("h3", _hoisted_1, "– " + _toDisplayString(_ctx.$t(_ctx.content.title)) + " –", 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (_ctx.data?.length)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.data, (item, key) => {
                          return (_openBlock(), _createBlock(_component_ion_item, {
                            key: `item-${key}`,
                            onClick: ($event: any) => (!_ctx.content.multiple && _ctx.setOption(item))
                          }, {
                            default: _withCtx(() => [
                              (_ctx.content.multiple)
                                ? (_openBlock(), _createBlock(_component_ion_checkbox, {
                                    key: 0,
                                    slot: "start",
                                    modelValue: item.active,
                                    "onUpdate:modelValue": ($event: any) => ((item.active) = $event)
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                : _createCommentVNode("", true),
                              _createVNode(_component_ion_label, { style: {"white-space":"normal"} }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.structure, (s, i) => {
                                    return (_openBlock(), _createBlock(_resolveDynamicComponent(s.tag), _mergeProps({
                                      key: `tag-${i}`,
                                      ref_for: true
                                    }, s.props, { class: ['wrap-text'] }), {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(+s['key']?.length ?
                          item[s['key']]
                          : '-'), 1)
                                      ]),
                                      _: 2
                                    }, 1040))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      : (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", null, _toDisplayString(_ctx.$t(_ctx.content.empty_title)), 1)
                          ]),
                          _: 1
                        }))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
          (_ctx.paginated)
            ? (_openBlock(), _createBlock(_component_ion_infinite_scroll, {
                key: 2,
                threshold: "100px",
                id: "infinite-scroll",
                onIonInfinite: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('load', $event)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_infinite_scroll_content, {
                    "loading-spinner": "bubbles",
                    "loading-text": _ctx.$t('Cargando más...')
                  }, null, 8, ["loading-text"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-open"]))
}