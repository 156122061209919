// OfflineModule.ts

// Capacitor API
import { Capacitor } from '@capacitor/core';

// Network API
import { Network } from '@capacitor/network';

// ErrorController
import { ErrorController } from '@/utils/OfflineModule/ErrorController';

// SyncController
import { SyncController } from '@/utils/OfflineModule/SyncController';

// DataProcessingController
import { DataProcessingController } from '@/utils/OfflineModule/DataProcessingController';

// CapacitorSQLite API
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { defineCustomElements as jeepSqlite } from "jeep-sqlite/loader";
import { CapacitorSQLite, SQLiteDBConnection, SQLiteConnection } from '@capacitor-community/sqlite';

// STORES
import { useOfflineStore } from '@/stores/offline';

export class OfflineModule {
    private static instance: OfflineModule;

    private db: SQLiteDBConnection | null = null;
    private dbConnectionName: string = 'gmaoTecnicos';
    private sqlite: SQLiteConnection;

    private errorController: ErrorController;

    public syncController!: SyncController;

    private dataProcessingController!: DataProcessingController;

    private Network = Network;
    public connectionStatus: boolean | undefined;

    public offline: any = useOfflineStore();

    // Mapa para rastrear las tablas en proceso de alteración
    private tableLocks: Map<string, Promise<void>> = new Map();

    /** FUNCIONAMIENTO OFFLINE
     * MODULO COMO SKELETON - UNA UNICA INSTANCIA

     * - LOGIN -> Al crear la primera instancia tenemos que llevar con esto
            lo necesario para que la aplicacion esté preparada en caso de que se pierda la conexion.
            + Creamos la única instancia
                - Aqui necesitamos establecer una conexion con la BD y preparar
                    el entorno local con los datos de produccion.
                - Despues de terminar debemos cerrar la conexion.
                    -> TODO: Esto es principalmente para no estar gastando procesos a lo tonto.
                        De esta forma, solo la activaremos cuando entraremos al modo offline.
     * - El Resto -> Vamos a reestablecer la conexion una vez nos pongamos en modo offline.
            Con esto, no habrá que volver a activarla desde cada funcion...
     */


    // TODO: Añadir funcion para cerrar conexión con la BD despues de un tiempo...
    /** SINGLETON APPROACH
     * To be described in more details...

     * Singleton means only one instance per app life cycle.
     * Constructor is going to be executed only at the begging -> we set all initial actions needed

     */
    private constructor () {
        console.log('Constructor!');

        this.sqlite  = new SQLiteConnection(CapacitorSQLite);

        this.errorController = new ErrorController();

        this.syncController = new SyncController();

        this.dataProcessingController = new DataProcessingController();

        this.initDBConnection().then(async () => {
            // We add an event to check the Network API
            this.checkConnection();

            // We download all production data to local.
            await this.getStartData();

            // After all we have to close the connection to the DB.
            this.db?.close();
        });
    }

    /**
     * Obtiene la instancia única de la clase OfflineModule.
     * 
     * Este método sigue el patrón Singleton para garantizar que solo exista una instancia
     * de OfflineModule durante el ciclo de vida de la aplicación. Si la instancia no existe,
     * se crea automáticamente.
     * 
     * @returns La instancia única de OfflineModule.
     */
    public static getInstance(): OfflineModule {
        if (!OfflineModule.instance) OfflineModule.instance = new OfflineModule();

        return OfflineModule.instance;
    }

    /**
     * Monitorea el estado de la conexión de red y ajusta el comportamiento del modo offline.
     * 
     * Este método registra un listener para los cambios en el estado de la red. Cuando no hay conexión,
     * inicializa la base de datos y vacía el almacén de sincronización. Si hay conexión y existen
     * cambios en la cola de sincronización, ejecuta el proceso de sincronización.
     * 
     * - Cuando la red está desconectada:
     *   - Abre la conexión con la base de datos local.
     *   - Vacía el almacén de datos sincronizados.
     * 
     * - Cuando la red está conectada:
     *   - Sincroniza los cambios pendientes con el servidor.
     *   - Captura y gestiona errores durante la sincronización.
     * 
     * @throws Error - Captura errores en el controlador de errores y permite la continuidad del proceso.
     */
    checkConnection() {
        this.Network.addListener('networkStatusChange', async (status) => {
            console.log('*** Network status changed', status);

            this.connectionStatus = !status.connected;
            this.offline.status = !status.connected;
            
            /** OPEN CONNECTION DE DB WHEN WE GOT OFFLINE=TRUE */
            if (!status.connected) {
                this.initDBConnection();
                this.syncController.emptyStore();

                // TODO: Cargar los componentes del parte...
            }
            
            /** TODO: VAMOS A MANTENERLA ABIERTA HASTA QUE ACABE EL MODO OFFLINE
             * Aunque eso depende porque pueden haber cambios... FIXME: PENSARLO...
             */

            if (status.connected && this.offline.counterQueue > 0) {

                const promesa = new Promise((resolve) => {
                    const fun = this.syncController.syncChanges();
                        resolve(fun);
                });

                promesa.catch((err) => {
                    // TODO: ErrorController -> show a message to user
                    // this.app?.appContext.config.globalProperties?.$openToastObject(
                    //     'Ha habido un error al sincronizar',
                    //     'Ha ocurrido un error en el servidor'
                    // );
                    console.error(err);
                    if (this.offline.loading?.presented) this.offline.loading.dismiss()
                });

            }
        });
    }

    /**
     * Inicializa la conexión con la base de datos SQLite.
     * 
     * Este método verifica la consistencia de las conexiones existentes y reutiliza
     * una conexión activa si está disponible. Si no existe una conexión activa, se crea una nueva.
     * Una vez abierta, permite realizar operaciones sobre la base de datos.
     *
     * @throws Error - Captura y registra errores en el controlador de errores si la inicialización falla.
     */
    public async initDBConnection() {
        await this.loadDOMEvent();
        /** TODO: No permitir más de un hilo de ejecucion */

        try {
            // FIXME: Distinguir casos de lectura y escritura sobre la BD
            const connectionConsistency = await this.sqlite.checkConnectionsConsistency();
            const isThereConnection = await this.sqlite.isConnection("gmaoTecnicos", false);

            if (connectionConsistency.result && isThereConnection.result) {
                this.db = await this.sqlite.retrieveConnection("gmaoTecnicos", false);
            } else {
                this.db = await this.sqlite.createConnection(this.dbConnectionName, false, 'no-encryption', 1, false);
            }

            await this.db.open();

            /**
             * En este punto está abierta la conexion con la BD
             * Y podemos realizar operaciones sobre ella.
             */

        } catch (error) {
            console.error('Error initializing database:', error);
            // TODO: ErrorController -> añadir error a la cola...
        }
    }

    /**
     * Construye la base de datos SQLite a partir de un conjunto de esquemas.
     * 
     * Este método ejecuta los comandos necesarios para crear las tablas en la base de datos
     * según los esquemas proporcionados. Si no hay esquemas disponibles, lanza una excepción.
     *
     * @param schemas - Un array de objetos que contienen las declaraciones de creación de tablas.
     * 
     * @throws Error - Captura y registra errores en el controlador de errores si la construcción falla.
     */
    async buildSQLiteDB (schemas: Array<any>) {
        if (!schemas.length) {new Error('No existe usuario')} // TODO: ErrorController Lanzamos excepcion...
        const statements = schemas.map((s) => s['Create Table']).join('|').replaceAll('|', '');

        try {
            await this.db?.execute(statements);
        } catch (error) {
            // TODO: ErrorController -> añadir error a la cola...
        }
    }

    /**
     * Carga los eventos del DOM necesarios para inicializar SQLite en diferentes plataformas.
     * 
     * Este método configura y prepara el entorno de SQLite para funcionar correctamente en navegadores,
     * Android e iOS. Realiza la inicialización de los elementos personalizados para compatibilidad con WebAssembly
     * en el navegador y gestiona permisos en dispositivos móviles.
     * 
     * @returns Una promesa que resuelve cuando la inicialización está completa.
     *
     * @throws Error - Captura y registra errores si la inicialización de la plataforma falla.
     */
    async loadDOMEvent(): Promise<any> {
        await defineCustomElements(window).then(() => {
            jeepSqlite(window);
        });

        const def = async () => {
            try {
                const platform = Capacitor.getPlatform();
                if (platform === "web") {
                    console.log('Initializing SQLite for Web...');
                    const jeepSqlite = document.createElement('jeep-sqlite');
                    jeepSqlite.setAttribute("wasmpath", "assets");
                    document.body.appendChild(jeepSqlite);

                    await customElements.whenDefined('jeep-sqlite');
                    await this.sqlite.initWebStore();
                    console.log('Web store initialized successfully');
                }

                    if (platform === 'android') {
                    try {
                        await (CapacitorSQLite as any).requestPermissions();
                    } catch (e) {
                        console.error('Failed to get Android permissions:', e);
                    }
                }

                if (platform === 'ios') {
                    // Agrega permisos o inicialización específica de iOS si es necesario.
                }
            } catch (e:any) {
                // TODO: ErrorController

                console.error('ERROR: ', e);
            }
        };

        return new Promise((resolve) => {
            if (document.readyState === "loading") {
                resolve(window.addEventListener('DOMContentLoaded', def));
            } else {
                resolve(def());
            }
        })
    }

    /**
     * Descarga los datos iniciales necesarios para la sincronización desde el servidor de producción.
     * 
     * Este método invoca la lógica del controlador de sincronización para descargar y preparar
     * los datos iniciales que se utilizarán en el entorno local.
     * 
     * @returns Una promesa que resuelve cuando los datos se han descargado y sincronizado.
     */
    async getStartData() {
        return await this.syncController.downloadProduction();
    }

    /** TODO: Gestion de modulos disponibles en OFFLINE
     * La idea es sencilla. Tendremos un listado que traeremos desde el servidor, donde saldrán todos los modulos
     *  que están disponibles en offline.
     * Los que estan disponibles -> se mostrarán de forma normal
     * Si NO estan -> Mostraremos un mensaje de que no está disponible.
     */


    /**
     * Procesa los datos recibidos de una respuesta de API y los inserta en la tabla de la base de datos local.
     * Si los datos incluyen modificaciones de esquema, delega esta tarea a `alterDBStructure`.
     *
     * @param table - Nombre de la tabla en la que se insertarán los datos.
     * @param data - Datos a insertar. Puede ser:
     *   - Un objeto individual.
     *   - Un array de objetos.
     *   - Un objeto con claves adicionales como `query` o `relations`.
     */
    async setInitialData(table: string, data: any): Promise<void> {
        console.log(`Table: ${table}`);

        const isArray: boolean = Array.isArray(data);

        if (isArray) {
            for (const record of data) {
                // Intenta modificar la estructura de la tabla si es necesario
                const alterTableResult = await this.alterDBStructure(table, record);

                // Si la estructura fue alterada, detiene el proceso
                if (alterTableResult) return;

                // Procesa y genera las consultas de inserción
                const processedData = this.dataProcessingController.processData(record);
                const columnsData = Object.keys(processedData).join(', ');
                const values = Object.values(processedData).join(', ');

                try {
                    // Inserta los datos procesados en la tabla
                    await this.db?.query(`INSERT OR IGNORE INTO ${table} (${columnsData.toString()}) VALUES (${values.toString()})`);
                } catch (error) {
                    console.error(`Error al insertar en la tabla "${table}" para el registro:`, record, error);
                    this.errorController.captureError(error as Error, `Error - Array`);
                    break; // Continúa con el siguiente registro en caso de error
                }
            }
        } else {

            // Caso para datos individuales
            const alterTableResult = await this.alterDBStructure(table, data);

            if (alterTableResult) return;

            const processedData = this.dataProcessingController.processData(data);
            const columnsData = Object.keys(processedData).join(', ');
            const values = Object.values(processedData).join(', ');

            try {
                // Inserta el dato procesado en la tabla
                await this.db?.query(`INSERT OR IGNORE INTO ${table} (${columnsData.toString()}) VALUES (${values.toString()})`);
            } catch (error) {
                console.error(`Error al insertar en la tabla "${table}" para el objeto:`, data);
                this.errorController.captureError(error as Error, `Error - Object`);
            }
        }

    }

    /**
     * Modifica la estructura de la base de datos local para agregar columnas adicionales
     * y sincroniza los datos relacionados con la tabla.
     *
     * @param table - El nombre de la tabla donde se aplicarán las modificaciones.
     * @param data - Objeto que contiene:
     *   - `query`: Consulta SQL para modificar la estructura de la tabla.
     *   - `data`: Array de objetos que representan los registros a insertar o actualizar.
     * @returns Una promesa que resuelve a `true` si las modificaciones se realizaron correctamente, o a `false` si no había modificaciones que realizar.
     */
    async alterDBStructure(
        table: string,
        data: { query?: string; data?: any[]; relations?: any }
    ): Promise<boolean> {
        const alterDBQuery = data?.query;
        const queryData = data?.data;
        const relations = data?.relations;

        /** NOTE: Si el objeto NO trae query, no incluyo modificaciones sobre la BD. */
        if (!alterDBQuery || !('query' in data)) return false;

        /** Verificar y agregar columnas necesarias */
        try {
            // Obtener columnas existentes
            const existingColumns = await this.findExistingColumns(table);

            // Extraer las columnas de query
            const columnsToAdd = alterDBQuery
                .split(';')
                .map((query) => {
                    const match = query.match(/ADD COLUMN\s+(\w+)/i);
                    return match ? match[1] : null; // Extraer el nombre de la columna
                })
                .filter((column): column is string => !!column) // Filtrar valores nulos
                .filter((column) => !existingColumns.includes(column)); // Excluir columnas ya existentes

            if (columnsToAdd.length > 0) {
                for (const column of columnsToAdd) {
                    try {
                        // console.log(`Agregando columna "${column}" a la tabla "${table}".`);
                        const alterQuery = `ALTER TABLE ${table} ADD COLUMN ${column} TEXT NULL;`;
                        await this.db?.execute(alterQuery, true);
                    } catch (error:any) {
                        if (error.message.includes("duplicate column name")) {
                            console.warn(`La columna "${column}" ya existe en la tabla "${table}".`);
                        } else {
                            console.error(`Error al agregar columna "${column}" a la tabla "${table}":`, error);
                            throw error; // Re-lanzar si el error no es de duplicados
                        }
                    }
                }
            } else {
                console.log(`No hay columnas nuevas para agregar a la tabla "${table}".`);
            }
        } catch (error) {
            console.error(`Error al procesar columnas para la tabla "${table}" con data:`, data);
            console.error(error);
            // TODO: ErrorController -> Registrar error en la cola de errores.
            return false;
        }
        /** Procesar los datos asociados */
        queryData?.forEach(async (record: any) => {
            const processedData = this.dataProcessingController.processData(record);

            const columnsData = Object.keys(processedData).join(', ');
            const values = Object.values(processedData).join(', ');
                
            try {
                // Inserta o actualiza los datos en la tabla.
                await this.db?.query(
                    `INSERT OR REPLACE INTO ${table} (${columnsData}) VALUES (${values});`
                );
            } catch (error) {
                console.error(`Error al insertar/actualizar en la tabla "${table}" para el registro:`, record);
                console.error(error);
                // TODO: ErrorController -> Registrar error en la cola de errores.
            }

            /** Sincronizar relaciones */
            if (relations) {
                try {
                    await this.syncRelationships(relations, record);
                } catch (error) {
                    console.error(`Error al sincronizar relaciones para la tabla "${table}":`, processedData);
                    this.errorController.captureError(error as Error, `Error - Sync Relationships`);
                }
            }
        });

        return true;
    }

    /**
     * Sincroniza las relaciones de un modelo con la base de datos local.
     * 
     * Este método procesa las relaciones asociadas a un modelo y sincroniza los datos en las tablas correspondientes.
     * Maneja relaciones de diferentes tipos, incluyendo arrays, objetos únicos y valores primitivos. Además, tiene un 
     * manejo especial para la relación "maquinas", donde se desestructura la clave `pivot` y se inserta en la tabla intermedia.
     *
     * @param relations - Un objeto donde las claves son nombres de las relaciones y los valores son nombres de las tablas asociadas.
     * @param data - Un objeto que contiene los datos principales junto con las relaciones a sincronizar.
     *
     * @throws Error - Captura y registra errores durante el proceso de sincronización en el controlador de errores.
     */
    async syncRelationships(
        relations: Record<string, string>, 
        data: Record<string, any>
    ): Promise<void> {
        for (const [relationKey, tableName] of Object.entries(relations)) {
            if (Object.prototype.hasOwnProperty.call(data, relationKey)) {
                const relatedData = data[relationKey];
                
                if (!relatedData?.length) continue;
                
                /** NOTE: Casos especiales de importación
                 * Al final tenemos unos casos como maquinas, lineas, materiales Add...
                 * Que necesitan mapearse de forma diferente a la habitual.
                 */
                if (tableName == 'almacen_articulos' && relationKey == 'almacenes') {
                    // Manejo especial para el caso de "maquinas"
                    try {
                        // console.log(typeof restoredData[relationKey], restoredData[relationKey]);
                        
                        const pivotData = relatedData.map((material: any) => {
                            if (material.pivot) {
                                const pivot = { ...material.pivot };
                                delete material.pivot; // Removemos pivot para evitar problemas con la inserción de máquinas
                                return {...material, ...pivot}; // Retornamos únicamente el pivot
                            }
                            return null; // Filtramos los casos donde no hay pivot
                        }).filter(Boolean); // Eliminamos valores nulos

                        // Insertar datos del pivot en la tabla intermedia
                        await this.safeInsertOrUpdate("almacen_articulos", pivotData);
                    } catch (error) {
                        console.error(`Error al procesar pivots para "maquinas" en la tabla "parte_maquinas":`, relatedData, error);
                        this.errorController.captureError(error as Error, `Sync Relationships - Pivot Error`);
                    }
                    continue;
                }
                if (relationKey === "maquinas") {
                    // Manejo especial para el caso de "maquinas"
                    try {
                        // console.log(typeof restoredData[relationKey], restoredData[relationKey]);
                        
                        const pivotData = relatedData.map((machine: any) => {
                            if (machine.pivot) {
                                const pivot = { ...machine.pivot };
                                delete machine.pivot; // Removemos pivot para evitar problemas con la inserción de máquinas
                                return {...machine, ...pivot}; // Retornamos únicamente el pivot
                            }
                            return null; // Filtramos los casos donde no hay pivot
                        }).filter(Boolean); // Eliminamos valores nulos

                        // Insertar datos del pivot en la tabla intermedia
                        await this.safeInsertOrUpdate("parte_maquinas", pivotData);
                    } catch (error) {
                        console.error(`Error al procesar pivots para "maquinas" en la tabla "parte_maquinas":`, relatedData, error);
                        this.errorController.captureError(error as Error, `Sync Relationships - Pivot Error`);
                    }
                    continue;
                }
                if (relationKey === "lineas") {
                    // Manejo especial para el caso de "maquinas"
                    try {
                        // Insertar datos del pivot en la tabla intermedia
                        await this.safeInsertOrUpdate("parte_materiales", relatedData);
                    } catch (error) {
                        console.error(`Error al procesar pivots para "maquinas" en la tabla "parte_maquinas":`, relatedData, error);
                        this.errorController.captureError(error as Error, `Sync Relationships - Pivot Error`);
                    }
                    continue;
                }
                if (relationKey === "materiales") continue;

                if (Array.isArray(relatedData)) {
                    // Relación con un array de datos
                    try {
                        await this.safeInsertOrUpdate(tableName, relatedData);
                    } catch (error) {
                        console.error(`Error al sincronizar relaciones (array) en la tabla "${tableName}":`, relatedData);
                        this.errorController.captureError(error as Error, `Sync Relationships - Array Error`);
                    }
                } else if (typeof relatedData === "object" && relatedData !== null) {
                    // Relación con un objeto único
                    try {
                        await this.safeInsertOrUpdate(tableName, [relatedData]); // Convertimos a array para reutilizar safeInsertOrUpdate
                    } catch (error) {
                        console.error(`Error al sincronizar relaciones (objeto) en la tabla "${tableName}":`, relatedData);
                        this.errorController.captureError(error as Error, `Sync Relationships - Object Error`);
                    }
                } else {
                    // Relación con un valor primitivo
                    try {
                        const primitiveData = [{ value: relatedData }];
                        await this.safeInsertOrUpdate(tableName, primitiveData);
                    } catch (error) {
                        console.error(`Error al sincronizar relaciones (primitivo) en la tabla "${tableName}":`, relatedData);
                        this.errorController.captureError(error as Error, `Sync Relationships - Primitive Error`);
                    }
                }
            
            }
        }
    }

    /**
     * Ejecuta una consulta SQL en la base de datos SQLite.
     * 
     * Este método ejecuta consultas SQL personalizadas con parámetros opcionales. Asegura que la base de datos 
     * esté inicializada antes de ejecutar la consulta. Maneja errores y permite registrar fallos en el controlador de errores.
     *
     * @param query - La consulta SQL a ejecutar.
     * @param params - Opcional. Un array de parámetros a utilizar en la consulta.
     * @returns Una promesa que resuelve con los resultados de la consulta o `undefined` si ocurre un error.
     *
     * @throws Error - Captura errores relacionados con la ejecución de la consulta.
     */
    async executeQuery(query: string, params: any[] = []): Promise<any> {
        if (!this.db) {
            console.error('Database connection is not initialized.');
            return;
        }
        try {
            const result = await this.db.query(query, params);
            return result;
        } catch (error) {
            console.error('Error executing query:', error);
            // TODO: ErrorController -> añadir error a la cola...
            // Manejo de errores
        }
    }

    /**
     * Abre una conexión con la base de datos SQLite.
     * 
     * La función verifica la consistencia de las conexiones existentes y reutiliza
     * una conexión activa si está disponible. Si no existe, se crea una nueva conexión.
     * Una vez abierta, la conexión permite realizar operaciones sobre la base de datos.
     *
     * @throws Error - Si ocurre algún problema al abrir la conexión.
     */
    public async openDBConnection() {
        try {
            // FIXME: Distinguir casos de lectura y escritura sobre la BD
            const connectionConsistency = await this.sqlite.checkConnectionsConsistency();
            const isThereConnection = await this.sqlite.isConnection("gmaoTecnicos", false);

            if (connectionConsistency.result && isThereConnection.result) {
                this.db = await this.sqlite.retrieveConnection("gmaoTecnicos", false);
            } else {
                this.db = await this.sqlite.createConnection(this.dbConnectionName, false, 'no-encryption', 1, false);
            }

            await this.db.open();

            /**
             * En este punto está abierta la conexion con la BD
             * Y podemos realizar operaciones sobre ella.
             */

        } catch (error) {
            console.error('Error opening database connection:', error);
            // TODO: ErrorController -> añadir error a la cola...
        }
    }

    /**
     * Cierra la conexión actual con la base de datos SQLite.
     * 
     * Este método asegura que cualquier conexión abierta con la base de datos se cierre correctamente.
     * Es útil para liberar recursos y evitar mantener conexiones abiertas innecesariamente.
     *
     * @throws Error - Si ocurre algún problema al cerrar la conexión.
     */
    public async closeDBConnection() {
        await this.db?.close();
    }

    /**
     * Inserta o actualiza datos en una tabla de SQLite, con verificación de existencia y manejo de errores.
     * @param tableName Nombre de la tabla en la que se insertarán o actualizarán los datos.
     * @param data Array de objetos que contienen los datos a insertar o actualizar.
     */
    private async safeInsertOrUpdate(tableName: string, data: any[]): Promise<void> {
        if (!this.db) {
            console.error("Database connection is not initialized.");
            this.errorController.captureError(
                new Error("Database connection is not initialized."),
                `Database connection error`
            );
            return;
        }

        // Verificar si la tabla existe
        const tableExists = await this.checkIfTableExists(tableName);
        if (!tableExists) {
            console.warn(`La tabla "${tableName}" no existe.`);
            this.errorController.captureError(
                new Error(`La tabla "${tableName}" no existe.`),
                `Table Not Found`
            );
            return;
        }

        for (const record of data) {
            try {
                // Verificar si hay columnas faltantes
                const missingColumns = await this.findMissingColumns(tableName, record);
                if (missingColumns.length > 0) {
                    // console.log(`Agregando columnas faltantes a la tabla "${tableName}":`, missingColumns);
                    await this.addMissingColumns(tableName, missingColumns);
                }

                const processedData = this.dataProcessingController.processData(record);

                const columnsData = Object.keys(processedData).join(', ');
                const values = Object.values(processedData).join(', ');

                const query = `INSERT OR REPLACE INTO ${tableName} (${columnsData.toString()}) VALUES (${values.toString()});`;
                
                await this.db.query(query);

            } catch (error) {
                // Capturar errores y continuar
                // TODO: ErrorController
                console.error(`Error al insertar/actualizar en la tabla "${tableName}":`, error);
                // this.errorController.captureError(error as Error, `Insert/Update Error in table ${tableName}`);
            }
        }
    }

    /**
     * Encuentra las columnas faltantes en la tabla comparando los datos con el esquema actual de la tabla.
     *
     * @param tableName - Nombre de la tabla en la base de datos.
     * @param data - Datos a insertar o actualizar.
     * @returns Array con los nombres de las columnas faltantes.
     */
    private async findMissingColumns(tableName: string, data: Record<string, any>): Promise<string[]> {
        if (!this.db) return [];

        try {
            const result = await this.db.query(`PRAGMA table_info(${tableName});`);
            const existingColumns = result.values?.map((row: any) => row.name) || [];
            const dataColumns = Object.keys(data);

            // Encontrar columnas que están en los datos pero no en la tabla
            return dataColumns.filter((column) => !existingColumns.includes(column));
        } catch (error) {
            console.error(`Error al obtener las columnas de la tabla "${tableName}":`, error);
            this.errorController.captureError(error as Error, "Find Missing Columns Error");
            return [];
        }
    }

    /**
     * Agrega columnas faltantes a una tabla en la base de datos de manera secuencial,
     * asegurando exclusividad por tabla para evitar conflictos.
     *
     * @param tableName - Nombre de la tabla en la base de datos.
     * @param columns - Array con los nombres de las columnas faltantes.
     */
    private async addMissingColumns(tableName: string, columns: string[]): Promise<void> {
        if (!this.db) return;

        // Si ya hay un proceso de alteración para esta tabla, espera a que termine
        if (this.tableLocks.has(tableName)) {
            // console.log(`Esperando a que se complete la operación en la tabla "${tableName}"`);
            await this.tableLocks.get(tableName);
            return;
        }

        // Crear una promesa para bloquear la tabla durante la operación
        const lock = (async () => {
            try {
                for (const column of columns) {
                    // Verificar si la columna ya existe antes de intentar agregarla
                    const existingColumns = await this.findExistingColumns(tableName);
                    if (existingColumns.includes(column)) {
                        console.log(`La columna "${column}" ya existe en la tabla "${tableName}".`);
                        continue;
                    }

                    // Agregar la columna si no existe
                    // console.log(`Agregando columna "${column}" a la tabla "${tableName}".`);
                    const alterQuery = `ALTER TABLE ${tableName} ADD COLUMN ${column} TEXT;`;
                    await this.db?.query(alterQuery);
                }
            } catch (error:any) {
                if (error?.message.includes("duplicate column name")) {
                    console.warn(`Columna duplicada detectada durante la operación: ${error.message}`);
                } else {
                    console.error(`Error al agregar columnas a la tabla "${tableName}":`, error);
                    this.errorController.captureError(error as Error, "Add Missing Columns Error");
                }
            } finally {
                // Liberar el bloqueo para esta tabla
                this.tableLocks.delete(tableName);
            }
        })();

        // Almacenar el bloqueo en el mapa
        this.tableLocks.set(tableName, lock);

        // Esperar a que la operación termine
        await lock;
    }

    /**
     * Obtiene las columnas existentes en una tabla.
     *
     * @param tableName - Nombre de la tabla en la base de datos.
     * @returns Array con los nombres de las columnas existentes.
     */
    private async findExistingColumns(tableName: string): Promise<string[]> {
        if (!this.db) return [];

        try {
            const result = await this.db.query(`PRAGMA table_info(${tableName});`);
            return result.values?.map((row: any) => row.name) || [];
        } catch (error) {
            console.error(`Error al obtener las columnas de la tabla "${tableName}":`, error);
            this.errorController.captureError(error as Error, "Find Existing Columns Error");
            return [];
        }
    }

    /**
     * Comprueba si una tabla existe en la base de datos SQLite.
     * @param tableName Nombre de la tabla.
     * @returns `true` si la tabla existe, `false` en caso contrario.
     */
    private async checkIfTableExists(tableName: string): Promise<boolean> {
        if (!this.db) return false;

        const query = `
            SELECT name 
            FROM sqlite_master 
            WHERE type='table' AND name=?;
        `;
        try {
            const result: any = await this.db?.query(query, [tableName]);
            return result && result.values.length > 0;
        } catch (error) {
            console.error(`Error al verificar si la tabla "${tableName}" existe:`, error);
            this.errorController.captureError(error as Error, "Table Existence Check Error");
            return false;
        }
    }


    // =====================================================================================
    // =====================OPTIMIZE: AND MOVE TO SEPARATE CONTROLELR=======================
    // =====================================================================================
    async cacheDataToOffline(table: string, data: any) {
        console.log(table, data);
        if (!table.length && (Array.isArray(data) && !data?.length || !Object.entries(data)?.length)) {
            console.error(`Data está vacio:: ${data}`);
            return;
        }

        const promesasPila: any[] = [];

        try {
            if (table === 'partes') {
                const keysToCache = [
                {
                    relation: 'maquinas',
                    table: 'maquinas',
                    columns: []
                },
                {
                    relation: 'maquinas_respuesta',
                    table: 'modelos_parte_campos_respuestas',
                    columns: []
                },
                {
                    relation: 'materiales',
                    table: 'articulos',
                    columns: []
                },
                {
                    relation: 'sistema',
                    table: 'sistemas',
                    columns: []
                },
                ];
                let queryParte: string = '';

                this.db?.beginTransaction();

                queryParte = `
                INSERT OR REPLACE INTO partes (id, id_estado_actual, estado_actual, id_tecnico, id_direccion, id_proyecto, id_tipo, problema, fecha, created_at, updated_at)
                VALUES (
                    ${data.id},
                    ${data.id_estado_actual},
                    '${JSON.stringify(data.estado_actual)}',
                    ${data.id_tecnico},
                    ${data.id_direccion},
                    ${data.id_proyecto},
                    ${data.id_tipo},
                    '${data.problema}',
                    '${data.fecha}',
                    '${data.created_at}',
                    '${data.updated_at}'
                );
                `;

                if (queryParte.length) {
                promesasPila.push(new Promise((resolve) => {
                    const item = this.db?.execute(queryParte, false);
                    resolve(item);
                }))
                }

                (Object.keys(data) || []).forEach((k: any) => {
                const model = keysToCache.find((a) => a.relation === k);
                
                if (model) {
                    const entry = data[k];
                    if (k === 'maquinas') {
                    let queryMaquinas: string = '';
                    (entry || []).map((e:any) => {
                        delete(e.documentos);
                        delete(e.hijas);
                        delete(e.imagenes);
                        

                        const newObj = {
                        id: e.pivot.id,
                        pivot: e.pivot,
                        id_parte: e.pivot.id_parte,
                        id_maquina: e.pivot.id_maquina,
                        tareas_completadas: e.pivot.tareas_completadas,
                        id_modelo_parte: e.pivot.id_modelo_parte,
                        t_estimado: e.pivot.t_estimado,
                        orden: e.pivot.orden,
                        }

                        const columns = Object.keys(newObj)
                        const values = Object.values(newObj).map((a:any) => {
                        // Si el valor es null, undefined, una cadena vacía o una cadena que solo tiene espacios
                        if (a === null || a === undefined || (typeof a === 'string' && !a.trim().length)) {
                            return '""';
                        } 
                        // Si el valor es una cadena no vacía
                        else if (typeof a === 'string') {
                            return `"${a}"`;
                        } 
                        // Si el valor es un objeto o un array no vacío
                        else if (typeof a === 'object' && (Array.isArray(a) || Object.keys(a).length)) {
                            return `'${JSON.stringify(a)}'`;
                        }

                        // Para todos los demás valores (incluyendo números), devolver el valor tal cual
                        return a;
                        });
                        queryMaquinas = `
                        INSERT OR IGNORE INTO parte_maquinas (${columns.toString()}) VALUES
                        (${values.toString()});
                        `;
                    });

                    if (queryMaquinas.length) {
                        promesasPila.push(new Promise((resolve) => {
                        const item = this.db?.run(queryMaquinas, [], false);
                        resolve(item);
                        }));

                    }

                    const checklists = entry.map((r:any) => r.respuestas).flat();

                    let queryRespuestas: string = '';
                    
                    (checklists || []).forEach((c:any) => {
                        
                        const columns = Object.keys(c);
                        const values = Object.values(c).map((a:any) => {
                        // Si el valor es null, undefined, una cadena vacía o una cadena que solo tiene espacios
                        if (a === null || a === undefined || (typeof a === 'string' && !a.trim().length)) {
                            return '""';
                        } 
                        // Si el valor es una cadena no vacía
                        else if (typeof a === 'string') {
                            return `"${a}"`;
                        } 
                        // Si el valor es un objeto o un array no vacío
                        else if (typeof a === 'object' && (Array.isArray(a) || Object.keys(a).length)) {
                            return `'${JSON.stringify(a)}'`;
                        }

                        // Para todos los demás valores (incluyendo números), devolver el valor tal cual
                        return a;
                        });
                        

                        queryRespuestas = ` INSERT OR IGNORE INTO modelos_parte_campos_respuestas (${columns.toString()}) VALUES (${values.toString()});`;

                        if (queryRespuestas.length) {
                        promesasPila.push(new Promise((resolve) => {
                            const item = this.db?.run(queryRespuestas, [], false);
                            resolve(item);
                        }));
                        }

                    });
                    }
                }
                });

                Promise.all(promesasPila)
                .then(() => {
                    this.db?.commitTransaction();
                }).catch((e) => {
                    const error = this.ensureError(e);
                    this.db?.rollbackTransaction();
                    throw new Error('Chaching WO', { cause: error });
                })
            }

            } catch (error) {
                console.error(`Ha ocurrido un error al cachear:: ${error}`);
            } finally {
            // sqlite.closeAllConnections();
            }
    }

    ensureError (value: unknown): Error {
        if (value instanceof Error) return value

        let stringified = '[Unable to stringify the thrown value]'
        try {
            stringified = JSON.stringify(value)
        } catch { /* empty */ }

        const error = new Error(`This value was thrown as is, not through an Error: ${stringified}`)
        return error
    }

// Métodos para escritura, lectura, actualización, etc.

// Método para manejar errores

/** NOTE: Guia para etiquetas en la vista Workorder.vue
 *  Encerramos bloques de la vista Workorder:
        ==> OPTIMIZE: BLOQUE_{NOMBRE_BLOQUE}
        <== OPTIMIZE: /BLOQUE_{NOMBRE_BLOQUE}

 *  -> Bloques que no están en Offline -
    "XXX: NOT IN OFFLINE"
 */

    /** NOTE: Bloques disponibles en Workorder
     *  - Bloque de Datos
        - Bloque de Proyecto
        - Bloque de Operario adicional
        - Bloque de Cambio de operario

        - Bloque de FAQ
        - Bloque de Gamas
        - Bloque de Documentos (NOT IN OFFLINE)
        - Bloque de Dibujos (#) (NOT IN OFFLINE)
        - Bloque de Zonas (NOT IN OFFLINE)

        - REVIEW: Bloque de Activos (#)
            + Shows IF workorder.id_direccion
            + Content:
                - Boton de Finalizar todos los activos: IF comp. finalizar_todos_los_checklists || tipo_letra === 'P'
                    + Actions => setAllChecklistsDone
                    - *NEW — XXX: [POST - SET ALL CHECKLISTS] NOT IN OFFLINE
                - Items:
                    - Contador del tiempo del activo
                    - Data: ...
            + Props:
                - Sliding options: delete
            + Actions:
                - Add:
                    + Permission?
                    + Asset by QR => QRScanner (OK)
                    + New asset => MSetActivo (OK) [Sin select del checklist]
                    + From list
                        1. IF comp. selector_sistemas_parte => MSetActivosSistema (Ok)
                        2  ELSE => MSetActivos (OK)
                        - TODO: Faltan relacion con modelo y sistema – para mostrarlos en el listado...
                        - TODO: Falta un loader para indicar que estamos cargando maquinas
                        - TODO: OFFLINE NEW — FILTRAR POR ACTIVOS QUE TENGAN CONTADORES
                - Delete:
                    + Permission?
                    + => deleteWorkorderActive (OK)
                - Boton 'Ver mas' -> to WOAssets.vue + delete action (A-OK)

        - Bloque de Imagenes (#)
        - Bloque de Videos (NOT IN OFFLINE)
        - Bloque de Imagenes Incidencia (#)

        - Bloque de Solucion (#)
        - Bloque de Gastos (NOT IN OFFLINE)
        - Bloque de Materiales (#)
        - REVIEW: Bloque de Horas (#)
            + Shows IF !+gmao.comportamientos.no_horas
            + Content:
                - Items:
                    - Data: ...
            + Props:
                - ...
            + Actions:
                - Add Clocking:
                    + Permission (OK)
                    + Horas normales => MSetAddHoras
                    + TODO: Horas en bloque: IF +this.gmao.comportamientos.horas_bloque => MSetBloqueHora

                - Edit Clocking:
                    + Permission (OK)
                    + => editTimes (OK)

                - Delete:
                    + Permission (OK)
                    + => deleteWorkorderActive (OK)

                - Boton 'Ver mas' -> (local)

        - Bloque de Desplazamientos (#)
        - Bloque de Observaciones (Tec/Cli) (#)

        - Bloque de Checks (#)
        - Bloque de Firma (#)


     *  ================================ IN PROGRESS ===================================
     *  NOTE: Bloques prioritarios
        - Bloque de Horas (#)

        - Bloque de Materiales (#)
        - Bloque de Solucion (#)
        - Bloque de Observaciones (Tec/Cli) (#)
        - Bloque de Checks (#)
        - Bloque de Firma (#)
        - Bloque de Imagenes (#)
        - Bloque de Imagenes Incidencia (#)
        - Bloque de Desplazamientos (#)
        - Bloque de Dibujos (#) (NOT IN OFFLINE)

     *  NOTE: El resto de bloques
        - Bloque de Datos
        - Bloque de Proyecto
        - Bloque de Operario adicional
        - Bloque de Cambio de operario
        - Bloque de FAQ
        - Bloque de Gamas
        - Bloque de Documentos (NOT IN OFFLINE)
        - Bloque de Zonas (NOT IN OFFLINE)
        - Bloque de Videos (NOT IN OFFLINE)
        - Bloque de Gastos (NOT IN OFFLINE)
     */
}
