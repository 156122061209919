<template>
  <ion-page>
    <AppToolbar>
      <template v-slot:start>
        <ion-icon :icon="chevronBack" @click="$router.go(-1)" class="f20"></ion-icon>
      </template>
      <template v-slot:end>
        <ion-icon v-if="workorder?.id_encuesta && isFinished && isSupervisor" :icon="documentTextOutline" @click="$router.push({ name: 'survey', params: { id: $route.params?.id } })" class="f20 leftSpace"></ion-icon>
        <ion-icon :icon="downloadOutline" @click="workorderPdf" class="f20 leftSpace"></ion-icon>
      </template>
      <template
        v-slot:bottom
        v-if="!+gmao.comportamientos?.no_timer"
      >
        <ion-button
          expand="block"
          color="tertiary"
          @click="manageWoTimer"
          :disabled="workorder?.not_editable"
        >
          <template v-if="timeIsRunning">
            <ion-icon  slot="start" :icon="stopCircleOutline"></ion-icon>
            {{ $t('stop_timer') }}
          </template>
          <template v-else>
            <ion-icon  slot="start" :icon="playCircleOutline"></ion-icon>
            {{ $t('start_timer') }}
          </template>
        </ion-button>
      </template>

      <template v-slot:chip v-if="timeIsRunning">
        <div style="margin-right: 8px">
          <svg height="10" width="10" class="blinking">
            <circle cx="5" cy="5" r="5" fill="red" />
          </svg>
        </div>
        {{ `${time.hours}h ${time.minutes}m ${time.seconds}s` }}
      </template>

      {{ $t('Ot') }} #{{ workorder?.id }}  <span v-if="workorder?.incidencia?.ref"> - {{ $t('incidence') }} #{{ workorder.incidencia.ref }}</span>

    </AppToolbar>


    <ion-content ref="content" :fullscreen="true">
      <!-- ==> OPTIMIZE: BLOQUE_DATOS -->
      <ion-card style="box-shadow: none; border-radius: 15px; margin-top:20px !important;" class="ios bloque">
        <ion-card-content class="ion-no-padding">
          <ion-grid class="ion-no-padding">
            <!-- ==> NOTE: BLOQUE_DATOS_NAVIGATE -->
            <ion-row>
              <ion-col size="12" v-if="!+gmao.comportamientos?.gmao_interno">
                <ion-item class="wo-item" lines="none">
                  <ion-button
                    class="header-icon ion-no-margin"
                    size="small"
                    fill="clear"
                    color="tertiary"
                    @click="presentActionSheetNavigate(workorder?.direccion?.latitud, workorder?.direccion?.longitud)"
                    v-if="workorder?.direccion?.latitud"
                  >
                    <ion-icon :icon="chevronForward"></ion-icon>
                  </ion-button>
                  <template v-if="workorder.direccion">
                    <ion-label>
                      <ion-text style="margin-bottom: 20px" class="overflowText">
                        <h3>{{ workorder.direccion?.cliente?.nombre }}</h3>
                      </ion-text>

                      <p>{{ workorder.direccion?.nombre }}</p>
                      <p>{{ workorder.direccion.direccion }}</p>
                      <p>{{ workorder.direccion.municipio }}</p>
                    </ion-label>
                  </template>

                  <template v-else>
                    <ion-label class="grey">{{ $t('Sin dirección') }}</ion-label>
                  </template>
                </ion-item>
              </ion-col>
            </ion-row>

            <!-- ==> NOTE: BLOQUE_DATOS_ -->
            <ion-row>
              <!-- ==> NOTE: BLOQUE_DATOS_DATE -->
              <ion-col size="12">
                <ion-item class="wo-item" lines="none">
                  <ion-label>
                    <ion-text>
                      <h3>{{ $t('Fecha') }}</h3>
                    </ion-text>

                    <p>
                      {{ this.$moment(String(workorder.fecha)).format('L') }}
                      <span v-if="workorder.hora_i" style="padding-left: 1em">{{
                        workorder.hora_i.slice(0, -3)
                      }}</span>
                      <span v-if="workorder.hora_f">
                        - {{ workorder.hora_f.slice(0, -3) }}</span
                      >
                    </p>
                  </ion-label>
                  <ion-button
                    class="header-icon ion-no-margin"
                    size="small"
                    fill="clear"
                    color="tertiary"
                    v-if="$hasPermissions('partes', 'editar') && $hasPermissions('fecha_parte', 'editar')"
                    :disabled="workorder.not_editable"
                    @click="() => {isUserOnline() ?  openOTDateModal = true : undefined}"
                  >
                    <ion-icon :icon="chevronForward"></ion-icon>
                  </ion-button>
                  <ion-modal :is-open="openOTDateModal" @didDismiss="openOTDateModal = false" ref="calendar" class="date-modal">
                    <ion-content force-overscroll="false">
                      <!-- <ion-datetime
                        :min="$moment().format('YYYY-MM-DD')"
                        presentation="date"
                        @ionChange="fechaOT"
                      /> -->
                      <ion-datetime presentation="date" @ionChange="fechaOT" first-day-of-week="1" />
                    </ion-content>
                  </ion-modal>
                </ion-item>
              </ion-col>

              <!-- ==> NOTE: BLOQUE_DATOS_STATUS -->
              <ion-col size="12">
                <ion-item class="wo-item" lines="none">
                  <ion-label>
                    <ion-text>
                      <h3>{{ $t('Estado') }}</h3>
                    </ion-text>
                    <p>{{ workorder?.estado_actual?.nombre }}</p>
                  </ion-label>
                  <ion-button
                    class="header-icon ion-no-margin"
                    size="small"
                    fill="clear"
                    color="tertiary"
                    @click="() => {isUserOnline() ?  editStatus = true : undefined}"
                    :disabled="workorder.not_editable"
                  >
                    <ion-icon :icon="chevronForward"></ion-icon>
                  </ion-button>

                  <!-- ==> OPTIMIZE: BLOQUE_DATOS_MODAL -->
                  <ion-modal
                    :initial-breakpoint="0.6"
                    :is-open="editStatus"
                    @didDismiss="editStatus = false"
                  >
                    <ion-header>
                      <ion-toolbar>
                        <ion-buttons slot="start">
                          <ion-button @click="editStatus = false">{{ $t('Cancelar') }}</ion-button>
                        </ion-buttons>
                        <ion-title> {{ $t('Cambiar Estado') }} </ion-title>
                        <ion-buttons slot="end">
                          <ion-button @click="updateEstado()">{{ $t('Cambiar') }}</ion-button>
                        </ion-buttons>
                      </ion-toolbar>
                    </ion-header>
                    <ion-content class="ion-padding">
                      <ion-item>
                        <ion-label>{{ $t('Estados') }}</ion-label>
                        <ion-select v-model="newStatus" @click="getParteEstados">
                          <ion-select-option
                            v-for="estado in estados"
                            :key="`estado-${estado.id}`"
                            :value="estado.id"
                          >
                            {{ estado?.nombre }}
                          </ion-select-option>
                        </ion-select>
                      </ion-item>
                    </ion-content>
                  </ion-modal>

                  <!-- <== OPTIMIZE: /BLOQUE_DATOS_MODAL -->

                </ion-item>
              </ion-col>

              <!-- ==> NOTE: BLOQUE_DATOS_TYPE -->
              <ion-col size="12" v-if="workorder?.tipo_label">
                <ion-item class="wo-item" lines="none">
                  <ion-label>
                    <ion-text>
                      <h3>{{ $t('Tipo') }}</h3>
                    </ion-text>
                    <p>{{ workorder?.tipo_label }}</p>
                  </ion-label>
                </ion-item>
              </ion-col>

              <!-- ==> NOTE: BLOQUE_DATOS_subTYPE -->
              <ion-col size="12" v-if="workorder?.id_tipo">
                <ion-item class="wo-item" lines="none">
                  <ion-label>
                    <ion-text>
                      <h3>{{ $t('Subtipo') }}</h3>
                    </ion-text>
                    <p>{{ workorder?.tipo_parte?.nombre }}</p>
                  </ion-label>
                </ion-item>
              </ion-col>

              <!-- ==> NOTE: BLOQUE_DATOS_INCIDENCE -->
              <ion-col size="12" v-if="workorder.id_incidencia">
                <ion-item class="wo-item" lines="none">
                  <ion-label>
                    <ion-text>
                      <h3>{{ $t('Incidencia') }}</h3>
                    </ion-text>

                    <p>{{ workorder?.incidencia?.codigo_personalizado||workorder?.id_incidencia }}</p>
                  </ion-label>
                </ion-item>
              </ion-col>
            </ion-row>

            <!-- ==> NOTE: BLOQUE_DATOS_RESPONSIBLE -->
            <ion-row>
              <ion-col size="12" v-if="workorder.id_tramitador">
                <ion-item class="wo-item" lines="none">
                  <ion-label>
                    <ion-text>
                      <h3>{{ $t('Tramitador') }}</h3>
                    </ion-text>
                    <p>{{ workorder.tramitador?.nombre_completo }}</p>
                  </ion-label>
                  <a
                    :href="`tel:${workorder.tramitador?.telefono}`"
                    v-if="workorder.tramitador?.telefono"
                    class="black"
                  >
                    <ion-buttons slot="end">
                      <ion-icon :icon="call" class="f20" />
                    </ion-buttons>
                  </a>

                  <a
                    :href="`tel:${workorder.tramitador?.movil}`"
                    v-if="workorder.tramitador?.movil"
                    style="margin-left: 1em"
                    class="black"
                  >
                    <ion-buttons slot="end">
                      <ion-icon :icon="phonePortraitOutline" class="f20" />
                    </ion-buttons>
                  </a>
                </ion-item>
              </ion-col>
            </ion-row>

            <!-- ==> NOTE: BLOQUE_DATOS_CONTACT -->
            <ion-row>
              <ion-col
                v-if="
                  workorder.id_direccion &&
                  workorder.direccion?.cliente?.contacto &&
                  !workorder.direccion?.contacto &&
                  !workorder.sistema?.contacto
                "
              >
                <ion-item class="wo-item" lines="none">
                  <ion-label>
                    <ion-text>
                      <h3>{{ $t('Contacto') }}</h3>
                    </ion-text>

                    <ion-text class="ion-text-wrap">{{ workorder.direccion?.cliente?.contacto }}</ion-text>
                  </ion-label>

                  <a
                    :href="`tel:${workorder.direccion?.cliente.telefono}`"
                    v-if="workorder.direccion?.cliente.telefono"
                    class="black"
                  >
                    <ion-buttons slot="end">
                      <ion-icon :icon="call" class="f20" />
                    </ion-buttons>
                  </a>

                  <a
                    :href="`tel:${workorder.direccion?.cliente.movil}`"
                    v-if="workorder.direccion?.cliente.movil"
                    style="margin-left: 1em"
                    class="black"
                  >
                    <ion-buttons slot="end">
                      <ion-icon :icon="phonePortraitOutline" class="f20" />
                    </ion-buttons>
                  </a>
                </ion-item>
              </ion-col>

              <ion-col v-if="workorder.id_direccion && workorder.direccion?.contacto && !workorder.sistema?.contacto">
                <ion-item class="wo-item" lines="none">
                  <ion-label>
                    <ion-text>
                      <h3>{{ $t('Contacto') }}</h3>
                    </ion-text>

                    <p class="ion-text-wrap">{{ workorder.direccion?.contacto }}</p>
                  </ion-label>

                  <a
                    :href="`tel:${workorder.direccion.telefono}`"
                    v-if="workorder.direccion.telefono"
                    class="black"
                  >
                    <ion-buttons slot="end">
                      <ion-icon :icon="call" class="f20" />
                    </ion-buttons>
                  </a>

                  <a
                    :href="`tel:${workorder.direccion.movil}`"
                    v-if="workorder.direccion.movil"
                    style="margin-left: 1em"
                    class="black"
                  >
                    <ion-buttons slot="end">
                      <ion-icon :icon="phonePortraitOutline" class="f20" />
                    </ion-buttons>
                  </a>
                </ion-item>
              </ion-col>

              <ion-col v-if="workorder.sistema?.contacto">
                <ion-item class="wo-item" lines="none">
                  <ion-label>
                    <ion-text>
                      <h3>{{ $t('Contacto') }}</h3>
                    </ion-text>

                    <p class="ion-text-wrap">{{ workorder.sistema?.contacto }}</p>
                  </ion-label>
                  <a
                    :href="`tel:${workorder.sistema.telefono}`"
                    v-if="workorder.sistema.telefono"
                    style="margin-left: 1em"
                    class="black"
                  >
                    <ion-buttons slot="end">
                      <ion-icon :icon="phonePortraitOutline" class="f20" />
                    </ion-buttons>
                  </a>
                </ion-item>
              </ion-col>

              <ion-col v-if="workorder?.incidencia?.requerido">
                <ion-item class="wo-item" lines="none">
                  <ion-label>
                    <ion-text>
                      <h3>{{ $t('Requerido por') }}</h3>
                    </ion-text>
                    <p>{{ workorder.incidencia.requerido }}</p>
                  </ion-label>
                </ion-item>
              </ion-col>
            </ion-row>

            <!-- ==> NOTE: BLOQUE_DATOS_DESCRIPTION -->
            <ion-row>
              <ion-col
                size="12"
                v-if="!+gmao.comportamientos.no_problema && workorder.problema"
              >
                <ion-item class="wo-item" lines="none">
                  <ion-label>
                    <ion-text>
                      <h3>{{ $t('Descripcion') }}</h3>
                    </ion-text>

                    <p class="ion-text-wrap">{{ workorder.problema }}</p>
                  </ion-label>
                </ion-item>
              </ion-col>
            </ion-row>

            <!-- ==> NOTE: BLOQUE_DATOS_PROJECT -->
            <ion-row v-if="workorder?.proyecto">
              <ion-col
                size="12"
                v-if="!+gmao.comportamientos.no_problema && workorder.problema"
              >
                <ion-item class="wo-item" lines="none">
                  <ion-label>
                    <ion-text>
                      <h3>{{ $t('Proyecto') }}</h3>
                    </ion-text>
                    <p>{{workorder.proyecto.cod_proyecto}} - {{ workorder.proyecto.titulo }}</p>
                  </ion-label>
                </ion-item>
              </ion-col>
            </ion-row>

            <!-- ==> NOTE: BLOQUE_DATOS_REFERENCE -->
            <ion-row>
              <ion-col
                size="12"
                v-if="workorder.referencia_externa && workorder.referencia_externa != '-'"
              >
                <ion-item class="wo-item" lines="none">
                  <ion-label>
                    <ion-text>
                      <h3>{{ $t('Referencia Externa') }}</h3>
                    </ion-text>

                    <p>{{ workorder.referencia_externa }}</p>
                  </ion-label>
                </ion-item>
              </ion-col>
            </ion-row>

            <!-- ==> NOTE: BLOQUE_DATOS_SYSTEM -->
            <ion-row>
              <ion-col
                size="12"
                v-if="workorder.sistema"
              >
                <ion-item
                  class="wo-item"
                  lines="none"
                  @click.prevent="workorder?.sistema?.lat && presentActionSheetNavigate(workorder?.sistema?.lat, workorder?.sistema?.lng) || undefined">
                  <ion-label>
                    <ion-text>
                      <h3 
                        style="display: flex;flex-direction: row;align-items: center;column-gap: 8px;"
                      >
                        {{ $t('Instalación') }}

                        <ion-icon
                          :icon="compass"
                          size="small"
                          color="tertiary"
                          v-if="workorder?.sistema?.lat"
                        />
                      </h3>
                    </ion-text>

                    <p class="ion-text-wrap">
                      <template v-if="workorder?.sistema?.sistemaTree">
                        {{ `${workorder.sistema?.sistemaTree} / ${workorder.sistema?.nombre}` }}
                      </template>

                      <template v-else>
                        {{ workorder?.sistema?.nombre }}
                      </template>
                    </p>
                  </ion-label>

                    <ion-icon
                      :icon="chevronForward" 
                      class=""
                      size="small"
                      color="tertiary"
                      v-if="$hasPermissions('partes', 'editar')"
                      :disabled="workorder.not_editable"
                      @click.stop="() => {isUserOnline() ?  getAddressSistems() : undefined}"
                    />

                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>

      <!-- <== OPTIMIZE: /BLOQUE_DATOS -->

      <!-- ==> OPTIMIZE: BLOQUE_PROYECTO -->
      <template v-if='workorder?.proyecto'>
        <ion-item lines="none" class="title">
          <h5>{{ $t('Proyecto') }}</h5>
        </ion-item>
        <ion-card
          style="box-shadow: none; border-radius: 15px"
          class="bloque"
        >
          <ion-card-content class="ion-no-padding">
            <ion-grid class="ion-no-padding">
              <ion-row>
                <ion-col size="12" v-if="workorder.proyecto.titulo">
                  <ion-item class="wo-item" lines="none">
                    <ion-label>
                      <ion-text>
                        <h3>{{ $t('Título') }}</h3>
                      </ion-text>
  
                      <p>{{ workorder.proyecto.titulo }}</p>
                    </ion-label>
                  </ion-item>
                </ion-col>
              </ion-row>
  
              <ion-row>
                <ion-col size="12" v-if="workorder.proyecto.cod_proyecto">
                  <ion-item class="wo-item" lines="none">
                    <ion-label>
                      <ion-text>
                        <h3>{{ $t('Código Proyecto') }}</h3>
                      </ion-text>
  
                      <p>{{ workorder.proyecto.cod_proyecto }}</p>
                    </ion-label>
                  </ion-item>
                </ion-col>
              </ion-row>
  
              <ion-row>
                <ion-col size="12" v-if="workorder.proyecto.estado">
                  <ion-item class="wo-item" lines="none">
                    <ion-label>
                      <ion-text>
                        <h3>{{ $t('Estado') }}</h3>
                      </ion-text>
  
                      <p>{{ workorder.proyecto.estado }}</p>
                    </ion-label>
                  </ion-item>
                </ion-col>
              </ion-row>
  
              <ion-row>
                <ion-col size="12" v-if="workorder.proyecto.op">
                  <ion-item class="wo-item" lines="none">
                    <ion-label>
                      <ion-text>
                        <h3>{{ $t('OP') }}</h3>
                      </ion-text>
  
                      <p>{{ workorder.proyecto.op }}</p>
                    </ion-label>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </template>
      <!-- <== OPTIMIZE: /BLOQUE_PROYECTO -->

      <!-- ==> OPTIMIZE: BLOQUE_OPERARIO_ADICIONAL -->
      <template v-if="!+gmao.comportamientos.no_operario_adicional">
        <ion-item
          lines="none"
          class="title"
          :disabled="workorder?.unassigned || workorder.not_editable"
        >
          <h5>{{ $t('OPERARIO ADICIONAL') }}</h5>
          <ion-button
            class="ion-no-margin"
            size="small"
            fill="clear"
            color="tertiary"
            @click="setOperarioAdicional = true"
            slot="end"
          >
            <ion-icon :icon="add" style="margin-right: 4px" />
            <span>{{ $t('Añadir') }}</span>
          </ion-button>
        </ion-item>

        <ion-card
          style="box-shadow: none; border-radius: 15px"
          class="bloque"
        >
          <ion-card-content class="ion-no-padding">
            <ion-item lines="none" v-if="!workorder?.tecnicos?.length" class="grey">
              {{ $t('Sin acompañantes') }}
            </ion-item>
            <ion-list lines="none" v-if="workorder?.tecnicos_adicionales">
              <ion-item-sliding
                v-for="tec in workorder.tecnicos_adicionales?.slice(0, tecnicosAd_local_cantidad)"
                :key="`tecnico-adicional-${tec.id}`"
                :disabled="workorder.not_editable"
              >
                <ion-item>
                  <ion-label>
                    <h3>{{ tec?.nombre_completo || 'Técnico sin nombre' }}</h3>
                  </ion-label>
                </ion-item>
  
                <ion-item-options side="end">
                  <ion-item-option @click="deleteOperarioAdicional(tec)" color="danger">
                    <ion-icon slot="start" :icon="closeOutline"></ion-icon>
                    {{ $t('Eliminar') }}
                  </ion-item-option>
                </ion-item-options>
              </ion-item-sliding>
  
              <!-- BUG: Posible Bug en Modo Offline -->
              <ion-button
                v-if="(tecnicosAd_local_cantidad <= workorder?.tecnicos_adicionales_count) && workorder.tecnicos_adicionales_count > 5"
                class="ion-no-margin"
                style="display:flex;"
                size="small"
                fill="clear"
                color="tertiary"
                @click="loadMoreTecnicosAd(workorder?.tecnicos_adicionales_count/5 + 1)"
              >
                <span>{{ $t('Ver más') }}</span>
              </ion-button>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </template>
      <!-- <== OPTIMIZE: /BLOQUE_OPERARIO_ADICIONAL -->

      <!-- ==> OPTIMIZE: BLOQUE_OPERARIO -->
      <ion-item
        lines="none"
        class="title"
        :disabled="workorder?.unassigned || workorder.not_editable"
      >
      <h5>{{ $t('CAMBIAR OPERARIO') }}</h5>
      <ion-button
          v-if="!!+gmao.comportamientos.cambio_operario"
          class="ion-no-margin"
          size="small"
          fill="clear"
          color="tertiary"
          @click="() => { isUserOnline() ? setOperario = true : undefined}"
          slot="end"
        >
          <ion-icon :icon="add" style="margin-right: 4px" />
          <span>{{ $t('Cambiar') }}</span>
        </ion-button>
      </ion-item>
      <ion-card
        style="box-shadow: none; border-radius: 15px"
        class="bloque"
      >
        <ion-card-content class="ion-no-padding">
          <ion-list lines="none">
            <ion-item :disabled="workorder.not_editable">
              <ion-label>
                <h3 class="grey">
                  {{ workorder?.tecnico?.nombre_completo || 'Sin técnico asignado' }}
                </h3>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>
      <!-- <== OPTIMIZE: /BLOQUE_OPERARIO -->

      <!-- ==> OPTIMIZE: PENDING: BLOQUE_FAQ -->
      <template v-if="+gmao.modulos.modulo_faq && !+gmao.comportamientos.faq_sobre_activos && workorder.id_problema_faq">
        <ion-item
          lines="none"
          class="title"
        >
          <h5 v-if="!+gmao.comportamientos.faq_solo_respuestas">
            {{ $t('PREGUNTAS FRECUENTES') }}
          </h5>
        </ion-item>
        <ion-card
          style="box-shadow: none; border-radius: 15px"
          class="bloque"
        >
          <template v-if="offline.status">
            <ion-item lines="none">
              <ion-text
                class="offline-warning">
                {{ $t('offline-generic-module-not-available-msg') }}
              </ion-text>
            </ion-item>
          </template>
          <template v-else>
            <ion-card-content v-if="workorder.id_problema_faq" class="ion-no-padding">
              <ion-item :disabled="workorder.not_editable">
                <ion-input
                  :value="workorder.problemaFAQ"
                  readonly
                  :placeholder="$t('Selecciona un problema')"
                  @click="setProblemaFAQ = true"
                  @ionChange="getReplies(workorder.id_problema_faq, true)"
                />
              </ion-item>
    
              <ion-item :disabled="workorder.not_editable">
                <ion-input
                  :value="workorder.solucionFAQ"
                  readonly
                  :placeholder="$t('Selecciona una solución')"
                  @click="setSolucionFAQ = true"
                  @ionChange="
                    setWorkorderUpdate({
                      id_problema_faq: workorder.id_problema_faq,
                      id_solucion_faq: workorder.id_solucion_faq,
                    })
                  "
                />
              </ion-item>
            </ion-card-content>
          </template>
        </ion-card>
      </template>

      <!-- <== OPTIMIZE: /BLOQUE_FAQ XXX: NOT IN OFFLINE -->

      <!-- ==> OPTIMIZE: BLOQUE_COMMUNICATIONS -->
      <!-- FIXME: PENDING: DUPLICATED - V-Comunicaciones -->
      <template v-if='!+gmao.comportamientos.ocultar_comunicaciones_parte && +gmao.comportamientos?.mostrar_bloque_comunicaciones_arriba'>
        <VCommunications
          :comunicaciones="workorder.comunicaciones"
          :not_editable="workorder.not_editable"
          @update:chatModal="chatModal = true"
        />
      </template>
      <!-- <== OPTIMIZE: /BLOQUE_COMMUNICATIONS -->

      <!-- ==> OPTIMIZE: BLOQUE_GAMAS -->
      <template v-if="!+gmao.comportamientos.no_especialidad">
        <ion-item lines="none" class="title">
          <h5>{{ $t('ESPECIALIDAD') }}</h5>
        </ion-item>

        <ion-card
          style="box-shadow: none; border-radius: 15px"
          class="bloque"
        >
          <ion-card-content class="ion-no-padding">
            <ion-list lines="none">
              <ion-item
                @click="setEspecialidad = true"
                :disabled="+gmao.comportamientos.no_editar_especialidad || workorder.not_editable"
              >
                <ion-label>
                  <p style="font-size: 10px">{{ $t('ESPECIALIDAD') }}</p>
                  <h3>{{ workorder?.gama?.nombre || $t('Sin especialidad') }}</h3>
                </ion-label>
  
                <ion-buttons slot="end">
                  <ion-icon color="tertiary" :icon="chevronForward"></ion-icon>
                </ion-buttons>
              </ion-item>
              <ion-item
                v-if="workorder.id_gama"
                @click="setSubespecialidad = true"
                :disabled="+gmao.comportamientos.no_editar_especialidad || workorder.not_editable"
              >
                <ion-label>
                  <p style="font-size: 10px">{{ $t('SUBESPECIALIDAD') }}</p>
                  <h3>
                    {{ workorder?.sub_gama?.nombre || $t('Sin subespecialidad') }}
                  </h3>
                </ion-label>
  
                <ion-buttons slot="end">
                  <ion-icon color="tertiary" :icon="chevronForward"></ion-icon>
                </ion-buttons>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </template>
      <!-- <== OPTIMIZE: /BLOQUE_GAMAS -->

      <!-- ==> OPTIMIZE: BLOQUE_DOCUMENTS  -->
      <template v-if="!+gmao.comportamientos.ocultar_documentos_wo_tecnicos">
        <ion-item
          lines="none"
          class="title"
        >
          <h5>{{ $t('DOCUMENTOS') }}</h5>
          <ion-button
            class="ion-no-margin"
            size="small"
            fill="clear"
            color="tertiary"
            v-if="$hasPermissions('documentos', 'crear')"
            @click="addWODocument = true"
            :disabled="workorder.not_editable || offline.status"
            slot="end"
          >
            <ion-icon :icon="add" style="margin-right: 4px" />
            <span>{{ $t('Añadir') }}</span>
          </ion-button>
        </ion-item>
  
        <ion-card
          style="box-shadow: none; border-radius: 15px"
          lines="none"
          class="bloque"
        >
          <template v-if="offline.status">
            <ion-item lines="none">
              <ion-text
                class="offline-warning">
                {{ $t('offline-generic-module-not-available-msg') }}
              </ion-text>
            </ion-item>
          </template>
          <template v-else-if="clientDocs?.length || dirDocs?.length || assetDocs?.length || workorder?.documentos?.length">
            <ion-card-content class="ion-no-padding">
                <DocumentTree
                  :client-docs="clientDocs"
                  :dir-docs="[dirDocs]"
                  :asset-docs="assetDocs"
                  :workorderDocs="workorder.documentos"
                  :fromWorkorder="true"
                  closeAccordion
                  @deleted="getWorkorder(this.$route.params.id)"
                />
              </ion-card-content>
          </template>
          <template v-else>
            <ion-item lines="none">{{ $t('no_documents') }}</ion-item>
          </template>
        </ion-card>
      </template>
      <!-- <== OPTIMIZE: /BLOQUE_DOCUMENTS -->

      <!-- ==> OPTIMIZE: BLOQUE_DRAWING -->
      <template v-if="!+gmao.comportamientos.no_dibujos">
        <ion-item
          lines="none"
          class="title"
        >
          <h5>{{ $t('DIBUJOS') }}</h5>
          <ion-button
            class="ion-no-margin"
            size="small"
            fill="clear"
            color="tertiary"
            @click="setWoDrawing = true"
            :disabled="workorder.not_editable || offline.status"
            slot="end"
          >
            <ion-icon :icon="add" style="margin-right: 4px" />
            <span>{{ $t('Añadir') }}</span>
          </ion-button>
        </ion-item>
  
        <ion-card style="box-shadow: none; border-radius: 15px" lines="none" class="bloque">
          <ion-card-content class="ion-no-padding">
            <template v-if="offline.status">
              <ion-item lines="none">
                <ion-text
                  class="offline-warning">
                  {{ $t('offline-generic-module-not-available-msg') }}
                </ion-text>
              </ion-item>
            </template>
            <template v-else-if="!workorder?.dibujos?.length">
              <ion-item lines="none" class="grey">{{ $t('no_drawings') }}</ion-item>
            </template>
            <template v-else>
              <ion-grid>
                <ion-row>
                  <ion-col
                    size="6"
                    v-for="(photo) in workorder?.dibujos"
                    :key="`dibujo-${photo.id}`"
                  >
                    <div class="drawing-container">
                      <ion-img
                        :src="photo.src"
                        style="
                          object-fit: cover;
                          width: 100%;
                          height: 100%;
                          position: relative;
                        "
                      />
                      <div class="drawing-container-content">
                        <div class="drawing-container-content-name">
                          {{photo.nombre}}
                        </div>
                        <div class="drawing-container-content-description">
                          {{photo.descripcion}}
                        </div>
                      </div>
                      <ion-buttons class="removeBtn">
                        <ion-button
                          @click="alertPopup($t('Estas-seguro-que-quieres-borrar-este-dibujo'), () => deleteMedia(photo.id, 'ParteDibujo'))"
                          :disabled="workorder.not_editable"
                        >
                          <ion-icon style="color: var(--ion-color-danger);" class="f20" :icon="closeCircle"></ion-icon>
                        </ion-button>
                      </ion-buttons>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </template>
          </ion-card-content>
        </ion-card>
      </template>
      <!-- <== OPTIMIZE: /BLOQUE_DRAWING XXX: NOT IN OFFLINE -->

      <!-- ==> OPTIMIZE: BLOQUE_ZONES -->
      <template v-if="+gmao.comportamientos?.zonas_incidencia && workorder?.zonas?.length && !+gmao.comportamientos?.no_zonas">
        <ion-item class="title" lines="none">
          <h5>{{ $t('ZONAS') }}</h5>
        </ion-item>
        <ion-card style="box-shadow: none; border-radius: 15px" lines="none" class="bloque">
          <template v-if="offline.status">
            <ion-item lines="none">
              <ion-text
                class="offline-warning">
                {{ $t('offline-generic-module-not-available-msg') }}
              </ion-text>
            </ion-item>
          </template>
          <template v-else-if='workorder?.zonas'>
            <ion-card-content class="ion-no-padding">
              <ion-grid>
                <ion-row>
                  <ion-col size="12">
                    <ion-chip
                      v-for="(zona, i) in workorder?.zonas"
                      :key="`zona-${i}`"
                    >
                      {{zona.nombre}}
                    </ion-chip>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-content>
          </template>
        </ion-card>
      </template>
      <!-- <== OPTIMIZE: /BLOQUE_ZONES XXX: NOT IN OFFLINE -->

      <!-- ==> OPTIMIZE: PENDING: BLOQUE_ASSETS -->
      <template v-if="workorder.id_direccion">
        <ion-item lines="none" class="title">
          <h5>{{ $t('ACTIVOS') }}</h5>
          <ion-button
            class="ion-no-margin"
            size="small"
            fill="clear"
            color="tertiary"
            @click="addActiveMenu"
            :disabled="workorder.not_editable"
            slot="end"
          >
            <ion-icon :icon="add" style="margin-right: 4px" />
            <span>{{ $t('Añadir') }}</span>
          </ion-button>
        </ion-item>
        <ion-card
          style="box-shadow: none; border-radius: 15px"
          lines="none"
          class="bloque"
        >
          <ion-card-content class="ion-no-padding">
            <template v-if="workorder?.maquinas?.length">
              <ion-list lines="none">
                <template v-if="!offline.status && (+gmao.comportamientos?.finalizar_todos_los_checklists || this.workorder.tipo_letra === 'P')">
                  <ion-button
                    class="ion-no-margin"
                    size="small"
                    fill="clear"
                    color="tertiary"
                    style="display:flex;"
                    @click="setAllChecklistsDone(true)"
                    :disabled="workorder.not_editable || areChecklistsDone"
                  >
                    <ion-icon :icon="checkmarkOutline" style="margin-right: 4px" />
                    <span>{{ $t('Finalizar todos los checklists') }}</span>
                  </ion-button>
    
                  <div class="item_separator">
                    <span></span>
                  </div>
    
                </template>
    
                <ion-item-sliding
                  v-for="activo in workorder?.maquinas"
                  :key="`active-${activo.id}`"
                  :disabled="workorder.not_editable"
                >
                  <ion-item
                    :class="[`priority-${activo.prioridad}`,{
                        'active-finished':
                          activo?.finalizado || activo?.pivot?.tareas_completadas,
                      }, 'activosOT',
                      { timeRunning: taskRunning(activo.id) }
                    ]"
                    @click=" () => { isUserOnline() ? $router.push({
                        name: 'item',
                        params: { asset: activo.id, workorder: workorder.id, sistema: activo?.id_sistema },
                      }) : undefined }
                    "
                    :disabled="activo.not_editable"
                  >
                    <ion-label>
                      <h3 class="wrap-text">
                        {{ activo?.nombre }} {{ activo?.modelo?.modelo }}
                        {{ activo?.modelo?.marca }}
                      </h3>
                      <p class="wrap-text">{{ activo?.fingarantia ? ("Garantía: " + activo?.fingarantia) : "" }}</p>
                      <p v-if="+gmao.comportamientos.mostrar_nombre_sistema_en_activo" class="wrap-text">{{ activo?.sistema?.nombre }}</p>
                      <p class="wrap-text">{{ activo?.numeroserie }}</p>
                      <p class="wrap-text">{{ activo?.ubicacion }}</p>
                      <p class="wrap-text">{{ activo?.observaciones }}</p>
                      <!-- <p class="wrap-text">{{ p.problema }}</p> -->
                    </ion-label>
    
                    <ion-buttons slot="end">
                      <ion-icon color="tertiary" :icon="taskRunning(activo.id) ? stopwatchOutline : chevronForward"></ion-icon>
                    </ion-buttons>
                  </ion-item>
    
                  <ion-item-options side="end">
                    <ion-item-option v-if="$hasPermissions('maquinas', 'borrar')" @click="deleteWorkorderActive(activo)" color="danger">
                      <ion-icon slot="start" :icon="closeOutline"></ion-icon>
                      {{ $t('Eliminar') }}
                    </ion-item-option>
                  </ion-item-options>
                </ion-item-sliding>
    
                <ion-button
                  v-if="workorder.maquinas_count > 10 || workorder?.maquinas?.length > 10"
                  class="ion-no-margin"
                  style="display:flex;"
                  size="small"
                  fill="clear"
                  color="tertiary"
                  @click="$router.push({name: 'wo-assets', params: { workorder: workorder?.id || $route.params?.id } })"
                >
                  <span>{{ $t('Ver más') }}</span>
                </ion-button>
                <!-- <ion-text
                  style="font-size: 12px; color: var(--ion-color-danger)"
                  class="ion-padding"
                  v-if="offline.status"
                >
                  {{ $t('No tiene conexión para poder ver los demás activos.') }}
                </ion-text> -->
              </ion-list>
            </template>
            <template v-else>
              <ion-item lines="none" class="grey">{{ $t('no_assets') }}</ion-item>
            </template>
          </ion-card-content>
        </ion-card>
      </template>
      <!-- <== OPTIMIZE: /BLOQUE_ASSETS -->

      <!-- ==> OPTIMIZE: PENDING: BLOQUE_IMAGES -->
      <template v-if="!+gmao.comportamientos.no_imagenes">
        <ion-item lines="none" class="title">
          <h5>{{ $t('IMAGENES') }}</h5>
          <ion-button
            class="ion-no-margin"
            size="small"
            fill="clear"
            color="tertiary"
            @click="addPhotoActionSheet"
            :disabled="workorder.not_editable"
            slot="end"
          >
            <ion-icon :icon="add" style="margin-right: 4px" />
            <span>{{ $t('Añadir') }}</span>
          </ion-button>
        </ion-item>
        <ion-card
          style="box-shadow: none; border-radius: 15px"
          class="bloque"
        >
          <ion-card-content class="ion-no-padding">
            <template v-if="!workorder?.imagenes?.length">
              <ion-item lines="none" class="grey">{{ $t('Sin imágenes') }}</ion-item>
            </template>
            <template v-else>
              <ion-grid>
                <ion-row class="ion-nowrap" style="overflow-x: auto">
                  <ion-col
                    size="5"
                    v-for="(photo) in (offline.status ? workorder?.imagenes : workorder?.imagenes?.filter((i) => ['png','jpeg','jpg'].includes(i.src?.match(/\.([^.]+)$/)[1])))"
                    :key="`imagen-${photo.id}`"
                  >
                    <div>
                      <ion-img
                        v-if="photo.src || photo.base64Src"
                        :src="offline.status ? photo.base64Src : photo.src"
                        @click="imageViewer(photo)"
                        style="
                          object-fit: cover;
                          width: 100%;
                          height: 100%;
                          position: relative;
                        "
                      />
                      <ion-buttons class="removeBtn">
                        <ion-button
                          @click="alertPopup($t('Estas-seguro-que-quieres-borrar-esta-imagen'), () => deleteMedia(photo.id))"
                          :disabled="workorder.not_editable"
                        >
                          <ion-icon style="color: var(--ion-color-danger);" class="f20" :icon="closeCircle"></ion-icon>
                        </ion-button>
                      </ion-buttons>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </template>
          </ion-card-content>
        </ion-card>
      </template>
      <!-- <== OPTIMIZE: /BLOQUE_IMAGES -->

      <!-- ==> OPTIMIZE: BLOQUE_VIDEOS -->
      <template v-if="!+gmao.comportamientos.no_videos">
        <ion-item lines="none" class="title">
          <ion-label>
            <h5>{{ $t('videos') }}</h5>
            <ion-text style="font-size: 10px; margin-right: 8px; max-width: 65%">
              {{ `${$t('formatos-aceptados')} (mp4, webm, mov)` }}
            </ion-text>
          </ion-label>
          <ion-button
            class="ion-no-margin"
            size="small"
            fill="clear"
            color="tertiary"
            :disabled="workorder.not_editable || offline.status"
            @click="openFileSelect"
            slot="end"
          >
            <ion-icon :icon="add" style="margin-right: 4px" />
            <input type="file" id="openVideoInput" ref="addVideo" style="display: none;" accept="video/*" @change="uploadWOVideo" />
            <canvas id="videoCanvasThumbnail" hidden></canvas>
            <span>{{ $t('Añadir') }}</span>
          </ion-button>
        </ion-item>
        <ion-card
          style="box-shadow: none; border-radius: 15px"
          class="bloque"
        >
          <template v-if="offline.status">
            <ion-item lines="none">
              <ion-text
                class="offline-warning">
                {{ $t('offline-generic-module-not-available-msg') }}
              </ion-text>
            </ion-item>
          </template>
  
          <template v-else>
            <ion-card-content class="ion-no-padding">
              <ion-item lines="none" v-if="!workorder?.imagenes?.filter((i) => ['mp4','webm', 'mov'].includes(i.src?.match(/\.([^.]+)$/)[1]))?.length"
                class="grey">{{ $t('sin-videos') }}
              </ion-item>
              <ion-grid v-if="workorder?.imagenes?.filter((i) => ['mp4','webm', 'mov'].includes(i.src?.match(/\.([^.]+)$/)[1]))?.length">
                <ion-row class="ion-nowrap" style="overflow-x: auto">
                  <ion-col
                    size="5"
                    v-for="(photo) in workorder?.imagenes?.filter((i) => ['mp4','webm', 'mov'].includes(i.src?.match(/\.([^.]+)$/)[1]))"
                    :key="`video-${photo.id}`"
                  >
                    <div>
                      <div style="object-fit: cover; width: 100%; height: 200px; position:relative;" @click="imageViewer(photo)">
                        <img
                          v-if="photo.src_thum"
                          :src="photo.src_thum"
                          style="
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                            position: relative;
                          "
                        />
                        <div v-else style="width: 100%;height: 100%;background-color: var(--ion-color-medium-tint);opacity: 0.2;"></div>
                        <ion-icon
                          v-if="['mp4', 'webm'].includes(photo.src?.match(/\.([^.]+)$/)[1])"
                          class="playIcon"
                          :icon="play"
                        />
                      </div>
                      <ion-buttons class="removeBtn">
                        <ion-button
                          @click="alertPopup($t('Estas-seguro-que-quieres-borrar-este-video'), () => deleteMedia(photo.id))"
                          :disabled="workorder.not_editable"
                        >
                          <ion-icon style="color: var(--ion-color-danger);" class="f20" :icon="closeCircle"></ion-icon>
                        </ion-button>
                      </ion-buttons>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-content>
          </template>
        </ion-card>
      </template>
      <!-- <== OPTIMIZE: /BLOQUE_VIDEOS XXX: NOT IN OFFLINE -->

      <!-- ==> OPTIMIZE: BLOQUE_INCIDENCE_IMAGE -->
      <template v-if="!+gmao.comportamientos.no_imagenes && workorder.id_incidencia">
        <ion-item
          lines="none"
          class="title"
        >
          <h5>{{ $t('IMÁGENES INCIDENCIA') }}</h5>
        </ion-item>
        <ion-card
          style="box-shadow: none; border-radius: 15px"
          class="bloque">
          <ion-card-content class="ion-no-padding">
            <template v-if="!workorder?.incidencia?.imagenes?.length">
              <ion-item lines="none" class="grey">{{ $t('Sin imágenes') }}</ion-item>
            </template>
            <template v-else>
              <ion-grid>
                <ion-row class="ion-nowrap" style="overflow-x: auto">
                  <ion-col
                    size="5"
                    v-for="(photo) in workorder.incidencia?.imagenes"
                    :key="`imagen-${photo.id}`"
                  >
                    <div>
                      <ion-img
                        v-if="photo.src"
                        :src="photo.src"
                        @click="imageViewer(photo, true)"
                        style="object-fit: cover; width: 100%; height: 100%"
                      />
                      <ion-buttons class="removeBtn">
                        <ion-button
                          @click="alertPopup($t('Estas-seguro-que-quieres-borrar-esta-imagen'), () => deleteMedia(photo.id))"
                          :disabled="workorder.not_editable"
                        >
                          <ion-icon style="color: var(--ion-color-danger);" class="f20" :icon="closeCircle"></ion-icon>
                        </ion-button>
                      </ion-buttons>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </template>
          </ion-card-content>
        </ion-card>
      </template>
      <!-- <== OPTIMIZE: /BLOQUE_INCIDENCE_IMAGE -->

      <!-- ==> OPTIMIZE: BLOQUE_SOLUTION -->
      <template
        v-if="
          !+gmao.comportamientos.no_solucion &&
          ((workorder.id_mantenimiento && !+gmao.comportamientos.ocultar_solucion_preventivo) ||
            !workorder.id_mantenimiento) &&
          !workorder?.tecnicos?.find((t) => t.id_tecnico == gmao.user?.id)
        ">
        <ion-item
          lines="none"
          class="title"
        >
          <ion-label>
            <h5>
              {{ $t('SOLUCION') }}
              <template v-if="!!+gmao.comportamientos?.solucion_obligatoria_parte">
                <span><b>*</b></span>
              </template>
            </h5>
          </ion-label>
          <ion-button
            class="ion-no-margin"
            size="small"
            fill="clear"
            color="tertiary"
            v-if="
              workOrders.textosPredefinidos[0] || this.offline.textosPredefinidos?.length
            "
            @click="setTextoSol = true"
            :disabled="workorder.not_editable"
            slot="end"
          >
            <ion-icon :icon="add" style="margin-right: 4px" />
            <span>{{ $t('Añadir') }}</span>
          </ion-button>

        </ion-item>

        <ion-card
          style="box-shadow: none; border-radius: 15px"
          class="bloque"
        >
          <ion-card-content class="ion-no-padding">
            <ion-grid>
              <ion-row>
                <ion-col size="12">
                  <GIonInput
                    :isTextArea="true"
                    :required="!!+gmao.comportamientos?.solucion_obligatoria_parte"
                    v-model="workorder.solucion"
                    :autoGrow="true"
                    :disabled="
                      workorder.not_editable ||
                      !!workorder?.tecnicos?.find((t) => t.id_tecnico == gmao.user?.id)
                    "
                    :placeholder="$t('placeholder_solucion_aplicada')"
                    :validation="(value) => value?.trim()?.length"
                    @update:modelValue="(value) => updateWorkorderField('solucion', value)"
                    :errorText="$t('Campo solucion es obligatorio')"
                  />
                  <!-- <ion-textarea
                    ref="solucion_input"
                    v-model="workorder.solucion"
                    class="gmao-textarea"
                    :autoGrow="true"
                    :disabled="
                      workorder.not_editable ||
                      !!workorder?.tecnicos?.find((t) => t.id_tecnico == gmao.user?.id)
                    "
                    :placeholder="$t('placeholder_solucion_aplicada')"
                    @ionChange="updateWorkorderField('solucion', $event.detail.value)"
                    @ionBlur="markTouched"
                    error-text="Campo solucion es obligatorio."
                    :debounce="1000"
                  ></ion-textarea> -->
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </template>
      <!-- <== OPTIMIZE: /BLOQUE_SOLUTION -->

      <!-- ==> OPTIMIZE: BLOQUE_EXPENSES -->
      <template v-if="this.gmao.esTecnico
        && !!this.gmao.modulos.modulo_gastos
        && (+this.gmao.comportamientos.mostrar_gastos || this.gmao.esUsuario)
      ">

        <ion-item lines="none" class="title">
          <h5>{{ this.gmao.comportamientos?.gastos_por_texto || this.$t('Gastos') }}</h5>
          <ion-button
            class="ion-no-margin"
            size="small"
            fill="clear"
            color="tertiary"
            @click="addExpense = true"
            :disabled="workorder.not_editable || offline.status"
            slot="end"
          >
            <ion-icon :icon="add" style="margin-right: 4px" />
            <span>{{ $t('Añadir') }}</span>
          </ion-button>
        </ion-item>
        <ion-card
          style="box-shadow: none; border-radius: 15px"
          class="bloque"
        >
          <template v-if="offline.status">
            <ion-item lines="none">
              <ion-text
                class="offline-warning">
                {{ $t('offline-generic-module-not-available-msg') }}
              </ion-text>
            </ion-item>
          </template>
          <template v-else>
            <ion-card-content class="ion-no-padding">
              <ion-list lines="none">
                <template v-if="!workorder?.gastos?.length">
                  <ion-item lines="none" class="grey">{{ $t('Sin gastos') }}</ion-item>
                </template>
                <template v-else>
                  <ion-item-sliding
                    v-for="(gasto) in workorder?.gastos"
                    :key="`gasto-${gasto.id}`"
                    :disabled="workorder.not_editable"
                  >
                    <ion-item>
                      <ion-label>
                        <h2 :class="gasto.pagado ? 'paid' : 'not_paid'">
                          {{
                            Intl.NumberFormat('de-DE', {
                              style: 'currency',
                              currency: 'EUR',
                            }).format(gasto.importe)
                          }}
                        </h2>
                        <p>
                          <b>{{ gasto.familia?.nombre }}</b> <span v-if="gasto.subfamilia"> &rarr; {{ gasto.subfamilia?.nombre }}</span>
                          {{ gasto.descripcion ? `: ${gasto.descripcion}` : '' }}
                        </p>
                      </ion-label>
                      <ion-label slot="end">
                        <p>{{ $moment(gasto.fecha).format('L') }}</p>
                      </ion-label>
                    </ion-item>
    
                    <ion-item-options side="end">
                      <ion-item-option @click="this.$router.push({ name: 'expenses-item', params: { id: gasto.id } })" color="tertiary">
                        <ion-icon slot="start" :icon="createOutline"></ion-icon>
                        {{ $t('Editar') }}
                      </ion-item-option>
                      <ion-item-option @click="() => alertPopup($t('Estas-seguro-que-quieres-borrar-este-gasto'), () => deleteWorkorderExpense(gasto?.id))" color="danger">
                        <ion-icon slot="start" :icon="closeOutline"></ion-icon>
                        {{ $t('Eliminar') }}
                      </ion-item-option>
                    </ion-item-options>
                  </ion-item-sliding>
                </template>
              </ion-list>
            </ion-card-content>
          </template>
        </ion-card>
      </template>
      <!-- <== OPTIMIZE: /BLOQUE_EXPENSES XXX: NOT IN OFFLINE -->

      <!-- ==> OPTIMIZE: PENDING: BLOQUE_MATERIALES -->
      <template v-if="!+gmao.comportamientos.no_articulos">
        <ion-item lines="none" class="title">
          <h5>{{ $t('MATERIALES') }}</h5>
          <ion-button
            class="ion-no-margin"
            size="small"
            fill="clear"
            color="tertiary"
            @click="() => { isUserOnline() ? addMaterial = true : undefined}"
            :disabled="workorder.not_editable"
            slot="end"
          >
            <ion-icon :icon="add" style="margin-right: 4px" />
            <span>{{ $t('Añadir') }}</span>
          </ion-button>
        </ion-item>
        <ion-card
          style="box-shadow: none; border-radius: 15px"
          class="bloque"
        >
          <ion-card-content class="ion-no-padding">
            <ion-list>
              <ion-item lines="none" v-if="!workorder?.lineas?.slice(0, lineas_local_cantidad)?.length"
              class="grey">{{ $t('Sin material') }}
              </ion-item>
              <ion-item-sliding
                v-for="(material, index) in workorder?.lineas?.slice(0, lineas_local_cantidad)"
                :key="`article-${material.id}`"
                :disabled="workorder.not_editable"
              >
                <ion-item :lines="workorder?.lineas?.length - 1 == index ? 'none' : null">
                  <ion-label>
                    <h3 class="wrap-text" v-if="material?.articulo">
                      {{
                        +gmao.comportamientos?.ver_codigo_articulo_en_partes_materiales
                          ? `(${material?.articulo?.codigo}) `
                          : ''
                      }}
                      {{ material?.articulo?.nombre }}
                    </h3>
                    <p class="wrap-text">{{ $t('Cantidad') }}: {{ material?.cantidad }}</p>
                    <p
                      class="wrap-text"
                      v-if="material?.articulo_movimiento?.almacen || material?.almacen"
                    >
                      {{ $t('Almacén') }}:
                      {{
                        material?.articulo_movimiento?.almacen?.almacen?.nombre ||
                        material?.almacen?.nombre ||
                        'General'
                      }}
                    </p>
                    <p class="wrap-text" v-if="material?.movistock?.lote || material?.lote">
                      {{ $t('Lote') }}:
                      {{ material?.movistock?.lote?.codigo || material?.lote?.codigo || '' }}
                    </p>
                    <p class="wrap-text">
                      {{ $t('Observaciones-tecnico') }}:
                      {{ material?.observaciones || '-' }}
                    </p>
                    <p class="wrap-text" v-if="material?.id_maquina">
                      {{ $t('Activo') }}:
                      {{ getMaquina(material?.id_maquina) || '-' }}
                    </p>
                    <p class="wrap-text" v-if="material.partida">
                      {{ $t('Partida') }}: {{ material?.partida?.codigo }} -
                      {{ material.partida.descripcion }}
                    </p>
                  </ion-label>
                  <div class="metadata-end-wrapper" slot="end">
                    <ion-chip v-if="material?.falta?.terminado == 0" color="danger" size="small">
                      {{ $t('Falta recibir') }}
                    </ion-chip>
                  </div>
                </ion-item>
  
                <ion-item-options side="end">
                  <!-- FIXME: Si tengo modulo stockage en offline petan al editar el material -->
                  <ion-item-option
                    v-if="!offline.status"
                    @click="editWoMaterial(material)"
                    color="tertiary"
                  >
                    <ion-icon slot="start" :icon="createOutline"></ion-icon>
                    {{ $t('Editar') }}
                  </ion-item-option>
                  <ion-item-option
                    v-if="!offline.status"
                    @click="deleteWorkorderMaterial(material)"
                    color="danger"
                  >
                    <ion-icon slot="start" :icon="closeOutline"></ion-icon>
                    {{ $t('Eliminar') }}
                  </ion-item-option>
                </ion-item-options>
              </ion-item-sliding>
  
              <ion-button
                v-if="(lineas_local_cantidad <= workorder?.lineas_count) && workorder.lineas_count > 5"
                class="ion-no-margin"
                style="display:flex;"
                size="small"
                fill="clear"
                color="tertiary"
                @click="loadMoreMateriales(workorder?.lineas_count/5 + 1)"
              >
                <span>{{ $t('Ver más') }}</span>
              </ion-button>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </template>
      <!-- <== OPTIMIZE: /BLOQUE_MATERIALES -->

      <!-- ==> OPTIMIZE: BLOQUE_HERRAMIENTAS -->
      <template v-if="workorder.herramientas?.length && !+gmao.comportamientos?.no_herramientas">
        <ion-item lines="none" class="title">
          <h5>{{ $t('HERRAMIENTAS') }}</h5>
        </ion-item>

        <ion-card
          style="box-shadow: none; border-radius: 15px"
          class="bloque"
        >
          <template v-if="offline.status">
            <ion-item lines="none">
              <ion-text
                class="offline-warning">
                {{ $t('offline-generic-module-not-available-msg') }}
              </ion-text>
            </ion-item>
          </template>
          <template v-else>
            <ion-card-content class="ion-no-padding">
              <ion-list lines="none">
                <ion-item
                  v-for="herramienta in workorder.herramientas"
                  :key="`herramienta-${herramienta?.id}`"
                  :disabled="
                    workorder.not_editable ||
                    ((herramienta.tecnico && herramienta?.tecnico?.id_tecnico != gmao.user.id) || herramienta.no_disponible)
                  "
                  style="margin-bottom:0;"
                  @click="$router.push({ name: 'tool', params: { id: herramienta.id, name: herramienta?.nombre } })"
                >
                  <ion-label>
                    <h3 class="wrap-text">{{ herramienta.numero_serie ? `${herramienta.numero_serie} -` : '' }} {{ herramienta.nombre }}</h3>
                    <p class="wrap-text">{{ herramienta.marca || 'Sin marca' }} - {{ herramienta.modelo || 'Sin modelo' }}</p>
                    <p class="wrap-text" v-if="herramienta.observaciones">{{ herramienta.observaciones }}</p>
                  </ion-label>
  
                  <ion-buttons slot="end">
                    <template v-if="herramienta.tecnico">
                      <ion-icon :icon="person" class="f20"></ion-icon>
                      <!-- <ion-label>{{ herramienta.tecnico.tecnico.nombre_completo }}</ion-label> -->
                    </template>
  
                    <template v-else-if="herramienta.no_disponible">
                      <ion-icon :icon="banOutline" class="f20"></ion-icon>
                      <!-- <ion-label>{{ $t('no-disponible') }}</ion-label> -->
                    </template>
  
                    <template v-else>
                      <ion-icon :icon="chevronForward" class="f20"></ion-icon>
                    </template>
                  </ion-buttons>
                </ion-item>
  
                <ion-button
                  v-if="workorder.herramientas_count > 5"
                  class="ion-no-margin"
                  style="display:flex;"
                  size="small"
                  fill="clear"
                  color="tertiary"
                  @click="searchingTools = true"
                >
                  <span>{{ $t('Ver más') }}</span>
                </ion-button>
              </ion-list>
            </ion-card-content>
          </template>
        </ion-card>
      </template>
      <!-- END: HERRAMIENTAS -->
      
      <ion-item lines="none" class="title" v-if="!+gmao.comportamientos.no_horas">
        <h5>{{ $t('time_tracking_header') }}</h5>
        <ion-button
          class="ion-no-margin"
          size="small"
          fill="clear"
          color="tertiary"
          @click="openHorasModal"
          :disabled="workorder.not_editable"
          slot="end"
        >
          <ion-icon :icon="add" style="margin-right: 4px" />
          <span>{{ $t('Añadir') }}</span>
        </ion-button>
      </ion-item>

      <ion-card
        style="box-shadow: none; border-radius: 15px"
        class="bloque"
        v-if="!+gmao.comportamientos.no_horas"
      >
        <ion-card-content class="ion-no-padding">
          <ion-list lines="none">
            <ion-item v-if="!workorder?.horas?.slice(0, horas_local_cantidad).length" class="grey">{{
              $t('No hay tiempo imputado')
            }}</ion-item>
            <template
              v-for="(hora) in workorder.horas?.slice(0, horas_local_cantidad).filter((h) => (h.hasta && !offline.status) || h.hasta != 'undefined')"
              :key="`horas-${hora.id}`"
            >
              <ion-item
                :disabled="workorder.not_editable"
                style="margin-bottom:0;"
                @click="resolveHourItemAction(hora)"
              >
                <ion-label style="margin-right: 0">
                    <div class="hora-item">
                      <div class="hora-item-tecnico">
                        <p class="ion-text-wrap wrap-text">
                          {{$t('Técnico')}} <br />
                          <b style="text-transform: capitalize">{{ hora?.tecnico?.nombre_completo }}</b>
                        </p>

                        <p v-if="hora.id_tipo_tiempo" class="ion-text-wrap wrap-text hora-tipo">
                          <!-- <b>{{$t('Tipo')}} </b> <br /> -->
                          {{ hora?.tipo_tiempo?.nombre }}
                        </p>
                      </div>
                      <div class="hora-item-hora">
                        <p class="wrap-text">
                          <!-- <b>{{$t('Desde')}}</b> <br /> -->
                          <!-- {{ hora.fecha ? `${$moment(hora.fecha).format('L')}` : `${$moment(hora.created_at).format('L')}` }} <b>·</b> {{ moment(hora.inicio, 'HH:mm:ss').format('HH:mm') }} -->
                          {{ moment.parseZone(hora?.desde_formatted).format('L · HH:mm') }}
                        </p>
                        <p class="wrap-text">
                          <!-- <b>{{$t('Hasta')}}</b> <br /> -->
                        <template v-if="hora?.hasta_formatted">
                          <template v-if="checkSameDate(hora)">
                            {{ moment.parseZone(hora?.hasta_formatted).format('HH:mm') }}
                          </template>

                          <template v-else>
                            {{ moment.parseZone(hora?.hasta_formatted).format('L · HH:mm') }}
                          </template>
                        </template>
                        <template v-else>
                          <span style="font-size: 12px;color: #c4c4c4;">
                            {{ $t('time_not_finished') }}
                          </span>
                        </template>
                        </p>
                      </div>
                    </div>
                </ion-label>
              </ion-item>

            </template>

            <ion-button
              v-if="(horas_local_cantidad <= workorder?.horas_count) && workorder.horas_count > 5"
              class="ion-no-margin"
              style="display:flex;"
              size="small"
              fill="clear"
              color="tertiary"
              @click="loadMoreHours(workorder.horas_count/5 + 1)"
            >
              <span>{{ $t('Ver más') }}</span>
            </ion-button>


          </ion-list>
        </ion-card-content>
      </ion-card>

      <!-- ==> OPTIMIZE: BLOQUE_DISPLACEMENT -->
      <template v-if="!+gmao.comportamientos.ocultar_selector_desplazamientos_app && !+gmao.comportamientos.gmao_interno">
        <ion-item lines="none" class="title">
          <h5>{{ $t('Desplazamiento') }}</h5>
        </ion-item>
        <ion-card
          style="box-shadow: none; border-radius: 15px"
          class="bloque"
        >
          <template v-if="offline.status">
            <ion-item lines="none">
              <ion-text
                class="offline-warning">
                {{ $t('offline-generic-module-not-available-msg') }}
              </ion-text>
            </ion-item>
          </template>
          <template v-else>
            <ion-card-content class="ion-no-padding">
              <ion-list lines="none">
                <ion-item
                  @click="setDisplacement = true"
                  :disabled="workorder.not_editable || +gmao.comportamientos.ver_selector_desplazamiento_no_editar"
                >
                  <ion-label>
                    <p style="font-size: 10px">{{ $t('DESPLAZAMIENTO') }}</p>
                    <h3 v-if="workorder?.desplazamiento">
                      {{
                        `${workorder?.desplazamiento?.codigo} - ${workorder?.desplazamiento?.descripcion}`
                        || $t('Sin desplazamiento')
                      }}
                    </h3>
                    <h3 v-else class="grey">{{$t('Sin desplazamiento')}}</h3>
                  </ion-label>
  
                  <ion-buttons slot="end">
                    <ion-icon  color="tertiary" :icon="chevronForward"></ion-icon>
                  </ion-buttons>
                </ion-item>
              </ion-list>
            </ion-card-content>
          </template>
        </ion-card>
      </template>

      <template v-if="!+gmao.comportamientos?.ocultar_bloque_desplazamientos_app && !+gmao.comportamientos?.desplazamientos">
        <ion-item lines="none" class="title">
          <h5>{{ $t('DESPLAZAMIENTOS IMPUTADOS') }}</h5>
          <ion-button
            class="ion-no-margin"
            size="small"
            fill="clear"
            color="tertiary"
            @click="addDisplacement = true"
            :disabled="workorder.not_editable || offline.status"
            slot="end"
          >
            <ion-icon :icon="add" style="margin-right: 4px" />
            <span>{{ $t('Añadir') }}</span>
          </ion-button>
        </ion-item>
        <ion-card
          style="box-shadow: none; border-radius: 15px"
          class="bloque"
        >
          <template v-if="offline.status">
            <ion-item lines="none">
              <ion-text
                class="offline-warning">
                {{ $t('offline-generic-module-not-available-msg') }}
              </ion-text>
            </ion-item>
          </template>
          <template v-else>
            <ion-card-content class="ion-no-padding">
              <ion-list>
                <ion-item lines="none" v-if="!workorder?.desplazamientos?.length"
                class="grey">{{ $t('Sin desplazamientos') }}
                </ion-item>
                <ion-item-sliding
                  v-for="(desplazamiento, index) in workorder?.desplazamientos"
                  :key="`article-${desplazamiento.id}`"
                  :disabled="workorder.not_editable"
                >
                  <ion-item :lines="workorder?.desplazamientos?.length - 1 == index ? 'none' : null">
  
                    <ion-label style="margin-right: 0">
                      <div class="hora-item">
                        <div class="hora-item-tecnico">
                          <p class="ion-text-wrap wrap-text">
                            {{$t('Técnico')}} <br />
                            <b style="text-transform: capitalize">{{ desplazamiento?.usuario?.nombre_completo }}</b>
                          </p>
  
                          <p v-if="desplazamiento?.desplazamiento" class="ion-text-wrap wrap-text hora-tipo">
                            <!-- <b>{{$t('Tipo')}} </b> <br /> -->
                            {{ `${desplazamiento?.desplazamiento?.codigo} ${desplazamiento?.desplazamiento?.descripcion}` }}
                          </p>
                        </div>
                        <div class="hora-item-hora">
                          <p class="wrap-text">
                            <!-- <b>{{$t('Fecha')}}</b> <br /> -->
                            {{ desplazamiento.fecha ? `${$moment(desplazamiento.fecha).format('L')}` : `${$moment(desplazamiento.created_at).format('L')}` }} <b>·</b> {{ moment(desplazamiento.fecha, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') }}
                          </p>
                          <p class="wrap-text" style="font-weight: 500;">
                            <!-- <b>{{$t('Cantidad')}}</b> <br /> -->
                            {{ desplazamiento.cantidad }} Km
                          </p>
                        </div>
                      </div>
                    </ion-label>
  
                  </ion-item>
  
                  <ion-item-options side="end">
                    <ion-item-option
                      @click="setDeleteWorkorderDisplacement(desplazamiento)"
                      color="danger"
                    >
                      <ion-icon slot="start" :icon="closeOutline"></ion-icon>
                      {{ $t('Eliminar') }}
                    </ion-item-option>
                  </ion-item-options>
                </ion-item-sliding>
              </ion-list>
            </ion-card-content>
          </template>
        </ion-card>
      </template>
      <!-- <== OPTIMIZE: /BLOQUE_DISPLACEMENT -->

      <!-- ==> OPTIMIZE: BLOQUE_COMMUNICATIONS -->
      <!-- FIXME: DUPLICATED - V-Comunicaciones -->
      <VCommunications
        v-if='!+gmao.comportamientos.ocultar_comunicaciones_parte && !+gmao.comportamientos?.mostrar_bloque_comunicaciones_arriba'
        :comunicaciones="workorder.comunicaciones"
        :not_editable="workorder.not_editable"
        @update:chatModal="chatModal = true"
      />
      <!-- <== OPTIMIZE: /BLOQUE_COMMUNICATIONS -->

      <!-- ==> OPTIMIZE: BLOQUE_OBSERVACIONES (TEC/CLI) -->
      <template v-if="!+gmao.comportamientos.no_observaciones_tec">
        <ion-item
          lines="none"
          class="title"
        >
          <h5>{{ $t('observaciones') }}</h5>
        </ion-item>
        <ion-card
          style="box-shadow: none; border-radius: 15px"
          class="bloque"
        >
          <ion-card-content class="ion-no-padding">
            <ion-grid>
              <ion-row>
                <ion-col size="12">
                  <GIonInput
                    :isTextArea="true"
                    v-model="workorder.observaciones"
                    :autoGrow="true"
                    :disabled="workorder.not_editable"
                    :placeholder="$t('Escribe aquí las observaciones...')"
                    @update:modelValue="(value) => updateWorkorderField('observaciones', value)"
                  />
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </template>

      <template v-if="!+gmao.comportamientos.no_observaciones_cli">
        <ion-item
          lines="none"
          class="title"
        >
          <h5>{{ $t('observaciones-clientes') }}</h5>
        </ion-item>
        <ion-card
          style="box-shadow: none; border-radius: 15px"
          class="bloque"
        >
          <ion-card-content class="ion-no-padding">
            <ion-grid>
              <ion-row>
                <ion-col size="12">
                  <GIonInput
                    :isTextArea="true"
                    v-model="workorder.observaciones_cliente"
                    :autoGrow="true"
                    :disabled="workorder.not_editable"
                    :placeholder="$t('Escribe aquí las observaciones...')"
                    @update:modelValue="(value) => updateWorkorderField('observaciones_cliente', value)"
                  />
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </template>
      <!-- <== OPTIMIZE: /BLOQUE_OBSERVACIONES (TEC/CLI) -->

      <!-- ==> OPTIMIZE: BLOQUE_CHECKS -->
      <ion-item
        lines="none"
        class="title"
        v-if="
          !+gmao.comportamientos.no_revision ||
          !+gmao.comportamientos.no_anomalias ||
          !+gmao.comportamientos.no_garantia ||
          +gmao.comportamientos.mostrar_pruebas ||
          !+gmao.comportamientos.no_revisar ||
          +gmao.comportamientos.tecnico_presupuesto_desde_ot
        "
      >
        <h5>{{ $t('Otros') }}</h5>
      </ion-item>
      <ion-card
        style="box-shadow: none; border-radius: 15px"
        class="bloque"
        v-if="
          !+gmao.comportamientos.no_revision ||
          !+gmao.comportamientos.no_anomalias ||
          !+gmao.comportamientos.no_garantia ||
          +gmao.comportamientos.mostrar_pruebas ||
          !+gmao.comportamientos.no_revisar ||
          +gmao.comportamientos.tecnico_presupuesto_desde_ot
        "
      >
        <ion-card-header class="gmao-gray" style="padding-left: 16px">
          <ion-card-subtitle>{{ $t('NO FINALIZADO') }}</ion-card-subtitle>
          <ion-text style="font-size: 10px; margin-right: 8px" v-if="isDuplicable">
            {{ $t('duplicar-el-parte-si-esta-marcado') }}
          </ion-text>
          <div
            class="header-icon checkboxes"
            style="display: flex; align-items: center; margin-right: 8px"
          >
            <ion-checkbox
              color="tertiary"
              :disabled="workorder.not_editable"
              v-model="not_finished"
            >
            </ion-checkbox>
          </div>
        </ion-card-header>
        <ion-card-header class="gmao-gray" v-if="!+gmao.comportamientos.no_anomalias" style="padding-left: 16px">
          <ion-card-subtitle>{{ $t('ANOMALIAS') }}</ion-card-subtitle>
          <ion-text style="font-size: 10px; margin-right: 8px; max-width: 65%">
            {{ $t('nuevo-conductivo-incidencia-si-esta-marcado') }}
          </ion-text>
          <div
            class="header-icon checkboxes"
            style="
              display: flex;
              justify-content: end;
              align-items: center;
              margin-right: 8px;
            "
          >
            <ion-checkbox
              color="tertiary"
              :disabled="workorder.not_editable"
              v-model="workorder.anomalies"
            >
            </ion-checkbox>
          </div>
        </ion-card-header>
        <ion-card-header class="gmao-gray" v-if="!+gmao.comportamientos.no_garantia" style="padding-left: 16px">
          <ion-card-subtitle>{{ $t('GARANTIA') }}</ion-card-subtitle>
          <div
            class="header-icon checkboxes"
            style="
              display: flex;
              justify-content: end;
              align-items: center;
              margin-right: 8px;
            "
          >
            <ion-checkbox
              color="tertiary"
              :checked="!!+workorder.garantia"
              :disabled="workorder.not_editable"
              @ionChange="updateWorkorderField('garantia', +$event.detail.checked)"
            ></ion-checkbox>
          </div>
        </ion-card-header>
        <ion-card-header class="gmao-gray" v-if="!!+gmao.comportamientos.mostrar_pruebas" style="padding-left: 16px">
          <ion-card-subtitle>{{ $t('PRUEBAS') }}</ion-card-subtitle>
          <div
            class="header-icon checkboxes"
            style="
              display: flex;
              justify-content: end;
              align-items: center;
              margin-right: 8px;
            "
          >
            <ion-checkbox
              color="tertiary"
              :checked="!!+workorder.pruebas"
              :disabled="workorder.not_editable"
              @ionChange="updateWorkorderField('pruebas', +$event.detail.checked)"
            ></ion-checkbox>
          </div>
        </ion-card-header>
        <ion-card-header class="gmao-gray" v-if="!+gmao.comportamientos.no_revisar" style="padding-left: 16px">
          <ion-card-subtitle>{{ $t('REVISAR') }}</ion-card-subtitle>
          <div
            class="header-icon checkboxes"
            style="
              display: flex;
              justify-content: end;
              align-items: center;
              margin-right: 8px;
            "
          >
            <ion-checkbox
              color="tertiary"
              :checked="!!+workorder.revisar"
              :disabled="workorder.not_editable"
              @ionChange="updateWorkorderField('revisar', +$event.detail.checked)"
            ></ion-checkbox>
          </div>
        </ion-card-header>
        <ion-card-header
          class="gmao-gray"
          v-if="
            +gmao.comportamientos.tecnico_presupuesto_desde_ot
          "
          style="padding-left: 16px"
        >
          <ion-card-subtitle>{{ $t('GENERAR PRESUPUESTO') }}</ion-card-subtitle>
          <div
            class="header-icon checkboxes"
            style="
              display: flex;
              justify-content: end;
              align-items: center;
              margin-right: 8px;
            "
          >
            <ion-checkbox
              color="tertiary"
              v-model="workorder.genera_presupuesto"
              :disabled="workorder.not_editable"
            >
            </ion-checkbox>
          </div>
        </ion-card-header>


        <div>
          <!-- <ion-card-header class="gmao-gray" v-if="+gmao.comportamientos.feedback_tecnicos_ampliado || this.workorder.tipo_letra == 'P'">
            <ion-card-subtitle>{{ $t('resuelto') }}</ion-card-subtitle>
            <div
              class="header-icon checkboxes"
              style="
                display: flex;
                justify-content: end;
                align-items: center;
                margin-right: 8px;
              "
            >
              <ion-checkbox
                color="tertiary"
                :checked="!!+workorder.resolved "
                :disabled="workorder.not_editable"
                @ionChange="updateWorkorderField('resolved', +$event.detail.checked)"
              ></ion-checkbox>
            </div>
          </ion-card-header> -->
          <ion-card-header class="gmao-gray" v-if="+gmao.comportamientos.feedback_tecnicos_ampliado" style="padding-left: 16px">
            <ion-card-subtitle>{{ $t('material-pendiente') }}</ion-card-subtitle>
            <div
              class="header-icon checkboxes"
              style="
              display: flex;
              justify-content: end;
              align-items: center;
              margin-right: 8px;
            "
            >
              <ion-checkbox
                color="tertiary"
                :checked="!!+workorder.pending_material"
                :disabled="workorder.not_editable"
                @ionChange="updateWorkorderField('pending_material', +$event.detail.checked)"
              ></ion-checkbox>
            </div>
          </ion-card-header>
          <ion-card-header class="gmao-gray" v-if="+gmao.comportamientos.feedback_tecnicos_ampliado && !gmao.comportamientos.ocultar_necesita_regresar" style="padding-left: 16px">
            <ion-card-subtitle>{{ $t('necesita-regresar') }}</ion-card-subtitle>
            <div
              class="header-icon checkboxes"
              style="
              display: flex;
              justify-content: end;
              align-items: center;
              margin-right: 8px;
            "
            >
              <ion-checkbox
                color="tertiary"
                :checked="!!+workorder.need_to_return"
                :disabled="workorder.not_editable"
                @ionChange="updateWorkorderField('need_to_return', +$event.detail.checked)"
              ></ion-checkbox>
            </div>
          </ion-card-header>
        </div>
      </ion-card>
      <!-- <== OPTIMIZE: /BLOQUE_CHECKS -->

      <!-- ==> OPTIMIZE: BLOQUE_SIGNATURE (TEC/CLI) -->
      <ion-item
        lines="none"
        class="title"
        v-if="!workorder?.tecnicos?.find((t) => t.id_tecnico == gmao.user?.id) || !!+gmao.comportamientos.tecnicos_secundarios_firma"
      >
        <h5>{{ $t('Firmas') }}</h5>
      </ion-item>
      <ion-card
        style="box-shadow: none; border-radius: 15px"
        class="bloque"
        v-if="
          !!+gmao.comportamientos.firma_tecnico &&
          !workorder?.tecnicos?.find((t) => t.id_tecnico == gmao.user?.id)
        "
      >
        <ion-card-header class="gmao-gray">
          <ion-card-subtitle>{{ $t('FIRMAR PARTE TECNICO') }}</ion-card-subtitle>
          <ion-button
            v-if="!workorder.firma_tec"
            class="header-icon ion-no-margin"
            size="small"
            fill="clear"
            color="tertiary"
            @click="setFirmaTecnico = true"
            :disabled="
              workorder.not_editable
              || offline.status
              || !!workorder?.tecnicos?.find((t) => t.id_tecnico == gmao.user?.id)
            "
          >
            <ion-icon :icon="add"></ion-icon>
          </ion-button>
        </ion-card-header>

        <template v-if="offline.status">
          <ion-item lines="none">
            <ion-text
              class="offline-warning">
              {{ $t('offline-generic-module-not-available-msg') }}
            </ion-text>
          </ion-item>
        </template>

        <template v-else>
          <ion-card-content class="ion-no-padding">
            <template v-if="workorder.firma_tecnico">
              <div>
                <!-- FIRMA* -->
                <template v-if="offline.status && !workorder.firma_tecnico?.base64Src">
                  <ion-item lines="none">
  
                    <ion-text
                      class="offline-warning">
                      {{ $t('offline-generic-image-not-available-msg') }}
                    </ion-text>
                  </ion-item>
  
                </template>
                <template v-else-if="workorder.firma_tecnico.base64Src">
                  <img :src="workorder.firma_tecnico.base64Src" />
                </template>
                <template v-else>
                  <img :src="workorder.firma_tecnico.src" />
                </template>
              </div>
            </template>
          </ion-card-content>
        </template>

      </ion-card>
      <ion-card
        style="box-shadow: none; border-radius: 15px"
        class="bloque"
        v-if="!workorder?.tecnicos?.find((t) => t.id_tecnico == gmao.user?.id) || !!+gmao.comportamientos.tecnicos_secundarios_firma"
      >

        <ion-card-header class="gmao-gray">
            <ion-card-subtitle>{{ this.gmao.comportamientos?.firma_parte_por_texto ? $t(this.gmao.comportamientos?.firma_parte_por_texto) : $t('FIRMAR PARTE') }}</ion-card-subtitle>

          <ion-text
            style="font-size: 10px; color: var(--ion-color-danger)"
            v-if="comportamientoNoFinalizadas"
          >
            {{
              $t('pending_checklist_before_signing')
            }}
          </ion-text>
          <ion-text
            style="font-size: 10px; color:"
            v-if="comportamientoSolucionObligatoria"
          >
            {{
              $t('solution_mandatory_to_sign')
            }}
          </ion-text>
          <ion-text
            style="font-size: 10px; color:"
            v-if="comportamientoNombreObligatoria"
          >
            {{
              $t('sign_name_mandatory_to_close')
            }}
          </ion-text>
          <ion-text
            style="font-size: 10px; color:"
            v-if="comportamientoVandalizadosGeneral"
          >
            {{
              $t('behaviour_asset_vandalized_msg')
            }}
          </ion-text>

          <ion-text
            style="font-size: 10px; color:"
            v-if="comportamientoVandalizadosFoto"
          >
            {{
              $t('behaviour_asset_vandalized_msg_photo')
            }}
          </ion-text>
          <ion-button
            class="header-icon ion-no-margin"
            size="small"
            fill="clear"
            color="tertiary"
            @click="() => {
              if (isOTClosableWithoutSignature) sendFirma(null, false);
              else setFirma = true
            }"
            :disabled="
              computedDisableFirma
              || offline.status
              || (workorder.firma
              && comportamientoNoFinalizadas
              && comportamientoSolucionObligatoria
              && comportamientoVandalizadosGeneral
              && comportamientoVandalizadosFoto)
            "
          >
            <template v-if="isOTClosableWithoutSignature">
              {{ this.$t('cerrar') }}
            </template>
            <template v-else>
              <ion-icon :icon="add"></ion-icon>
            </template>

          </ion-button>
        </ion-card-header>

        <template v-if="offline.status">
          <ion-item lines="none">
            <ion-text
              class="offline-warning">
              {{ $t('offline-generic-module-not-available-msg') }}
            </ion-text>
          </ion-item>
        </template>

        <template v-else>
          <ion-card-content class="ion-no-padding">

            <template v-if="workorder.firma">
              <template v-if="offline.status && !workorder.firma?.base64Src">
                <ion-item lines="none">
                  <ion-text
                    class="offline-warning">
                    {{ $t('offline-generic-image-not-available-msg') }}
                  </ion-text>

                </ion-item>
              </template>
              <template v-else-if="workorder.firma?.base64Src">
                <div>
                  <img :src="workorder.firma?.base64Src" />
                </div>
              </template>
              <template v-else>
                <div>
                  <img :src="workorder.firma.src" />
                </div>
              </template>
            </template>

            <template v-else>
              <div v-if="signature.image">
                <img :src="signature.image" />
              </div>
            </template>

            <div v-if="(workorder.firma && workorder.not_editable) || workorder.firma || +gmao.comportamientos.primero_datos_despues_firma">
              <!-- DNI -->
              <ion-item v-if="!+gmao.comportamientos.ocultar_dni_firma" class="f10">
                <ion-input
                  :disabled="workorder.not_editable"
                  v-model="signature.dni"
                  :value="signature?.dni"
                  :label="$t(gmao.comportamientos.dni_por_texto || 'DNI')"
                ></ion-input>
              </ion-item>

              <!-- NOMBRE -->
              <ion-item v-if="!+gmao.comportamientos.no_nombre" class="f10">
                  <ion-input
                  :disabled="workorder.not_editable"
                  v-model="signature.nombre"
                  :value="signature?.nombre"
                  :label="this.gmao.comportamientos?.firma_parte_nombre_por_texto ? $t(this.gmao.comportamientos?.firma_parte_nombre_por_texto) : $t('Nombre')"
                  maxlength="50"
                ></ion-input>
              </ion-item>

              <!-- EMAIL -->
              <ion-item
                lines="none"
                v-if="!+gmao.comportamientos.no_parte_email"
                class="f10"
              >
                <ion-input
                  :disabled="workorder.not_editable"
                  v-model="signature.email"
                  :value="signature.email"
                  :label="$t('Enviar a email')"
                ></ion-input>
              </ion-item>

              <ion-button
                expand="block"
                color="tertiary"
                v-bind:disabled="workorder.not_editable || isDisabled || comportamientoSolucionObligatoria || comportamientoNoFinalizadas || comportamientoNombreObligatoria"
                @click="
                  +gmao.comportamientos.fotofin_app_tecnico
                    ? sendPhotoFirma(signature)
                    : sendFirma(signature)
                "
              >
                {{ $t('Finalizar parte') }}
              </ion-button>
            </div>
          </ion-card-content>
        </template>
      </ion-card>
      <!-- <== OPTIMIZE: /BLOQUE_SIGNATURE (TEC/CLI) -->
    </ion-content>

    <!-- TODO: *** Asignar Partes desde 'Ot's sin asignar' -->
    <ion-footer collapse="fade">
      <ion-button v-if="workorder?.unassigned" expand="block" @click="assignWorkorder">
        {{ $t('asignar') }}
      </ion-button>
    </ion-footer>

  <!-- ============ MODALES ======================= -->
    <MSetTipoTiempo
      v-model="setTipoTiempos"
      :data="hourTypes"
      @done="(event) => selectTipoTiempo(event, true)"
    />

    <MSetOperarioAdicional
      v-model="setOperarioAdicional"
      :data="tecnicos"
      @done="selectOperarioAdicional"
    />

    <MSetOperario
      v-model="setOperario"
      :data="tecnicos"
      @done="selectOperario"
    />

    <MSetTextoSolucion
      v-model="setTextoSol"
      :data="textosSoluciones"
      @done="selectSolution"
    />

    <MSetFinder
      v-model="setDisplacement"
      :returnWholeObject="true"
      @done="(e) => {
        updateWorkorderField('id_desplazamiento', e?.id).then(() => {
          workorder.desplazamiento = e
        });
        setDisplacement = false
      }"
      :data="desplazamientoTipos"
      :content="{
        title: 'Selecciona un desplazamiento',
        empty_title: 'No hay tipos',
        multiple: false,
      }"
      :structure="[
        {
          tag: 'h3',
          key: 'descripcion'
        },
        {
          tag: 'p',
          key: 'codigo'
        }
      ]"
    />

    <MSetEspecialidad
      v-model="setEspecialidad"
      :data="gamas"
      @done="(e) => selectEspecialidad(e, 'id_gama', 'gama')"
    />

    <MSetSubEspecialidad
      v-model="setSubespecialidad"
      :data="subgamas"
      @done="(e) => selectEspecialidad(e, 'id_subgama', 'sub_gama')"
    />

    <!-- FIXME: OFFLINE NOT WORKING -->
    <MSetActivos
      v-model="setActivos"
      :data="(items || []).flat()"
      @search="(e) => {
        activeSearchAsset = e
        applyFiltersMaquinas(true)
      }"
      @newAsset="(name) => {
        newActivo.nombre = name;
        setActivo = true;
        setActivos = false
      }"
      @done="setWorkorderActives"
      @load="(e) => onLoadMaquinas(e)"
    />

    <!-- FIXME: OFFLINE NOT WORKING -->
    <MSetActivosSistema
      v-model="setActivosSistema"
      :data="(assets || []).flat()"
      :allSistemasLength="!!(computedActivosSistema || [])?.length"
      :newActivo="newActivo"
      @search="(e) => {
        if ((computedActivosSistema && assets?.length)) {
          activeSystemSearch = e
          applyFiltersMaquinasSistemas(true)
        }
      }"
      @done="setWorkorderActives"
      @selectSistema="selectingSistema = true"
      @load="(e) => onLoadMaquinasSistemas(e)"
    />

    <!-- FIXME: OFFLINE NOT WORKING -->
    <MSetSelectingSistema
      v-model="selectingSistema"
      :data="(sistemas || [])"
      @done="(e) => {
        newActivo.sistema = e;
        applyFiltersMaquinasSistemas(true);
        selectingSistema = false;
      }"
    />

    <MSetActivo
      v-model="setActivo"
      :newActivo="newActivo"
      @setModeloNewActivo="setModeloNewActivo = true"
      @setSistema="setSistema = true"
      @addPhotoActivo="addPhotoActivo()"
      @galleryPicker="galleryPicker('asset')"
      @update:newActivo="(e) => newActivo = e"
      @done="setWorkorderActive"
      :isDisabled="isDisabledActive"
    />

    <MSetHora
      v-model="setHora"
      :newHora="newHora"
      :isMainTechnician="isMainTechnician"
      :isTecnicosAdicionales="isTecnicosAdicionales"
      :tipoPorDefecto="tipoTiempoPorDefecto"
      @tecnicosModal="tecnicosModal = true"
      @setTipoTiempos="setTipoTiempos = true"
      @done="setStartTiempos"
    />

    <MSetBloqueHora
      v-model="addBloqueHoras"
      :newHoraBlock="newHoraBlock"
      :selectedBloque="selectedBloque"
      :isMainTechnician="isMainTechnician"
      :isTecnicosAdicionales="isTecnicosAdicionales"
      :techName="getTechName"
      @tecnicosModal="tecnicosModal = true"
      @partidasModal="partidasModal = true"
      @update:selectedBloque="(i) => selectedBloque = i"
      @update:newHoraBlock="(e) => newHoraBlock.observaciones = e?.value || ''"
      @tiposTiempoModal="tiposTiempoModal = true"
      @done="setWorkorderTimesBlock"
    />

    <MSetAddHoras
      ref="refSetAddHoras"
      v-model="addHoras"
      :newHora="newHora"
      :isMainTechnician="isMainTechnician"
      :isTecnicosAdicionales="isTecnicosAdicionales"
      :hasPartidas="(workorder?.obra?.partidas || [])?.length"
      :comportamientos="{
        campo_tipo_tiempo_obligatorio: +this.gmao.comportamientos?.campo_tipo_tiempo_obligatorio,
        usar_partidas_en_obras: +this.gmao.comportamientos?.usar_partidas_en_obras
      }"
      :hayTipo="hayTipo"
      :techName="getTechName"
      :tiposTiempo="hourTypes"
      :tipoPorDefecto="tipoTiempoPorDefecto"
      :deleteItemCallback="(hour) => deleteTimes(hour)"
      @tecnicosModal="tecnicosModal = true"
      @partidasModal="partidasModal = true"
      @setTipoTiempos="setTipoTiempos = true"
      @done="setWorkorderTimes"
    />

    <MSetTecnicosModal
      v-model="tecnicosModal"
      :newHora="newHora"
      :isTecnicosAdicionales="isTecnicosAdicionales"
      :data="computedTecnicos"
      :tecnicoSearch="tecnicoSearch"
      @update:tecnicoSearch="(e) => tecnicoSearch = e"
      @done="(e) => {
        if (!e) {
          newHora.tecnico = { nombre_completo: $t('todos-los-tecnicos') };
          newHoraBlock.tecnico = { nombre_completo: $t('todos-los-tecnicos') };
        } else {
          newHora.tecnico = e;
          newHoraBlock.tecnico = e;
        }
        tecnicosModal = false;
      }"
    />

    <MSetPartidasModal
      v-model="partidasModal"
      :data="workorder?.obra?.partidas"
      @done="(e) => selectingPartida(e)"
    />

    <!-- FIXME: DUPLICATED? SETTIPOTIEMPOS & SETTIPOTIEMPO -->
    <MSetTipoTiempo
      v-model="tiposTiempoModal"
      :data="hourTypes"
      @done="(event) => selectingTipoTiempo(event)"
    />

    <!-- *NEW — REVIEW: Se guardan pero no visualizan los cambios -->
    <MSetProblemaFAQ
      v-model="setProblemaFAQ"
  :data="questions.filter(question => {
    const query = (searchQueryQuestion || '').toLowerCase().trim(); // Normaliza el término de búsqueda
    return !query || question.texto.toLowerCase().includes(query); // Filtro dinámico
  })"
      @done="(event) => {
        if (!event) {
          workorder.id_problema_faq = null;
          getReplies(workorder.id_problema_faq, true);
        } else {
          workorder.id_problema_faq = event.id;
          workorder.problemaFAQ = event.texto;
          setWorkorderUpdate({id_problema_faq: event.id})
        }
        setProblemaFAQ = false;
      }"
    />

    <MSetSolucionFAQ
      v-model="setSolucionFAQ"
      :data="replies"
      @done="(event) => {
        if (!event) {
          workorder.id_solucion_faq = null;
          workorder.solucionFAQ = null;
        } else {
          workorder.id_solucion_faq = event.id;
          workorder.solucionFAQ = event.texto;
        }
        setSolucionFAQ = false;
      }"
    />

    <MSetSistema
      v-model="setSistema"
      :data="sistemas"
      @done="(s) => {
        if (!s) {
          newActivo.id_sistema = null;
          newActivo.nombreSistema = null;
        } else {
          newActivo.id_sistema = s.id;
          newActivo.nombreSistema = s?.nombre;
        }
        setSistema = false;
      }"
    />

    <MSetActivoMaterial
      v-model="setActivoMaterial"
      :data="workorderAssets"
      @done="(maquina) => {
        if (!maquina) {
          selectedMaterial.activo = null;
          selectedMaterial.maquinaNombre = null;
        } else {
          selectedMaterial.activo = maquina.id;
          selectedMaterial.nombreActivo =
            (maquina?.modelo?.modelo ?? '') +
            ' ' +
            (maquina?.modelo?.marca ?? '') +
            ' ' +
            (maquina?.nombre ?? 'Sin nombre');
        }
        setActivoMaterial = false;
      }"
    />

    <!-- TODO: +*+ SEGUIMIENTO -->
    <MSetMaterials
      v-model="setMaterials"
      :selectedMaterial="this.selectedMaterial"
      :offlineToolkit="{
        offlineModule,
        offlineActionsController
      }"
      :cliente="workorder?.direccion?.id_cliente || null"
      :comportamientos="{
        ver_codigo_articulo_en_partes_materiales: +gmao.comportamientos.ver_codigo_articulo_en_partes_materiales
      }"
      @done="(material) => {
        if (editMaterial) {
          selectedMaterial.articulo = material;
          selectedMaterial.id_articulo = material.id;
          selectedMaterial.articulo_movimiento = {};
          selectedMaterial.articulo_movimiento.almacen = {};
          selectedMaterial.articulo_movimiento.almacen.almacen = {};
          getAlmacenesMaterial(selectedMaterial?.articulo?.id, true);

        } else {
          selectedMaterial.material = material;
        }
        setMaterials = false;
      }"
      @done:image="(material) => {
        datosMaterial = material;
        imageMaterialModal = true;
      }"
    />

    <MSearchTools
      v-model="searchingTools"
      :parte="workorder?.id"
      :editable="!workorder?.not_editable"
    />

    <MSetModeloNewActivo
      ref="refSetModeloNewActivo"
      v-model="setModeloNewActivo"
      :offlineToolkit="{
        offlineModule,
        offlineActionsController
      }"
      @done="(m) => {
        newActivo.id_modelo = m.id;
        newActivo.modeloNombre = `${m.marca ?? ''} - ${m.modelo}`;
        getAvailableChecklist(m)
        setModeloNewActivo = false;
      }"
      @newModelo="(m) => {
        setNewModelo = true;
        newModelo.modelo = m || 'Modelo';
      }"
    />

    <MSetNewModelo
      v-model="setNewModelo"
      :newModelo="newModelo"
      @done="setNewModeloWO"
      @update:newModelo="(m) => newModelo = m"
    />

    <MSetMaterialLotes
      v-model="setMaterialLotes"
      :data="lotes"
      @done="(lote) => {
        if (
          gmao.modulos.modulo_stockage &&
          gmao.modulos.modulo_lotes &&
          selectedMaterial?.movistock?.lote
        ) {
          selectedMaterial.movistock.lote.id = lote?.lote.id;
          selectedMaterial.movistock.lote.codigo = lote?.lote.codigo;
        } else {
          selectedMaterial.lote = lote;
        }
        setMaterialLotes = false;
      }"
    />

    <!--
      TODO: Revisar el siguiente flujo:
        Edito material. Cambio almacen -> Se cambia el lote, se vacia? o se mantiene igual?
    -->
    <MSetMaterialAlmacen
      v-model="setMaterialAlmacen"
      :data="almacenes"
      @done="(almacen) => {
        if (selectedMaterial.id) {
          selectedMaterial.articulo_movimiento.almacen.almacen = almacen;
          selectedMaterial.articulo_movimiento.almacen.id_almacen = almacen.id;
          selectedMaterial.articulo_movimiento.id_almacen = almacen.id;
            gmao.modulos.modulo_lotes && getLotesMaterial(
              almacen?.id,
              selectedMaterial?.articulo?.id,
              true
            );
        } else {
          selectedMaterial.almacen = almacen;
          gmao.modulos.modulo_lotes && getLotesMaterial(
            { id: almacen?.id },
            selectedMaterial?.material?.id
          );
        }
          setMaterialAlmacen = false;
      }"
    />

    <MSetImageModal
      v-model="imageModal"
      :data="workorder.imagenes || []"
      :index="clickedIndex"
      :is_offline="!offline.status"
    />

    <MSetImageModal
      v-model="imageModalIncidence"
      :data="workorder.incidencia?.imagenes || []"
      :index="clickedIndex"
      :is_offline="!offline.status"
      :is_incidence="true"
    />

    <MSetChatModal
      v-model="chatModal"
      :data="workorder.comunicaciones || []"
      @done="(c) => setNewCommunication(c)"
    />

    <MSetFirma
      ref="firmaPadRef"
      v-model="setFirma"
      @done="saveFirma"
    />

    <!-- FIRMA TÉCNICO -->
    <MSetFirma
      ref="firmaPadRefTecnico"
      v-model="setFirmaTecnico"
      :firma_tecnico="true"
      @done="saveFirmaTecnico"
    />

    <MSetImageMaterialModal
      v-model="imageMaterialModal"
      :data="datosMaterial"
      @done="(c) => setNewCommunication(c)"
    />

    <MSetDuplicateWorkorder
      v-model="duplicateWorkorder"
      :maquinas="notFinishedActives"
      :workorder="workorder"
      :offlineToolkit="{
        offlineModule,
        offlineActionsController
      }"
      @goBack="goBack"
    />

    <MSetMakeBudget
      v-model="setMessageForBudget"
      :workorder="workorder"
      @goBack="goBack"
    />

    <MSetAddWODocument
      v-model="addWODocument"
      :workorder="workorder"
      @done="getWorkorder(this.workorder.id)"
    />

    <MSetAddExpense
      ref="addExpenseRef"
      v-model="addExpense"
      :modulos="{
        modulo_proyectos: gmao.user.modulos?.modulo_proyectos
      }"
      :comportamientos="{
        gastos_oculta_pagado: gmao.comportamientos.gastos_oculta_pagado,
        texto_aviso_gasto_nuevo: gmao.comportamientos.texto_aviso_gasto_nuevo,
        foto_obligatoria_gastos: gmao.comportamientos.foto_obligatoria_gastos,
      }"
      :workorder="workorder"
      @done="getWorkorder(this.workorder.id)"
      @setFamilia="(value) => {
        setFamilia = true;
        $refs.setFamiliaRef.getExpensesFam(null, this.workorder.proyecto?.id);
      }"
      @setSubFamilia="(value) => {
        setSubFamilia = true
        $refs.setSubFamiliaRef.getExpensesFam(value.familia_id, value.proyecto_id);
      }"
    />

    <MSetAddDisplacement
      ref="addDisplacementRef"
      v-model="addDisplacement"
      :comportamientos="{
        mostrar_cuenta_linea: gmao.comportamientos.mostrar_cuenta_linea,
      }"
      @done="getWorkorder(this.workorder.id)"
    />

    <MSetFamilia
      ref="setFamiliaRef"
      v-model="setFamilia"
      @setSubFamilia="(value) => {
        setSubFamilia = true;
        $refs.setSubFamiliaRef.getExpensesFam(value?.tipo?.id, workorder?.proyecto?.id);
      }"
      @update:expenseAdd="(value) => {
        $refs.addExpenseRef.expenseAdd = value
          console.log(value)
      }"
    />

    <MSetSubFamilia
      ref="setSubFamiliaRef"
      v-model="setSubFamilia"
      @update:expenseAdd="(value) => {
        $refs.addExpenseRef.expenseAdd.subtipo = value
      }"
    />

    <!-- FIRMA TÉCNICO -->
    <MSetWoDrawing
      ref="woDrawing"
      v-model="setWoDrawing"
      :workorder="workorder"
      @done="getWorkorder($route.params.id || workorder?.id)"
    />

    <MSetFinder
      v-model="setEditSistemaOT"
      :returnWholeObject="true"
      @done="(e) => {
        setUpdateWoSystem(e)
      }"
      :data="sistemas"
      :content="{
        title: 'Selecciona una instalación',
        empty_title: 'No hay instalaciones',
        multiple: false,
      }"
      :structure="[
        {
          tag: 'p',
          key: 'nombre'
        }
      ]"
    />
    <!-- POR MIGRAR -->
    <ion-modal
      :is-open="editMaterial"
      :initialBreakpoint="0.95"
      @didDismiss="editMaterial = false"
    >
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="editMaterial = false">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>

          <ion-title>{{ $t('Editar artículo') }}</ion-title>

          <ion-buttons slot="end">
            <ion-button @click="setWorkorderMaterials(true)">{{
              $t('Editar')
            }}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">
        <ion-list style="border-radius: 15px">
          <ion-item>
            <ion-label>{{ $t('Cantidad') }}</ion-label>
            <ion-input
              class="ion-text-right"
              v-model="selectedMaterial.cantidad"
              type="tel"
              min="0"
            />
          </ion-item>

          <ion-item v-if="workorder.sistema && workorder?.maquinas?.length">
            <ion-label>{{ $t('Activo') }}</ion-label>
            <ion-input
              class="ion-text-right"
              @click="setActivoMaterial = true; getMaquinas(workorder?.id || $route?.params?.id || null)"
              :value="selectedMaterial.nombreActivo"
              readonly
            />
          </ion-item>

          <template v-if="gmao.modulos.modulo_stockage">
            <ion-item @click="setMaterials = true">
              <ion-label>{{ $t('Artículo') }}</ion-label>
              <ion-input
                class="ion-text-right"
                :value="selectedMaterial?.articulo?.nombre"
                readonly
                clear-input
                @ionChange="
                  () => {
                    if (
                      gmao.modulos.modulo_lotes &&
                      selectedMaterial?.movistock?.lote
                    ) {
                      selectedMaterial.movistock.lote.codigo = '';
                    } else {
                      selectedMaterial.lote = null;
                    }
                  }
                "
              ></ion-input>
            </ion-item>

            <ion-item>
              <ion-label>{{ $t('Almacén') }}</ion-label>
              <ion-input
                class="ion-text-right"
                :value="selectedMaterial?.articulo_movimiento?.almacen?.almacen?.nombre"
                readonly
                @click="setMaterialAlmacen = true"
              />
            </ion-item>

            <template v-if="gmao.modulos.modulo_lotes">
              <ion-item v-if="selectedMaterial?.movistock?.lote">
                <ion-label>{{ $t('Lote') }}</ion-label>
                <ion-input
                  class="ion-text-right"
                  v-if="lotes"
                  :value="selectedMaterial?.movistock?.lote?.codigo"
                  readonly
                  @click="setMaterialLotes = true"
                />
              </ion-item>
              <ion-item v-else>
                <ion-label>{{ $t('Lote') }}</ion-label>
                <ion-input
                  class="ion-text-right"
                  v-if="lotes"
                  :value="selectedMaterial?.lote?.lote?.codigo"
                  readonly
                  @click="setMaterialLotes = true"
                />
              </ion-item>
            </template>

            <ion-item v-if="selectedMaterial?.articulo?.almacenes[0]?.pivot?.ubicacion">
              <ion-label>{{ $t('Ubicacion') }}</ion-label>
              <ion-input
                class="ion-text-right"
                :value="selectedMaterial?.articulo?.almacenes[0]?.pivot?.ubicacion"
                readonly
                clear-input
              />
            </ion-item>

          </template>

          <ion-item
            v-if="+gmao.comportamientos?.usar_partidas_en_obras && workorder?.obra?.partidas"
            @click="partidasModal = true"
          >
            <ion-label>{{ $t('Partida presupuestaria') }}</ion-label>
            <ion-input
              class="ion-text-right"
              :value="
                selectedMaterial.partida
                  ? `${selectedMaterial.partida.codigo} - ${selectedMaterial.partida.descripcion}`
                  : ''
              "
              readonly
              clear-input
            ></ion-input>
          </ion-item>

          <ion-item>
            <ion-label position="floating">{{ $t('Description') }}</ion-label>
            <ion-textarea v-model="selectedMaterial.observaciones"></ion-textarea>
          </ion-item>

          <ion-item v-if="gmao.modulos.modulo_faltas">
            <ion-label>
              {{ $t('Falta por recibir') }}
              <br/>
              <ion-note
                class="ion-text-nowrap"
                style="font-size: 10px; color: var(--ion-color-danger)"
                v-if="selectedMaterial.faltas"
              >
                {{ $t('El parte pasará al estado asignado a faltas siempre que esté definido.') }}
              </ion-note>
            </ion-label>
            <ion-checkbox
            :disabled="
              gmao.modulos.modulo_stockage && (!selectedMaterial.articulo_movimiento.almacen.almacen || !selectedMaterial.articulo) || !selectedMaterial.articulo
            "
            slot="end"
            color="tertiary"
            v-model="selectedMaterial.faltas"
            ></ion-checkbox>

          </ion-item>

          <!-- TODO: -->
          <!-- <ion-item>
            <ion-label>{{ $t('Imagen') }}</ion-label>
            <ion-button
              class="ion-no-margin"
              size="small"
              fill="clear"
              color="tertiary"
              @click="addPhotoMaterial()"
            >
              <ion-icon :icon="camera"></ion-icon>
            </ion-button>
          </ion-item>

          <ion-img
            v-if="selectedMaterial.image"
            style="width: 200px; height: 200px"
            :src="selectedMaterial.image"
          ></ion-img> -->
        </ion-list>

        <div style="text-align: center">
          <ion-button class="f30" fill="clear" @click="qrArticle = true">
            <ion-icon :icon="qrCodeOutline"></ion-icon>
          </ion-button>
        </div>
      </ion-content>
    </ion-modal>

    <ion-modal
      :is-open="setNewMaterialAlmacen"
      :initialBreakpoint="0.95"
      @didDismiss="setNewMaterialAlmacen = false"
    >
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="setNewMaterialAlmacen = false">{{
              $t('Cancelar')
            }}</ion-button>
          </ion-buttons>

          <ion-title>{{ $t('Seleccionar almacen') }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-list fullscreen>
          <ion-item
            v-for="(almacen, key) in almacenes"
            :key="`material-almacen-${key}`"
            @click="
              () => {
                selectedMaterial.almacen = almacen;
                getLotesMaterial(
                  { id: selectedMaterial?.almacen?.id },
                  selectedMaterial?.material?.id
                );
                setNewMaterialAlmacen = false;
              }
            "
          >
            {{ almacen?.nombre }}
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-modal>

    <ion-modal
      :is-open="setMaterialActivo"
      :initialBreakpoint="0.95"
      @didDismiss="setMaterialActivo = false"
    >
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="setMaterialActivo = false">{{
              $t('Cancelar')
            }}</ion-button>
          </ion-buttons>

          <ion-title>{{ $t('Seleccionar activo') }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-list fullscreen>
          <ion-item
            @click="
              () => {
                selectedMaterial.activo = null;
                selectedMaterial.maquinaNombre = null;
                setMaterialActivo = false;
              }
            "
          >
            <ion-label>
              <h3 class="grey">– {{ $t('Sin activo') }} –</h3>
            </ion-label>
          </ion-item>
          <ion-item
            v-for="(maquina, key) in nestedAssetsWO"
            :key="`maquina-material-${key}`"
            @click="
              () => {
                selectedMaterial.activo = maquina.id;
                selectedMaterial.maquinaNombre =
                  (maquina?.modelo?.modelo ?? '') +
                  ' ' +
                  (maquina?.modelo?.marca ?? '') +
                  ' ' +
                  (maquina?.nombre ?? 'Sin nombre');
                setMaterialActivo = false;
              }
            "
          >
            {{
              (maquina?.modelo?.modelo ?? '') +
              ' ' +
              (maquina?.modelo?.marca ?? '') +
              ' ' +
              (maquina?.nombre ?? 'Sin nombre')
            }}
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-modal>

    <!-- ==> OPTIMIZE: BLOQUE_MATERIALES -->
    <ion-modal
      :is-open="addMaterial"
      :initialBreakpoint="0.95"
      @didDismiss="addMaterial = false"
    >
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="addMaterial = false">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>

          <ion-title>{{ $t('Añadir artículo') }}</ion-title>

          <ion-buttons slot="end">
            <ion-button @click="setWorkorderMaterials(null)">{{
              $t('Añadir')
            }}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">
        <ion-list style="border-radius: 15px">
          <ion-item>
            <ion-label>{{ $t('Cantidad') }}</ion-label>
            <ion-input
              class="ion-text-right"
              v-model="selectedMaterial.cantidad"
              type="tel"
              min="0"
            />
          </ion-item>

          <!-- v-if="workorder.sistema && workorder.sistema.maquinas?.length > 0" -->
          <ion-item v-if="workorder.sistema && workorder?.maquinas?.length">
            <ion-label>{{ $t('Activo') }}</ion-label>

            <ion-input
              class="ion-text-right"
              @click="setActivoMaterial = true; getMaquinas(workorder?.id || $route?.params?.id || null)"
              :value="selectedMaterial?.nombreActivo"
              readonly
              clear-input
            ></ion-input>
          </ion-item>

          <template v-if="gmao.modulos.modulo_stockage">
            <ion-item @click="setMaterials = true">
              <ion-label>{{ $t('Artículo') }}</ion-label>
              <ion-input
                class="ion-text-right"
                :value="selectedMaterial?.material?.nombre"
                readonly
                clear-input
              />
            </ion-item>
  
            <ion-item @click="getAlmacenesMaterial">
              <ion-label>{{ $t('Almacén') }}</ion-label>
              <ion-input
                class="ion-text-right"
                :value="selectedMaterial?.almacen?.nombre"
                readonly
                :disabled="!selectedMaterial.material"
                @click="setMaterialAlmacen = true"
                @ionChange="
                  getLotesMaterial(
                    { id: selectedMaterial?.almacen?.id },
                    selectedMaterial?.material?.id
                  )
                "
              />
            </ion-item>
  
            <ion-item v-if="gmao.modulos.modulo_lotes">
              <ion-label>{{ $t('Lote') }}</ion-label>
              <ion-input
                class="ion-text-right"
                :value="selectedMaterial.lote?.lote?.codigo"
                :disabled="!selectedMaterial.almacen"
                readonly
                @click="setMaterialLotes = true"
              />
            </ion-item>

            <ion-item v-if="selectedMaterial?.almacen?.articulos?.length && selectedMaterial?.almacen?.articulos[0]?.pivot?.ubicacion">
              <ion-label>{{ $t('Ubicacion') }}</ion-label>
              <ion-input
                class="ion-text-right"
                :value="selectedMaterial?.almacen?.articulos[0]?.pivot?.ubicacion"
                readonly
                clear-input
              />
            </ion-item>
          </template>


          <ion-item
            v-if="+gmao.comportamientos?.usar_partidas_en_obras && workorder?.obra?.partidas"
            @click="partidasModal = true"
          >
            <ion-label>{{ $t('Partida presupuestaria') }}</ion-label>
            <ion-input
              class="ion-text-right"
              :value="
                selectedMaterial.partida
                  ? `${selectedMaterial.partida.codigo} - ${selectedMaterial.partida.descripcion}`
                  : ''
              "
              readonly
              clear-input
            ></ion-input>
          </ion-item>

          <ion-item>
            <ion-label position="floating">{{ $t('Description') }}</ion-label>
            <ion-textarea v-model="selectedMaterial.descripcion"></ion-textarea>
          </ion-item>

          <ion-item v-if="gmao.modulos.modulo_faltas">
            <ion-label>
              {{ $t('Falta por recibir') }}
              <br/>
              <ion-note
                class="ion-text-nowrap"
                style="font-size: 10px; color: var(--ion-color-danger)"
                v-if="selectedMaterial.faltas"
              >
                {{ $t('El parte pasará al estado asignado a faltas siempre que esté definido.') }}
              </ion-note>
            </ion-label>
            <ion-checkbox
              :disabled="
                gmao.modulos.modulo_stockage && (!selectedMaterial?.almacen || !selectedMaterial.material) || !selectedMaterial.material
              "
              slot="end"
              color="tertiary"
              v-model="selectedMaterial.faltas"
            ></ion-checkbox>

          </ion-item>

          <ion-item>
            <ion-label>{{ $t('Imagen') }}</ion-label>
            <ion-button
              size="small"
              fill="clear"
              color="tertiary"
              @click="addPhotoMaterial()"
            >
              <ion-icon :icon="camera"></ion-icon>
            </ion-button>
          </ion-item>

          <ion-img
            v-if="selectedMaterial.image"
            style="width: 200px; height: 200px"
            :src="selectedMaterial.image"
          ></ion-img>
        </ion-list>

        <div style="text-align: center">
          <ion-button class="f30" fill="clear" @click="qrArticle = true">
            <ion-icon :icon="qrCodeOutline"></ion-icon>
          </ion-button>
        </div>
      </ion-content>
    </ion-modal>
    <!-- ==> OPTIMIZE: /BLOQUE_MATERIALES -->

    <!-- COMPONENTES -->
    <QRScanner
      v-model="qrArticle"
      :title="$t('escanea-qr-de-articulo')"
      @success="getArticle"
      prompt-code
    />

    <QRScanner
      v-model="qrActivo"
      :title="$t('escanea-qr-de-activo')"
      @success="setMaquina"
      prompt-code
    />

    <NewWorkorder
      v-model="settingPartes"
      :custom-address="workorder?.direccion"
      :anomalies-assets="workorderAnomalyAssets"
      @done="createdWorkorder"
      @goBack="goBack"
    />

    <NewIncidence
      v-model="settingIncidencias"
      :custom-address="workorder?.direccion"
      :anomalies-assets="workorderAnomalyAssets"
      :sistema-id="workorder.id_sistema"
      @done="updateWorkorderIncidenceID"
      @goBack="goBack"
    />

    <VideoPlayer
      v-if="showVideoPlayer"
      v-model="showVideoPlayer"
      ref="videplayer_ref"
      :videoData="playVideoObject"
    />

  </ion-page>
</template>

<script>

import {
  actionSheetController,
  alertController,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  IonFooter,
  toastController,
  loadingController,
  IonDatetime,
  IonImg,
  IonSelect,
  IonSelectOption,
  isPlatform,
} from '@ionic/vue';

// import { Geolocation } from '@capacitor/geolocation';

import {
  add,
  addCircleOutline,
  call,
  camera,
  carSportOutline,
  chevronBack,
  chevronForward,
  closeCircle,
  closeOutline,
  createOutline,
  imagesOutline,
  informationCircle,
  mapOutline,
  phonePortraitOutline,
  qrCodeOutline,
  stopwatchOutline,
  play,
  downloadOutline,
  checkmarkOutline,
  playCircleOutline,
  stopCircleOutline,
  documentTextOutline,
  compass,
  banOutline,
  person,
} from 'ionicons/icons';

import moment from 'moment';

import AppToolbar from '@/components/AppToolbar.vue';
import DocumentTree from '@/components/DocumentTree.vue';

import QRScanner from '@/components/QRScanner.vue';
import NewWorkorder from '@/components/NewWorkorder.vue';
import NewIncidence from '@/components/NewIncidence.vue';

// VIEW COMPONENT IMPORT
import VCommunications from '@/components/Workorder/VCommunications.vue';
import GIonInput from '@/components/GIonInput.vue';


// MODAL COMPONENT IMPORT
import MSetTipoTiempo from '@/components/Workorder/MSetTipoTiempo.vue';
import MSetOperarioAdicional from '@/components/Workorder/MSetOperarioAdicional.vue';
import MSetOperario from '@/components/Workorder/MSetOperario.vue';
import MSetTextoSolucion from '@/components/Workorder/MSetTextoSolucion.vue';
import MSetEspecialidad from '@/components/Workorder/MSetEspecialidad.vue';
import MSetSubEspecialidad from '@/components/Workorder/MSetSubEspecialidad.vue';
import MSetActivos from '@/components/Workorder/MSetActivos.vue';
import MSetActivosSistema from '@/components/Workorder/MSetActivosSistema.vue';
import MSetSelectingSistema from '@/components/Workorder/MSetSelectingSistema.vue';
import MSetActivo from '@/components/Workorder/MSetActivo.vue';
import MSetHora from '@/components/Workorder/MSetHora.vue';
import MSetBloqueHora from '@/components/Workorder/MSetBloqueHora.vue';
import MSetAddHoras from '@/components/Workorder/MSetAddHoras.vue';
import MSetTecnicosModal from '@/components/Workorder/MSetTecnicosModal.vue';
import MSetPartidasModal from '@/components/Workorder/MSetPartidasModal.vue';
import MSetProblemaFAQ from '@/components/Workorder/MSetProblemaFAQ.vue';
import MSetSolucionFAQ from '@/components/Workorder/MSetSolucionFAQ.vue';
import MSetSistema from '@/components/Workorder/MSetSistema.vue';
import MSetActivoMaterial from '@/components/Workorder/MSetActivoMaterial.vue';
import MSetMaterials from '@/components/Workorder/MSetMaterials.vue';
import MSearchTools from '@/components/Workorder/MSearchTools.vue';
import MSetModeloNewActivo from '@/components/Workorder/MSetModeloNewActivo.vue';
import MSetMaterialLotes from '@/components/Workorder/MSetMaterialLotes.vue';
import MSetMaterialAlmacen from '@/components/Workorder/MSetMaterialAlmacen.vue';
import MSetImageModal from '@/components/Workorder/MSetImageModal.vue';
import MSetFirma from '@/components/Workorder/MSetFirma.vue';
import MSetChatModal from '@/components/Workorder/MSetChatModal.vue';
import MSetImageMaterialModal from '@/components/Workorder/MSetImageMaterialModal.vue';
import MSetDuplicateWorkorder from '@/components/Workorder/MSetDuplicateWorkorder.vue';
import MSetMakeBudget from '@/components/Workorder/MSetMakeBudget.vue';
import MSetAddWODocument from '@/components/Workorder/MSetAddWODocument.vue';
import MSetAddExpense from '@/components/Workorder/MSetAddExpense.vue';
import MSetFamilia from '@/components/Workorder/MSetFamilia.vue';
import MSetSubFamilia from '@/components/Workorder/MSetSubFamilia.vue';
import MSetWoDrawing from '@/components/Workorder/MSetWoDrawing.vue';
import MSetNewModelo from '@/components/Workorder/MSetNewModelo.vue';
import MSetAddDisplacement from '@/components/Workorder/MSetAddDisplacement.vue';

import MSetFinder from '@/components/MSetFinder.vue';


// Capacitor Video Player
import VideoPlayer from '@/components/CVideoPlayer.vue';

import { useGmaoStore } from '@/stores/gmao';
import { useWorkOrdersStore } from '@/stores/workorders';

import { ref, getCurrentInstance } from 'vue';

// OfflineModule
import { OfflineModule } from '@/utils/OfflineModule/OfflineModule';

// OfflineActionsController
import { OfflineActionsController } from '@/utils/OfflineModule/OfflineActionsController';

// ErrorController
import { ErrorController as errorController } from '@/utils/OfflineModule/ErrorController';

import { useOfflineStore } from '@/stores/offline';

export default {
  name: 'WorkOrderPage',

  components: {
    // VIEW
    VCommunications,

    // MODAL
    MSetTipoTiempo,
    MSetOperarioAdicional,
    MSetOperario,
    MSetTextoSolucion,
    MSetEspecialidad,
    MSetSubEspecialidad,
    MSetActivos,
    MSetActivosSistema,
    MSetSelectingSistema,
    MSetActivo,
    MSetHora,
    MSetBloqueHora,
    MSetAddHoras,
    MSetTecnicosModal,
    MSetPartidasModal,
    MSetProblemaFAQ,
    MSetSolucionFAQ,
    MSetSistema,
    MSetActivoMaterial,
    MSetMaterials,
    MSetModeloNewActivo,
    MSetMaterialLotes,
    MSetMaterialAlmacen,
    MSetImageModal,
    MSetFirma,
    MSetChatModal,
    MSetImageMaterialModal,
    MSetDuplicateWorkorder,
    MSetMakeBudget,
    MSetAddWODocument,
    MSetAddExpense,
    MSetFamilia,
    MSetSubFamilia,
    MSetWoDrawing,
    MSetAddDisplacement,
    MSetNewModelo,
    MSearchTools,

    MSetFinder,

    // OTHER
    AppToolbar,
    DocumentTree,
    QRScanner,
    NewWorkorder,
    NewIncidence,
    VideoPlayer,
    GIonInput,


    // IONIC
    IonTextarea,
    IonContent,
    IonPage,
    IonCard,
    IonCheckbox,
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonList,
    IonItemSliding,
    IonItemOption,
    IonItemOptions,
    IonButton,
    IonCardContent,
    IonGrid,
    IonRow,
    IonButtons,
    IonIcon,
    IonCol,
    IonItem,
    IonLabel,
    IonText,
    IonInput,
    IonCardHeader,
    IonCardSubtitle,
    IonFooter,
    IonDatetime,
    IonImg,
    IonSelect,
    IonSelectOption,
  },

  setup() {
    const gmao = useGmaoStore();
    const workOrders = useWorkOrdersStore();
    const app = getCurrentInstance();

    const offline = useOfflineStore();
    const offlineActionsController = new OfflineActionsController();
    const ErrorController = new errorController();

    /** NOTE: BLOQUE_HORAS */
    const initialState_newHora = {
      id: null,
      from: '00:00',
      to: '00:00',
      date: app.appContext.config.globalProperties.$moment().format('YYYY-MM-DD'),
      date_fin: app.appContext.config.globalProperties.$moment().format('YYYY-MM-DD'),
      hora: {tipo_tiempo: {}}
    };

    const newHora = ref({...initialState_newHora});

    /** REVIEW: Estas funciones se pueden hacer dinamicas, pero vamos por partes... */
    function resetState() {
      newHora.value = {
          ...initialState_newHora,
          hora: { ...initialState_newHora.hora }
      };
    }

    // function setState(horaObject) {
    //   for (const key in horaObject) {
    //     if (Object.prototype.hasOwnProperty.call(horaObject, key)) {
    //       newHora.value[key] = horaObject[key];
    //     }
    //   }
    //   // newHora.value = { ...initialState_newHora, ...horaObject };
    // }

    return {
      // ICONS
      app,
      gmao,
      offline,
      workOrders,
      add,
      call,
      camera,
      closeOutline,
      informationCircle,
      chevronBack,
      mapOutline,
      carSportOutline,
      phonePortraitOutline,
      chevronForward,
      qrCodeOutline,
      closeCircle,
      stopwatchOutline,
      createOutline,
      imagesOutline,
      play,
      downloadOutline,
      checkmarkOutline,
      playCircleOutline,
      stopCircleOutline,
      documentTextOutline,
      compass,
      banOutline,
      person,

      // MOMENT JS
      moment,

      // ==> Offline related
        offlineModule: ref(null),
        offlineActionsController,
        ErrorController,
      // <== Offline related

      navigator,
      estados: ref([]),
      setModeloNewActivo: ref(false),
      isDisabled: ref(false),
      loading: ref(false),
      workorder: ref({}),
      not_finished:ref(false),
      questions: ref([]),
      replies: ref([]),
      maquinasParte: ref([]),
      setActivoMaterial: ref(false),
      setMaterialAlmacen: ref(false),
      setMaterialLotes: ref(false),
      setProblemaFAQ: ref(false),
      setSolucionFAQ: ref(false),
      setSistema: ref(false),
      setActivos: ref(false),
      setActivosSistema: ref(false),
      selectingSistema: ref(false),
      setActivo: ref(false),
      setOperario: ref(false),
      setEspecialidad: ref(false),
      setSubespecialidad: ref(false),
      setFirma: ref(false),
      setFirmaTecnico: ref(false),
      setMaterials: ref(false),
      chatModal: ref(false),
      imageModal: ref(false),
      imageMaterialModal: ref(false),
      datosMaterial: ref(null),
      addMaterial: ref(false),
      addHoras: ref(false),
      addBloqueHoras: ref(false),
      setHora: ref(false),
      tecnicosModal: ref(false),
      partidasModal: ref(false),
      tiposTiempoModal: ref(false),
      selectedBloque: ref(null),
      duplicateWorkorder: ref(false),
      addWODocument: ref(false),
      setTipoTiempos: ref(false),
      hayTipo: ref(false),
      searchQueryQuestion:ref(null),
      searchQueryReply:ref(null),
      /** NOTE: BLOQUE_HORAS */
      newHora,
      resetState,
      // setState,
      /** NOTE: BLOQUE_HORAS */
      newHoraBlock: ref({
        hours: [
          { type: 'd_morning', from: null, to: null },
          { type: 'j_morning', from: null, to: null },
          { type: 'j_afternoon', from: null, to: null },
          { type: 'd_afternoon', from: null, to: null },
        ],
      }),
      newActivo: ref({}),
      newCommunication: ref(null),
      selectedMaterial: ref({
        cantidad: 1,
      }),
      almacenes: ref([]),
      sistemas: ref([]),
      lotes: ref([]),
      hourTypes: ref([]),
      activeSearch: ref(null),
      activeSystemSearch: ref(null),
      activeSearchAsset: ref(null),
      activeQuestionSearch: ref(null),
      activeReplieSearch: ref(null),

      tecnicoSearch: ref(null),
      signature: ref({}),
      signatureTecnico: ref({}),
      items: ref([]),
      tecnicos: ref([]),
      gamas: ref([]),
      subgamas: ref([]),
      clientDocs: ref([]),
      dirDocs: ref([]),
      assetDocs: ref([]),
      settingPartes: ref(false),
      settingIncidencias: ref(false),
      setTextoSol: ref(false),
      editStatus: ref(false),

      qrArticle: ref(false),
      qrActivo: ref(false),
      clickedIndex: ref(),
      clickedImageId: ref(),
      timeTracking: ref(null),
      time: ref({
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),

      setOperarioAdicional: ref(false),

      editMaterial: ref(false),

      current_page: ref(1),
      last_page: ref(null),
      total: ref(null),
      textosSoluciones: ref([]),
      imageModalIncidence: ref(false),
      showVideoPlayer: ref(false),
      playVideoObject: ref({
        src: ref(null),
      }),

      // PAGINACIONES
      herramientas_pagination: ref({
        current_page: ref(1),
        last_page: ref(null),
        total: ref(null)
      }),

      searchingTools: ref(false),

      workorderAnomalyAssets: ref([]),

      assets: ref([]),

      selectedTeam: ref(null),

      addExpense: ref(false),

      addDisplacement: ref(false),

      setFamilia: ref(false),
      setSubFamilia: ref(false),

      workorderAssets: ref(null),
      newStatus: ref(null),

      isWorkingDay: ref([]),

      setWoDrawing: ref(false),

      isDisabledActive: ref(false),
      newModelo: ref({}),
      setNewModelo: ref(false),

      tipoTiempoPorDefecto: ref({}),
      areChecklistsDone: ref(false),

      setDisplacement: ref(false),
      desplazamientoTipos: ref([]),
      openOTDateModal: ref(false),

      /** ==> PAGINACION LOCAL */
      horas_local_page: ref(1),
      horas_local_limit: ref(5),
      horas_local_cantidad: ref(0),

      lineas_local_page: ref(1),
      lineas_local_limit: ref(5),
      lineas_local_cantidad: ref(0),

      tecnicosAd_local_page: ref(1),
      tecnicosAd_local_limit: ref(5),
      tecnicosAd_local_cantidad: ref(0),

      /** <== PAGINACION LOCAL */

      // Local Counter
      alertAnomalyCount: ref(1),
      setEditSistemaOT: ref(false),

      isLoadingWorkorder: ref(false),

      setMessageForBudget: ref(false),

      anomaliasModal: ref(null),
      isAlertOpen: ref(false)
    };
  },

  beforeRouteLeave(to, from, next) {
    if (to?.name.includes('workorders')) this.offline.workorder = {};
    next();
  },

  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     vm.loadWorkorder();
  //   });
  // },


  async ionViewWillEnter() {
    await this.loadWorkorder();
    this.setTimeRegister();
  },

  /** XXX: Optimizar la inicialización de esta vista. Hay problemas con iniciar algunas llamadas,
   * antes de que se obtenga la OT, etc.
   */
  async created() {
    // Init OfflineModule
    this.offlineModule = OfflineModule.getInstance();

    console.log('Parte: ', this.offline.workorder);

    this.newActivo = {
      id_parte: this.offline.workorder?.id,
    };

    if (!((+this.gmao.comportamientos?.jornada_requerida_visualizar_ots && this.isRunning) || !+this.gmao.comportamientos?.jornada_requerida_visualizar_ots)) {
      this.$openToastObject(this.$t('No se puede acceder a la OT'), this.$t('Debes iniciar la jornada para acceder a la orden de trabajo'), 'danger');
      this.$router.go(-1);
    }

    if (this.$route.query.asset) {
      this.$router.push({
        name: 'item',
        params: { asset: this.$route.query.asset, workorder: this.workorder.id },
      });
    }

    this.loadMoreHours();
    this.loadMoreMateriales();
    this.loadMoreTecnicosAd();
    this.getHourTypes();
    this.askForTimer();
    this.resetState();
    this.setTimeRegister();
    this.getAlmacenes();
    
    if (this.workorder?.id && !this.workorder?.id_tecnico) {
      this.workorder.unassigned = true;
      this.workorder.not_editable = true;
    }

    this.selectedTeam = this.gmao?.user?.equipos_responsable.map((e) => e.id);
    // ESTABLECER ACTIVOS AL ENTRAR O EMPUJAR A COMPROBACIONES
    if (!+this.gmao.comportamientos.checklist_previo) {
      if (+this.gmao.comportamientos.selector_sistemas_parte) {
        this.setActivosSistema = !!this.$route.query?.set_activos;
      } else {
        this.setActivos =
          !!this.$route.query?.set_activos &&
          (this.workorder.sistema?.maquinas_count ||
            +this.gmao.comportamientos?.gmao_interno);
      }
    } else {
      const maquinaCheck = (this.workorder?.maquinas || [])?.find(
        (m) => m.nombre == 'Comprobaciones' && !+m.pivot?.tareas_completadas
      );

      if (maquinaCheck) {
        this.$router.push({
          name: 'item',
          params: { asset: maquinaCheck.id, workorder: this.workorder.id, sistema: maquinaCheck.id_sistema },
        });
      }
    }

    this.getTextosPredefinidos();

    if (this.workorder?.id_problema_faq) this.getReplies(this.workorder?.id_problema_faq);

    this.getMaquinas(this.workorder?.id || this.$route?.params?.id || null);

    // CHECK IF TIME IS TRACKING
    if (this.timeIsRunning) {
      this.timeTracking = setInterval(this.elapsedTime.bind(this), 1000);
    }
    this.getQuestions();

    this.resetState();

    if (+this.gmao.comportamientos?.cliente_email_app) {
      this.signature = {
        dni: this.workorder?.direccion?.email ? this.workorder?.direccion?.dni : null,
        nombre: this.workorder?.direccion?.email
          ? this.workorder?.direccion?.nombre
          : this.workorder?.direccion?.cliente?.nombre,
        email:
          this.workorder?.direccion?.email || this.workorder?.direccion?.cliente?.email,
      };
    }
  },

  watch: {
    'workOrders.timeRegister': {
      handler(value) {
        if (value) {
          this.timeTracking = setInterval(this.elapsedTime.bind(this), 1000);
        } else {
          clearInterval(this.timeTracking);
        }
      },
      deep: true,
    },
    'selectedMaterial.material': {
      handler(newValue, oldValue) {
        if (oldValue) {
          this.selectedMaterial.almacen = null;
          this.selectedMaterial.lote = null;
        }
      },
      deep: true,
    },
    'selectedMaterial.articulo': {
      handler(newValue, oldValue) {
        if (oldValue && this.selectedMaterial?.articulo_movimiento) {
          this.selectedMaterial.articulo_movimiento.almacen.almacen.id = null;
          if (this.selectedMaterial?.movistock) {
            this.selectedMaterial.movistock.lote.id = null;
          }
        }
      },
      deep: true,
    },

    async setDisplacement(value) {
      if (value) {
        await this.$refs?.addDisplacementRef.getDisplacementTypes().then((data) => {
          this.desplazamientoTipos = data;
        })
      }
    },

    // ==> OPTIMIZE: BLOQUE_GAMAS
      async setEspecialidad(value) {
        if (value) {
          this.gamas = await this.$axios
            .get('/v2/users/actions.php', {
              params: {
                call: 'getGamas',
                token: this.gmao.user.token,
                page: -1,
                v2: 1,
              },
            })
            .then(({ data }) => {
              return data;
            })
            .catch(async (e) => {
              if (e.code == 'ERR_NETWORK' && this.offline.status) {
                try {
                  const query = `
                    SELECT *
                    FROM gamas
                  `;

                  const setEspecialidadPromise = new Promise((resolve) => {
                    resolve(
                      this.offlineActionsController.SQLiteQuery(
                        'setEspecialidad_workorder',
                        'gamas',
                        query,
                        e.config,
                        this.offlineModule?.db
                      )
                    );
                  });
                  setEspecialidadPromise.then(() => {
                    // FIXME: *Revisar
                    setTimeout(() => {
                      this.gamas = this.offline.gamas;
                      // sqlite.closeConnection('gmaoTecnicos');
                    }, 500);
                  });
                } catch (err) {
                  // Error Controller
                  // this.$Sentry.captureException(err);
                }
              }
            });

          this.gamas = this.gamas?.filter((g) => !g.id_gama);
        }
      },

      setSubespecialidad(value) {
        if (value) {
          this.$axios
            .get('/v2/users/actions.php', {
              params: {
                call: 'getGamas',
                id_gama: this.workorder.id_gama,
                token: this.gmao.user.token,
                page: -1,
                v2: 1,
              },
            })
            .then(({ data }) => {
              this.subgamas = data;
            })
            .catch(async (e) => {
              if (e.code == 'ERR_NETWORK' && this.offline.status) {
                try {
                  const query = `
                    SELECT *
                    FROM gamas
                    WHERE id_gama=${this.workorder?.id_gama}
                  `;

                  const setSubespecialidadPromise = new Promise((resolve) => {
                    resolve(
                      this.offlineActionsController.SQLiteQuery(
                        'setSubespecialidad_workorder',
                        'gamas',
                        query,
                        e.config,
                        this.offlineModule?.db
                      )
                    );
                  });
                  setSubespecialidadPromise.then(() => {
                    // FIXME: *Revisar
                    setTimeout(() => {
                      this.subgamas = this.offline.subgamas;
                      // sqlite.closeConnection('gmaoTecnicos');
                    }, 500);
                  });
                } catch (err) {
                  // Error Controller
                  // this.$Sentry.captureException(err);
                }
              }
            });
        }
      },
    // <== OPTIMIZE: /BLOQUE_GAMAS

    // ==> OPTIMIZE: BLOQUE_MATERIALES
    // ==> OPTIMIZE: /BLOQUE_MATERIALES
      addMaterial(value) {
        if (!value) {
          this.selectedMaterial = {
            cantidad: 1,
          };
        }
      },

      editMaterial(value) {
        if (!value) {
          this.selectedMaterial = {
            cantidad: 1,
          };
        }
      },
    // ==> OPTIMIZE: /BLOQUE_MATERIALES

    setActivo(value) {
      if (!value) {
        this.newActivo = {
          id_parte: this.workorder.id,
        };
      } else {
        // Ahora se llama al abrir el componente
        // this.applyFiltersModelos(true);
        this.getSistemas().then((data) => {
          if (data?.length == 1) this.newActivo.id_sistema = data[0].id;
        });
        // this.applyFiltersSistemas(true).then(() => {
        //   if (this.sistemas.flat()?.length == 1) this.newActivo.id_sistema = this.sistemas.flat()[0].id
        // });
      }
    },

    selectingSistema(value) {
      if (value) {
        this.getSistemas();
        // this.applyFiltersSistemas(true);
      }
    },

    setActivos(value) {
      if (value) this.applyFiltersMaquinas(true);
    },

    // ==> OPTIMIZE: BLOQUE_OPERARIO
      setOperario(value) {
        if (value) {
          this.$axios
            .get('/v2/users/actions.php', {
              params: {
                call: 'getTecnicos',
                token: this.gmao.user.token,
                unassigned: this.workorder?.unassigned || '',
                team: this.selectedTeam?.toString() ||'',
                workorder: this.workorder?.id,
                page: -1,
                v2: 1,
              },
            })
            .then(({ data }) => {
              this.tecnicos = data;
              // this.selectedTeam = null;
            })
            .catch(async (e) => {
              if (e.code == 'ERR_NETWORK' && this.offline.status) {
                try {
                  const query = `
                    SELECT *
                    FROM usuarios
                    WHERE usuarios.id_role IN (5, 9) AND usuarios.deleted_at IS NULL
                    ORDER BY usuarios.nombre
                  `;

                  const setOperarioPromise = new Promise((resolve) => {
                    resolve(
                      this.offlineActionsController.SQLiteQuery(
                        'setOperario_workorder',
                        'usuarios',
                        query,
                        e.config,
                        this.offlineModule?.db
                      )
                    );
                  });
                  setOperarioPromise.then(() => {
                    // FIXME: *Revisar
                    setTimeout(() => {
                      this.tecnicos = this.offline.tecnicos;
                      // sqlite.closeConnection('gmaoTecnicos');
                    }, 500);
                  });
                } catch (err) {
                  // this.$Sentry.withScope(function(scope) {

                  //   scope.setTag("offline", "Workorder: Set Operario");

                  //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

                  // });
                    // Error Controller
                    // this.$Sentry.captureException(err);
                }
              }
            });
        }
      },
    // <== OPTIMIZE: /BLOQUE_OPERARIO

    // ==> OPTIMIZE: BLOQUE_OPERARIO_ADICIONAL
      setOperarioAdicional(value) {
        if (value) {
          this.$axios
            .get('/v2/users/actions.php', {
              params: {
                call: 'getTecnicos',
                token: this.gmao.user.token,
                unassigned: this.workorder?.unassigned || '',
                team: this.selectedTeam?.toString() ||'',
                workorder: this.workorder?.id,
                page: -1,
                v2: 1,
              },
            })
            .then(({ data }) => {
              if(this.workorder.tecnicos_adicionales.length) {
                this.tecnicos = data?.filter(
                  (v) => !this.workorder.tecnicos_adicionales.map((t) => t.id).includes(v.id)
                );
              } else {
                this.tecnicos = data;
              }
            })
            .catch(async (e) => {
              if (e.code == 'ERR_NETWORK' && this.offline.status) {
                try {
                  // FIXME:
                  // $proveedor = $this->worker->id_proveedor;
                  // $regs = Usuario::when($proveedor, function($q) use($proveedor) {
                  //   $q->where('id_proveedor', $proveedor);
                  // })->whereIn('id_role', [5, 9])->orderby('nombre');
                  const query = `
                    SELECT *
                    FROM usuarios
                    WHERE usuarios.id_role IN (5, 9) AND usuarios.deleted_at IS NULL
                    ORDER BY usuarios.nombre
                  `;
                  const getOperariosAdicPromise = new Promise((resolve) => {
                    resolve(
                      this.offlineActionsController.SQLiteQuery(
                        'setOperarioAdicional_workorder',
                        'usuarios',
                        query,
                        e.config,
                        this.offlineModule?.db
                      )
                    );
                  });
                  getOperariosAdicPromise.then(() => {
                    this.tecnicos = [];
                    // FIXME: *Revisar
                    setTimeout(() => {
                      this.tecnicos = this.offline.operariosAdicionales?.filter(
                        (v) =>
                          !this.workorder?.tecnicos_adicionales
                            ?.map((t) => t.id)
                            .includes(v?.id)
                      );
                      // sqlite.closeConnection('gmaoTecnicos');
                    }, 5);
                  });
                } catch (err) {
                  // this.$Sentry.withScope(function(scope) {

                  //   scope.setTag("offline", "Workorder: GET/SET OperariosAdicional");

                  //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

                  // });
                  // Error Controller
                  // this.$Sentry.captureException(err);
                }
              }
            });
        }
      },

    // <== OPTIMIZE: /BLOQUE_OPERARIO_ADICIONAL

    // OPTIMIZE: BLOQUE_HORAS
    addHoras(value) {
      if (value) {
        this.getHourTypes();
        if (!this.newHora.id) {
          // NOTE: Caso nuevo registro
          this.newHora.hora.tipo_tiempo = this.hourTypes?.find((t) => +t.tipo_defecto) || this.hourTypes?.find((t) => +t.por_defecto);
          this.newHora.nombreTipoTiempo = this.newHora.hora?.tipo_tiempo?.nombre;
          this.newHora.id_tipo_tiempo = this.newHora.hora?.tipo_tiempo?.id;

          this.newHora.tecnico = this.isTecnicosAdicionales && this.isMainTechnician
            ? { nombre_completo: this.$t('todos-los-tecnicos') }
            : this.workorder?.tecnicos_adicionales?.find((t) => t.id === this.gmao.user?.id) || this.workorder?.tecnico;
        } else {
          // NOTE: Caso cuando edito
        }
      } else {
        this.resetState();
      }
    },

    addBloqueHoras(value) {
      if (!value) {
        this.newHoraBlock = {
          hours: [
            { type: 'd_morning', from: null, to: null },
            { type: 'j_morning', from: null, to: null },
            { type: 'j_afternoon', from: null, to: null },
            { type: 'd_afternoon', from: null, to: null },
          ],
        };
      } else {
        this.getHourTypes();

        this.newHoraBlock.tecnico = this.isTecnicosAdicionales && this.isMainTechnician
          ? { nombre_completo: this.$t('todos-los-tecnicos') }
          : this.workorder?.tecnicos_adicionales?.find((t) => t.id === this.gmao.user?.id) || this.workorder?.tecnico;
      }
    },
    // OPTIMIZE: /BLOQUE_HORAS

    // NOTE: Esto es del registro del tiempo del parte
    setHora(value) {
      if (!value) {
        this.resetState();
      } else {
        this.getHourTypes();

        if (!('hora' in this.newHora)) this.newHora.hora = {};
        this.newHora.hora.tipo_tiempo = this.hourTypes?.find((t) => +t.tipo_defecto) || this.hourTypes?.find((t) => +t.por_defecto);
        this.newHora.id_tipo_tiempo = this.newHora.hora?.tipo_tiempo?.id;


        if (this.isMainTechnician && this.isTecnicosAdicionales && !+this.gmao.comportamientos?.tiempos_propio_tecnico) {
              this.newHora.tecnico = { nombre_completo: this.$t('todos-los-tecnicos') };
        } else {
              this.newHora.tecnico = this.computedTecnicos?.find((t) => t?.id == this.gmao?.user?.id) || this.workorder?.tecnico;
        }
      }
    },
  },

  computed: {
    avisar_escribir_anomalias_al_finalizar() {
      return this.workorder?.tipo_letra === 'P' && +this.gmao.comportamientos?.avisar_escribir_anomalias_al_finalizar
    },
    areTherePhotosInWO() {
      return this.workorder?.imagenes?.length ||
        this.workorder?.maquinas?.some((a) => {
          return a?.respuestas?.find((r) => r.foto?.length) || (+a?.imagenes_count || a?.imagenes?.length)
        });
    },
    isOTClosableWithoutSignature() {
      return !+this.workorder?.requiere_firma;
    },

    // OPTIMIZE: BLOQUE_HORAS
    isMainTechnician() {
      return this.workorder?.id_tecnico == this.gmao.user?.id;
    },

    isTecnicosAdicionales() {
      return this.workorder?.tecnicos_adicionales?.length;
    },
    // OPTIMIZE: 7BLOQUE_HORAS

    isRunning() {
      const last = this.gmao.time.at(-1);
      if (!last) return false;

      return !last.stop;
    },
    // OPTIMIZE: BLOQUE_HORAS
    getTechName() {
      if (this.newHora?.tecnico?.id) {
        return this.newHora?.tecnico?.nombre_completo;
      } else if (this.newHora?.id) {
        return this.newHora?.hora?.tecnico?.nombre_completo;
      }
      return this.$t('todos-los-tecnicos');
    },
    // OPTIMIZE: /BLOQUE_HORAS

    timeIsRunning() {
      return (
        this.workOrders?.timeRegister?.from &&
        this.workOrders?.timeRegister?.workorder === this.workorder.id
      );
    },

    finishedActives() {
      return this.workorder?.maquinas?.every(
        (m) =>
          (m.respuestas || [])
            ?.filter((r) => r.campo?.tipo != 'SEP')
            .every((r) => (isNaN(r.valor) ? !!r.valor : !!+r.valor))
      );
    },

    partiallyFinishedActives() {
      return this.workorder?.maquinas?.every(
        (m) =>
          m.respuestas
            ?.filter((r) => r.campo?.tipo != 'SEP')
            .some((r) => (isNaN(r.valor) ? !!r.valor : !!+r.valor))
      );
    },

    comportamientoVandalizadosGeneral() {
      return +this.gmao.comportamientos?.vandalizado_obligatoria_parte && this.workorder?.maquinas?.every(
        (m) => m.pivot?.vandalized == null
      );
    },

    comportamientoVandalizadosFoto() {
      const imagesAssetIds = this.workorder?.imagenes?.map((i) => i.id_maquina)?.filter(i => i)?.sort((a, b) => a-b);
      const assetsIds = this.workorder?.maquinas?.filter((m) => +m.pivot?.vandalized)?.map((m) => m.id)?.sort((a, b) => a-b);

      return +this.gmao.comportamientos?.vandalizado_obligatoria_parte &&
        assetsIds?.length &&
        !this.comportamientoVandalizadosGeneral &&
        !(imagesAssetIds?.length && imagesAssetIds?.every((a) => assetsIds.includes(a)));
    },

    notFinishedActives() {
      return this.workorder?.maquinas?.filter(
        (m) =>
          !(
            m.respuestas
              ?.filter((r) => r.campo?.tipo != 'SEP')
              .every((r) => (isNaN(r.valor) ? !!r.valor : !!+r.valor))
          )
      );
    },

    comportamientoSolucionObligatoria() {
      return !!+this.gmao.comportamientos?.solucion_obligatoria_parte && !this.workorder?.solucion?.length;
    },

    comportamientoNombreObligatoria() {
      return !!+this.gmao.comportamientos?.nombre_firma_obligatoria &&
        !+this.gmao.comportamientos.no_nombre &&
        !this.signature?.nombre?.length;
    },

    comportamientoNoFinalizadas() {
      return (
        (!!+this.gmao.comportamientos.checklist_obligatorio_tecnico &&
          !this.finishedActives) ||
        (!!+this.gmao.comportamientos.checklist_parcial_tecnico &&
          !this.partiallyFinishedActives)
      );
    },

    computedDisableFirma() {
      return (
        this.workorder.not_editable ||
        (!!this.workorder?.tecnicos?.find((t) => t.id_tecnico == this.gmao.user?.id) && !+this.gmao.comportamientos.tecnicos_secundarios_firma) ||
        this.comportamientoNoFinalizadas || this.comportamientoSolucionObligatoria || this.comportamientoVandalizadosGeneral || this.comportamientoVandalizadosFoto
      );
    },

    computedActivosSistema() {
      return this.newActivo.sistema
        ? this.sistemas?.find(
            (s) => s.id == this.newActivo.sistema.id
          )
        : null;
    },

    // FIXME: DES-USO
    // computedAddMaquinas() {
    //   const maq = this.computedActives
    //     .concat(this.activeSearch ? this.items : [])
    //     ?.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);

    //   return maq?.filter(
    //     (sm) =>
    //       !this.workorder?.maquinas?.some((m) => m.id == sm.id) &&
    //       Object.values({
    //         nombre: sm?.nombre,
    //         modelo_nombre: sm.modelo?.nombre,
    //         sistema: sm.sistema?.nombre,
    //         observacion: sm.observaciones,
    //         numero_serie: sm.numeroserie,
    //         ubicacion: sm.ubicacion,
    //         marca: sm.modelo?.marca,
    //         modelo: sm.modelo?.modelo,
    //       })
    //         .join(`;`)
    //         ?.toLowerCase()
    //         .includes((this.activeSearch || '').toLowerCase())
    //   );
    // },

    // FIXME: DES-USO
    // computedSelectableTecnicos() {
    //   return this.tecnicos?.filter((t) =>
    //     t?.nombre_completo.toLowerCase().includes(this.tecnicoSearch?.toLowerCase() || '')
    //   );
    // },

    computedTecnicos() {
      return this.workorder?.tecnicos_adicionales
        ?.concat([this.workorder?.tecnico])
        ?.filter((t) =>
          t?.nombre_completo
            .toLowerCase()
            .includes(this.tecnicoSearch?.toLowerCase() || '')
        );
    },

    isDuplicable() {
      /**
       *  FIXME: Hola, persona del futuro. Si falla en Conductivos, es aquí. Comprobar tipo letra "C" e "I"
       *  Parte de tipo I, preguntado y se debe duplicar, a no ser de nuevos cambios...
       * */
      return (
        (this.workorder.tipo_letra == 'P' &&
          !!+this.gmao.comportamientos.duplicar_no_finalizados_preventivos) ||
        (this.workorder.tipo_letra == 'C' &&
          !!+this.gmao.comportamientos.duplicar_no_finalizados) ||
        (this.workorder.tipo_letra == 'O' &&
          !!+this.gmao.comportamientos.duplicar_no_finalizados_obras) ||
        this.workorder.tipo_letra == 'I' ||
        this.workorder.tipo_letra == 'G'
      );
    },

    isFinished() {
      return (this.workorder?.estados_historicos || []).some((e) => [+this.gmao.comportamientos.estado_pasarfimatec].includes(+e?.id_estado));
    },

    isSupervisor() {
      return (this.workorder?.equipo?.responsables || []).some((e) => [+this.gmao?.user?.id].includes(+e?.id));
    }
  },

  methods: {

    isUserOnline() {
      if (this.offline.status) {
        this.$openToastObject(
          this.$t('offline-generic-action-not-available-msg'),
          this.$t('offline-generic-action-not-available-msg-guard'),
          'danger'
        );
        return false;
      }

      return true;
    },

    async setUpdateWoSystem(system) {

      await this.alertPopup(
        this.$t('warning-facility-change-ot'),
        async () => {
          let loading = null;
          loading = await loadingController.create({
            message: this.$t('loading'),
          });

          await loading.present();

          const formdata = new FormData();
          formdata.append('parte', this.workorder?.id || '');
          formdata.append('sistema', system?.id || '');

          // *NEW — XXX: [POST - setChangeWoSistem] NOT IN OFFLINE
          this.$axios
            .post(`/v2/users/actions.php?call=setChangeWoSistem&token=${this.gmao.user.token}&v2=1`, formdata)
            .then(({ data }) => {

              if (!+data.status) {
                this.$openToastObject(this.$t('Ha ocurrido un error'), data.mns || this.$t('No ...'), 'danger');
              } else this.getWorkorder(this.$route.params?.id);
            }).finally(async () => {await loading?.dismiss(); this.setEditSistemaOT = false});
        },
        { cancel: this.$t('cancelar'), confirm: this.$t('ok') }
      );
    },

    async getAddressSistems() {

      await this.getSistemas().then(() => {
        this.setEditSistemaOT = true
        // if (Array.isArray(data) && data?.length) 
        // else {
        //   this.$openToastObject(
        //     this.$t('Ha ocurrido un error'),
        //     this.$t('message-no-items-available'),
        //     'secondary'
        //   );
        // }
      });
    },

    resolveHourItemAction(item) {
      const isThereActiveTime = (this.workorder?.horas || [])
        ?.some((a) => !((a.fecha_fin && a.fin) || a.hasta));

      if (this.$hasPermissions('horas', 'editar')) {
        // NOTE: Control para tiempos en curso
        if (isThereActiveTime || !((item.fecha_fin && item.fin) || item.hasta)) {
          this.$openToastObject(
            this.$t('no_permission_edit'),
            this.$t('no_permission_edit-msg'),
            'dark',
            'bottom',
          );
          return;
        }
        this.editTimes(item);
      } else {
        this.$openToastObject(
          this.$t('Ha ocurrido un error'),
          this.$t('generic-edit-error-message')
        );
      }
    },
    /**
     * LOAD WORKORDER

     * LOCAL FUNCTION THAT LOADS WORKORDER ACCORDING TO NETWORK STATUS
     */
    async loadWorkorder() {
      if (this.isLoadingWorkorder) {
        console.log('Carga de Workorder ya en proceso.');
        return;
      }
      this.isLoadingWorkorder = true;

      try {
          await this.getWorkorder(this.$route.params?.id).then(() => {
            this.setAllChecklistsDone();
            this.getIsWorkingDay();
          });

          // Revisar si el Workorder no se actualizó correctamente
          if (!this.workorder) {
            this.workorder = this.workOrders.getWorkorder(this.$route.params?.id);
          }

          this.offline.workorder = this.workorder || {};
      } catch (error) {
        console.error('Error cargando Workorder:', error);
      } finally {
        this.isLoadingWorkorder = false; // Resetear bandera
      }

      // if (!this.workorder) this.workorder = this.workOrders.getWorkorder(this.$route.params?.id)
    },

    async cacheWorkorder(workorder) {
      if (!this.offline?.status) {
        try {
          await this.offlineModule.cacheDataToOffline('partes', structuredClone(workorder));
        } catch (error) {
          console.error('No se sido posible cachear la OT:: ', error);
        }
      }
    },

    async loadMoreHours(page = +this.horas_local_page) {
      this.horas_local_cantidad = page * +this.horas_local_limit;

      let loading = null;
      if (+page > 1) {
        loading = await loadingController.create({
          message: this.$t('cargando...'),
        });
        await loading.present();
      }

      await loading?.dismiss();
    },

    async loadMoreMateriales(page = +this.lineas_local_page) {
      this.lineas_local_cantidad = page * +this.lineas_local_limit;

      let loading = null;
      if (+page > 1) {
        loading = await loadingController.create({
          message: this.$t('cargando...'),
        });
        await loading.present();
      }

      await loading?.dismiss();
    },

    async loadMoreTecnicosAd(page = +this.tecnicosAd_local_page) {
      this.tecnicosAd_local_cantidad = page * +this.tecnicosAd_local_limit;

      let loading = null;
      if (+page > 1) {
        loading = await loadingController.create({
          message: this.$t('cargando...'),
        });
        await loading.present();
      }

      await loading?.dismiss();
    },

    manageWoTimer() {
      return this.timeIsRunning && +this.offline.status ?
        this.toggleTimer() : (this.isWorkingDay ? this.toggleTimer() : this.workingDayPrompt())
    },

    getAlmacenes() {
      return this.$axios
      .get('/v2/users/actions.php', {
          params: {
              call: 'getAlmacenes',
              token: this.gmao.user.token,
              id_cliente: this.workorder?.direccion?.id_cliente || null,
              page: -1,
              v2: 1,
          },
      })
      .then(({ data }) => {
          this.gmao.warehouse = data;
      }).catch(async (e) => {
        if (e.code == 'ERR_NETWORK' && this.offline.status) {
          // *NEW — XXX: [GET - ALMACENES] NOT IN OFFLINE
        }
      });
    },

    setTimeRegister() {
      const areTherePendingTimers = this.workorder?.horas?.filter((h) => !h.fin || h.fin == "undefined") || [];
      const pendingTimer = areTherePendingTimers?.find((t) => t.id_tecnico == this.gmao.user?.id);
      let user = this.gmao.user?.id;

      if (this.isMainTechnician && areTherePendingTimers?.length > 1) user = areTherePendingTimers.map((u) => {
        return `${u.id_tecnico}-${u?.id}`
      }).toString();

      if (areTherePendingTimers.length && pendingTimer?.id) {
        this.workOrders.timeRegister = {
          id: pendingTimer?.id,
          from: pendingTimer?.desde_formatted ? this.$moment(pendingTimer?.desde_formatted).format() : this.moment(`${pendingTimer?.fecha}T${pendingTimer?.inicio}`, 'YYYY-MM-DDTHH:mm:ss').format(),
          workorder: this.workorder?.id,
          longitud: pendingTimer?.longitud,
          latitud: pendingTimer?.latitud,
          created_by: pendingTimer?.created_by,
          current_timer: pendingTimer?.id,
          user
        };
      } else {
        if (this.workOrders.timeRegister?.workorder == this.workorder.id) this.workOrders.timeRegister = {};
      }
    },

    async setAllChecklistsDone(setAction = false, onlyTasks = false) {
      let loading = null;
      if (setAction) {
        loading = await loadingController.create({
          message: this.$t('realizando-los-checklists'),
        });

        await loading.present();
      }

      const formdata = new FormData();
      formdata.append('parte', this.workorder?.id || '');
      formdata.append('set_action', +setAction);
      formdata.append('only_tasks', +onlyTasks);

      // *NEW — XXX: [POST - SET ALL CHECKLISTS] NOT IN OFFLINE
      this.$axios
        .post(`/v2/users/actions.php?call=setAllChecklistsDone&token=${this.gmao.user.token}&v2=1`, formdata)
        .then(({ data }) => {
          // Devuelve el estado de activos completados
          if (!setAction) this.areChecklistsDone = data.datos;

          if (!+data.status) {
            this.$openToastObject(this.$t('Ha ocurrido un error'), data.msn || this.$t('No ...'), 'danger');
          } else {
            if (setAction) this.getWorkorder(this.$route.params?.id);
            // if (setAction && loading?.dismiss()) this.setAllChecklistsDone();
          }
        }).catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            // *NEW — XXX: [GET - SET ALL CHECKLIST DONE] NOT IN OFFLINE
          }
        }).finally(async () => await loading?.dismiss());
    },

    taskRunning(asset_id) {
      return this.workOrders.activeTimeRegister?.active === asset_id;
    },

    askForTimer() {
      if (this.workorder?.not_editable) return;

      // const hasTimeRecords = !!+this.workorder?.horas?.length;

      if (this.workorder?.id_tecnico == this.gmao?.user?.id) {

        if (!this.timeIsRunning) {
          if (+this.gmao.comportamientos.preguntar_tiempo_al_abrir_ot)
          {
            this.$alertController(
              this.$t('start_timer'),
              this.$t('init_timer_msg'),
              () => this.toggleTimer(),
              {cancel: this.$t('Cancelar'), confirm: this.$t('Aceptar')}
            );
            return;
          }

          if (+this.gmao.comportamientos.obligar_iniciar_tiempos_al_abrir_ot) {
            this.toggleTimer();
            return;
          }

          // else {this.toggleTimer();}
        }
      }
    },

    deleteWorkorderExpense(expense_id) {
      const formdata = new FormData();
      formdata.append('expense_id', expense_id || '');

      // *NEW — XXX: [POST - DELETE WORKORDER EXPENSE] NOT IN OFFLINE
      this.$axios
        .post(`/v2/users/actions.php?call=setExpensesDelete&token=${this.gmao.user.token}&v2=1`, formdata)
        .then(({ data }) => {
          if (!+data.status && !data.gasto?.id) {
            this.$openToastObject(this.$t('Ha ocurrido un error'), data.msn || this.$t('No se ha podido añadir el gasto.'), 'danger');
          } else this.getWorkorder(this.$route.params?.id);
        }).catch((error) => {
          console.log(error);
        });
    },

    getAvailableChecklist(modelo) {
      if (modelo) {
        this.$axios
          .get('/v2/users/actions.php', {
            params: {
              call: 'getAvailableChecklist',
              u: this.gmao.user.id,
              modelo: modelo?.id || '',
              parte: this.workorder?.id,
              token: this.gmao.user.token,
              v2: 1,
            },
          })
          .then(({ data }) => {
            if (data) {
              this.newActivo.checklist = data;
              this.newActivo.id_modelo_parte = data?.id
            } else this.newActivo.checklist = data;
          }).catch((error) => {
          // TODO: Offline
          // *NEW — XXX: [GET – AVAILABLE CHECKLIST] NOT IN OFFLINE
          console.log(error);
        });
      }
    },

    openFileSelect() {
      document.getElementById('openVideoInput').click();
    },

    capture(file, time = 0.0) {
      return new Promise((resolve, reject) => {
        // Create video tag and load file in it
        const videoElement = document.createElement('video');
        videoElement.setAttribute('src', URL.createObjectURL(file));
        videoElement.load();
        videoElement.addEventListener('error', (exception) => reject('Video failed when loading', exception));

        videoElement.addEventListener('loadedmetadata', () => {
          if (videoElement.duration < time) return reject('Video too short');

          setTimeout(() => {
            videoElement.currentTime = time;
          }, 200);

          videoElement.addEventListener('seeked', () => {
            const canvas = document.createElement('canvas');
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;

            const context = canvas.getContext('2d');
            context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

            context.canvas.toBlob((blob) => {
              resolve(blob);
            }, "image/jpeg", 0.75);
          })
        });
      });
    },

    async uploadWOVideo(data) {
      const loading = await loadingController.create({
        message: this.$t('subiendo-video'),
      });

      await loading.present();

      const video = data.target.files[0];
      const reader = new FileReader();
      const promise = new Promise((resolve) => {
        reader.readAsDataURL(video);
        reader.onload = () => {
          resolve(reader.result)
        };
      });

      promise.then(async (data) => {
        let videoThumbnail = null;
        try {
          videoThumbnail = await this.capture(video, 2.0);
        } catch (err) {
          throw this.$Sentry.captureException(err);
        } finally {
          const formData =  new FormData();
          const videoBlob = this.$base64toBlob(data.split(',')[1], data.split(';')[0].split(':')[1]);

          formData.append('id_parte', this.workorder.id);
          formData.append('file', videoBlob);
          formData.append('file_thumbnail', videoThumbnail || '');

          // *NEW — XXX: [POST - VIDEO UPLOAD] NOT IN OFFLINE
          this.$axios
            .post(
              `/v2/users/actions.php?call=setImageWorkorder&token=${this.gmao.user.token}&v2=1`,
              formData,
              {
                headers: { 'Content-Type': 'multipart/form-data' },
              }
            )
            .then(({ data }) => {
              this.getWorkorder(this.workorder.id);
              if (data.imagen?.src?.length) {
                this.openToastOptions(
                  this.$t('video-subido-con-exito'),
                  this.$t('el-video-se-ha-subido-correctamente-a-la-orden-de-trabajo'),
                  'success'
                );
              } else if(!data.imagen && data?.msn) {
                this.openToastOptions(
                  this.$t('error-al-subir-el-video'),
                  this.$t(data?.msn)
                );
              } else {
                this.openToastOptions(
                  this.$t('error-al-subir-el-video'),
                  this.$t(
                    'el-servidor-no-ha-podido-procesar-la-subida-del-video-por-favor-intentalo-mas-tarde'
                  )
                );
              }
            }).finally(async () => {
              await loading.dismiss();
            }).catch((error) => {
          // TODO: Offline
          console.log(error);
        });
        }

      });
    },

    getIsWorkingDay() {
      // *NEW — XXX: [GET - IS WORKING DAY] NOT IN OFFLINE
      this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getIsWorkingDay',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            v2: 1,
          },
        })
        .then(({ data }) => {
          this.isWorkingDay = data;
        }).catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            // *NEW — XXX: [GET - getIsWorkingDay] NOT IN OFFLINE
          }
        });
    },

    workingDayPrompt(){
      this.alertPopup(
        this.$t(
          'Vas a fichar en un día de vacaciones/ausencia ¿Estás seguro?'),
        () => {
          this.toggleTimer();
        },
        { cancel: 'cancelar', confirm: 'confirmar' }
      );
    },

    /** XXX: OPTIMIZE: */
    selectTipoTiempo(tipo, modal = false) {
      if (!('hora' in this.newHora) && tipo) this.newHora.hora = {};

      if (!tipo) {
        this.tipoTiempoPorDefecto = null;
      } else if(!this.newHora.id) {
        this.newHora.hora.tipo_tiempo = tipo || {};
        this.newHora.id_tipo_tiempo = tipo?.id || null;
        this.newHora.nombreTipoTiempo = tipo?.nombre || null;

        this.$refs.refSetAddHoras.modalHour.id_tipo_tiempo = tipo?.id || null;
        this.$refs.refSetAddHoras.modalHour.nombreTipoTiempo = tipo?.nombre || null;
      }

      if (modal) {
        this.newHora.hora.tipo_tiempo = tipo || {};
        this.newHora.id_tipo_tiempo = tipo?.id || null;
        this.newHora.nombreTipoTiempo = tipo?.nombre || null;
        this.$refs.refSetAddHoras.modalHour.id_tipo_tiempo = tipo?.id || null;
        this.$refs.refSetAddHoras.modalHour.nombreTipoTiempo = tipo?.nombre || null;
      }

      this.setTipoTiempos = false;
      if(this.newHora.id_tipo_tiempo){
        this.hayTipo = true;
      }else{
        this.hayTipo = false;

      }
    },

    async getTextosPredefinidos() {

      if (this.offline.status && !this.workOrders.textosPredefinidos?.length) {

        try {
          const query = `
            SELECT *
            FROM textos_predefinidos
          `;
          const getTextosPredefinidosPromise = new Promise((resolve) => {
            resolve(
              this.offlineActionsController.SQLiteQuery(
                'getTextosPredefinidos_general',
                'textos_predefinidos',
                query,
                { method: 'get' },
                this.offlineModule?.db
              )
            );
          });
          getTextosPredefinidosPromise.then(() => {
            // FIXME: *Revisar
            setTimeout(() => {
              // sqlite.closeConnection('gmaoTecnicos');
              this.textosSoluciones = this.offline.textosPredefinidos;
            }, 500);
            // return this.offline.textosPredefinidos;
          });
        } catch (err) {
          // this.$Sentry.withScope(function(scope) {

          //   scope.setTag("offline", "Workorder: GET TextosPredef");

          //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

          // });
          // Error controller
          // this.$Sentry.captureException(err);
        }
        
        return;
      }
      this.textosSoluciones = this.workOrders.textosPredefinidos;
      if (!this.offline.status && !this.workOrders.textosPredefinidos?.length) {
        // *NEW — XXX: [GET - TEXTOS PREDEFINIDOS] NOT IN OFFLINE
        this.$axios
          .get('/v2/users/actions.php', {
            params: {
              call: 'getTextosPredefinidos',
              u: this.gmao.user.id,
              token: this.gmao.user.token,
              v2: 1,
            },
          })
          .then(({ data }) => {
            this.workOrders.textosPredefinidos = data;
            this.textosSoluciones = data;
            return;
          }).catch((error) => {
          // TODO: Offline
          console.log(error);
        });
      }
    },

    editWoMaterial(m) {
      const localM = JSON.parse(JSON.stringify(m));
      localM.partidas = this.workorder?.obra?.partidas.find(
        (p) => p.id === localM.id_partidap
      );

      if (m.maquina) {
        localM.maquinaNombre =
          (m.maquina?.modelo?.modelo ?? '') +
          ' ' +
          (m.maquina?.modelo?.marca ?? '') +
          ' ' +
          (m.maquina?.nombre ?? 'Sin nombre');
        localM.activo = m.id_maquina;
      }

      this.getAlmacenesMaterial(localM.articulo?.id, true).then(() => {
        this.selectedMaterial = JSON.parse(JSON.stringify(localM));
        if (this.selectedMaterial.movistock) {
          this.getLotesMaterial(
            localM.articulo_movimiento?.almacen?.almacen?.id,
            localM.articulo?.id,
            true
          );
        }
        this.editMaterial = true;
      });
    },

    async galleryPicker(type = null) {
      const photos = await this.$Camera.pickImages();

      if (photos) this.uploadPhotoWorkOrder(photos.photos, type);
    },

    async uploadPhotoWorkOrder(photos, type) {
      switch (type) {
        case 'asset':
          this.uploadMultiplePhotoAsset(photos);
          break;

        case 'images':
          this.uploadMultiplePhotos(photos);
          break;
      }
    },

    async uploadMultiplePhotoAsset(photos) {
      const loading = await loadingController.create({
        message: this.$t('subiendo-imagenes'),
      });

      await loading.present();

      this.newActivo.blobs = [];
      this.newActivo.images = [];

      const promises = photos.map(async (photo) => {
        photo.base64String =
          photo.base64String ||
          (await this.$getBase64Image(photo.webPath).then((data) => data.split`,`[1]));
        // const blob = this.$base64toBlob(photo.base64String);
        const blob = await new Promise((resolve, reject) => {
          this.$compressImage(this.$base64toBlob(photo.base64String), resolve, reject);
        });
        this.newActivo.blobs.push(blob);
        this.newActivo.images.push(`data:image/png;base64,${photo.base64String}`);

        return new Promise((resolve) => {
          resolve();
        });
      });

      Promise.all(promises).finally(async () => {
        await loading.dismiss();
      });
    },

    async uploadMultiplePhotos(photos) {
      const loading = await loadingController.create({
        message: this.$t('subiendo-imagenes'),
      });

      await loading.present();

      let imageUpload = null;
      let query = '';

      if (navigator.onLine) {
        const promises = photos.map(async (photo) => {
          const formData = new FormData();

          photo.base64String =
            photo.base64String ||
            (await this.$getBase64Image(photo.webPath).then((data) => data.split`,`[1]));

          const blob = await new Promise((resolve, reject) => {
            this.$compressImage(this.$base64toBlob(photo.base64String), resolve, reject);
          });

          formData.append('file', blob);
          formData.append('id_parte', this.workorder.id);

          // *NEW — XXX: [POST - MULTIPLE PHOTOS] NOT IN OFFLINE
          imageUpload = await this.$axios
            .post(
              `/v2/users/actions.php?call=setImageWorkorder&token=${this.gmao.user.token}&v2=1`,
              formData,
              {
                headers: { 'Content-Type': 'multipart/form-data' },
              }
            )
            .then(({ data }) => data)
            .catch(() => {
              this.openToastOptions(
                this.$t('error-al-subir-las-imagenes'),
                this.$t(
                  'el-servidor-no-ha-podido-procesar-la-subida-de-imagenes-por-favor-intentalo-mas-tarde'
                )
              );
            });

          return new Promise((resolve) => {
            resolve(imageUpload);
          });
        });

        Promise.all(promises)
          .then(() => {
            this.openToastOptions(
              this.$t('imagenes-subidas-con-exito'),
              this.$t('las-imagenes-se-han-subido-correctamente-a-la-orden-de-trabajo'),
              'success'
            );

            this.getWorkorder(this.workorder.id);
          })
          .finally(async () => {
            await loading.dismiss();
          });
      } else {
        photos.forEach(async (photo, index) => {
          const formData = new FormData();

          photo.base64String =
            photo.base64String ||
            (await this.$getBase64Image(photo.webPath).then((data) => data.split`,`[1]));
          const blob = this.$base64toBlob(photo.base64String);

          formData.append('file', blob);
          formData.append('id_parte', this.workorder.id);

          let axiosPayload = {
            data: formData,
            headers: { headers: { 'Content-Type': 'multipart/form-data' } }
          };


          this.$axios
            .post(
              `/v2/users/actions.php?call=setImageWorkorder&token=${this.gmao.user.token}&v2=1`,
              axiosPayload.data,
              axiosPayload.headers,
            )
            .then(() => {
              if (index == photos?.length - 1) {
                this.getWorkorder(this.workorder.id);
              }
              this.openToastOptions(
                this.$t('imagenes-subidas-con-exito'),
                this.$t('las-imagenes-se-han-subido-correctamente-a-la-orden-de-trabajo'),
                'success'
              );
            })
            .catch(async (e) => {
              if (e.code == 'ERR_NETWORK' && this.offline.status) {
                console.log('MIRAR: ', e);
                axiosPayload = {
                  ...axiosPayload,
                  callFunction: 'setImageWorkorder',
                  props: {
                    layer: 2,
                    group: 'images',
                    parentTempId: this.workorder?.id || -1,
                    dependsOn: ['partes'],
                    dependencies: [{keyInDependent: 'id_parte', keyInParent: 'id'}]
                  },
                }

                this.offlineModule.syncController.addChange(axiosPayload);

                query += `
                  INSERT INTO parte_imagenes (id, syncObject, id_parte, id_tecnico, base64Src, created_at, updated_at, last_modified)
                  VALUES (${this.$moment().unix() + index}, '${JSON.stringify(
                  axiosPayload
                )}', ${this.workorder.id}, ${
                  this.workorder?.id_tecnico
                }, 'data:image/png;base64,${
                  photo.base64String
                }', '${this.$moment().format(
                  'YYYY-MM-DD H:mm:ss'
                )}', '${this.$moment().format(
                  'YYYY-MM-DD H:mm:ss'
                )}', '${this.$moment().format('YYYY-MM-DD H:mm:ss')}');
                `;
                if (index == photos?.length - 1 && query.trim()?.length)
                  this.uploaMultiplePhotosSQL(query, e);
                loading.dismiss();
              } else {
                this.openToastOptions(
                  this.$t('error-al-subir-las-imagenes'),
                  this.$t(
                    'el-servidor-no-ha-podido-procesar-la-subida-de-imagenes-por-favor-intentalo-mas-tarde'
                  )
                );
              }
            });
        });
      }
    },

    async uploaMultiplePhotosSQL(query, e) {
      try {
        const uploadMultiplePhotosPromise = new Promise((resolve) => {
          resolve(
            this.offlineActionsController.SQLiteQuery(
              'uploadMultiplePhotos_workorder',
              'parte_imagenes',
              query,
              e.config,
              this.offlineModule?.db
            )
          );
        });
        uploadMultiplePhotosPromise.then(() => {
          // FIXME: *Revisar
          setTimeout(() => {
            // sqlite.closeConnection('gmaoTecnicos').then(() => {
              this.getWorkorder(this.$route.params.id);
            // });
          }, 500);
        });

      } catch (err) {
        // this.$Sentry.withScope(function(scope) {

        //   scope.setTag("offline", "Workorder: SET Mulitple Images");

        //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

        // });
        // Error Controller
        // this.$Sentry.captureException(err);
      }
    },

    async updateWorkorderIncidenceID(data) {
      if (navigator.onLine)
        await this.updateWorkorderField('id_incidencia', data?.incidencia?.id);
      else await this.updateWorkorderField('id_incidencia', data?.id);
    },

    onLoadMaquinas(event) {
      if (!this.loading && this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFiltersMaquinas(false).then(() => {
          event.target.complete();
        });
      } else {
        // event.target.disabled = true;
        event.target.complete();
      }
    },

    applyFiltersMaquinas(reset = true) {
      if (reset) {
        this.items = [];
        this.current_page = 1;
      }

      const flag = this.activeSearchAsset || this.setActivos ? null : (this.workorder?.id || this.$route?.params?.id || null);
      return this.getMaquinas(flag);
    },

    onLoadHerramientas(event) {
      if (!this.loading && this.herramientas_pagination?.current_page < this.herramientas_pagination?.last_page) {
        this.herramientas_pagination.current_page += 1;
        this.applyFiltersHerramientas(false).then(() => {
          event.target.complete();
        });
      } else {
        // event.target.disabled = true;
        event.target.complete();
      }
    },

    applyFiltersHerramientas(reset = true) {
      if (reset) {
        // this.items = [];
        this.herramientas_pagination.current_page = 1;
      }

      // const flag = this.activeSearchAsset || this.setActivos ? null : (this.workorder?.id || this.$route?.params?.id || null);
      // return this.getMaquinas(flag);
    },

    async getAlmacenesMaterial(id = null, editting = false) {
      const id_articulo = editting
        ? id || this.selectedMaterial?.articulo?.id
        : this.selectedMaterial?.material?.id;

      return await this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getAlmacenesMaterial',
            token: this.gmao.user.token,
            articulo: id_articulo,
            page: -1,
            v2: 1,
          },
        })
        .then(({ data }) => {
          this.almacenes = data;
          if (this.almacenes?.length == 1) {
            if (editting) {
              const almacen = this.almacenes[0];
              this.selectedMaterial.articulo_movimiento.almacen.almacen = almacen;
              this.selectedMaterial.articulo_movimiento.almacen.id_almacen = almacen.id;
              this.selectedMaterial.articulo_movimiento.id_almacen = almacen.id;
              this.getLotesMaterial(
                this.selectedMaterial.articulo_movimiento?.almacen?.almacen?.id,
                this.selectedMaterial?.articulo?.id,
                true
              );
            } else {
              this.selectedMaterial.almacen = this.almacenes[0];
            }
          }
          return data;
        })
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {

              const query = `
                SELECT *
                FROM almacenes
                WHERE id IN (SELECT id_almacen FROM almacen_articulos WHERE id_articulo=${
                  id_articulo || 0
                })
              `;
              const getAlmacenesMaterialPromise = new Promise((resolve) => {
                resolve(
                  this.offlineActionsController.SQLiteQuery(
                    'getAlmacenesMaterial_workorder',
                    'almacen_articulos',
                    [query, { id_articulo }],
                    e.config,
                    this.offlineModule?.db
                  )
                );
              });
              getAlmacenesMaterialPromise.then(() => {
                // FIXME: *Revisar
                setTimeout(() => {
                  this.almacenes = this.offline.almacenesArticulo;
                  return this.almacenes;
                }, 100);
              });
            } catch (err) {
              // Error Controller
              // this.$Sentry.captureException(err);
            }
          }
        });
    },

    getAllSistemas(sistemas, level = 0, parent = '') {
      sistemas?.forEach((ss) => {
        ss.level = level;
        ss.name = level && (!+this.gmao.comportamientos?.gmao_interno || +this.gmao.comportamientos?.ver_direccion_en_instalacion) ?
          `${parent} - ${ss?.nombre}` : (ss.id != 1) ? `> ${ss?.nombre}` : ss?.nombre;
        if (ss.all_sistemas?.length) {
          sistemas = sistemas.concat(
            this.getAllSistemas(ss.all_sistemas, level + 1, ss.name)
          );
        }
      });

      return sistemas;
    },

    getLotesMaterial(ev, id = null, editting = false) {
      // FIXME: ++* POSIBLE MIGRACION A MSetMaterialLotes
      const id_articulo = editting
        ? id || this.selectedMaterial?.articulo?.id
        : this.selectedMaterial?.material?.id;
      let id_almacen = ev?.id;
      if (editting) {
        id_almacen = ev;
      }
      this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getLotesMaterial',
            token: this.gmao.user.token,
            articulo: id_articulo,
            almacen: id_almacen,
            page: -1,
            v2: 1,
          },
        })
        .then(({ data }) => {
          this.lotes = data;
          if (this.lotes?.length == 1) {
            this.selectedMaterial.lote = this.lotes[0];

            if (editting) {
              if (
                this.gmao.modulos.modulo_stockage &&
                this.gmao.modulos.modulo_lotes &&
                this.selectedMaterial?.movistock?.lote
              ) {
                this.selectedMaterial.movistock.lote = this.lotes[0]?.lote || {};
              } else {
                this.selectedMaterial.lote = this.lotes[0] || {};
              }
            }
          }
        })
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const query = `
                SELECT *
                FROM lotes_almacenes
                WHERE id_almacen=${
                  id_almacen || 0
                } AND id_lote IN (SELECT id FROM lotes WHERE id_articulo=${
                id_articulo || 0
              })
              `;
              const getLotesMaterialPromise = new Promise((resolve) => {
                resolve(
                  this.offlineActionsController.SQLiteQuery(
                    'getLotesMaterial_workorder',
                    'lotes',
                    query,
                    e.config,
                    this.offlineModule?.db
                  )
                );
              });
              getLotesMaterialPromise.then(() => {
                // FIXME: *Revisar
                setTimeout(() => {
                  // sqlite.closeConnection('gmaoTecnicos').then(() => {
                    this.lotes = this.offline.lotes;
                  // });
                }, 500);
              });
            } catch (err) {
              // this.$Sentry.withScope(function(scope) {

              //   scope.setTag("offline", "Workorder: GET Lotes Material");

              //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

              // });
              // Error Controller
              // this.$Sentry.captureException(err);
            }
          }
        });
    },

    async mergeAssetChild(data) {
      if (data?.length) {
        return data
          ?.filter((m) => !data.some(m1 => m1.id == m.id_maquina))
          .map(
            (m) => {
              m.sub_maquinas = data?.filter(m1 => m1.id_maquina == m.id);
              m.sub_maquinas_count = m.sub_maquinas?.length;
              return m;
            }
          );
      }
      return [];
    },

    getWorkorder(value) {
      return this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getParte',
            token: this.gmao.user.token,
            value,
            field: 'id',
            offline: 1,
            page: -1,
            v2: 1,
          },
        })
        .then(async ({ data }) => {
          if (!data) {
            this.showErrorToast(
              this.$t('error-al-entrar-al-parte'),
              this.$t('no-tienes-acceso-al-parte')
            );
            this.goBackAfterDelay();
            return;
          }

          const workorder = this.extractWorkorderData(data);
          try {
            this.handleDocumentAssignments(workorder);
            this.handleAssetFinalization(workorder);

            if (workorder.id_partida_default) {
              const partida = this.getDefaultPartida(workorder);
              if (partida) this.selectingPartida(partida);
            }

            this.processWorkOrderLines(workorder);

            if (workorder?.firma) {
              if (!workorder?.firma.dni) workorder.firma.dni = '';
              if (!workorder?.firma.nombre) workorder.firma.nombre = '';

              this.signature = workorder?.firma;
            }

            // TODO: FIXME: Cache Workorder Offline
            // this.cacheWorkorder(workorder);
          } catch (error) {
            console.error("Error procesando el parte::", error);
          } finally {
            if (workorder?.id) {
              Object.assign(this.workorder, workorder);
            }
          }
          return workorder;
        }).catch(async (error) => {
          if (error.code === 'ERR_NETWORK' && this.offline.status) {
            let workorder = {};
            try {
              workorder = await this.handleOfflineWorkOrder(value, error);
            } catch (error) {
              console.error("Error procesando el parte en offline::", error);
            } finally {
              if (workorder?.id) {
                Object.assign(this.workorder, workorder);
              }
            }
            return workorder;
          } else {
            console.error("Error al obtener el parte::", error);
            // TODO: ErrorController
            throw error;
          }
        });
    },

    /**
     * Extrae y retorna los datos principales del objeto workorder.
     */
    extractWorkorderData(data) {
      return 'data' in data ? data.data : data;
    },

    /**
     * Navega de regreso después de 3 segundos.
     */
    goBackAfterDelay() {
      setTimeout(() => {
        this.$router.go(-1);
      }, 3000);
    },

    /**
     * Muestra un mensaje de error y navega de regreso después de un retraso.
     */
    showErrorToast(title, message) {
      this.openToastOptions(title, message);
    },

    /**
     * Asigna documentos a las propiedades de workorder.
     */
    handleDocumentAssignments(workorder) {
      const hasDirDocs = workorder?.direccion?.documentos?.length > 0;
      const hasAssetsDocs = workorder?.maquinas?.some((m) => m.documentos?.length);

      this.dirDocs = hasDirDocs ? workorder.direccion : [];
      this.clientDocs = this.dirDocs?.cliente?.documentos || [];
      this.assetDocs = hasAssetsDocs ? workorder.maquinas : [];
    },

    /**
     * Actualiza el estado de finalización de las máquinas.
     */
    handleAssetFinalization(workorder) {
      const actives = this.workorder?.maquinas;
      if (workorder?.tipo_letra === 'L' && workorder.contadores?.length) {
        workorder.maquinas = workorder.maquinas.concat(workorder.contadores);
      }

      workorder?.maquinas?.forEach((m) => {
        const needle = (actives || [])?.find((a) => a.id == m.id);
        if (needle) m.finalizado = needle.finalizado;
      });
    },

    /**
     * Obtiene la partida por defecto para la workorder.
     */
    getDefaultPartida(workorder) {
      return workorder.id_partida_default
        ? workorder?.obra?.partidas?.find((p) => p.id === workorder.id_partida_default)
        : workorder?.obra?.partida_defecto;
    },

    /**
     * Procesa las líneas de la workorder.
     */
    processWorkOrderLines(workorder) {
      workorder.lineas?.forEach((line) => {
        line.faltas = !!line?.falta || false;
      });
    },

    /**
     * Maneja la obtención de la workorder en modo offline.
     */
    async handleOfflineWorkOrder(value, error) {
      try {
        const query = `
          SELECT *
          FROM partes P
          WHERE P.id=${value}
        `;
        const parte = await this.offlineActionsController.SQLiteQuery(
          'getParte_general',
          'partes',
          query,
          error.config,
          this.offlineModule?.db
        );

        if (!parte) {
          console.error('Parte no encontrado en modo offline.');
          return;
        }

        return this.initializeOfflineWorkOrder(parte);
      } catch (offlineError) {
        console.error('Error en modo offline:', offlineError);
        // TODO: ErrorController
        throw offlineError;
      }
    },

    /**
     * Inicializa la workorder en modo offline.
     */
    initializeOfflineWorkOrder(parte) {
      this.workorder = { ...parte };

      this.handleAssetFinalization(parte);

      if (parte.id_partida_default) {
        const partida = this.getDefaultPartida(parte);
        if (partida) this.selectingPartida(partida);
      }

      this.processWorkOrderLines(parte);

      if (parte?.firma) {
        if (!parte?.firma.dni) parte.firma.dni = '';
        if (!parte?.firma.nombre) parte.firma.nombre = '';

        this.signature = parte?.firma;
      }

      // TODO: Mezclar datos de máquinas (mergeAssetChild)
      // this.workorder.maquinas = await this.mergeAssetChild(this.workorder.maquinas || []);

      console.log('Workorder final (offline):', this.workorder);

      return parte;
    },

      getQuestions() {
        this.$axios
          .get('/v2/users/actions.php', {
            params: {
              call: 'getPreguntas',
              token: this.gmao.user.token,
              value: this.gmao.user.id,
              page: -1,
              v2: 1,
            },
          })
          .then(({ data }) => {
            this.questions = data;
          })
          .finally(() => {
            // this.loading = false;
          })
          .catch(async (e) => {
            if (e.code == 'ERR_NETWORK' && this.offline.status) {
              try {
                const query = `
                  SELECT *
                  FROM faq_preguntas
                  ORDER BY posicion ASC
                `;
                const getQuestionsPromise = new Promise((resolve) => {
                  resolve(
                    this.offlineActionsController.SQLiteQuery(
                      'getQuestions_workorder',
                      'faq_preguntas',
                      query,
                      e.config,
                      this.offlineModule?.db
                    )
                  );
                });
                getQuestionsPromise.then(() => {
                  // FIXME: *Revisar
                  setTimeout(() => {
                    this.questions = this.offline.questions;
                    // sqlite.closeConnection('gmaoTecnicos');
                  }, 500);
                });
              } catch (err) {
                // this.$Sentry.withScope(function(scope) {
                //   scope.setTag("offline", "Workorder: GET Questions");
                //   scope.setFingerprint([err.name, err.message, String(err.stack)]);
                // });
                // Error Controller
                // this.$Sentry.captureException(err);
              }
            }
          });
      },

      getReplies(value, reset = false) {
        if (reset) {
          this.workorder.id_solucion_faq = null;
          this.setWorkorderUpdate({
            id_problema_faq: this.workorder.id_problema_faq,
            id_solucion_faq: null,
          });
        }
        this.$axios
          .get('/v2/users/actions.php', {
            params: {
              call: 'getRespuestas',
              token: this.gmao.user.token,
              value,
              field: 'id_pregunta',
              page: -1,
              v2: 1,
            },
          })
          .then(({ data }) => {
            this.replies = data;
          })
          .finally(() => {
            // this.loading = false;
          })
          .catch(async (e) => {
            if (e.code == 'ERR_NETWORK' && this.offline.status) {
              try {
                const query = `
                  SELECT *
                  FROM faq_respuestas R
                  WHERE R.id_pregunta=${value}
                  ORDER BY posicion ASC
                `;
                const getRepliesPromise = new Promise((resolve) => {
                  resolve(
                    this.offlineActionsController.SQLiteQuery(
                      'getReplies_workorder',
                      'faq_respuestas',
                      query,
                      e.config,
                      this.offlineModule?.db
                    )
                  );
                });
                getRepliesPromise.then(() => {
                  // FIXME: *Revisar
                  setTimeout(() => {
                    this.replies = this.offline.replies;
                    // sqlite.closeConnection('gmaoTecnicos');
                  }, 500);
                });
              } catch (err) {
                // this.$Sentry.withScope(function(scope) {

                //   scope.setTag("offline", "Workorder: GET Replies");

                //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

                // });
                // Error Controller
                // this.$Sentry.captureException(err);
              }
            }
          });
      },

    // <== OPTIMIZE: /BLOQUE_FAQ

    getMaquinas(parte_id = null) {
      return this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getMaquinas',
            token: this.gmao.user.token,
            // sistema: this.workorder.id_sistema,
            // direccion: this.workorder.id_direccion,
            page: this.current_page,
            searchAsset: this.activeSearchAsset,
            tipo_parte: this.workorder.tipo_letra,
            parte_id,
            v2: 1,
          },
        })
        .then(({ data }) => {
          if (parte_id) {
            this.workorderAssets = data;
          } else {
            this.items.push(data.data);
            this.current_page = data.current_page;
            this.last_page = data.last_page;
            this.total = data.total;
          }
        })
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const query = `
                SELECT *
                FROM maquinas
                ${this.activeSearchAsset ?
                  `WHERE nombre
                    LIKE '%${this.activeSearchAsset}%'
                    OR numeroserie LIKE '%${this.activeSearchAsset}%'
                    OR ubicacion LIKE '%${this.activeSearchAsset}%'
                    OR observaciones LIKE '%${this.activeSearchAsset}%'`
                  : ''
                }
                  ORDER BY id_sistema`;

              const getMaquinasPromise = new Promise((resolve) => {
                resolve(
                  this.offlineActionsController.SQLiteQuery(
                    'getMaquinas_workorder',
                    'maquinas',
                    query,
                    e.config,
                    this.offlineModule?.db
                  )
                );
              });
              getMaquinasPromise.then(() => {
                // FIXME: *Revisar
                setTimeout(() => {
                  this.items = this.offline.assets;
                }, 500);
              });
            } catch (err) {
              // this.$Sentry.withScope(function(scope) {

              //   scope.setTag("offline", "Workorder: GET Maquinas");

              //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

              // });
              // Error Controller
              // this.$Sentry.captureException(err);
            }
          }
        });
    },

    onLoadMaquinasSistemas(event) {
      if (!this.loading && this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFiltersMaquinasSistemas(false).then(() => {
          event.target.complete();
        });
      } else event.target.complete();
    },
    applyFiltersMaquinasSistemas(reset = true) {
      if (reset) {
        this.assets = [];
        this.current_page = 1;
      }
      return this.getMaquinasSistemas();
    },

    async getMaquinasSistemas() {
      const { sistema } = this.newActivo;
      return await this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getMaquinasSistemas',
            token: this.gmao.user.token,
            sistema: sistema?.id || null,
            search: this.activeSystemSearch,
            page: this.current_page,
            v2: 1,
          },
        })
        .then(({ data }) => {

          this.assets.push(data.data);

          this.current_page = data.current_page;
          this.last_page = data.last_page;
          this.total = data.total;

          // this.sistemas = data;
          // return data;
        })
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {

              const query = `
                SELECT *
                FROM maquinas
                WHERE id_sistema IN (
                  SELECT id
                  FROM sistemas
                  WHERE id_padre=${sistema?.id} AND id_direccion=${this.workorder?.id_direccion || ''}
                ) OR id_sistema=${sistema?.id}
                ${this.activeSystemSearch?.length && this.activeSystemSearch ?
                  `AND (
                    nombre LIKE "%${this.activeSystemSearch}%" OR
                    numeroserie LIKE "%${this.activeSystemSearch}% OR"
                    ubicacacion LIKE "%${this.activeSystemSearch}% OR"
                    observaciones LIKE "%${this.activeSystemSearch}% OR"
                  )`
                : ''}
              `;
              const getMaquinasSistemasPromise = new Promise((resolve) => {
                resolve(
                  this.offlineActionsController.SQLiteQuery(
                    'getMaquinasSistemas_workorder',
                    'maquinas',
                    query,
                    e.config,
                    this.offlineModule?.db
                  )
                );

              });
              getMaquinasSistemasPromise.then(() => {
                setTimeout(() => {
                  this.assets = [];
                  // sqlite.closeConnection('gmaoTecnicos').then(() => {
                    this.assets = [this.offline.assets];
                  // });
                }, 500);
              });
            } catch (err) {
              //  Error Controller
              this.$Sentry.captureException(err);
            }
          }
      });
    },

    async getSistemas() {
      return await this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getSistemas',
            token: this.gmao.user.token,
            direccion: this.workorder.id_direccion,
            // page: this.current_page,
            page: -1,
            v2: 1,
          },
        })
        .then(({ data }) => {

          // this.sistemas.push(data.data);

          // this.current_page = data.current_page;
          // this.last_page = data.last_page;
          // this.total = data.total;

          if (this.$appVersion() > 148) this.sistemas = data;
          else this.sistemas.push(data);

          return data;
        })
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const query = `
                with recursive cte as (
                  select     *
                  from       sistemas
                  where      id_direccion = ${this.workorder.id_direccion} and id_padre is null
                  union all
                  select     p.*
                  from       sistemas p
                  inner join cte
                          on p.id_padre = cte.id
                )
                select * from cte;
              `;
              console.log(query)
              const getSistemasPromise = new Promise((resolve) => {
                resolve(
                  this.offlineActionsController.SQLiteQuery(
                    'getSistemas_workorder',
                    'maquina_modelos',
                    query,
                    e.config,
                    this.offlineModule?.db
                  )
                );
              });
              getSistemasPromise.then(() => {
                // FIXME: *Revisar
                setTimeout(() => {
                  // sqlite.closeConnection('gmaoTecnicos').then(() => {
                    this.sistemas = this.offline.sistemas;
                  // });
                }, 500);
              });
            } catch (err) {
              // this.$Sentry.withScope(function(scope) {

              //   scope.setTag("offline", "Workorder: GET Sistemas");

              //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

              // });
              // Error Controller
              // this.$Sentry.captureException(err);
            }
          }
        });
    },

    setNewModeloWO() {
      // *NEW — XXX: [POST - SET NEW MODELO] NOT IN OFFLINE
      const obj = {
        marca: this.newModelo.marca,
        modelo: this.newModelo.modelo,
        id_familia: this.newModelo.id_familia || null,
        id_subfamilia: this.newModelo.id_subfamilia || null
      };

      this.$axios
        .post(
          `/v2/users/actions.php?call=setNewModelo&token=${this.gmao.user.token}&v2=1`,
          {data: {...obj}},
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          }
        ).then(() => {
          this.setNewModelo = false;
          this.$refs.refSetModeloNewActivo.applyFilters(true);
        })
    },

    setWorkorderUpdate(fields) {
      let axiosPayload = {
        data: { id: this.workorder.id, fields },
        headers: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      }
      this.$axios
        .post(
          `/v2/users/actions.php?call=setWorkorderUpdate&token=${this.gmao.user.token}&v2=1`,
          { data: { ...axiosPayload.data } },
          axiosPayload.headers
        )
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const val = Object.values(fields);
              let query = '';

              axiosPayload = {
                ...axiosPayload,
                callFunction: 'setWorkorderUpdate',
                props: {
                  layer: 2,
                  group: 'partes',
                  parentTempId: axiosPayload.data?.id || this.workorder?.id,
                  dependsOn: ['partes'],
                  dependencies: [{keyInDependent: 'id', keyInParent: 'id'}]
                },
              }
              this.offlineModule.syncController.addChange(axiosPayload);

              Object.keys(fields).forEach((f, i) => {
                query += `
                UPDATE partes
                SET ${f}=${val[i]}
                WHERE id=${this.workorder?.id};
              `;
              });
              const parte = {
                id: this.workorder.id,
              };

              const setWorkorderUpdatePromise = new Promise((resolve) => {
                resolve(
                  this.offlineActionsController.SQLiteQuery(
                    'setWorkorderUpdate_workorder',
                    'partes',
                    [query, parte],
                    e.config,
                    this.offlineModule?.db
                  )
                );
              });
              setWorkorderUpdatePromise.then(() => {
                // FIXME: *Revisar
                setTimeout(() => {
                  // sqlite.closeConnection('gmaoTecnicos').then(() => {
                    this.workorder = this.offline.workorder;
                  // });
                }, 500);
              });
            } catch (err) {
              // this.$Sentry.withScope(function(scope) {

              //   scope.setTag("offline", "Workorder: PUT WO's");

              //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

              // });
              // Error Controller
              // this.$Sentry.captureException(err);
            }
          }
        });
    },

    setWorkorderActives() {
      let activos = null;
      if (+this.gmao.comportamientos.selector_sistemas_parte) {
        activos = (this.assets?.flat() || [])
          ?.filter((m) => m.active)
          .concat(this.workorder.maquinas)
          ?.map((m) => m.id);
      } else {
        // TODO: Flattear en una computada y filtrar solo los que NO estén seleccionados
        if (this.workorder.tipo_letra == 'L') {
          activos = this.items
            .flat()
            ?.filter((m) => m.active)
            .concat(this.workorder?.contadores)
            .map((m) => m.id);
        } else {
          activos = this.items
            .flat()
            ?.filter((m) => m.active)
            .concat(this.workorder.maquinas)
            .map((m) => m.id);
        }

      }

      activos = [...new Set(activos)];

      let axiosPayload = {
        data: {
          id_parte: this.workorder.id,
          tipo_parte: this.workorder.tipo_letra,
          activos,
        },
        headers: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      };

      if (this.workorder.tipo_letra == 'L') {
        axiosPayload.data.nuevos_contadores = this.items.flat()?.filter((m) => m.active).map((m) => m.id);
      }

      this.$axios
        .post(
          `/v2/users/actions.php?call=setWorkorderActives&token=${this.gmao.user.token}&v2=1`,
          { data: { ...axiosPayload.data } },
          axiosPayload.headers
        )
        .then(async ({ data }) => {
          data.activos = await this.mergeAssetChild(data.activos);
          this.workorder.maquinas = data.activos;
          this.setActivos = false;
          this.setActivosSistema = false;
          this.getWorkorder(this.$route.params.id);
        })
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              axiosPayload = {
                ...axiosPayload,
                callFunction: 'setWorkorderActives',
                props:{
                  layer: 2,
                  group: 'maquinas',
                  parentTempId: axiosPayload.data?.id_parte || this.workorder?.id,
                  dependsOn: ['partes'],
                  dependencies: [{keyInDependent: 'id_parte', keyInParent: 'id'}]
                }
              }

              this.offlineModule.syncController.addChange(axiosPayload);

              let query = '';

              activos.forEach((maquina, index) => {
                query += `INSERT INTO parte_maquinas (id, ${
                  index < 1 ? ` syncObject,` : ''
                } id_parte, id_maquina, created_at, updated_at, last_modified)
                  VALUES
                (
                  ${this.$moment().unix() + index}
                  ${index < 1 ? `, '${JSON.stringify(axiosPayload)}',` : ','}
                  ${axiosPayload.data?.id_parte || this.workorder?.id},
                  ${maquina},
                  '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                  '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                  '${this.$moment().format('YYYY-MM-DD H:mm:ss')}');\n`;
              });


              const setWorkorderActivesPromise = new Promise((resolve) => {
                resolve(
                  this.offlineActionsController.SQLiteQuery(
                    'setWorkorderActiveSync_workorder',
                    'parte_maquinas',
                    query,
                    e.config,
                    this.offlineModule?.db
                  )
                );
              });
              setWorkorderActivesPromise.then(() => {
                setTimeout(() => {
                  this.setActivos = false;
                  this.setActivosSistema = false;
                  // FIXME: *Revisar
                  // sqlite.closeConnection('gmaoTecnicos').then(() => {
                    this.getWorkorder(this.workorder.id);
                  // });
                }, 500);
              });
            } catch (err) {
              // this.$Sentry.withScope(function(scope) {

              //   scope.setTag("offline", "Workorder: SET WO Actives");

              //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

              // });
              // Error Controller
              // this.$Sentry.captureException(err);
            }
          }
        });
    },

    async setWorkorderActive() {
      const formData = new FormData();

      if (this.newActivo.id_modelo == null) {
        this.openToastOptions(
          this.$t('error-al-guardar-activo'),
          this.$t('selecciona-un-modelo-para-el-activo')
        );
      } else if (this.newActivo.id_sistema == null) {
        this.openToastOptions(
          this.$t('error-al-guardar-activo'),
          this.$t('selecciona-un-sistema-para-el-activo')
        );
      } else {
        if (this.offline.status) this.newActivo.id = this.$moment().unix();
        for (const [key, value] of Object.entries(this.newActivo)) {
          if (key != 'image' && key != 'images') {
            if (value instanceof Array) {
              value.forEach((img, index) => {
                formData.append(`${key}${index}`, img);
              });
            } else {
              formData.append(key, value);
            }
          }
        }
        let axiosPayload = {
          data: formData,
          headers: { headers: { 'Content-Type': 'multipart/form-data' } }
        }

        this.isDisabledActive = true;
        const loading = await loadingController.create({ message: this.$t('Espera...') });
        await loading.present();

        this.$axios
          .post(
            `/v2/users/actions.php?call=setWorkorderActive&token=${this.gmao.user.token}&v2=1`,
            formData,
            axiosPayload.headers
          )
          .then(({ data }) => {
            if (data?.activo && !data?.status) {
              this.$openToastObject(
                this.$t('Ha ocurrido un error'),
                this.$t(data.activo.msg),
                'danger'
              );
            } else {
              const act = data.activo;
              act.not_editable = false;
              this.workorder.maquinas.push(act);
              this.setActivo = false;
              this.isDisabledActive = false;
            }
          })
          .catch(async (e) => {
            if (e.code == 'ERR_NETWORK' && this.offline.status) {
              try {
                axiosPayload = {
                  ...axiosPayload,
                  callFunction: 'setWorkorderActive',
                  props: {
                    layer: 2,
                    group: 'maquinas',
                    tempId: axiosPayload.data.get('id') || -1, // *NEW — REVIEW: Ver si lo crea con el unix
                    parentTempId: this.workorder?.id,
                    dependsOn: ['partes'],
                    dependencies: [{keyInDependent: 'id_parte', keyInParent: 'id'}]
                  }
                }
                this.offlineModule.syncController.addChange(axiosPayload);

                let query;

                if (this.newActivo.id_modelo) {
                  query = `
                  parteID=${
                    this.workorder.id
                  }|INSERT INTO maquinas (id, syncObject, nombre, numeroserie, ubicacion, observaciones, id_modelo, id_sistema, created_at, updated_at, last_modified)
                  VALUES
                    (
                      ${this.$moment().unix()},
                      '${JSON.stringify(axiosPayload)}',
                      '${this.newActivo?.nombre || ''}',
                      '${this.newActivo?.numeroserie || ''}',
                      '${this.newActivo?.ubicacion || ''}',
                      '${this.newActivo?.observaciones || ''}',
                      ${this.newActivo?.id_modelo || 0},
                      ${this.newActivo?.id_sistema || 0},
                      '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                      '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                      '${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
                    )
                `;
                  if (this.newActivo?.images && this.newActivo.images?.length) {
                    this.newActivo?.images?.forEach((imagen) => {
                      query += `;\n
                    INSERT INTO maquina_imagenes (id, id_maquina, id_usuario, base64Src, created_at, updated_at, last_modified) VALUES
                    (
                      ${this.$moment().unix() + 1},
                      id_maquina?,
                      ${this.gmao.user.id},
                      '${imagen}',
                      '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                      '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                      '${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
                    )
                    `;
                    });
                  }
                }
                const setWorkorderActivePromise = new Promise((resolve) => {
                  resolve(
                    this.offlineActionsController.SQLiteQuery(
                      'setWorkorderActive_workorder',
                      'maquinas',
                      query,
                      e.config,
                      this.offlineModule?.db
                    )
                  );
                });
                setWorkorderActivePromise.then(() => {
                  setTimeout(() => {
                    // const act = data.activo;
                    // act.not_editable = false;
                    // this.workorder.maquinas.push(act);
                    this.setActivo = false;
                    this.isDisabledActive = false;
                    // FIXME: *Revisar
                    // sqlite.closeConnection('gmaoTecnicos').then(() => {
                      this.getWorkorder(this.$route.params.id);
                    // });
                  }, 500);
                });
                // FIXME: *** Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
              } catch (err) {
              //   this.$Sentry.withScope(function(scope) {

              //   scope.setTag("offline", "Workorder: SET WO Active");

              //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

              // });
                // Error Controller
                // this.$Sentry.captureException(err);
              }
            }
          }).finally(async () => {
            this.isDisabledActive = false;
            await loading?.dismiss();
          });
      }
    },

    deleteWorkorderActive(active) {
      this.alertPopup(
        this.$tc(
          'seguro-que-quieres-quitar-el-activo-active-nombre-del-parte-this-workorder-id',
          [active?.nombre, this.workorder.id]
        ),
        () => {
          let axiosPayload = {
            data: {
              id_parte: this.workorder.id,
              id_maquina: active.id,
              tipo_letra: this.workorder.tipo_letra,
            },
            headers: { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
          };
          this.$axios
            .post(
              `/v2/users/actions.php?call=setDeleteWorkorderActive&token=${this.gmao.user.token}&v2=1`,
              { data: { ...axiosPayload.data } },
              axiosPayload.headers
            )
            .then(() => {
              const index = this.workorder.maquinas.findIndex((m) => m.id == active.id);
              this.getWorkorder(this.$route.params.id);
              this.workorder.maquinas.splice(index, 1);
            })
            .catch(async (e) => {
              if (e.code == 'ERR_NETWORK' && this.offline.status) {
                try {
                  // *NEW — REVIEW: Comprobar con activo existente y nuevo
                  // *NEW — REVIEW: Probar esto
                  axiosPayload = {
                    ...axiosPayload,
                    callFunction: 'setDeleteWorkorderActive',
                    props: {
                      layer: 3,
                      group: 'maquinas',
                      parentTempId: active?.id,
                      dependsOn: ['partes', 'maquinas'],
                      dependencies: [{keyInDependent: 'id_parte', keyInParent: 'id'}, {keyInDependent: 'id_maquina', keyInParent: 'id'}]
                    },
                  };

                  this.offlineModule.syncController.addChange(axiosPayload);

                  const query = `
                    UPDATE parte_maquinas
                    SET
                      deleted_at='${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                      last_modified='${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                      syncObject='${JSON.stringify(axiosPayload)}'
                    WHERE id_parte=${this.workorder?.id} AND id_maquina=${active.id}
                  `;
                  const deleteWorkorderActivePromise = new Promise((resolve) => {
                    resolve(
                      this.offlineActionsController.SQLiteQuery(
                        'deleteWorkorderActive_workorder',
                        'parte_maquinas',
                        query,
                        e.config,
                        this.offlineModule?.db
                      )
                    );
                  });
                  deleteWorkorderActivePromise.then(() => {
                    setTimeout(() => {
                      this.setActivos = false;
                      // FIXME: *Revisar
                      // sqlite.closeConnection('gmaoTecnicos').then(() => {
                        this.getWorkorder(this.$route.params.id);
                      // });
                    }, 500);
                  });
                } catch (err) {
                  // this.$Sentry.withScope(function(scope) {

                  //   scope.setTag("offline", "Workorder: DEL WO Active");

                  //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

                  // });
                  // Error Controller
                  // this.$Sentry.captureException(err);
                }
              }
            });
        },
        { cancel: 'cancelar', confirm: 'quitar' }
      );
    },

    setDeleteWorkorderDisplacement(desplazamiento) {

      this.alertPopup(
        this.$t('seguro-que-quieres-quitar-el-desplazamiento-del-parte-this-workorder-id'),
        () => {
          const formData = new FormData();
          formData.append('id_desplazamiento', desplazamiento?.id || '');

          // *NEW — XXX: [POST - SET DELETE DISPLACEMENT] NOT IN OFFLINE
          this.$axios
            .post(`/v2/users/actions.php?call=setDeleteWorkorderDisplacement&token=${this.gmao.user.token}&v2=1`, formData)
            .then(() => {
              const index = this.workorder.desplazamientos.findIndex((l) => l.id == desplazamiento?.id);
              this.workorder.desplazamientos.splice(index, 1);
            })
        });
    },

    async deleteWorkorderMaterial(material) {
      const materialDel = {
        id_parte: this.workorder.id,
        id_articulo: material.id_articulo,
        id_articulo_movimiento: material.id_articulo_movimiento,
        id_lote_almacen: material?.movistock?.id_lote_almacen,
        id_linea_parte_material: material?.movistock?.id_linea_parte_material,
      };
      this.alertPopup(
        this.$t('seguro-que-quieres-quitar-el-material-del-parte-this-workorder-id', [
          this.workorder.id,
        ]),
        () => {

          let axiosPayload = {
            data: {...materialDel},
            headers: { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
          };
          this.$axios
            .post(
              `/v2/users/actions.php?call=setDeleteWorkorderMaterial&token=${this.gmao.user.token}&v2=1`,
              { data: { ...axiosPayload.data } },
              axiosPayload.headers
            )
            .then(() => {
              const index = this.workorder.lineas.findIndex((l) => l.id == material.id);
              this.workorder.lineas.splice(index, 1);
            })
            .catch(async (e) => {
              if (e.code == 'ERR_NETWORK' && this.offline.status) {
                try {

                  // *NEW — FIXME: No funciona...
                  axiosPayload = {
                    ...axiosPayload,
                    callFunction: 'setDeleteWorkorderMaterial',
                    props: {
                      layer: 3,
                      group: 'lineas',
                      parentTempId: material?.id,
                      dependsOn: ['partes', 'lineas'],
                      dependencies: [{keyInDependent: 'id_parte', keyInParent: 'id'}, {keyInDependent: 'id_articulo', keyInParent: 'id'}]
                    },
                  }

                  this.offlineModule.syncController.addChange(axiosPayload);

                  const query = `
                    UPDATE parte_materiales
                    SET
                      deleted_at='${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                      last_modified='${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                      updated_at='${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                      syncObject='${JSON.stringify(axiosPayload)}'
                    WHERE id_parte=${this.workorder.id} AND id_articulo=${
                    material.id_articulo
                  } AND id_tecnico=${this.gmao.user.id}
                  `;
                  const deleteWorkorderMaterialPromise = new Promise((resolve) => {
                    resolve(
                      this.offlineActionsController.SQLiteQuery(
                        'deleteWorkorderMaterial_workorder',
                        'parte_materiales',
                        query,
                        e.config,
                        this.offlineModule?.db
                      )
                    );
                  });
                  deleteWorkorderMaterialPromise.then(() => {
                    setTimeout(() => {
                      // sqlite.closeConnection('gmaoTecnicos').then(() => {
                        const index = this.workorder.lineas.findIndex(
                          (l) => l.id == material.id
                        );
                        this.workorder.lineas.splice(index, 1);
                        this.getWorkorder(this.$route.params.id);
                      // });
                    }, 500);
                  });
                } catch (err) {
                  // Error Controller
                  // this.$Sentry.captureException(err);
                }
              }
            });
        },
        { cancel: 'cancelar', confirm: 'quitar' }
      );
    },

    // XXX: DEPRECATED. Better to use this.$alertController
    async alertPopup(header, callback, { cancel, confirm } = { cancel: 'cancelar', confirm: 'Confirmar' }) {
      const alert = await alertController.create({
        cssClass: 'my-custom-class',
        header,
        buttons: [
          {
            text: this.$t(cancel),
            role: 'cancel',
            cssClass: 'secondary',
            id: 'cancel-button',
          },
          {
            text: this.$t(confirm),
            id: 'confirm-button',
            handler: callback,
          },
        ],
      });

      await alert.present();
    },

    // ==> OPTIMIZE: BLOQUE_ASSETS*
      async addActiveMenu() {
        const buttons = [
          {
            text: this.$t('anadir-activo-mediante-qr'),
            icon: qrCodeOutline,
            handler: () => {
              this.qrActivo = true;
            },
          }
        ];
        if(this.$hasPermissions('maquinas','crear')) {
          buttons.push(
          {
          text: this.$t('Añadir un nuevo activo'),
          icon: addCircleOutline,
          handler: () => {
            this.setActivo = true;
          },
          })
        }
        buttons.push(
          {
            text: this.$t('cancelar'),
            icon: closeOutline,
            role: 'cancel',
          },
        )

        buttons.unshift(
          {
            text: this.$t('anadir-desde-listado-de-activos'),
            icon: add,
            handler: () => {
              if (+this.gmao.comportamientos.selector_sistemas_parte) this.setActivosSistema = true;
              else this.setActivos = true;
            },
          }
        )

        const actionSheet = await actionSheetController.create({
          header: this.$t('anadir-activos'),
          cssClass: 'my-custom-class',
          buttons,
        });

        // this.getSistemas();
        await actionSheet.present();
      },
    // <== OPTIMIZE: /BLOQUE_ASSETS

    async addPhotoActionSheet() {
      try {
        const actionLinks = [];

        actionLinks.push({
          text: this.$t('gallery-photo'),
          icon: imagesOutline,
          handler: async () => {
            await this.galleryPicker('images');
          },
        });

        actionLinks.push({
          text: this.$t('camera-photo'),
          icon: camera,
          handler: async () => {
            const photo = await this.$Camera.getPhoto({
              resultType: this.$CameraResultType.Base64,
              source: this.$CameraSource.Camera,
              quality: 100,
              saveToGallery: true,
            });

            if (photo) {
              this.uploadPhoto(photo);
            }
          },
        });

        actionLinks.push({
          text: this.$t('cancelar'),
          role: 'cancel',
        });

        const actionSheet = await actionSheetController.create({
          header: this.$t('IMÁGENES'),
          buttons: actionLinks,
        });

        await actionSheet.present();
      } catch (error) {
        this.openToastOptions(
          this.$t('algo-no-ha-ido-bien'),
          this.$t('error-al-abrir-camara')
        );
        console.log(error);
      }
    },

    async addPhotoMaterial() {
      const photo = await this.$Camera.getPhoto({
        resultType: this.$CameraResultType.Base64,
        source: this.$CameraSource.Camera,
        promptLabelPhoto: this.$t('gallery-photo'),
        promptLabelPicture: this.$t('camera-photo'),
        quality: 100,
        saveToGallery: true,
      });

      if (photo) {
        this.uploadPhotoMaterial(photo);
      }
    },

    async addPhotoActivo() {
      const photo = await this.$Camera.getPhoto({
        resultType: this.$CameraResultType.Base64,
        source: this.$CameraSource.Camera,
        quality: 100,
        promptLabelPhoto: this.$t('gallery-photo'),
        promptLabelPicture: this.$t('camera-photo'),
        saveToGallery: true,
      });

      if (photo) {
        this.uploadPhotoActivo(photo);
      }
    },

    async uploadPhotoMaterial(photo) {
      photo.base64String =
        photo.base64String ||
        (await this.$getBase64Image(photo.webPath).then((data) => data.split`,`[1]));

      const blob = await new Promise((resolve, reject) => {
        this.$compressImage(this.$base64toBlob(photo.base64String), resolve, reject);
      });

      this.selectedMaterial.blob = blob;
      this.selectedMaterial.image = `data:image/png;base64,${photo.base64String}`;
    },
    async uploadPhotoActivo(photo) {
      const blob = await this.$base64toBlob(photo.base64String);
      this.newActivo.blob = blob;
      this.newActivo.image = `data:image/png;base64,${photo.base64String}`;
    },

    uploadPhoto(photo) {
      const blob = this.$base64toBlob(photo.base64String);

      const formData = new FormData();

      formData.append('id_parte', this.workorder.id);
      formData.append('file', blob);

      let axiosPayload = {
        data: formData,
        headers: { headers: { 'Content-Type': 'multipart/form-data' } }
      };

      this.$axios
        .post(
          `/v2/users/actions.php?call=setImageWorkorder&token=${this.gmao.user.token}&v2=1`,
          axiosPayload.data,
          axiosPayload.headers
        )
        .then(({ data }) => {
          this.getWorkorder(this.workorder.id);
          if (data.imagen.src?.length) {
            this.openToastOptions(
              this.$t('imagen-subida-con-exito'),
              this.$t('la-imagen-se-ha-subido-correctamente-a-la-orden-de-trabajo'),
              'success'
            );
          } else {
            this.openToastOptions(
              this.$t('error-al-subir-la-imagen'),
              this.$t(
                'el-servidor-no-ha-podido-procesar-la-subida-de-imagen-por-favor-intentalo-mas-tarde'
              )
            );
          }
        })
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              // *NEW — REVIEW: VER POR QUE ESTÁ DUPLICADA ESTO EN OFFLINE
              axiosPayload = {
                ...axiosPayload,
                callFunction: 'setImageWorkorder',
                props: {
                  layer: 2,
                  group: 'images',
                  parentTempId: this.workorder?.id || -1,
                  dependsOn: ['partes'],
                  dependencies: [{keyInDependent: 'id_parte', keyInParent: 'id'}]
                }
              };
              this.offlineModule.syncController.addChange(axiosPayload);

              const query = `
                INSERT INTO parte_imagenes (id, id_parte, syncObject, id_tecnico, base64Src, created_at, updated_at, last_modified)
                VALUES (${this.$moment().unix()}, ${this.workorder.id}, '${JSON.stringify(
                axiosPayload
              )}', ${this.workorder?.id_tecnico}, 'data:image/png;base64,${
                photo.base64String
              }', '${this.$moment().format(
                'YYYY-MM-DD H:mm:ss'
              )}', '${this.$moment().format(
                'YYYY-MM-DD H:mm:ss'
              )}', '${this.$moment().format('YYYY-MM-DD H:mm:ss')}')
              `;
              const uploadPhotoPromise = new Promise((resolve) => {
                resolve(
                  this.offlineActionsController.SQLiteQuery(
                    'uploadPhoto_workorder',
                    'parte_imagenes',
                    query,
                    e.config,
                    this.offlineModule?.db
                  )
                );
              });
              uploadPhotoPromise.then(() => {
                setTimeout(() => {
                  // FIXME: *Revisar
                  // sqlite.closeConnection('gmaoTecnicos').then(() => {
                    this.getWorkorder(this.$route.params.id);
                  // });
                }, 500);
              });
            } catch (err) {
              // this.$Sentry.withScope(function(scope) {

              //   scope.setTag("offline", "Workorder: SET WO Image");

              //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

              // });
              // Error Controller
              // this.$Sentry.captureException(err);
            }
          }
        });
    },

    saveFirma() {
      const { isEmpty, data } = this.$refs.firmaPadRef.$refs.signaturePad.saveSignature();
      if (!isEmpty) this.signature.image = data;
      this.setFirma = false;

      const content = this.$refs.content;

      this.$nextTick(async () => {
        const scrollEl = await content.$el.getScrollElement();
        let y = scrollEl.scrollHeight - scrollEl.clientHeight;
        y += 50; // PARCHE PARA SCROLL MÁXIMO
        return content.$el.scrollToPoint(undefined, y, 500);
      });

      // Guardamos la firma sin cambiar el estado del parte.
      this.saveFirmaImagen();
    },

    async saveFirmaImagen() {

      let geolocation;

      const blob = this.$base64toBlob(this.signature.image.split(',')[1]);
      const formData = new FormData();

      // Or '' porque FormData no entiende de null, y solo acepta strings o Blobs
      if (+this.gmao.comportamientos.parte_firma_geolocalizacion == 1) {
        const startTime = performance.now();
        geolocation = await this.$getGeolocation(false);
        const endTime = performance.now();

        if ((endTime - startTime) > 10000 ) {
          geolocation = {};
        }

        formData.append('lat', geolocation?.coords?.latitude || '');
        formData.append('lng', geolocation?.coords?.longitude || '');
      } else {
        formData.append('lat', '');
        formData.append('lng', '');
      }

      formData.append('name', this.signature?.nombre || '');
      formData.append('dni', this.signature?.dni || '');
      formData.append('enviar_email', this.signature.email || '');
      formData.append('signature', blob);

      formData.append('id_parte', this.workorder.id);

      // *NEW — XXX: [POST - SET WORKORDER SIGNATURE] NOT IN OFFLINE
      const syncObject = {
        callFunction: 'setWorkorderSignatureImage',
        data: {
          name: this.signature?.nombre,
          dni: this.signature?.dni,
          enviar_email: this.signature?.email,
          signature: blob,
          id_parte: this.workorder.id,
          lat: geolocation?.coords?.latitude,
          lng: geolocation?.coords?.longitude,
        },
      };

      console.log(syncObject);

      this.$axios
        .post(
          `/v2/users/actions.php?call=setWorkorderSignatureImage&token=${this.gmao.user.token}&v2=1`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        )
        .then(({ data }) => {
          // this.$openToastObject(this.$t('No puede cerrar la OT'), this.$t('Es necesario subir fotos a la OT para poder finalizarla.'), 'danger');
          if (data?.firma?.id) {
            const datosFirmaLocal = structuredClone(this.signature);
            this.workorder.firma = data.firma;

            if (+this.gmao.comportamientos.primero_datos_despues_firma) {
              const dni = datosFirmaLocal.dni;
              const nombre = datosFirmaLocal.nombre;
              const email = datosFirmaLocal.email;

              this.workorder.firma.dni = dni;
              this.workorder.firma.nombre = nombre;
              this.workorder.firma.email = email;
            }

          }
          // this.getWorkorder(this.workorder?.id);

        }).catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            // *NEW — XXX: [SET - FIRMA IMG] NOT IN OFFLINE
            try {
              const startTime = performance.now();
              geolocation = await this.$getGeolocation(false);
              const endTime = performance.now();

              if ((endTime - startTime) > 10000 ) {
                geolocation = {};
              }
            } catch (e) {
              // this.$Sentry.withScope(function(scope) {

              //   scope.setTag("geolocation", "Workorder: Geolocation fetching failed");

              //   scope.setFingerprint([e.name, e.message, String(e.stack)]);

              // });
                console.error('1', e)
                this.$Sentry.captureException(e);
            }

            try {
              console.log(2, blob, this.signature)
              const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

              const ret = await sqlite.checkConnectionsConsistency();
              const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

              let db = new SQLiteDBConnection();
              if (ret.result && isConn) {
                db = await sqlite.retrieveConnection('gmaoTecnicos', false);
              } else {
                db = await sqlite.createConnection(
                  'gmaoTecnicos',
                  false,
                  'no-encryption',
                  1,
                  false
                );
              }
              await db.open();

              const query = `
              INSERT OR REPLACE INTO parte_firma
                (
                  id,
                  syncObject,
                  id_parte,
                  id_tecnico,
                  ${
                    +this.gmao.comportamientos.parte_firma_geolocalizacion == 1
                      ? 'lat, lng,'
                      : ''
                  } 
                  firma_tec,
                  nombre,
                  dni,
                  base64Src,
                  enviar_email,
                  created_at,
                  updated_at,
                  last_modified
                )
                VALUES
                (
                  ${this.$moment().unix()},
                  '${JSON.stringify(syncObject)}',
                  ${this.workorder.id},
                  ${this.gmao.user?.id},
                  ${
                    +this.gmao.comportamientos.parte_firma_geolocalizacion == 1
                      ? `'${geolocation?.coords?.latitude || ''}', '${
                          geolocation?.coords?.longitude || ''
                        }',`
                      : ''
                  }
                  ${0},
                  '${this.signature?.nombre || ''}',
                  '${this.signature?.dni || ''}',
                  '${this.signature.image || ''}',
                  '${this.signature?.email || ''}',
                  '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                  '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                  '${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
                )`;
              const sendFirmaImgPromise = new Promise((resolve) => {
                resolve(
                  this.$SQLiteQuery(
                    'sendFirma_workorder',
                    'parte_firma',
                    [query, true], // NOTE: true significa que solo se tiene que guardar la img, y no cambiar el estado.
                    e.config,
                    db
                  )
                );
              });
              sendFirmaImgPromise.then(() => {
                setTimeout(() => {
                  this.setActivos = false;
                  this.getWorkorder(this.$route.params.id);
                  // sqlite.closeConnection('gmaoTecnicos').then(async () => {
                  // });
                }, 500);
              });
            } catch (err) {
              // this.$Sentry.withScope(function(scope) {

              //   scope.setTag("offline", "Workorder: SET WO Firma");

              //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

              // });
              console.error('2', err)
                this.$Sentry.captureException(err);
            }
          } else {
            this.openToastOptions(
              this.$t('error-al-subir-la-firma'),
              this.$t(
                'el-servidor-no-ha-podido-procesar-la-subida-de-imagen-por-favor-intentalo-mas-tarde'
              )
            );
          }
        });
    },

    saveFirmaTecnico() {
      const { isEmpty, data } = this.$refs.firmaPadRefTecnico.$refs.signaturePadTecnico.saveSignature();
      if (!isEmpty) this.signatureTecnico.image = data;
      this.setFirmaTecnico = false;

      this.sendFirma(this.signatureTecnico, true);

      const content = this.$refs.content;

      this.$nextTick(async () => {
        const scrollEl = await content.$el.getScrollElement();
        let y = scrollEl.scrollHeight - scrollEl.clientHeight;
        y += 50; // PARCHE PARA SCROLL MÁXIMO
        return content.$el.scrollToPoint(undefined, y, 500);
      });
    },

    async sendPhotoFirma(firma) {
      this.isDisabled = true;
      const photo = await this.$Camera.getPhoto({
        resultType: this.$CameraResultType.Base64,
        source: this.$CameraSource.Camera,
        promptLabelCancel: this.$t('Cancelar'),
        promptLabelPicture: this.$t('camera-photo'),
        quality: 100,
        saveToGallery: true,
      });

      if (photo) {
        this.uploadPhoto(photo);
        this.sendFirma(firma);
      }
    },

    goBack() {
      if(!this.setMessageForBudget && !this.duplicateWorkorder && !this.settingPartes && !this.settingIncidencias && !this.isAlertOpen) {
        this.$router.go(-1);
      }
    },

    async sendFirma(firma, tecnico = false) {
      if (!tecnico) this.isDisabled = true;
      
      if (+this.gmao.comportamientos.primero_datos_despues_firma) {
        this.isDisabled = false;
        if (!this.signature.image && !this.signature.src) return this.$openToastObject(this.$t('No puede cerrar la OT'), this.$t('Es necesario añadir la firma antes de finalizar el parte'), 'danger');
      }

      if (+this.gmao.comportamientos.foto_obligatoria_app_tecnico && !this.areTherePhotosInWO) {
        this.isDisabled = false;
        this.$openToastObject(this.$t('No puede cerrar la OT'), this.$t('Es necesario subir fotos a la OT para poder finalizarla.'), 'danger');
        return;
      }

      if (this.avisar_escribir_anomalias_al_finalizar && +this.alertAnomalyCount) {
        this.isDisabled = false;
        const alert = await alertController.create({
          cssClass: 'my-custom-class',
          header: this.$t(`Si ha encontrado anomalías y no las has registrado, hagalo antes de finalizar el parte`),
          buttons: [
            {
              text: this.$t('Registrar anomalias'),
              role: 'cancel',
              cssClass: 'secondary',
              id: 'cancel-button',
              handler: () => this.alertAnomalyCount--
            },
            {
              text: this.$t('Cerrar OT'),
              id: 'confirm-button',
              handler: () => {this.alertAnomalyCount--; this.sendFirma(firma, tecnico)},
            },
          ],
        });

        await alert.present();

        return;
      }

      // CHECKLIST FINAL
      if (+this.gmao.comportamientos.checklist_fin && !tecnico) {
        const maquinaCheck = (this.workorder?.maquinas || []).find(
          (m) => m.nombre == 'Comprobaciones finales' && !+m.pivot?.tareas_completadas
        );
        
        if (maquinaCheck) {
          this.isDisabled = false;
          return this.$router.push({
            name: 'item',
            params: { asset: maquinaCheck.id, workorder: this.workorder.id, sistema: maquinaCheck.id_sistema },
          });
        }
      }

      let blob;

      if (!this.isOTClosableWithoutSignature && firma?.image) {
        blob = this.$base64toBlob(firma.image.split(',')[1]);
      }

      const anomalias = this.workorder.anomalies;
      const formData = new FormData();

      let geolocation;

      // Or '' porque FormData no entiende de null, y solo acepta strings o Blobs
      if (+this.gmao.comportamientos.parte_firma_geolocalizacion == 1) {
        const startTime = performance.now();
        geolocation = await this.$getGeolocation(false);
        const endTime = performance.now();

        if ((endTime - startTime) > 10000 ) {
          geolocation = {};
        }

        formData.append('lat', geolocation?.coords?.latitude || '');
        formData.append('lng', geolocation?.coords?.longitude || '');
      } else {
        formData.append('lat', '');
        formData.append('lng', '');
      }
      formData.append('name', firma?.nombre || '');
      formData.append('dni', firma?.dni || '');
      formData.append('id_parte', this.workorder.id);
      formData.append('firma_tec', +tecnico);
      if (blob && !this.isOTClosableWithoutSignature) formData.append('signature', blob);
      formData.append('enviar_email', this.signature.email || '');
      formData.append('genera_presupuesto', this.workorder?.genera_presupuesto || '');
      console.log(this.not_finished);
      formData.append('not_finished', this.not_finished || '');

      // if (this.workorder?.firma?.id) formData.append('parte_firma', this.workorder?.firma?.id || '');

      let axiosPayload = {
        data: formData,
        headers: { headers: { 'Content-Type': 'multipart/form-data' } }
      };

      this.loading = false;

      if (!tecnico) {
        if (this.workOrders.timeRegister?.from) {
          this.workOrders.timeRegister.to = this.$moment().format();
          this.storeTimes(this.workOrders.timeRegister);
          this.workOrders.timeRegister = null;
          this.time = { hours: 0, minutes: 0, seconds: 0 };
        }
      }

      this.$axios
        .post(
          `/v2/users/actions.php?call=setWorkorderSignature&token=${this.gmao.user.token}&v2=1`,
          axiosPayload.data,
          axiosPayload.headers
        )
        .then(async ({ data }) => {
          if (!tecnico) {
            this.workorder.firma = data.imagen;
            this.workorder.not_editable = true;

            if (this.workorder.anomalies) {
              if (this.isAlertOpen) {
                console.log('El alert ya está abierto.');
                return;
              }
              this.isAlertOpen = true;
              this.workorderAnomalyAssets = this.workorder?.maquinas?.filter((m) => m.anomalia);
              if (this.workorderAnomalyAssets?.length) this.workorderAnomalyAssets?.map((m) => m.activo = true);

              this.anomaliasModal = await alertController.create({
                header:
                  'Se han registrado anomalías en el parte. ¿Deseas abrir un conductivo o una incidencia?',
                buttons: [
                  {
                    text: this.$t('conductive'),
                    id: 'conductive-button',
                    handler: () => {
                      this.settingPartes = true;
                      this.isAlertOpen = false; // Restablecer cuando se cierra
                    },
                  },
                  {
                    text: this.$t('incidence'),
                    id: 'incidence-button',
                    handler: () => {
                      this.settingIncidencias = true;
                      this.isAlertOpen = false; // Restablecer cuando se cierra
                    },
                  },
                  {
                    text: this.$t('not_register'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    id: 'cancel-button',
                    handler: () => {
                      this.$router.go(-1);
                    },
                  },
                ],
              });

              await this.anomaliasModal.present();
            }
          } else {
            this.workorder.firma_tecnico = data.imagen;
          }

          // COMPROBAMOS SI EL PARTE ESTÁ FINALIZADO
          if (!tecnico && this.not_finished && this.isDuplicable) {
            this.duplicateWorkorder = true;
          }
          // COMPROBAMOS SI EL PARTE ESTÁ FINALIZADO
          if (!tecnico && this.workorder.genera_presupuesto) {
            this.setMessageForBudget = true;
          }

          if (!tecnico && (data?.imagen?.id) && !this.workorder.anomalies && !this.duplicateWorkorder & !this.setMessageForBudget) {
            this.$router.go(-1);
          }
        })
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {

              axiosPayload = {
                ...axiosPayload,
                callFunction: 'setWorkorderSignature',
                props: {
                  layer: 2,
                  group: 'signature',
                  parentTempId: axiosPayload.data.get('id_parte') || this.workorder?.id,
                  dependsOn: ['partes'],
                  dependencies: [{keyInDependent: 'id_parte', keyInParent: 'id'}]
                }
              }
              this.offlineModule.syncController.addChange(axiosPayload);


              const startTime = performance.now();
              geolocation = await this.$getGeolocation(false);
              const endTime = performance.now();

              if ((endTime - startTime) > 10000 ) {
                geolocation = {};
              }
            } catch (e) {
              // this.$Sentry.withScope(function(scope) {

              //   scope.setTag("geolocation", "Workorder: Geolocation fetching failed");

              //   scope.setFingerprint([e.name, e.message, String(e.stack)]);

              // });
                this.$Sentry.captureException(e);
            }
            try {
              const query = `
              INSERT OR REPLACE INTO parte_firma
                (id, syncObject, id_parte, id_tecnico, ${
                  +this.gmao.comportamientos.parte_firma_geolocalizacion == 1
                    ? 'lat, lng,'
                    : ''
                } firma_tec, nombre, dni, base64Src, created_at, updated_at, last_modified)
              VALUES
              (
                ${this.$moment().unix()},
                '${JSON.stringify(axiosPayload)}',
                ${this.workorder.id},
                ${this.gmao.user?.id},
                ${
                  +this.gmao.comportamientos.parte_firma_geolocalizacion == 1
                    ? `'${geolocation?.coords?.latitude || ''}', '${
                        geolocation?.coords?.longitude || ''
                      }',`
                    : ''
                }
                ${+tecnico || 0},
                '${firma?.nombre || ''}',
                '${firma?.dni || ''}',
                '${firma?.image || ''}',
                '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                '${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
              )`;

              const sendFirmaPromise = new Promise((resolve) => {
                resolve(
                  this.offlineActionsController.SQLiteQuery(
                    'sendFirma_workorder',
                    'parte_firma',
                    query,
                    e.config,
                    this.offlineModule?.db
                  )
                );
              });
              sendFirmaPromise.then(() => {
                // FIXME: *Revisar
                setTimeout(async () => {
                  this.setActivos = false;
                  // sqlite.closeConnection('gmaoTecnicos').then(async () => {
                    await this.getWorkorder(this.$route.params.id).then(async () => {
                      if (!tecnico) {
                        this.workorder.firma = this.workorder?.firma?.base64Src;
                        this.workorder.not_editable = true;

                      if (this.workorder.anomalies) {
                        this.anomaliasModal = await alertController.create({
                          header:
                            'Se han registrado anomalías en el parte. ¿Deseas abrir un conductivo o una incidencia?',
                          buttons: [
                            {
                              text: this.$t('conductive'),
                              id: 'conductive-button',
                              handler: () => {
                                this.settingPartes = true;
                                this.isAlertOpen = false; // Restablecer cuando se cierra
                              },
                            },
                            {
                              text: this.$t('incidence'),
                              id: 'incidence-button',
                              handler: () => {
                                this.settingIncidencias = true;
                                this.isAlertOpen = false; // Restablecer cuando se cierra
                              },
                            },
                            {
                              text: this.$t('not_register'),
                              role: 'cancel',
                              cssClass: 'secondary',
                              id: 'cancel-button',
                            },
                          ],
                        });

                        await this.anomaliasModal.present();
                      }
                    } else {
                      this.workorder.firma_tecnico = this.workorder?.firma_tecnico?.base64Src;
                    }

                    // COMPROBAMOS SI EL PARTE ESTÁ FINALIZADO
                    if (!tecnico && this.workorder.not_finished && this.isDuplicable) {
                      this.duplicateWorkorder = true;
                    }
                    if (!tecnico && !anomalias && !this.duplicateWorkorder) {
                      this.$router.go(-1);
                    }
                  });
                  // sqlite.closeConnection('gmaoTecnicos').then(async () => {
                  // });
                }, 500);
              });
            } catch (err) {
              // this.$Sentry.withScope(function(scope) {

              //   scope.setTag("offline", "Workorder: SET WO Firma");

              //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

              // });
              // Error Controller
              // this.$Sentry.captureException(err);
            }
          } else {
            // FIXME:
            this.openToastOptions(
              this.$t('error-al-subir-la-firma'),
              this.$t(
                'el-servidor-no-ha-podido-procesar-la-subida-de-imagen-por-favor-intentalo-mas-tarde'
              )
            );
          }
        });
    },

    getMaquina(maquina) {
      const activo = this.workorder?.maquinas?.find((m) => m.id == maquina);
      return activo
        ? `${activo?.nombre ?? ''} ${activo?.modelo?.modelo ?? ''} ${
            activo?.modelo?.marca ?? ''
          }`
        : null;
    },

    getHourTypes() {
      this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getHourTypes',
            token: this.gmao.user.token,
            proyecto: this.workorder?.id_proyecto,
            parte_id: this.workorder?.id,
            page: -1,
            v2: 1,
          },
        })
        .then(({ data }) => {
          const anyDefaultOption = data.find((t) => +t.tipo_defecto) || data.find((t) => +t?.por_defecto);
          this.hourTypes = data;

          // Tipo de hora por defecto
          this.tipoTiempoPorDefecto = this.hourTypes.find((t) => +t.tipo_defecto) || this.hourTypes.find((t) => +t.por_defecto);
          if (+this.gmao.comportamientos?.campo_tipo_tiempo_obligatorio == 1) {
            // this.newHora.id_tipo_tiempo = this.hourTypes[0].id;
            this.selectTipoTiempo(this.hourTypes[0]);
          }

          if (Object.keys(anyDefaultOption || {}).length) {
            this.selectTipoTiempo(anyDefaultOption);
          }
        })
        .catch((e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            this.hourTypes = this.workOrders.tiposHora.at(0).data;
          }
        });
    },

    getArticle(result) {
      const { code, type } = result;
      let ids = null;

      switch (type) {
        case 'qr':
          ids = code.split`->`;

          if (ids?.length == 2) {
            this.$axios
              .get('/v2/users/actions.php', {
                params: {
                  call: 'getMaterial',
                  token: this.gmao.user.token,
                  value: this.gmao.user.id,
                  almacen: ids[0],
                  articulo: ids[1],
                  page: -1,
                  v2: 1,
                },
              })
              .then(({ data }) => {
                this.selectedMaterial.material = data;
                if (data?.almacenes && data?.almacenes?.length) {
                  this.selectedMaterial.almacen = (data?.almacenes || [])?.pop()?.id || {};
                }
              })
              .catch(async (e) => {
                if (e.code == 'ERR_NETWORK' && this.offline.status) {
                  try {
                    const query = `
                      SELECT * FROM articulos
                      WHERE id=${ids[1]}
                    `;

                    const vars = {
                      id_almacen: ids[0],
                      id_articulo: ids[1],
                    };

                    const getArticlePromise = new Promise((resolve) => {
                      resolve(
                        this.offlineActionsController.SQLiteQuery(
                          'getArticle_workorder',
                          'articulos',
                          [query, vars],
                          e.config,
                          this.offlineModule?.db
                        )
                      );
                    });
                    getArticlePromise.then(() => {
                      setTimeout(() => {
                        // sqlite.closeConnection('gmaoTecnicos').then(async () => {
                          this.selectedMaterial.material = this.offline.articulo;
                          this.selectedMaterial.almacen = this.offline.articulo.almacenes[0].id;
                        // });
                      }, 500);
                    });
                  } catch (err) {
                    // this.$Sentry.withScope(function(scope) {

                    //   scope.setTag("offline", "Workorder: GET Articulo");

                    //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

                    // });
                    // Error Controller
                    // this.$Sentry.captureException(err);
                  }
                }
              })
              .finally(() => {
                // this.loading = false;
              });
          }
          break;

        case 'barcode':
          this.$axios
            .get('/v2/users/actions.php', {
              params: {
                call: 'getMaterial',
                token: this.gmao.user.token,
                value: this.gmao.user.id,
                barcode: code,
                page: -1,
                v2: 1,
              },
            })
            .then(({ data }) => {
              this.selectedMaterial.material = data;
              this.selectedMaterial.almacen = data.almacenes[0].id;
            })
            .finally(() => {
              // this.loading = false;
            }).catch((error) => {
          // TODO: Offline
          console.log(error);
        });
          break;
      }
    },

    // ==> OPTIMIZE: BLOQUE_ASSETS
      setMaquina(result) {
        const { code, type } = result;

        if (type == 'qr') {

          let axiosPayload = {
            data: {
              id_parte: this.workorder.id,
              activos: [btoa(code)],
              tipo_parte: this.workorder.tipo_letra,
              encrypted: 1,
              btoa: 1,
            },
            headers: { headers: { 'Content-Type': 'application/x-www-form-urlencoded'} }
          }
          this.$axios
            .post(
              `/v2/users/actions.php?call=setWorkorderActives&token=${this.gmao.user.token}&v2=1`,
              { data: { ...axiosPayload.data } },
              axiosPayload.headers
            )
            .then(async ({ data }) => {
              data.activos = await this.mergeAssetChild(data.activos);
              this.workorder.maquinas = data.activos;
              this.setActivos = false;
              this.setActivosSistema = false;
              this.getWorkorder(this.$route.params.id);
            })
            .catch(async (e) => {
              if (e.code == 'ERR_NETWORK' && this.offline.status) {
                try {

                  axiosPayload = {
                    ...axiosPayload,
                    callFunction: 'setWorkorderActives',
                    props: {
                      layer: 2,
                      group: 'maquinas',
                      parentTempId: this.workorder?.id,
                      dependsOn: ['partes'],
                      dependencies: [{keyInDependent: 'id_parte', keyInParent: 'id'}]
                    }
                  }
                  this.offlineModule.syncController.addChange(axiosPayload);
                  const query = `SELECT * FROM maquinas WHERE codigo_qr='${code}'`;

                  const getMquinaPromise = new Promise((resolve) => {
                    resolve(
                      this.offlineActionsController.SQLiteQuery(
                        'getMaquinas_workorder',
                        'maquinas',
                        query,
                        { method: 'get' },
                        this.offlineModule?.db
                      )
                    );
                  });

                  getMquinaPromise.then(() => {
                    setTimeout(() => {
                      const maquina = this.offline.assets[0];

                      const querySet = `INSERT INTO parte_maquinas
                        (id, syncObject, id_parte, id_maquina, created_at, updated_at, last_modified)
                      VALUES
                        (${this.$moment().unix()}, '${JSON.stringify(axiosPayload)}', ${
                        this.workorder.id
                      }, ${maquina?.id || 0}, '${this.$moment().format(
                        'YYYY-MM-DD H:mm:ss'
                      )}', '${this.$moment().format(
                        'YYYY-MM-DD H:mm:ss'
                      )}', '${this.$moment().format('YYYY-MM-DD H:mm:ss')}')\n`;
                      const setMaquinaPromise = new Promise((resolve) => {
                        resolve(
                          this.offlineActionsController.SQLiteQuery(
                            'setMaquina_workorder',
                            'parte_maquinas',
                            querySet,
                            e.config,
                            this.offlineModule?.db
                          )
                        );
                      });
                      setMaquinaPromise.then(() => {
                        // FIXME: *Revisar
                        setTimeout(async () => {
                          // sqlite.closeConnection('gmaoTecnicos').then(async () => {
                            await this.getWorkorder(this.$route.params.id);
                            this.setActivos = false;
                            this.setActivosSistema = false;
                          // });
                        }, 500);
                      });
                    }, 500);
                  });

                } catch (err) {
                  // this.$Sentry.withScope(function(scope) {

                  //   scope.setTag("offline", "Workorder: SET Maquina");

                  //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

                  // });
                  // Error Controller
                  // this.$Sentry.captureException(err);
                }
              }
            });
        }
      },
    // <== OPTIMIZE: /BLOQUE_ASSETS

    async openToastOptions(header, message, color = 'danger') {
      const toast = await toastController.create({
        header,
        message,
        icon: informationCircle,
        position: 'top',
        color,
        duration: '7000',
        buttons: [
          {
            text: this.$t('cerrar'),
            role: 'cancel',
          },
        ],
      });

      await toast.present();
    },

    // ==> OPTIMIZE: BLOQUE_DATOS
      // -> NAVEGACION
    async presentActionSheetNavigate(toLat, toLong) {
      const destination = toLat + ',' + toLong;
      const actionLinks = [];

        actionLinks.push({
          text: 'Google Maps',
          icon: mapOutline,
          handler: () => {
            window.open('https://www.google.com/maps/search/?api=1&query=' + destination);
          },
        });

        actionLinks.push({
          text: 'Waze',
          icon: carSportOutline,
          handler: () => {
            window.open('https://waze.com/ul?ll=' + destination + '&navigate=yes&z=10');
          },
        });

        actionLinks.push({
          text: this.$t('cancelar'),
          icon: closeOutline,
          role: 'cancel',
        });

        const actionSheet = await actionSheetController.create({
          header: this.$t('ir-al-destino'),
          buttons: actionLinks,
        });

        await actionSheet.present();
      },

      // -> ESTADO PARTE
      updateEstado() {
        // *NEW — XXX: [POST - SET PARTE ESTADO] NOT IN OFFLINE
        this.$axios
          .post(
            `/v2/users/actions.php?call=setEstadoParte&token=${this.gmao.user.token}&v2=1`,
            {
              data: {
                estado: this.newStatus,
                parte: this.workorder.id,
              }
            },
            {
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }
          )
          .then(() => {
            this.editStatus = false;
            this.getWorkorder(this.$route.params.id);
          }).catch((error) => {
            // TODO: Offline
            console.log(error);
          });
      },

      getParteEstados() {
        // *NEW — XXX: [GET - GET PARTE ESTADOS] NOT IN OFFLINE
        this.$axios
          .get('/v2/users/actions.php', {
            params: {
              call: 'getParteEstados',
              u: this.gmao.user.id,
              token: this.gmao.user.token,
              v2: 1,
            },
          })
          .then(({ data }) => {
            this.estados = data;
          }).catch((error) => {
            console.log(error);
          });
      },

      // -> FECHA PARTE
      fechaOT(ev) {
        // *NEW — XXX: [POST - CHANGE FECHA OT] NOT IN OFFLINE
        this.updateWorkorderField(
          'fecha',
          this.$moment(ev.detail.value).format('YYYY-MM-DD')
        )
          .then(() => {
            this.getWorkorder(this.workorder?.id);
            this.$refs.calendar.$el.dismiss(null, 'cancel');
          })
          .catch((e) => {
            console.log('Error Cambio Fecha', e);
          });
      },


    // <== OPTIMIZE: BLOQUE_DATOS
    // ==> OPTIMIZE: BLOQUE_MATERIALES
      // *NEW — XXX: ver la logica de esta funcion... porque tiene tela...
    async setWorkorderMaterials(edit = null) {
      const {
        cantidad,
        material,
        descripcion,
        articulo,
        almacen
      } = this.selectedMaterial;
      let queryImage = '';
      let axiosPayload = {};

      if (material || descripcion || (articulo || !!edit)) {
        if (cantidad) {
          if (
            !(
              material &&
              !(
                (this.gmao.modulos.modulo_stockage && almacen) ||
                !this.gmao.modulos.modulo_stockage
              )
            )) {
              // XXX: Refactorizar esto en cuanto antes. Está imposible.
              if (this.selectedMaterial.blob) {
                const formdata = new FormData();
                formdata.append('file', this.selectedMaterial.blob);
                formdata.append('id_usuario', this.gmao.user.id);


                axiosPayload = {
                  data: formdata,
                  headers: { headers: { 'Content-Type': 'multipart/form-data' } }
                };
                const image = await this.$axios
                  .post(`/v2/users/actions.php?call=setImputacionMaterialImagen&token=${this.gmao.user.token}&v2=1`,
                    axiosPayload.data,
                    axiosPayload.headers)
                  .then(({ data }) => {
                    return data.imagen;
                  })
                  .catch(async (e) => {
                    if (e.code == 'ERR_NETWORK' && this.offline.status) {
                      axiosPayload = {
                        ...axiosPayload,
                        callFunction: 'setImputacionMaterialImagen',
                        props: {
                          layer: 3,
                          group: 'images',
                          parentTempId: this.selectedMaterial?.id || -1, // *NEW — REVIEW: No se ni que pasa aqui...
                          dependsOn: ['lineas'],
                          dependencies: [{keyInDependent: 'id_material', keyInParent: 'id'}] // *NEW — REVIEW: Esto hace falta?
                        },
                      }
                      this.offlineModule.syncController.addChange(axiosPayload);
                      queryImage = `
                        INSERT OR REPLACE INTO material_linea_imagenes (id, syncObject, id_usuario, base64Src, created_at, updated_at, last_modified) VALUES
                        (
                          ${this.$moment().unix() + 1},
                          '${JSON.stringify(axiosPayload)}',
                          ${this.gmao.user.id},
                          '${this.selectedMaterial.image}',
                          '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                          '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                          '${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
                        );
                        SELECT last_insert_rowid();
                      `;
                    }
                  });

                if (navigator.onLine) {
                  this.selectedMaterial.id_imagen = image.id;
                }
              }

              axiosPayload = {
                data: {
                  id_parte: this.workorder.id,
                  material: { ...this.selectedMaterial }, // OPTIMIZE: Estamos mandando todos los datos
                  edit,
                },
                headers: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
              }
              this.$axios
                .post(
                  `/v2/users/actions.php?call=setImputacionMaterial&token=${this.gmao.user.token}&v2=1`,
                  { data: { ...axiosPayload.data } },
                  axiosPayload.headers
                )
                .then(() => {
                  this.addMaterial = false;
                  if (this.editMaterial) {
                    this.editMaterial = false;
                  }
                  this.getWorkorder(this.workorder.id);
                })
                .catch(async (e) => {
                  if (e.code == 'ERR_NETWORK' && this.offline.status) {
                    try {
                      axiosPayload = {
                        ...axiosPayload,
                        callFunction: 'setImputacionMaterial',
                        props: {
                          layer: 2,
                          group: 'lineas',
                          parentTempId: this.workorder?.id || -1,
                          dependsOn: ['partes'],
                          dependencies: [{keyInDependent: 'id_parte', keyInParent: 'id'}]
                        },
                      }
                      this.offlineModule.syncController.addChange(axiosPayload);
                      const query = `
                        INSERT OR REPLACE INTO parte_materiales
                          (id, syncObject, id_parte, id_tecnico, id_articulo, cantidad, pcp, pvp, descuento_por, observaciones, id_maquina, iva, id_imagen, id_partidap, created_at, updated_at, last_modified)
                        VALUES
                        (
                          ${!edit ? this.$moment().unix() : this.selectedMaterial?.id},
                          '${JSON.stringify(axiosPayload)}',
                          ${this.workorder?.id || 0},
                          ${this.gmao.user?.id || 0},
                          ${!edit ? (material?.id || null) : (material.id || null)},
                          ${+cantidad || 0},
                          ${
                            !edit
                              ? material?.pcp?.toFixed(2) || 0.0
                              : parseFloat(material?.pcp).toFixed(2) || 0.0
                          },
                          ${
                            !edit
                              ? material?.pvp?.toFixed(2) || 0.0
                              : parseFloat(material?.pvp).toFixed(2) || 0.0
                          },
                          0,
                          '${
                            !edit
                              ? this.selectedMaterial?.descripcion || ''
                              : descripcion?.observaciones || ''
                          }',
                          ${
                            !edit
                              ? this.selectedMaterial?.activo || 0
                              : this.selectedMaterial?.id_maquina || 0
                          },
                          ${this.gmao.comportamientos?.tipo_iva_default || 0},
                          '',
                          ${this.selectedMaterial?.id_partidap || 0},
                          '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                          '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                          '${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
                        );
                        SELECT last_insert_rowid();
                      `;

                      try {
                        const setWorkorderMaterialsPromise = new Promise((resolve) => {
                            resolve(
                            this.offlineActionsController.SQLiteQuery(
                              'setWorkorderMaterials_workorder',
                              'parte_materiales',
                              [query, queryImage],
                              e.config,
                              this.offlineModule?.db
                            )
                          );
                        });
                        setWorkorderMaterialsPromise.then(() => {
                          // FIXME: *Revisar
                          setTimeout(() => {
                            // sqlite.closeConnection('gmaoTecnicos').then(() => {
                              this.addMaterial = false;
                              if (this.editMaterial) {
                                this.editMaterial = false;
                              }
                              this.getWorkorder(this.$route.params.id);
                            // });
                          }, 500);
                        });
                      } catch (err) {
                        console.error(err);
                        // Error Controller
                        // this.$Sentry?.captureException(err);
                      }
                      // FIXME: *** Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
                    } catch (err) {
                      console.error(err);
                      // Error Controller
                      // this.$Sentry?.captureException(err);
                    }
                  }
                });
            } else {
              this.openToastOptions(
                this.$t('error-al-guardar-articulo'),
                this.$t('selecciona-un-almacen-para-el-articulo')
              );
            }
          } else {
            this.openToastOptions(
              this.$t('error-al-guardar-articulo'),
              this.$t('anade-una-cantidad-al-articulo')
            );
          }
        } else {
          this.openToastOptions(
            this.$t('error-al-guardar-articulo'),
            this.$t('selecciona-un-articulo-o-agrega-una-descripcion')
          );
        }
      },
    // ==> OPTIMIZE: /BLOQUE_MATERIALES

    imageViewer(photo, incidence = false) {
      const ext = photo.src?.match(/\.([^.]+)$/)[1];
      switch (ext) {
        case 'mp4':
        case 'webm': // Videos
          if (!isPlatform('android') && !isPlatform('ios')) {
            window.open(photo.src);
          } else {
            this.showVideoPlayer = true;
            this.playVideoObject.src = photo.src;
          }
          break;

        default: // Imagenes
          if (!incidence) this.imageModal = true;
          else this.imageModalIncidence = true;

          this.clickedImageId = photo.id;
          this.clickedIndex = this.workorder.imagenes?.findIndex((a) => a.id == photo.id);
          break;
      }
    },

    deleteMedia(imageID, modelo = 'ParteImagen') {
      if (!this.isUserOnline()) {return;}

      const mediaList = modelo == 'ParteImagen'
        ? this.workorder.imagenes :
          (modelo == 'ParteDibujo' ? this.workorder.dibujos : []);

      const fileIndex = mediaList?.findIndex((i) => i.id == imageID);
      let axiosPayload = {
        data: {
          id_imagen: imageID,
          id_parte: this.workorder.id,
          modelo
        },
        headers: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      };
      this.$axios
        .post(
          `/v2/users/actions.php?call=setDeleteWorkorderImage&token=${this.gmao.user.token}&v2=1`,
          { data: { ...axiosPayload.data } },
          axiosPayload.headers,
        )
        .then(() => {
          mediaList.splice(fileIndex, 1);
          this.getWorkorder(this.$route.params.id);
        })
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {

              axiosPayload = {
                ...axiosPayload,
                callFunction: 'setDeleteWorkorderImage',
                props: {
                  layer: 3,
                  group: 'images',
                  parentTempId: this.axiosPayload.data.id_imagen || -1,
                  dependsOn: ['images'],
                  dependencies: [{keyInDependent: 'id_imagen', keyInParent: 'id'}]
                }
              };
              this.offlineModule.syncController.addChange(axiosPayload);

              const query = `
                UPDATE parte_imagenes
                SET
                  deleted_at='${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                  last_modified='${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                  syncObject='${JSON.stringify(axiosPayload)}'
                WHERE parte_imagenes.id_parte=${
                  this.workorder?.id || 0
                } AND parte_imagenes.id=${imageID || 0}
              `;
              const deleteImagePromise = new Promise((resolve) => {
                resolve(
                  this.offlineActionsController.SQLiteQuery(
                    'deleteImage_workorder',
                    'parte_imagenes',
                    query,
                    e.config,
                    this.offlineModule?.db
                  )
                );
              });
              deleteImagePromise.then(() => {
                // FIXME: *Revisar
                setTimeout(() => {
                  // sqlite.closeConnection('gmaoTecnicos').then(() => {
                    this.getWorkorder(this.$route.params.id);
                    this.workorder.imagenes.splice(fileIndex, 1);
                  // });
                }, 500);
              });
            } catch (err) {
              // Error Controller
              // this.$Sentry.captureException(err);
            }
          }
        });
    },

    assignWorkorder() {
      if (this.gmao?.user?.equipos_responsable?.length) {
        // this.selectedTeam = this.workorder.id_equipo;

        this.selectedTeam = this.gmao?.user?.equipos_responsable.map((e) => e.id);
        this.setOperario = true;
        return;
      }
      // *NEW — XXX: [POST - SET ASSIGNAR PARTE??] NOT IN OFFLINE
      return this.$axios
        .post(
          `/v2/users/actions.php?call=setAssignWorkorder&token=${this.gmao.user.token}&v2=1`,
          {
            data: {
              id_parte: this.workorder.id,
              id_tecnico: this.gmao.user.id,
            }
          },
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          }
        )
        .then(async () => {
          await this.getWorkorder(this.workorder.id);
          this.workorder.unassigned = false;
          this.workorder.not_editable = false;
          this.askForTimer();
        }).catch((error) => {
          // TODO: Offline
          console.log(error);
        });
    },

    async checkForPendingTasks() {
      return await this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getCheckForPendingTiming',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            v2: 1,
          },
        })
        .then(({ data }) => {
          if (data && 'status' in data) {
            if (data?.status === 1) {
              if ('task' in data && data?.task?.id) {
                // NOTE: Tiene tiempo pendiente de otra OT y asignamos
                this.workOrders.timeRegister = data.task;
              }
            } else if (data?.status === 0) {
              console.log(0)
              // NOTE: No hacemos nada. Porque entendemos que no tiene pendientes
            } else if (data?.status === -1) {
              console.log(-1)
              // NOTE: No hacemos nada. Porque entendemos que ha ocurrido un error
            }
          }
          
        }).catch((error) => {
          // TODO: Offline
          console.log(error);
        });
    },

    async toggleTimer() {
      let geo = null;
      if (this.workorder?.not_editable) return;

      if(+this.gmao.comportamientos?.parte_geolocalizacion) {
        geo = await this.$getGeolocation(false);
      }

      /** NOTE: Comprobación extra si existen tiempos abiertos pasados o futuros */
      await this.checkForPendingTasks();

      /** Comprobacion de tiemposParte existente */
      if (this.workOrders.timeRegister?.workorder && this.workOrders.timeRegister?.workorder !== this.workorder?.id)
      {
        return await this.alertPopup(
          this.$tc(
            'el-parte-this-workorders-timeregister-workorder-esta-en-ejecucion-quieres-finalizarlo-y-comenzar-esta-tarea',
            [this.workOrders.timeRegister?.workorder]
          ),
          async () => {
            this.workOrders.timeRegister.to = this.$moment().format();
            const savePreviousTime = this.storeTimes(this.workOrders.timeRegister);
            savePreviousTime.then(() => {
              this.workOrders.timeRegister = {};
              this.time = { hours: 0, minutes: 0, seconds: 0 };

              this.toggleTimer();

            }).catch(() => {
              this.$openToastObject(this.$t('Ha ocurrido un error'), this.$t('No se han podido guardar las horas del parte.'), 'danger');
            });

          },
          { cancel: 'cancelar', confirm: 'finalizar' }
        );
      }

      /** CREACION OBJETO TimeRegister */
      // TODO: Mirar tipos de hora "setHora"
      if ((this.hourTypes?.length || this.isTecnicosAdicionales) && !this.timeIsRunning) {
        this.setHora = true;
        return;
      } else {
        const hora = this.workorder?.horas?.find((h) => {
          return this.$moment(h.created_at).isSame(new Date(), 'day');
        });

        if (this.workOrders.timeRegister?.from && this.workOrders.timeRegister?.workorder === this.workorder?.id) {
          this.workOrders.timeRegister.to = this.$moment().format();
        }

        if (+this.gmao.comportamientos.fotoinicio_app_tecnico && !hora) {
          const photo = await this.$Camera.getPhoto({
            resultType: this.$CameraResultType.Base64,
            source: this.$CameraSource.Camera,
            promptLabelCancel: this.$t('Cancelar'),
            promptLabelPhoto: this.$t('gallery-photo'),
            promptLabelPicture: this.$t('camera-photo'),
            quality: 100,
            saveToGallery: true,
          });

          if (photo) {
            if (geo && geo.coords) {
              this.workOrders.timeRegister.from =  this.workOrders.timeRegister?.from || this.$moment(),
              this.workOrders.timeRegister.longitud = geo.coords.longitude;
              this.workOrders.timeRegister.latitud = geo.coords.latitude;
              this.workOrders.timeRegister.workorder = this.workorder.id;

            } else {
              this.workOrders.timeRegister.from =  this.workOrders.timeRegister?.from || this.$moment(),
              this.workOrders.timeRegister.workorder = this.workorder.id;
            }

            this.uploadPhoto(photo);
          }

        } else {
          if (geo && geo.coords) {
            this.workOrders.timeRegister.from =  this.workOrders.timeRegister?.from || this.$moment(),
            this.workOrders.timeRegister.longitud = geo.coords.longitude;
            this.workOrders.timeRegister.latitud = geo.coords.latitude;
            this.workOrders.timeRegister.workorder = this.workorder.id;
          } else {
            this.workOrders.timeRegister.from =  this.workOrders.timeRegister?.from || this.$moment(),
            this.workOrders.timeRegister.workorder = this.workorder.id;
          }
        }
        // if (this.workOrders.timeRegister?.workorder === this.workorder.id) {
        if(geo && geo.coords){
          this.workOrders.timeRegister.longitud_final = geo.coords.longitude;
          this.workOrders.timeRegister.latitud_final = geo.coords.latitude;
        }
        this.storeTimes(this.workOrders.timeRegister);
      }

    },

    // OPTIMIZE: BLOQUE_HORAS
    async storeTimesBlock(times) {
      times.hours = times.hours?.filter((t) => t.from && t.to).map((h, i) => {
        if (this.offline.status) h.tempId = this.$moment().unix() + i;
        return h;
      });

      const loading = await loadingController.create({ message: this.$t('Espera...') });
      await loading.present();

      let axiosPayload = {
        data: {...times},
        headers: { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      };

      this.$axios
        .post(
          `/v2/users/actions.php?call=setWorkorderTimesBlock&token=${this.gmao.user.token}&v2=1`,
          { data: { ...axiosPayload.data } },
          axiosPayload.headers
        )
        .then(() => {
          this.getWorkorder(this.workorder.id);
          this.addBloqueHoras = false;
        })
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {

              let query = '';
              let users = [];

              query = `
                INSERT INTO parte_horas
                (
                  id,
                  syncObject,
                  id_parte,
                  id_tecnico,
                  id_tipo_tiempo,
                  id_partidap,
                  desde_formatted,
                  hasta_formatted,
                  fecha,
                  fecha_fin,
                  desde,
                  hasta,
                  inicio,
                  fin,
                  observaciones,
                  created_at,
                  updated_at,
                  last_modified
                )
                VALUES
              `;

              if (typeof times.user == 'string' && times.user.split(',')?.length) {
                users = times?.user?.split(',');
              }

              const currentTimestamp = this.$moment().unix();

              axiosPayload = {
                ...axiosPayload,
                callFunction: 'setWorkorderTimesBlock',
                props: {
                  layer: 2,
                  group: 'hours',
                  tempId: currentTimestamp, // *NEW — REVIEW: Ver si lo crea con el unix
                  parentTempId: this.workorder?.id || -1,
                  dependsOn: ['partes', 'hours'],
                  dependencies: [{keyInDependent: 'workorder', keyInParent: 'id'}]
                }
              }
              this.offlineModule.syncController.addChange(axiosPayload);

              times.hours?.forEach((hour, index) => {
                /** NOTE: Añadir desde/hasta formateado */
                const desdeMoment = this.moment(hour.from, 'HH:mm');
                const hastaMoment = this.moment(hour.to, 'HH:mm');
                const offsetFormatted = desdeMoment.utcOffset() / 60;
                const offsetFormatted2 = hastaMoment.utcOffset() / 60;

                const formattedDate = desdeMoment.utcOffset(offsetFormatted).format('YYYY-MM-DDTHH:mm:ssZ');
                const formattedDate2 = hastaMoment.utcOffset(offsetFormatted2).format('YYYY-MM-DDTHH:mm:ssZ');

                if (users?.length > 1) {
                  users.forEach((user, index2) => {
                    query += `
                      (
                        ${
                          `${hour.tempId}-H${Math.floor(Math.random() * 100)}`
                        },
                        ${
                          index2 < 1 && index < 1
                            ? `'${JSON.stringify(axiosPayload)}',`
                            : `${null},`
                        }
                        ${this.workorder.id},
                        ${user},
                        ${hour.id_tipo || null},
                        ${hour.id_partidap || null},
                        '${formattedDate || ''}',
                        '${formattedDate2 || ''}',
                        '${this.moment(hour.from, 'HH:mm').format('YYYY-MM-DD') || ''}',
                        '${this.moment(hour.to, 'HH:mm').format('YYYY-MM-DD') || ''}',
                        '${this.moment(hour.from, 'HH:mm').format("YYYY-MM-DD HH:mm:ss") || ''}',
                        '${this.moment(hour.to, 'HH:mm').format("YYYY-MM-DD HH:mm:ss") || ''}',
                        '${hour.from || ''}',
                        '${hour.to || ''}',
                        '${times.observaciones || ''}',
                        '${
                          +this.gmao.comportamientos?.horas_con_fecha_de_parte
                            ? this.workorder?.fecha || ''
                            : this.$moment().format('YYYY-MM-DD H:mm:ss')
                        }',
                        '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                        '${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
                      )${
                        index == times?.hours?.length - 1 &&
                        index2 == users?.length - 1
                          ? ';'
                          : ','
                      }
                    `;
                  });
                } else {
                  query += `
                      (
                        ${hour.tempId},
                        ${index < 1 ? `'${JSON.stringify(axiosPayload)}',` : `${null},`}
                        ${this.workorder?.id || 0},
                        ${times?.user || 0},
                        ${hour?.id_tipo || null},
                        ${hour?.id_partidap || null},
                        '${formattedDate || ''}',
                        '${formattedDate2 || ''}',
                        '${this.moment(hour.from, 'HH:mm').format('YYYY-MM-DD') || ''}',
                        '${this.moment(hour.to, 'HH:mm').format('YYYY-MM-DD') || ''}',
                        '${this.moment(hour.from, 'HH:mm').format("YYYY-MM-DD HH:mm:ss") || ''}',
                        '${this.moment(hour.to, 'HH:mm').format("YYYY-MM-DD HH:mm:ss") || ''}',
                        '${hour.from || ''}',
                        '${hour.to || ''}',
                        '${times?.observaciones || ''}',
                        '${
                          +this.gmao.comportamientos?.horas_con_fecha_de_parte
                            ? this.workorder?.fecha || ''
                            : this.$moment().format('YYYY-MM-DD H:mm:ss')
                        }',
                        '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                        '${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
                      )${index == times?.hours?.length - 1 ? ';' : ','}
                    `;
                }
              });

              const storeTimesPromise = new Promise((resolve) => {
                resolve(
                  this.offlineActionsController.SQLiteQuery(
                    'storeTimes_workorder',
                    'parte_horas',
                    query,
                    e.config,
                    this.offlineModule?.db
                  )
                );
              });
              storeTimesPromise.then(() => {
                // FIXME: *Revisar
                setTimeout(() => {
                  // sqlite.closeConnection('gmaoTecnicos').then(() => {
                    this.addBloqueHoras = false;
                    this.getWorkorder(this.$route.params.id);
                  // });
                }, 500);
              });
              // FIXME: *** Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
            } catch (err) {
              console.error('Horas bloque ::', err);
              // this.$Sentry.withScope(function(scope) {

              //   scope.setTag("offline", "Workorder: SET WO Times");

              //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

              // });
              // Error Controller
              // this.$Sentry.captureException(err);
            }
          }
        }).finally(async () => await loading?.dismiss());
    },

    async storeTimes(timeRegister) {
      timeRegister.user || (timeRegister.user = this.gmao.user.id);

      timeRegister.id_tiempo = this.newHora?.id || null;
      if (!timeRegister.from) {
        this.openToastOptions(
          this.$t('error-al-guardar-horas'),
          this.$t('selecciona-las-horas')
        );
      } else {

        const loading = await loadingController.create({ message: this.$t('Espera...') });
        await loading.present();

        let axiosPayload = {
          data: { ...timeRegister },
          headers: { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
        };
        return await this.$axios
          .post(
            `/v2/users/actions.php?call=setWorkorderTimes&token=${this.gmao.user.token}&v2=1`,
            { data: axiosPayload.data },
            axiosPayload.headers
          )
          .then(async () => {
            await this.getWorkorder(this.workorder.id);
            this.setTimeRegister();
            this.addHoras = false;
          })
          .catch(async (e) => {
            if (e.code == 'ERR_NETWORK' && this.offline.status) {
              try {

                let query = '';

                // NOTE: Revisar esto. Es porque al crear en offline no tenemos id del registro en este 
                // punto, y lo necesitamos para evitar duplicado al editar tiempos en la cola de syn

                axiosPayload.data.id = timeRegister?.id || this.$moment().unix();

                axiosPayload = {
                  ...axiosPayload,
                  callFunction: 'setWorkorderTimes',
                  props: {
                    layer: 2,
                    group: 'hours',
                    tempId: axiosPayload.data?.id || -1, // *NEW — REVIEW: Ver si lo crea con el unix
                    parentTempId: this.workorder?.id || -1,
                    dependsOn: ['partes', 'hours'],
                    dependencies: [{keyInDependent: 'workorder', keyInParent: 'id'}]
                  }
                }
                this.offlineModule.syncController.addChange(axiosPayload);

                /** NOTE: Añadir desde/hasta formateado */
                const desdeMoment = this.moment(timeRegister.from);
                const hastaMoment = this.moment(timeRegister.to);
                const offsetFormatted = desdeMoment.utcOffset() / 60;
                const offsetFormatted2 = hastaMoment.utcOffset() / 60;

                const formattedDate = desdeMoment.utcOffset(offsetFormatted).format('YYYY-MM-DDTHH:mm:ssZ');
                const formattedDate2 = hastaMoment.utcOffset(offsetFormatted2).format('YYYY-MM-DDTHH:mm:ssZ');

                if (
                  typeof timeRegister.user == 'string' &&
                  Array.isArray(timeRegister.user.split(','))
                ) {
                  query = `
                    INSERT INTO parte_horas
                    (
                      id,
                      syncObject,
                      id_parte,
                      id_tecnico,
                      id_tipo_tiempo,
                      id_partidap,
                      desde_formatted,
                      hasta_formatted,
                      fecha,
                      fecha_fin,
                      desde,
                      hasta,
                      inicio,
                      fin,
                      observaciones,
                      created_at,
                      updated_at,
                      last_modified
                    )

                    VALUES
                  `;
                  timeRegister.user.split(',').forEach((user, index) => {
                    query += `
                      (${axiosPayload.data?.id + index},
                      ${index < 1 ? `'${JSON.stringify(axiosPayload)}', ` : `${null},`}
                      ${this.workorder?.id},
                      ${user},
                      ${timeRegister.id_tipo_tiempo || null},
                      ${timeRegister.id_partidap || null},
                      '${formattedDate || ''}',
                      '${formattedDate2 || ''}',
                      '${this.moment(timeRegister.from).format('YYYY-MM-DD') || ''}',
                      '${this.moment(timeRegister.to).format('YYYY-MM-DD') || ''}',
                      '${this.moment(timeRegister.from).format("YYYY-MM-DD HH:mm:ss") || ''}',
                      '${this.moment(timeRegister.to).format("YYYY-MM-DD HH:mm:ss") || ''}',
                      '${this.moment(timeRegister.from).format('HH:mm') || ''}',
                      '${this.moment(timeRegister.to).format('HH:mm') || ''}',
                      '${timeRegister.observaciones || ''}',
                      '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                      '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                      '${this.$moment().format('YYYY-MM-DD H:mm:ss')}')
                      ${index == timeRegister.user.split(',')?.length - 1 ? ';' : ','
                    }
                    `;
                  });
                } else {
                  query = `
                    INSERT OR REPLACE INTO parte_horas
                    (
                      id,
                      syncObject,
                      id_parte,
                      id_tecnico,
                      id_tipo_tiempo,
                      id_partidap,
                      desde_formatted,
                      ${timeRegister.to ? 'hasta_formatted,' : ''}
                      fecha,
                      ${timeRegister.to ? 'fecha_fin,' : ''}
                      desde,
                      ${timeRegister.to ? 'hasta,' : ''}
                      inicio,
                      ${timeRegister.to ? 'fin,' : ''}
                      observaciones,
                      created_at,
                      updated_at,
                      last_modified
                    )
                    VALUES
                    (${axiosPayload.data?.id},
                      '${JSON.stringify(axiosPayload)}',
                      ${this.workorder?.id},
                      ${timeRegister.user},
                      ${timeRegister.id_tipo_tiempo || null},
                      ${timeRegister.id_partidap || null},
                      '${formattedDate || ''}',
                      ${timeRegister.to ? `'${formattedDate2 || ''}',` : '' }
                      '${this.moment(timeRegister.from).format('YYYY-MM-DD') || ''}',
                      ${timeRegister.to ? `'${this.moment(timeRegister.to).format('YYYY-MM-DD') || ''}',` : '' }
                      '${this.moment(timeRegister.from).format("YYYY-MM-DD HH:mm:ss") || ''}',
                      ${timeRegister.to ? `'${this.moment(timeRegister.to).format("YYYY-MM-DD HH:mm:ss") || ''}',` : '' }
                      '${this.moment(timeRegister.from).format('HH:mm') || ''}',
                      ${timeRegister.to ? `'${this.moment(timeRegister.to).format('HH:mm') || ''}',` : '' }
                      '${timeRegister.observaciones || ''}',
                      '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                      '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                      '${this.$moment().format('YYYY-MM-DD H:mm:ss')}')
                  `;
                }
                const storeTimesPromise = new Promise((resolve) => {
                  resolve(
                    this.offlineActionsController.SQLiteQuery(
                      'storeTimes_workorder',
                      'parte_horas',
                      query,
                      e.config,
                      this.offlineModule?.db
                    )
                  );
                });
                storeTimesPromise.then(() => {
                  // FIXME: *Revisar
                  setTimeout(async () => {
                    this.addHoras = false;
                    // sqlite.closeConnection('gmaoTecnicos').then(() => {
                      if (this.workOrders.timeRegister.to) this.workOrders.timeRegister = {};
                      await loading?.dismiss();
                      this.getWorkorder(this.$route.params?.id);
                    // });
                  }, 500);
                });
                // FIXME: *** Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
              } catch (err) {
                console.log(err);
                this.ErrorController.captureError(new Error(err), 'No se ha podido imputar el tiempo.', true)
                // this.$Sentry.withScope(function(scope) {

                //   scope.setTag("offline", "Workorder: SET WO Times");

                //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

                // });
                // Error Controller
                // this.$Sentry.captureException(err);
              }
            }
          }).finally(async () => await loading?.dismiss());
      }
    },

    setWorkorderTimes(hora) {
      this.newHora = hora;
      this.newHora.from = this.$moment(`${this.newHora.date} ${this.newHora.from}`).format();
      this.newHora.to = this.$moment(`${this.newHora.date_fin} ${this.newHora.to}`).format();

      this.newHora.user =
      this.newHora?.tecnico?.id ||
      this.workorder.tecnicos_adicionales
          .concat([this.workorder?.tecnico])
          .map((t) => t?.id).join`,`;
      this.newHora.workorder = this.workorder?.id;
      this.storeTimes(this.newHora);
      // this.addHoras = false;
    },

    setWorkorderTimesBlock() {
      this.newHoraBlock.user =
        this.newHoraBlock?.tecnico?.id ||
        (this.workorder.tecnicos_adicionales
          || []).concat([this.workorder?.tecnico])
          .map((t) => t.id).join`,`;

      this.newHoraBlock.workorder = this.workorder.id;

      this.storeTimesBlock(this.newHoraBlock);
    },
    // OPTIMIZE: /BLOQUE_HORAS

    async setStartTiempos() {
      let geo = null;
      if (+this.gmao.comportamientos?.parte_geolocalizacion) {
          geo = await this.$getGeolocation(false);
      }

      const tecnicos =
        this.newHora?.tecnico?.id ||
        (this.workorder.tecnicos_adicionales
          || []).concat([this.workorder?.tecnico])
          .map((t) => t?.id).join`,`;

      const hora = (this.workorder?.horas || []).find((h) => {
        return this.$moment(h.created_at).isSame(new Date(), 'day');
      });
      // TODO: No hay tecnico...
      // console.log(this.newHora, tecnicos)
      // console.log(this.workorder, hora)

      if (+this.gmao.comportamientos.fotoinicio_app_tecnico && !hora) {
        const photo = await this.$Camera.getPhoto({
          resultType: this.$CameraResultType.Base64,
          source: this.$CameraSource.Camera,
          promptLabelCancel: this.$t('Cancelar'),
          promptLabelPhoto: this.$t('gallery-photo'),
          promptLabelPicture: this.$t('camera-photo'),
          quality: 100,
          saveToGallery: true,
        });

        if (photo) {
          if(geo && geo.coords) {
            this.workOrders.timeRegister.from =  this.workOrders.timeRegister?.from || this.$moment().format(),
            this.workOrders.timeRegister.longitud = geo.coords.longitude;
            this.workOrders.timeRegister.latitud = geo.coords.latitude;
            this.workOrders.timeRegister.workorder = this.workorder.id;
            this.workOrders.timeRegister.user = tecnicos;
            this.workOrders.timeRegister.hour_type = this.newHora.id_tipo_tiempo;
          } else {
            this.workOrders.timeRegister.from =  this.workOrders.timeRegister?.from || this.$moment().format(),
            this.workOrders.timeRegister.workorder = this.workorder.id;
            this.workOrders.timeRegister.user = tecnicos;
            this.workOrders.timeRegister.hour_type = this.newHora.id_tipo_tiempo;
          }


          this.uploadPhoto(photo);
        }

        this.setHora = false;
      } else {
        this.setHora = false;
        if(geo && geo.coords) {
          this.workOrders.timeRegister.from =  this.workOrders.timeRegister?.from || this.$moment().format(),
          this.workOrders.timeRegister.longitud = geo.coords.longitude;
          this.workOrders.timeRegister.latitud = geo.coords.latitude;
          this.workOrders.timeRegister.workorder = this.workorder.id;
          this.workOrders.timeRegister.user = tecnicos;
          this.workOrders.timeRegister.hour_type = this.newHora.id_tipo_tiempo;
        } else {
          this.workOrders.timeRegister.from =  this.workOrders.timeRegister?.from || this.$moment().format(),
          this.workOrders.timeRegister.workorder = this.workorder.id;
          this.workOrders.timeRegister.user = tecnicos;
          this.workOrders.timeRegister.hour_type = this.newHora.id_tipo_tiempo;
        }

      }

      if (this.offline.status) {
        this.workOrders.timeRegister.id_tipo_tiempo = this.newHora.id_tipo_tiempo;
      }
      
      this.storeTimes(this.workOrders.timeRegister);
    },

    editTimes(hora) {
      const nuevaHora = {
        id: hora?.id,
        hora,
        tecnico: hora.tecnico,
        from: this.moment(hora.inicio, 'HH:mm:ss').format('HH:mm'),
        to: this.moment(hora.fin, 'HH:mm:ss').format('HH:mm'),
        date: hora.fecha,
        date_fin: hora.fecha_fin,
        id_tipo_tiempo: hora.id_tipo_tiempo,
        observaciones: hora.observaciones,
        partida: hora.partida,
        id_partidap: hora.id_partidap,
      };

      this.newHora = nuevaHora;

      this.addHoras = true;
    },

    deleteTimes(hora) {
      if (this.offline?.status) {
        this.$openToastObject(
          this.$t('Ha ocurrido un error'),
          this.$t('generic-offline-error-message')
        );
        return;
      }

      let axiosPayload = {
        data: {hora: hora.id},
        headers: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      };

      this.$axios
        .post(
          `/v2/users/actions.php?call=setDeleteTimes&token=${this.gmao.user.token}&v2=1`,
          axiosPayload.data,
          axiosPayload.headers
        )
        .then(() => {
          const index = this.workorder.horas.findIndex((h) => h.id == hora.id);
          this.workorder.horas.splice(index, 1);

          this.addHoras = false;
        })
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {

              axiosPayload = {
                ...axiosPayload,
                callFunction: 'setDeleteTimes',
                props: {
                  layer: 3,
                  group: 'hours',
                  parentTempId: hora.id || -1,
                  dependsOn: ['hours'],
                  dependencies: [{keyInDependent: 'hora', keyInParent: 'id'}]
                }
              }
              this.offlineModule.syncController.addChange(axiosPayload);

              let query = '';
              query = `
                UPDATE parte_horas
                SET
                  deleted_at='${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                  last_modified='${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                  syncObject='${JSON.stringify(axiosPayload)}'
                WHERE id=${hora.id || 0}
              `;

              const deleteTimesPromise = new Promise((resolve) => {
                resolve(
                  this.offlineActionsController.SQLiteQuery(
                    'deleteTimes_workorder',
                    'parte_horas',
                    query,
                    e.config,
                    this.offlineModule?.db
                  )
                );
              });
              deleteTimesPromise.then(() => {
                // FIXME: *Revisar
                setTimeout(() => {
                  // sqlite.closeConnection('gmaoTecnicos').then(() => {
                    const index = this.workorder.horas.findIndex((h) => h.id == hora.id);
                    this.workorder.horas.splice(index, 1);
                    this.addHoras = false;
                    this.getWorkorder(this.$route.params.id);
                  // });
                }, 500);
              });
              // FIXME: *** Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
            } catch (err) {
              // this.$Sentry.withScope(function(scope) {

              //   scope.setTag("offline", "Workorder: DEL Times");

              //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

              // });
              // Error Controller
              // this.$Sentry.captureException(err);
            }
          }
        });
    },

    elapsedTime() {
      if (this.workOrders.timeRegister) {
        const time = this.$moment().diff(
          this.$moment(this.workOrders.timeRegister.from),
          'seconds'
        );

        this.time.hours = Math.floor(time / 3600);
        this.time.minutes = String(Math.floor((time % 3600) / 60)).padStart(2, '0');
        this.time.seconds = String((time % 3600) % 60).padStart(2, '0');
      }
    },

    // ==> OPTIMIZE: BLOQUE_HORAS
      openHorasModal() {
        if (!+this.gmao.comportamientos.horas_bloque) {
          this.addHoras = true;
        } else {
          this.addBloqueHoras = true;
        }
      },
    // ==> OPTIMIZE: /BLOQUE_HORAS

    createdWorkorder(data) {
      this.settingPartes = false;
      if (navigator.onLine) {
        this.getPartes().then(() => {
          if (data) {
            this.$router.replace({
              name: 'workorder',
              params: { id: data?.parte?.id, set_activos: true },
            });
          }
        });
      } else {
        // FIXME: *** Error connection closed
        this.$router.replace({
          name: 'workorder',
          params: {
            id: data?.parte?.id,
            set_activos: true,
          },
        });
      }
    },

    getPartes() {
      this.loading = true;
      // *NEW — XXX: [GET - PARTES] NOT IN OFFLINE
      return this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getPartes',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            order_column: 'created_at',
            order_cardinality: 'DESC',
            page: 1,
            v2: 1,
          },
        })
        .then(({ data }) => {
          // this.workOrders.workorders = [];
          this.workOrders.workorders.push(data.data);
        }).catch((error) => {
          // TODO: Offline
          console.log(error);
        });
    },

    // ==> OPTIMIZE: BLOQUE_GAMAS
      selectEspecialidad(value, field, relationship) {
        this.workorder[field] = value.id;
        this.workorder[relationship] = value;

        let axiosPayload = {
          data: {
            workorder: this.workorder.id,
            values: field == 'id_gama' ? [value.id, null] : [value.id],
            fields: [field],
          },
          headers: { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
        };

        this.$axios
          .post(
            `/v2/users/actions.php?call=setSelectEspecialidad&token=${this.gmao.user.token}&v2=1`,
            { data: { ...axiosPayload.data } },
            axiosPayload.headers
          )
          .catch(async (e) => {
            if (e.code == 'ERR_NETWORK' && this.offline.status) {
              try {
                let query;

                axiosPayload = {
                  ...axiosPayload,
                  callFunction: 'setSelectEspecialidad',
                  props: {
                    layer: 2,
                    group: 'gama',
                    parentTempId: this.workorder?.id || -1,
                    dependsOn: ['partes'],
                    dependencies: [{keyInDependent: 'workorder', keyInParent: 'id'}]
                  }
                }
                this.offlineModule.syncController.addChange(axiosPayload);

                if (field == 'id_gama') {
                  query = `
                  UPDATE partes
                  SET id_gama=${value.id}, last_modified='${this.$moment().format(
                    'YYYY-MM-DD H:mm:ss'
                  )}'
                  WHERE partes.id=${this.workorder?.id || 0}
                `;
                }
                if (field == 'id_subgama') {
                  query = `
                  UPDATE partes
                  SET id_subgama=${value.id}, id_gama=${
                    value.id_gama
                  }, last_modified='${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
                  WHERE partes.id=${this.workorder?.id || 0}
                `;
                }
                const selectEspecialidadPromise = new Promise((resolve) => {
                  resolve(
                    this.offlineActionsController.SQLiteQuery(
                      'selectEspecialidad_workorder',
                      'gamas',
                      query,
                      e.config,
                      this.offlineModule?.db
                    )
                  );
                });
                selectEspecialidadPromise.then(() => {
                  // FIXME: *Revisar
                  setTimeout(() => {
                    this.workorder = this.offline.workorder;
                    // sqlite.closeConnection('gmaoTecnicos');
                  }, 500);
                });
              } catch (err) {
                // this.$Sentry.withScope(function(scope) {

                //   scope.setTag("offline", "Workorder: GET Especialidad");

                //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

                // });
                // Error Controller
                // this.$Sentry.captureException(err);
              }
            }
          });

        if (this.setEspecialidad) {
          this.workorder.id_subgama = false;
          this.workorder.sub_gama = null;

          this.setEspecialidad = false;
          if (+value.especialidades_count) this.setSubespecialidad = true;
        } else {
          this.setSubespecialidad = false;
        }
      },
    // <== OPTIMIZE: /BLOQUE_GAMAS

    // ==> OPTIMIZE: BLOQUE_OPERARIO
      selectOperario(value) {
        this.alertPopup(
          this.$t('¿Estas seguro? Dejarás de tener accesible la OT'),
          () => {
            if (value) {
              this.workorder.id_tecnico = value.id;
              this.workorder.tecnico = value;
            }

            let axiosPayload = {
              data: {
                workorder: this.workorder.id,
                value: !value ? value : value.id,
              },
              headers: { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
            };

            this.$axios
              .post(
                `/v2/users/actions.php?call=setSelectOperario&token=${this.gmao.user.token}&v2=1`,
                { data: { ...axiosPayload.data } },
                axiosPayload.headers
              )
              .then(() => {
                const index = this.workOrders.workorders[0].findIndex(
                  (w) => w.id == this.workorder.id
                );
                this.workOrders.workorders[0].splice(index, 1);
                this.$router.go(-1);
              })
              .catch(async (e) => {
                if (e.code == 'ERR_NETWORK' && this.offline.status) {
                  try {

                    let query;

                    axiosPayload = {
                      ...axiosPayload,
                      callFunction: 'setSelectOperario',
                      props: {
                        layer: 2,
                        group: 'tecnico',
                        parentTempId: this.workorder?.id || -1,
                        dependsOn: ['partes'],
                        dependencies: [{keyInDependent: 'workorder', keyInParent: 'id'}]
                      }
                    }
                    this.offlineModule.syncController.addChange(axiosPayload);

                    if (!value) {
                      query = `
                          INSERT INTO parte_estados_historial (id, syncObject, id_parte, id_estado, id_usuario, last_modified) VALUES (${this.$moment().unix()}, '${JSON.stringify(
                        axiosPayload
                      )}', ${this.workorder.id}, 1, ${
                        this.gmao.user.id
                      }, ${this.$moment().format('YYYY-MM-DD H:mm:ss')});
                          UPDATE partes
                          SET id_estado_actual=1, last_modified='${this.$moment().format(
                            'YYYY-MM-DD H:mm:ss'
                          )}'
                          WHERE partes.id=${this.workorder.id}
                        `;
                    } else {
                      query = `
                          UPDATE partes
                          SET id_tecnico=${
                            value.id
                          }, last_modified='${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
                          WHERE partes.id=${this.workorder.id}
                        `;
                    }

                    const setOperarioPromise = new Promise((resolve) => {
                      resolve(
                        this.offlineActionsController.SQLiteQuery(
                          'selectOperario_workorder',
                          'partes',
                          query,
                          e.config,
                          this.offlineModule?.db
                        )
                      );
                    });
                    setOperarioPromise.then(() => {
                      // this.workorders = [];
                      setTimeout(() => {
                        if (this.gmao.user?.id != value?.id || !value) {
                          const index = this.offline.workorders.findIndex(
                            (w) => w.id == this.workorder.id
                          );
                          this.offline.workorders.splice(index, 1);
                          this.$router.go(-1);
                        }
                        // FIXME: *Revisar
                        // sqlite.closeConnection('gmaoTecnicos');
                      }, 500);
                    });
                  } catch (err) {
                    // this.$Sentry.withScope(function(scope) {

                    //   scope.setTag("offline", "Workorder: SET Operario WO's");

                    //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

                    // });
                    // Error Controller
                    // this.$Sentry.captureException(err);
                  }
                }
              });

            this.setOperario = false;
          },
          { cancel: 'Cancelar', confirm: 'Aceptar' }
        );
      },
    // <== OPTIMIZE: /BLOQUE_OPERARIO

    // ==> OPTIMIZE: BLOQUE_OPERARIO_ADICIONAL
      selectOperarioAdicional() {
        const tecnicos = this.tecnicos?.filter((m) => m?.operario == true);

        this.workorder?.tecnicos?.push(...tecnicos);
        if (!navigator.onLine && !this.workorder?.tecnicos_adicionales) {
          this.workorder.tecnicos_adicionales = [];
        }
        this.workorder.tecnicos_adicionales?.push(...tecnicos);

        let axiosPayload = {
          data: {
            workorder: this.workorder.id,
            value: this.workorder?.tecnicos_adicionales?.map((t) => t.id),
          },
          headers: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        };
        this.$axios
          .post(
            `/v2/users/actions.php?call=setSelectOperarioAdicional&token=${this.gmao.user.token}&v2=1`,
            { data: { ...axiosPayload.data } },
            axiosPayload.headers
          )
          .catch(async (e) => {
            if (e.code == 'ERR_NETWORK' && this.offline.status) {
              try {
                let query = ``;

                axiosPayload = {
                  ...axiosPayload,
                  callFunction: 'setSelectOperarioAdicional',
                  props: {
                    layer: 2,
                    group: 'tecnico',
                    parentTempId: this.workorder?.id || -1,
                    dependsOn: ['partes'],
                    dependencies: [{keyInDependent: 'workorder', keyInParent: 'id'}]
                  }
                }
                this.offlineModule.syncController.addChange(axiosPayload);

                this.workorder?.tecnicos_adicionales.forEach((t, idx) => {
                  query += `
                  INSERT OR REPLACE INTO parte_tecnicos (id, syncObject, id_parte, id_tecnico, created_at, last_modified)
                  VALUES (${this.$moment().unix() + idx}, '${JSON.stringify(
                    axiosPayload
                  )}', ${this.workorder?.id}, ${t.id}, '${this.$moment().format(
                    'YYYY-MM-DD H:mm:ss'
                  )}', '${this.$moment().format('YYYY-MM-DD H:mm:ss')}');
                `;
                });
                const setOperarioAdicionalPromise = new Promise((resolve) => {
                  resolve(
                    this.offlineActionsController.SQLiteQuery(
                      'selectOperarioAdicional_workorder',
                      'parte_tecnicos',
                      query,
                      e.config,
                      this.offlineModule?.db
                    )
                  );
                });
                setOperarioAdicionalPromise.then(() => {
                  // this.workorders = [];
                  setTimeout(() => {
                    // this.workorders = [this.offline.workorders];
                    // sqlite.closeConnection('gmaoTecnicos');
                  }, 500);
                });
              } catch (err) {
                // this.$Sentry.withScope(function(scope) {

                //   scope.setTag("offline", "Workorder: SET Operario Adic WO's");

                //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

                // });
                // Error Controller
                // this.$Sentry.captureException(err);
              }
            }
          });

        this.setOperarioAdicional = false;
      },

      deleteOperarioAdicional(tec) {
        this.alertPopup(
          this.$t('seguro-que-quieres-quitar-este-operario-adicional', [
            tec?.nombre,
            this.workorder.id,
          ]),
          () => {
            const index = this.workorder?.tecnicos_adicionales.findIndex(
              (m) => m.id == tec.id
            );
            this.workorder?.tecnicos_adicionales.splice(index, 1);

            const indexT = (this.workorder?.tecnicos || []).findIndex((m) => m.id == tec.id);
            this.workorder?.tecnicos.splice(indexT, 1);

            let axiosPayload = {
              data: {
                workorder: this.workorder.id,
                    value: this.workorder?.tecnicos_adicionales.map((t) => t.id),
              },
              headers: { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
            };
            this.$axios
              .post(
                `/v2/users/actions.php?call=setSelectOperarioAdicional&token=${this.gmao.user.token}&v2=1`,
                { data: { ...axiosPayload.data } },
                axiosPayload.headers
              )
              .then(({ data }) => {
                console.log('Success: ', data);
              })
              .catch(async (e) => {
                if (e.code == 'ERR_NETWORK' && this.offline.status) {
                  try {

                    axiosPayload = {
                      ...axiosPayload,
                      callFunction: 'setSelectOperarioAdicional',
                      props: {
                        layer: 2,
                        group: 'tecnico',
                        parentTempId: this.workorder?.id || -1,
                        dependsOn: ['partes'],
                        dependencies: [{keyInDependent: 'workorder', keyInParent: 'id'}]
                      }
                    }
                    this.offlineModule.syncController.addChange(axiosPayload);

                    const query = `
                      DELETE FROM parte_tecnicos WHERE parte_tecnicos.id_parte=${this.workorder?.id} AND parte_tecnicos.id_tecnico=${tec.id}
                    `;
                    const deleteOperarioAdicPromise = new Promise((resolve) => {
                      resolve(
                        this.offlineActionsController.SQLiteQuery(
                          'deleteOperarioAdicional_workorder',
                          'parte_tecnicos',
                          query,
                          e.config,
                          this.offlineModule?.db
                        )
                      );
                    });
                    deleteOperarioAdicPromise.then(() => {
                      // FIXME: *Revisar
                      setTimeout(() => {
                        this.workorder = this.offline.workorder;
                        // sqlite.closeConnection('gmaoTecnicos');
                      }, 500);
                    });
                  } catch (err) {
                    // this.$Sentry.withScope(function(scope) {

                    //   scope.setTag("offline", "Workorder: DEL Operario Adic");

                    //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

                    // });
                    // Error Controller
                    this.$Sentry.captureException(err);
                  }
                }
              });
          },
          { cancel: 'cancelar', confirm: 'quitar' }
        );
      },
    // <== OPTIMIZE: /BLOQUE_OPERARIO_ADICIONAL

    // ==> OPTIMIZE: BLOQUE_SOLUTION
    selectSolution(value) {
      this.workorder.solucion = JSON.parse(JSON.stringify(value.texto));

      this.updateWorkorderField('solucion', value.texto);

      this.setTextoSol = false;
    },
    // <== OPTIMIZE: /BLOQUE_SOLUTION

    checkSameDate(hora) {
      const fecha1 = moment.parseZone(hora?.desde_formatted).format('YYYY-MM-DD');
      const fecha2 = moment.parseZone(hora?.hasta_formatted).format('YYYY-MM-DD');

      return fecha1 === fecha2;
    },

    setNewCommunication(message) {

      if (!message) {
        this.$openToastObject(this.$t('Ha ocurrido un error.'), this.$t('Debe introducir un mensaje para poder enviarlo.'), 'danger');
        return;
      }

      let axiosPayload = {
        data: {
          workorder: this.workorder.id,
          user: this.gmao.user.id,
          message,
        },
        headers: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      };

      this.$axios
        .post(
          `/v2/users/actions.php?call=setNewCommunication&token=${this.gmao.user.token}&v2=1`,
          { data: { ...axiosPayload.data } },
          axiosPayload.headers
        )
        .then(({ data }) => {
          this.newCommunication = null;
          const communication = data.communication;
          communication.new = 1;

          this.workorder.comunicaciones.push(communication);
          this.chatModal = false;
        })
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {

              axiosPayload = {
                ...axiosPayload,
                callFunction: 'setNewCommunication',
                props: {
                  layer: 2,
                  group: 'communication',
                  parentTempId: this.workorder?.id || -1,
                  dependsOn: ['partes'],
                  dependencies: [{keyInDependent: 'workorder', keyInParent: 'id'}]
                }
              }
              this.offlineModule.syncController.addChange(axiosPayload);

              const query = `
                INSERT INTO incidencia_comunicaciones (id, syncObject, id_parte, id_usuario, id_via, mensaje, created_at, updated_at, last_modified)
                VALUES (
                  ${this.$moment().unix()},
                  '${JSON.stringify(axiosPayload)}',
                  ${this.workorder?.id},
                  ${this.gmao.user?.id},
                  1,
                  '${message}',
                  '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                  '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                  '${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
                )
              `;
              const setNewCommunicationPromise = new Promise((resolve) => {
                resolve(
                  this.offlineActionsController.SQLiteQuery(
                    'setNewCommunication_workorder',
                    'incidencia_comunicaciones',
                    query,
                    e.config,
                    this.offlineModule?.db
                  )
                );
              });
              setNewCommunicationPromise.then(() => {
                // FIXME: *Revisar
                setTimeout(() => {
                  this.newCommunication = null;
                  if (this.offline.comunicacion) {
                    const communication = this.offline.comunicacion;
                    communication.new = 1;

                    this.workorder?.comunicaciones.push(communication);
                    this.chatModal = false;
                  }
                  // sqlite.closeConnection('gmaoTecnicos');
                }, 500);
              });
            } catch (err) {
              // this.$Sentry.withScope(function(scope) {

              //   scope.setTag("offline", "Workorder: SET new commun");

              //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

              // });
              // Error Controller
              // this.$Sentry.captureException(err);
            }
          }
        });
    },

    updateWorkorderField(field, value) {
      console.log(field, value);

      let axiosPayload = {
        data: {
          workorder: this.workorder?.id || this.$route.params?.id,
          user: this.gmao.user.id,
          field,
          value,
        },
        headers: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      };
      return this.$axios
        .post(
          `/v2/users/actions.php?call=setUpdateWorkorder&token=${this.gmao.user.token}&v2=1`,
          { data: { ...axiosPayload.data } },
          axiosPayload.headers
        )
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {

              axiosPayload = {
                ...axiosPayload,
                callFunction: 'setUpdateWorkorder',
                props: {
                  layer: 2,
                  group: 'partes',
                  parentTempId: this.workorder?.id || -1,
                  dependsOn: ['partes'],
                  dependencies: [{keyInDependent: 'workorder', keyInParent: 'id'}]
                }
              }
              this.offlineModule.syncController.addChange(axiosPayload);

              const query = `
                  UPDATE partes
                  SET ${field}=${
                typeof value == 'string'
                  ? `'${value}'`
                  : typeof value == 'number'
                  ? value
                  : JSON.stringify(value)
              }, last_modified='${this.$moment().format('YYYY-MM-DD H:mm:ss')}', syncObject='${JSON.stringify(axiosPayload)}'
                  WHERE partes.id=${this.workorder?.id}
                `;
              const updateWorkorderFieldPromise = new Promise((resolve) => {
                resolve(
                  this.offlineActionsController.SQLiteQuery(
                    'updateWorkorderField_workorder',
                    'partes',
                    query,
                    e.config,
                    this.offlineModule?.db
                  )
                );
              });
              updateWorkorderFieldPromise.then(async () => {
                // FIXME: *Revisar
                setTimeout(() => {
                  this.workorder = this.offline.workorder;
                  // sqlite.closeConnection('gmaoTecnicos');
                }, 500);
              });
            } catch (err) {
              // Error Controller
              // this.$Sentry.captureException(err);
            }
          }
        });
    },

    selectingPartida(partida) {
      if (this.selectedBloque == null) {
        this.selectedMaterial.partida = partida;

        this.selectedMaterial.id_partidap = partida?.id;
        this.newHora.partida = partida;
        this.newHora.id_partidap = partida?.id;
      } else {
        this.newHoraBlock.hours[this.selectedBloque].partida = partida;
        this.newHoraBlock.hours[this.selectedBloque].id_partidap = partida.id;
      }

      this.partidasModal = false;
    },

    selectingTipoTiempo(tipo) {
      if (this.selectedBloque != null) {
        this.newHoraBlock.hours[this.selectedBloque].tipo = tipo;
        this.newHoraBlock.hours[this.selectedBloque].id_tipo = tipo.id;
      }

      this.tiposTiempoModal = false;
    },

    workorderPdf() {
      window.open(
        `${this.gmao.workspace.api.split('/api')[0]}/public/partes/obtenerparte.php?id=${this.workorder.id}&key=${this.workorder.key_value}`
      );
    },

  },
};
</script>

<style lang="scss" scoped>
.removeBtn {
  position: absolute;
  top: 0.5rem;
  right: 0.25rem;
}
.playIcon {
  font-size: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: var(--ion-color-tertiary);
  opacity: 0.7;
}
.date-modal {
  background-color: rgba(0, 0, 0, 0.4);
  --width: 290px;
  --height: 382px;
  --border-radius: 8px;
}

.date-modal ion-datetime {
  height: 382px;
  width: 100%;
}

.wo-item {
  /*border: 1px solid #dadada;*/
  height: 100%;
  --background: white !important;

  // & ion-text { margin-bottom: 20px; }
  & p {
    font-size: 12px;
  }
}

.priority-1 {
  border-left: 15px solid var(--priority-1);
  & > ion-icon {
    color: var(--priority-1);
  }
}
.priority-2 {
  border-left: 15px solid var(--priority-2);
  & > ion-icon {
    color: var(--priority-2);
  }
}
.priority-3 {
  border-left: 15px solid var(--priority-3);
  & > ion-icon {
    color: var(--priority-3);
  }
}
.priority-4 {
  border-left: 15px solid var(--priority-4);
  & > ion-icon {
    color: var(--priority-4);
  }
}
.priority-5 {
  border-left: 15px solid var(--priority-5);
  & > ion-icon {
    color: var(--priority-5);
  }
}
.bloque {
  margin-bottom: 1em !important;
}

.camera {
  height: 100%;
  background: #000;
  width: 100%;
}

.active-finished::part(native) {
  background-color: #00acac20;
  min-height: 70px;
}

ion-content {
  --ion-background-color: #f6f6f6;
}

ion-card.bloque {
  --background: white !important;
  margin-top: 8px;
  margin-inline: 16px;
}

.title {
  text-transform: capitalize;
  --ion-background-color: transparent;
  margin-bottom: -15px !important;
}

.title ion-button::part(native) {
  padding-right: 0;
}

ion-item {
  --ion-background-color: white;
}

ion-list {
  --ion-background-color: white;
}

h5 {
  font-size: 10pt !important;
  color: #767676;
}
item-inner {
  border-style: none !important;
}
.checkboxes .ion-color {
  --border-color: var(--ion-color-tertiary);
}

.activosOT {
  min-height: 70px;
  margin-bottom: 1px;

  display: flex;
  flex-direction: row;
  flex-shrink: inherit;
  width: 100%;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  &:last-child {
    margin-bottom: 0;
  }
}
.overflowText > h3 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 90%;
}

.drawing-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid grey;
  border-radius: 12px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;

  &-content {
    display: flex;
    flex-direction: column;

    &-name {
      font-weight: bold;
    }

    &-description {
      font-weight: 400;
      font-size: 0.725rem;
    }
  }
}

.timeRunning {
  &::part(native) {
    background-color: beige;
  }
}

.item {
  margin-bottom: 0 !important;
}
.item_separator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.item_separator > span {
  width: 65%;
  height: 2px;
  background-color: var(--ion-color-secondary);
  border-radius: 1rem;
}

.metadata-end-wrapper {
  position: absolute;

  top: 10px;
  inset-inline-end: 10px;

  font-size: 0.8rem;

  display: flex;
  align-items: center;
}

.hora-tipo {
  text-transform: lowercase !important;
}
.hora-tipo:first-letter {
  text-transform: capitalize !important;
}

.hora-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &-tecnico {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    p  {
      white-space: nowrap !important;
    }
  }

  &-hora {
    text-align: right;
  }
}

</style>