import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { style: {"font-size":"12px","text-align":"right"} }
const _hoisted_3 = { style: {"font-size":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_item, {
      lines: "none",
      class: "title"
    }, {
      default: _withCtx(() => [
        _createElementVNode("h5", null, _toDisplayString(_ctx.$t('COMUNICACIONES')), 1),
        _createVNode(_component_ion_button, {
          class: "ion-no-margin",
          size: "small",
          fill: "clear",
          color: "tertiary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:chatModal', true))),
          disabled: $props.not_editable,
          slot: "end"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              icon: $setup.add,
              style: {"margin-right":"4px"}
            }, null, 8, ["icon"]),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('Ver más')), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    }),
    _createVNode(_component_ion_card, {
      style: {"box-shadow":"none","border-radius":"15px","margin-top":"8px"},
      class: "bloque"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_card_content, { class: "ion-no-padding" }, {
          default: _withCtx(() => [
            (!$props.comunicaciones?.length)
              ? (_openBlock(), _createBlock(_component_ion_item, {
                  key: 0,
                  lines: "none",
                  class: "grey"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('No hay mensajes registrados')), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (($props.comunicaciones || [])?.length)
              ? (_openBlock(), _createBlock(_component_ion_list, {
                  key: 1,
                  lines: "none"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createElementVNode("h3", {
                              innerHTML: $props.comunicaciones?.at(-1)?.mensaje
                            }, null, 8, _hoisted_1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_label, { slot: "end" }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", _hoisted_2, _toDisplayString($props.comunicaciones?.at(-1).usuario?.nombre_completo), 1),
                            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$moment($props.comunicaciones?.at(-1)?.created_at).format('lll')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}