<template>
  <transition name="list">
    <ion-card v-if="+gmao.comportamientos?.jornada_tecnico && !hidden">
      <ion-card-content>
        <ion-item lines="none">
          <ion-icon slot="start" :icon="timeOutline" />
          <ion-icon slot="end" :icon="chevronForwardCircleOutline" @click="() => {
            if (+gmao.comportamientos.tiempos_no_efectivos_crud) {
              $router.push({
                name: 'non_effective_timings',
              })
            } else {
              $router.push({
                name: 'time-recording',
              })
            }
          }"
            v-if="(this.gmao.esTecnico || this.gmao.esUsuario) && !!this.gmao.modulos.modulo_tiempos_no_efectivos"
          />

          <ion-label style="display: flex; align-items: center;">
            <div>
              <h2>{{ $t('horas de hoy') }}</h2>
              <h3 v-if="time">{{ `${time.hours}h ${time.minutes}m ${time.seconds}s` }}</h3>
            </div>

            <div v-show="isRunning" style="margin-left: 16px;">
              <svg height="10" width="10" class="blinking">
                <circle cx="5" cy="5" r="5" fill="red" />
              </svg>
            </div>
          </ion-label>
        </ion-item>
      </ion-card-content>

      <div class="hr" style="margin: 0;" />

      <ion-card-content>
        <ion-button :disabled="disableButton" expand="full" @click="isRunning ? inputTime() : (isWorkingDay ? inputTime() : workingDayPrompt())">
          {{ isRunning ? $t('detener jornada') : $t('iniciar jornada') }}
        </ion-button>
      </ion-card-content>
    </ion-card>
  </transition>

  <!-- MODAL SELECCIÓN VEHÍCULO (INICIO JORNADA) -->
  <ion-modal :is-open="selectingVehicle" @didDismiss="selectingVehicle = false"
    :initialBreakpoint="0.95">
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ $t('Selecciona vehiculo') }}</ion-title>

        <ion-buttons slot="start">
          <ion-button @click="cancelModal()">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <!-- INICIO JORNADA -->
      <template v-if="!isRunning">
        <ion-item @click="setAddress = true">
          <ion-label>{{ $t('Direcciones') }}</ion-label>
          <ion-input class="ion-text-right" :value="selectedAddress?.nombre" readonly
            clear-input></ion-input>
        </ion-item>

        <ion-item v-if="gmao.modulos?.modulo_vehiculos" @click="setVehicleModal = true">
          <ion-label>{{ $t('vehiculos') }}</ion-label>
          <ion-input class="ion-text-right"
            :value="`${selectedVehicle?.matricula || ''} ${selectedVehicle?.descripcion ? `(${selectedVehicle?.descripcion})` : ''}`"
            readonly clear-input></ion-input>
          <!-- <ion-select :placeholder="$t('Selecciona un vehículo')" v-model="selectedVehicle">
            <ion-select-option v-for="v in vehicles" :key="`vehicle-${v.id}`" :value="v.id">
              {{ v.matricula }} ({{ v.descripcion }})
            </ion-select-option>
          </ion-select>-->
        </ion-item>
      </template>

      <ion-item class="roundedInput" v-if="gmao.modulos?.modulo_vehiculos">
        <ion-label position="floating">{{ $t('Kilómetros') }}</ion-label>
        <ion-input v-model="kms"></ion-input>
      </ion-item>
      <ion-button expand="full"
        @click="isRunning ? (+this.gmao.user.comportamientos.jornada_comida ? selectingComida = true : setVehicle(selectedVehicle, kms)) : setVehicle(selectedVehicle, kms, selectedAddress)">
        {{ isRunning ? $t('Detener') : $t('Iniciar') }}
      </ion-button>
    </ion-content>
  </ion-modal>

  <ion-modal :is-open="selectingComida" @didDismiss="selectingComida = false"
    :initialBreakpoint="0.95">
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ $t('Selecciona pausa') }}</ion-title>

        <ion-buttons slot="start">
          <ion-button @click="cancelModalComida()">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <ion-list>
        <ion-item>
          <ion-label>{{ $t('¿Has hecho alguna pausa?') }}</ion-label>
          <ion-select v-model="selectedTiempoComida">
            <ion-select-option value="0">{{$t('0 minutos')}}</ion-select-option>
            <ion-select-option value="900">{{$t('15 minutos')}}</ion-select-option>
            <ion-select-option value="1800">{{$t('30 minutos')}}</ion-select-option>
            <ion-select-option value="2700">{{$t('45 minutos')}}</ion-select-option>
            <ion-select-option value="3600">{{$t('1 hora')}}</ion-select-option>
            <ion-select-option value="4500">{{$t('1 hora 15 minutos')}}</ion-select-option>
            <ion-select-option value="5400">{{$t('1 hora 30 minutos')}}</ion-select-option>
            <ion-select-option value="6300">{{$t('1 hora 45 minutos')}}</ion-select-option>
            <ion-select-option value="7200">{{$t('2 horas')}}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>
      <ion-button expand="full"
        @click="!+this.gmao.modulos?.modulo_vehiculos ? sinVehiculos() : setVehicle(selectedVehicle, kms)">
        {{ $t('Finalizar') }}
      </ion-button>
    </ion-content>
  </ion-modal>

  <!-- MODAL DIRECCIONES -->
  <ion-modal :is-open="setAddress" @didDismiss="setAddress = false" :initialBreakpoint="0.95">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="setAddress = false">{{
            $t('Cancelar')
            }}</ion-button>
        </ion-buttons>

        <ion-title>{{ $t('seleccionar-direccion') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <ion-searchbar :placeholder="$t('buscar-direccion')" v-model="addressSearchAjax"
        @ionInput="applyFiltersDirecciones(true)" :debounce="1000" />

      <ion-list>
        <ion-item v-for="address in addresses?.flat()" :key="`address-${address.id}`" @click="
            () => {
              selectedAddress = address;
              setAddress = false;
            }
          ">
          <ion-label>
            <h3>{{ address.nombre }}</h3>
            <p>{{ address.direccion }}</p>
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-infinite-scroll threshold="100px" id="infinite-scroll"
        @ionInfinite="onLoadDirecciones($event)">
        <ion-infinite-scroll-content loading-spinner="bubbles"
          :loading-text="$t('Cargando más direcciones...')">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-modal>

  <!-- MODAL VEHICULOS -->
  <ion-modal :is-open="setVehicleModal" @didDismiss="setVehicleModal = false" :initialBreakpoint="0.95">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="setVehicleModal = false">{{
            $t('Cancelar')
            }}</ion-button>
        </ion-buttons>

        <ion-title>{{ $t('Selecciona un vehículo') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <ion-searchbar :placeholder="$t('buscar-vehiculo')" v-model="vehicleSearchAjax"
        @ionInput="applyFiltersVehicle(true)" :debounce="1000" />

      <ion-list>
        <ion-item v-for="vehicle in vehicles?.flat()" :key="`vehicle-${vehicle.id}`" @click="
            () => {
              selectedVehicle = vehicle;
              setVehicleModal = false;
            }
          ">
          <ion-label>
            <p>{{ vehicle.matricula }} ({{ vehicle.descripcion }})</p>
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-infinite-scroll threshold="100px" id="infinite-scroll"
        @ionInfinite="onLoadVehicles($event)">
        <ion-infinite-scroll-content loading-spinner="bubbles"
          :loading-text="$t('Cargando más vehiculos...')">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-modal>


</template>

<script>
// OfflineModule
import { OfflineModule } from '@/utils/OfflineModule/OfflineModule';

// OfflineActionsController
import { OfflineActionsController } from '@/utils/OfflineModule/OfflineActionsController';

// ErrorController
import { ErrorController as errorController } from '@/utils/OfflineModule/ErrorController';

import { useOfflineStore } from '@/stores/offline';

import { useGmaoStore } from '@/stores/gmao';

import { ref, getCurrentInstance } from 'vue';

import {
  timeOutline,
  chevronForwardCircleOutline
} from 'ionicons/icons';

import {
  IonModal,
  IonSelect,
  IonSelectOption,
  IonInput,
  alertController,
  IonContent,
  IonCard,
  IonButton,
  IonCardContent,
  IonItem,
  IonLabel,
  IonIcon,
  IonList,
  // loadingController,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
} from '@ionic/vue';

export default {
  name: 'TimeToggle',

  props: {
    hidden: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    IonModal,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonContent,
    IonCard,
    IonButton,
    IonCardContent,
    IonItem,
    IonLabel,
    IonIcon,
    IonList,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSearchbar,
  },

  setup() {
    const gmao = useGmaoStore();
    const offline = useOfflineStore();

    const app = getCurrentInstance();

    const offlineActionsController = new OfflineActionsController();
    const ErrorController = new errorController();

    return {
      timeOutline,
      chevronForwardCircleOutline,
      gmao,
      offline,

      app,

      disableButton: ref(false),
      addresses: ref([]),
      isWorkingDay: ref([]),
      isVehicleModalShow: ref(null),
      timerInterval: ref(null),
      selectingVehicle: ref(false),
      selectedAddress: ref(null),
      selectedVehicle: ref(null),
      selectingComida: ref(false),
      selectedTiempoComida: ref("0"),
      vehicles: ref([]),
      kms: ref(null),

      setAddress: ref(false),
      addressSearchAjax: ref(null),
      setVehicleModal: ref(false),
      vehicleSearchAjax: ref(null),

      time: ref({
        hours: 0,
        minutes: 0,
        seconds: 0
      }),

      // ==> Offline related
        offlineModule: ref(null),
        offlineActionsController,
        ErrorController,
      // <== Offline related
    }
  },

  watch: {
    selectingVehicle(nv) {
      if(!nv) {
        this.selectedVehicle = null;
        this.kms = null;
        this.disableButton = false;
      } else {
        if (!this.offline.status)this.applyFiltersVehicle(true);
        else this.getVehicles();
      }
    },
    setAddress(v) {
      if (v){
        if (!this.offline.status) this.applyFiltersDirecciones(true);
        else this.getDirecciones();

      }
    },
    setVehicleModal(v) {
      if (v) {
        if (!this.offline.status)this.applyFiltersVehicle(true);
        else this.getVehicles();
      }
    },
    selectingComida(){
      if (!this.selectingComida) {
          this.disableButton = false;
      }
    },

  },

  created() {
    // Init OfflineModule
      this.offlineModule = OfflineModule.getInstance();

    this.applyFiltersDirecciones(true);

    this.getTechJornada();

    this.elapsedTime();
    setInterval(this.elapsedTime.bind(this), 1000);

    setInterval(this.checkTimer.bind(this), 10000);

    // this.getVehicles();
    this.getIsWorkingDay();
    this.getVehicleModalShow();
  },




  computed: {
    isRunning() {
      const last = this.gmao.time.at(-1);
      if(!last) return false;

      return !last.stop;
    },
  },

  methods: {
    // TODO: XXX: Globalizar la funcion
    isUserOnline() {
      if (this.offline.status) {
        this.$openToastObject(
          this.$t('offline-generic-action-not-available-msg'),
          this.$t('offline-generic-action-not-available-msg-guard'),
          'danger'
        );
        return false;
      }

      return true;
    },

    async getTechJornada() {
      // XXX: Check for new offline
      // NOTE: Ignores the request if offline -> Se setea en syncOffline.
      // if (this.offline.status) return;

      return this.$axios.get('/v2/users/actions.php', {
        params: {
          call: 'getAllTechWorkingDays',
          token: this.gmao.user.token,
          value: !this.gmao?.time?.length ? this.$moment().tz('Europe/Madrid').format('HH:mm:ss') : null,
          page: -1,
          v2: 1
        }
      }).then(({ data }) => {
          // TODO: Mirar por la implementacion en syncOffline. Esta va a quedar obsoleta.
          data = data && data?.map((d) => {
            const start = d?.desde_formatted ? this.$moment(d?.desde_formatted).format() : this.$moment(`${d.fecha} ${d.inicio}`).tz('Europe/Madrid').format();
            const stop = d?.desde_formatted ?
              (d.hasta_formatted ? this.$moment(d?.hasta_formatted).format() : undefined) :
              (d.fecha_fin ? this.$moment(`${d.fecha_fin} ${d.fin}`).tz('Europe/Madrid').format() : undefined);
  
            const timeTemp = {
              id: d?.id,
              start,
              stop,
              // duracion: d.temp_duracion,
              coords_start: {
                latitude: d?.geo_inicio?.latitude || null,
                longitude: d?.geo_inicio?.longitude || null,
              },
              address_coords: {}
            }

            if (+this.gmao.modulos?.modulo_vehiculos && d?.jornada_vehiculo) {
              timeTemp.vehicle = d?.jornada_vehiculo?.id_vehiculo
              timeTemp.km = {
                from: d?.jornada_vehiculo?.km_inicio
              }
            }

            return timeTemp;
          }) || [];

          this.gmao.time = data;
          
          return data;
      }).catch(async (e) => {
        if (e.code == 'ERR_NETWORK' && this.offline.status) {
          const userId = this.gmao.user?.id;
          const moduloVehiculos = this.gmao.user.modulos?.modulo_vehiculos;

          const query = `
            WITH active_hour AS (
              SELECT *
              FROM jornada_horas
              WHERE id_tecnico = ${userId}
                AND (
                  fin IS NULL
                  OR DATETIME('now', '-2 hours') < DATETIME(fecha_fin || ' ' || fin)
                )
              ORDER BY created_at DESC
              LIMIT 1
            )
            SELECT jh.id, jh.fecha, jh.fecha_fin, jh.inicio, jh.fin, jh.created_at,
                  CASE
                    WHEN ${moduloVehiculos} = 1 THEN vh.id_vehiculo
                    ELSE NULL
                  END AS id_vehiculo,
                  CASE
                    WHEN ${moduloVehiculos} = 1 THEN vh.km_inicio
                    ELSE NULL
                  END AS km_inicio
            FROM (
              SELECT jh.*
              FROM jornada_horas jh
              WHERE jh.id_tecnico = ${userId}
                AND jh.fecha = (SELECT fecha FROM active_hour)
              UNION ALL
              SELECT *
              FROM active_hour
            ) jh
            LEFT JOIN vehiculo_horas vh
              ON ${moduloVehiculos} = 1
              AND vh.id_tecnico = jh.id_tecnico
              AND (
                vh.fecha_inicio = jh.fecha
                OR vh.fecha_fin = jh.fecha_fin
              )
              AND vh.hora_inicio IS NOT NULL
              AND vh.hora_fin IS NULL
            GROUP BY jh.id, jh.fecha, jh.fecha_fin, jh.inicio, jh.fin, jh.created_at, id_vehiculo, km_inicio;
          `;
          const getTechJornadasPromise = new Promise((resolve) => {
              resolve(
                this.offlineActionsController.SQLiteQuery(
                  'getAllTechWorkingDays',
                  'jornada_horas',
                  query,
                  e.config,
                  this.offlineModule?.db
                )
              );
          });
          await getTechJornadasPromise.then((data) => {
            setTimeout(() => {
              this.gmao.time = data;
              // sqlite.closeConnection('gmaoTecnicos');
            }, 500);
          });
        }
      });
    },

    onLoadVehicles(event) {
      if (!this.loading && this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFiltersVehicle(false).then(() => {
          event.target.complete();
        });
      } else event.target.complete();
    },

    applyFiltersVehicle(reset = true) {
      if (reset) {
        this.vehicles = [];
        this.current_page = 1;
      }
      return this.getVehicles();
    },

    cancelModal() {
      this.disableButton = false;
      this.selectingVehicle = false;
    },

    cancelModalComida() {
      this.disableButton = false;
      this.selectingComida = false;
    },

    getVehicles() {
      this.$axios.get('/v2/users/actions.php', {
        params: {
          call: 'getVehiculos',
          token: this.gmao.user.token,
          value: this.gmao.user.id,
          page: this.current_page,
          search: this.vehicleSearchAjax,
          v2: 1
        }
      }).then(({ data }) => {
        this.vehicles.push(data.data);

        this.current_page = data.current_page;
        this.last_page = data.last_page;
        this.total = data.total;

        if(!data?.error) {
          (data.data || [])?.flat()?.forEach(element => {
            (element.id_tecnico == this.gmao.user.id) ? this.selectedVehicle = element.id : null ;
          });
        }
      }).catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {

              const query = `
                SELECT *
                FROM vehiculos
                ${this.vehicleSearchAjax?.length ? `
                  WHERE matricula LIKE "%${this.vehicleSearchAjax}%" OR
                    descripcion LIKE "%${this.vehicleSearchAjax}%"
                ` : ''}
              `;
              const getVehiculosTec = new Promise((resolve) => {
                resolve(
                  this.offlineActionsController.SQLiteQuery(
                    'getVehiculosTex',
                    'vehiculos',
                    query,
                    e.config,
                    this.offlineModule?.db
                  )
                );
              });
              getVehiculosTec.then(() => {
                setTimeout(() => {
                  // sqlite.closeConnection('gmaoTecnicos').then(() => {
                    this.vehicles = this.offline.vehiculos;
                    return this.vehicles;
                  // });
                }, 100);
              });
            } catch (err) {
              // TODO: ErrorController
              this.$Sentry.captureException(err);
            }
          }
        });
    },

    async getVehicleModalShow() {
      /**
       * Offline: se puede implementar en local pero necesitariamos tablas de VehiculoHoras y Vehiculo
       *  Para replicar la logica del backend...
       * NOTE: De momento mostramos siempre
       */
      if (this.offline.status) {
        // this.$openToastObject(
        //   this.$t('offline-generic-module_vehicle-not-available-msg'),
        //   this.$t('offline-generic-action-not-available-msg-guard'),
        //   'danger'
        // );
        this.isVehicleModalShow = !!this.gmao.modulos?.modulo_vehiculos;
        return;
      }
      this.disableButton = true;
      return this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getVehicleModalShow',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            v2: 1,
          },
        })
        .then(({ data }) => {
          this.isVehicleModalShow = data;
          return data;
        })
    },

    getIsWorkingDay() {
      this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getIsWorkingDay',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            v2: 1,
          },
        })
        .then(({ data }) => {
          this.isWorkingDay = data;

          this.offline.isWorkingDay = data;
        }).catch(async (e) => {
            if (e.code == 'ERR_NETWORK' && this.offline.status) {
              try {
                const query = '';
                
                const getIsWorkingDay = new Promise((resolve) => {
                  resolve(
                    this.offlineActionsController.SQLiteQuery(
                      'getIsWorkingDay',
                      'usuarios_vacaciones',
                      query,
                      e.config,
                      this.offlineModule?.db
                    )
                  );
                });
                getIsWorkingDay.then(() => {
                  setTimeout(() => {
                    // sqlite.closeConnection('gmaoTecnicos').then(() => {
                      this.isWorkingDay = this.offline.isWorkingDay;
                    // });
                  }, 500);
                });
              } catch (err) {
                console.error(err);
              }
            }
          });
    },

    workingDayPrompt() {
      this.alertPopup(
        this.$t(
          'Vas a fichar en un día de vacaciones/ausencia ¿Estás seguro?'),
        () => {
          this.inputTime();
        },
        { cancel: 'cancelar', confirm: 'confirmar' }
      );
    },

    async alertPopup(
      header,
      callback,
      { cancel, confirm } = { cancel: 'cancel', confirm: 'confirm' }
    ) {
      const alert = await alertController.create({
        cssClass: 'my-custom-class',
        header,
        buttons: [
          {
            text: this.$t(cancel),
            role: 'cancel',
            cssClass: 'secondary',
            id: 'cancel-button',
          },
          {
            text: this.$t(confirm),
            id: 'confirm-button',
            handler: callback,
          },
        ],
      });

      await alert.present();
    },

    async inputTime() {
      this.disableButton = true;
      const { time } = this.gmao;

      const direcciones = this.addresses.flat();

      // REVIEW: NOT available in OFFLINE
      await this.getVehicleModalShow();
      
      // TODO: FIX CAMBIO
      if (this.isVehicleModalShow && this.gmao.modulos?.modulo_vehiculos && direcciones.length > 1) {
        const last = time.at(-1);
        
        if (last && !last.km?.to && last.vehicle && !this.isRunning) {
          // this.setVehicle(last.vehicle, last.km.from);
          if (this.isVehicleModalShow || direcciones.length > 1) this.selectingVehicle = true;
          else this.setVehicle((this.vehicles[0]?.id || null), last?.km?.from);
        } else {
          if (!last || (!last?.vehicle && 'to' in (last?.km || {})) || last.vehicle) {

            if (this.isVehicleModalShow || direcciones.length > 1) this.selectingVehicle = true;
            else this.setVehicle((this.vehicles[0]?.id || null), last?.km?.from);

          } else {
            if (+this.gmao.user.comportamientos.jornada_comida && this.isRunning) {

              this.selectingComida = true;

            } else {
              if (this.isVehicleModalShow || direcciones.length > 1) this.selectingVehicle = true;
              else this.setVehicle((this.vehicles[0]?.id || null), last?.km?.from);

            }
          }
        }
      } else {
        if (this.isRunning) {
          if (+this.gmao.user.comportamientos.jornada_comida) {
            this.selectingComida = true;
          } else {
            this.sinVehiculos();
          }
        } else {
          let latitude = null;
          let longitude = null;

          if (+this.gmao.comportamientos.jornada_geolocalizacion) {

            const geolocation = await this.$getGeolocation(true);
            latitude = geolocation?.coords?.latitude;
            longitude = geolocation?.coords?.longitude;
            // console.log('Obtengo: ', geolocation);
          }

          const hour = {
            start: this.$moment().format(),
            coords_start: {
              latitude,
              longitude
            }
          };

          const syncHour = {
            id: hour?.id,
            time: this.$moment().format(),
            tz: this.$moment().format('Z'),
            inicio: this.$moment().tz('Europe/Madrid').format('HH:mm:ss'),
            // fin: jornada.stop ? this.$moment(jornada.stop).format("HH:mm:ss") : null,
            // descontado: this.selectedTiempoComida,
            fecha: this.$moment(hour.start).tz('Europe/Madrid').format('YYYY-MM-DD'),
            fecha_tz: hour?.start,
            id_tecnico: this.gmao.user.id,
            // vehicle: jornada?.vehicle || null,
            // km_from: jornada?.km?.from,
            // km_to: jornada?.km?.to,
            coords_inicio: hour.coords_start,
            address_coords: hour.address_coords
          }

          hour.id = syncHour.id;

          time.push(hour);

          const axiosPayload = {
            data: { jornadas: [ syncHour ] },
            headers: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
          };

          console.log('COMIENZO DE JORNADA');
          
          this.$axios.post(`/v2/users/actions.php?call=setCreateJornada&token=${this.gmao.user.token}&v2=1`, 
            { data: { ...axiosPayload.data } },
            axiosPayload.headers
          ).then(({ data }) => {
            this.getTechJornada();
            if (data && typeof data == 'object' && data?.status) {
              syncHour.id = data?.jornadas?.length && data?.jornadas.at(0).id;

              this.handleJornadaOffline(syncHour, axiosPayload);
              this.$emit('set', data);
              
            } else {
              this.$openToastObject(
                this.$t('Ha ocurrido un error'),
                this.$t('No se ha podido imputar la jornada. Compruebe la conexión'),
                'danger'
              );
            }
          }).catch(async (e) => {
            if (e.code == 'ERR_NETWORK' && this.offline.status) {
              this.handleJornadaOffline(syncHour, axiosPayload);
            }
          }).finally(() => {
            this.disableButton = false;
          });
        }
      }
    },

    async handleJornadaOffline(syncHour, axiosPayload) {
      try {
        let query = '';
        const unixID = this.$moment().unix();

        axiosPayload = {
          ...axiosPayload,
          callFunction: 'setCreateJornada',
          props: {
            layer: 1,
            group: 'jornada_horas',
            tempId: unixID,
            parentTempId: undefined,
            dependsOn: ['jornada_horas'],
            dependencies: [{keyInDependent: 'id', keyInParent: 'id'}]
          },
        }

        /** REVIEW: TODO: Comprobar si hay conexion o no
         * Porque si hay, no es necesario subir el axiosPayload a la cola
         *  Si la conexión falla – SI
         *  Si no tiene conextión – SI
         */
        if (this.offline.status) this.offlineModule.syncController.addChange(axiosPayload);

        query = `
          INSERT INTO jornada_horas
          (
            id,
            syncObject,
            id_tecnico,
            inicio,
            fecha,
            desde,
            desde_tz,
            geo_inicio,
            id_direccion,
            last_modified
          ) VALUES
          (
            '${axiosPayload.props.tempId}',
            '${JSON.stringify(axiosPayload)}',
            ${this.gmao.user.id},
            '${syncHour.inicio}',
            '${syncHour.fecha}',
            '${syncHour.time}',
            '${syncHour.tz}',
            '${JSON.stringify(syncHour.coords_inicio)}',
            ${syncHour.id_direccion || 'null'},
            '${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
          );
        `;
        
        await this.offlineModule.openDBConnection();
        const setCreateJornada = new Promise((resolve) => {
          resolve(
            this.offlineActionsController.SQLiteQuery(
              'setCreateJornada',
              'jornada_horas',
              query,
              { method: 'post' },
              this.offlineModule?.db
            )
          );
        });
        setCreateJornada.then(() => {
          setTimeout(async () => {
            // sqlite.closeConnection('gmaoTecnicos').then((data) => {
              // this.getTechJornada();
              if (this.offline.jornada_horas) {
                // this.gmao.time.push(this.offline.jornada_horas)
                this.$emit('set', this.offline.jornada_horas);
              } else {
                this.$openToastObject(
                  this.$t('Ha ocurrido un error'),
                  this.$t('No se ha podido imputar la jornada. Compruebe la conexión'),
                  'danger'
                );
              }
              if (!this.offline.status) await this.offlineModule.closeDBConnection();
            // });
          }, 500);
        });
      } catch (err) {
        // TODO: ErrorController
        // this.$Sentry.captureException(err);
      }
    },

    //SIN MODULO VEHICULOS LLAMADA
    async sinVehiculos() {
      this.disableButton = true;
      const { time } = this.gmao;

      let latitude = null;
      let longitude = null;

        if (+this.gmao.comportamientos.jornada_geolocalizacion) {
          const geolocation = await this.$getGeolocation(true);
          latitude = geolocation?.coords?.latitude;
          longitude = geolocation?.coords?.longitude;
        }

      const last = time[time.length - 1];

          last.stop = this.$moment().format();
          last.coords_end = {
            latitude,
            longitude
          };

          let axiosPayload = {};
          try {
            axiosPayload = {
              data: {
                jornadas: [
                  {
                    id_jornada: last?.id || (this.offline.status && this.$moment().unix()),
                    inicio: this.$moment(last.start).format("HH:mm:ss"),
                    fin: this.$moment(last.stop).tz('Europe/Madrid').format("HH:mm:ss"),
                    fecha: this.$moment(last.start).format('YYYY-MM-DD'),
                    fecha_fin: this.$moment(last.stop).tz('Europe/Madrid').format('YYYY-MM-DD'),
                    fecha_tz: this.$moment(last.start).format(),
                    fecha_fin_tz: last.stop ? this.$moment(last.stop).format() : null,
                    id_tecnico: this.gmao.user.id,
                    vehicle: !this.isVehicleModalShow ? (this.vehicles ? this.vehicles[0]?.id : null) : null,
                    descontado: this.selectedTiempoComida,
                    coords_inicio: last.coords_start,
                    coords_end: last.coords_end,
                    address_coords: last.address_coords
                  }
                ]
              },
              headers: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            }
          } catch (error) {
            // TODO: ErrorController
            console.error(error);
          }

          console.log('FIN DE JORNADA SIN VEHICULOS');
          this.$axios.post(`/v2/users/actions.php?call=setJornada&u=${this.gmao.user.id}&token=${this.gmao.user.token}&v2=1`,
            { data:{ ...axiosPayload.data } },
            axiosPayload.headers,
          ).then(({ data }) => {
            this.getTechJornada();
            if (data && typeof data == 'object' && data?.status) {
              this.$emit('set', data);
            } else {
              this.$openToastObject(
                this.$t('Ha ocurrido un error'),
                this.$t('No se ha podido imputar la jornada. Compruebe la conexión'),
                'danger'
              );
            }
          }).catch(async (e) => {
            if (e.code == 'ERR_NETWORK' && this.offline.status) {
              try {

                const jornada = axiosPayload.data.jornadas[0];
                const idJornada = jornada.id_jornada;
                const unixID = this.$moment().unix();

                axiosPayload = {
                  ...axiosPayload,
                  callFunction: 'setJornada',
                  props: {
                    layer: 2,
                    group: 'jornada_horas',
                    tempId: unixID,
                    parentTempId: idJornada,
                    dependsOn: ['jornada_horas'],
                    dependencies: [{keyInDependent: 'id_jornada', keyInParent: 'id'}]
                  },
                }
                this.offlineModule.syncController.addChange(axiosPayload);

                const fechaFin = jornada.fecha_fin ? `'${jornada.fecha_fin}'` : "NULL";
                const fin = jornada.fin ? `'${jornada.fin}'` : "NULL";
                const fechaFinTz = jornada.fecha_fin_tz ? `'${jornada.fecha_fin_tz}'` : "NULL";
                const hasta = fechaFin; // Usaremos el mismo valor de fechaFin para hasta
                const hastaTz = fechaFinTz; // Usamos el mismo valor de fechaFinTz para hastaTz
                const duracion = jornada.fecha_fin_tz 
                  ? Math.abs(new Date(jornada.fecha_fin_tz).getTime() - new Date(jornada.fecha_tz).getTime()) - jornada.descontado
                  : 0;
                const descontado = jornada.descontado;
                const coordsInicio = jornada.coords_inicio ? `'${jornada.coords_inicio}'` : "NULL";
                const coordsEnd = jornada.coords_end ? `'${jornada.coords_end}'` : "NULL";

                const query = `
                  UPDATE jornada_horas
                  SET
                    fecha_fin = ${fechaFin},
                    fin = ${fin},
                    hasta = ${hasta},
                    hasta_tz = ${hastaTz},
                    duracion = ${duracion},
                    geo_inicio = ${JSON.stringify(coordsInicio)},
                    geo_fin = ${JSON.stringify(coordsEnd)},
                    tiempo_comida = ${descontado},
                    id_direccion = 'NULL',
                    syncObject = '${JSON.stringify(axiosPayload)}',
                    last_modified = '${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
                  WHERE id = ${idJornada};
                `;
                
                const setJornadaPromise = new Promise((resolve) => {
                  resolve(
                    this.offlineActionsController.SQLiteQuery(
                      'setJornada_workorder',
                      'jornada_horas',
                      [idJornada, query],
                      e.config,
                      this.offlineModule?.db
                    )
                  );
                });
                setJornadaPromise.then(() => {
                  
                  setTimeout(() => {
                    // sqlite.closeConnection('gmaoTecnicos').then(() => {
                      if (this.offline.jornada_horas) {
                        this.$emit('set', this.offline.jornada_horas);
                      } else {
                        this.$openToastObject(
                          this.$t('Ha ocurrido un error'),
                          this.$t('No se ha podido imputar la jornada. Compruebe la conexión'),
                          'danger'
                        );
                      }
                    // });
                  }, 500);
                });
              } catch (err) {
                console.error(err);
                // TODO: ErrorController
                this.$Sentry.captureException(err);
              }
            }
          }).finally(() => {
            this.disableButton = false;
          });

          this.selectingComida = false;
    },

    elapsedTime() {
      const time = this.gmao.time
        // .filter((v) => this.$moment(v?.start).isSame(this.$moment(), 'day'))
        .reduce((a, v) => a + (v?.stop ? this.$moment(v?.stop) : this.$moment())
        .diff(
          this.$moment(v?.start), 'seconds'
        ), 0);

      this.time.hours = Math.floor((time) / 3600);
      this.time.minutes = String(Math.floor(((time) % 3600) / 60)).padStart(2, '0');
      this.time.seconds = String(((time) % 3600) % 60).padStart(2, '0');
    },

    checkTimer() {
      if(this.isRunning) {
        this.getTechJornada();
      }
    },

    async setVehicle(vehicle, kms, address = null) {
      const { time } = this.gmao;
      let jornada = {};
      let latitude = null;
      let longitude = null;

      if (+this.gmao.comportamientos.jornada_geolocalizacion) {
        const geolocation = await this.$getGeolocation(true);
        latitude = geolocation?.coords?.latitude;
        longitude = geolocation?.coords?.longitude;
      }

      if(!this.isRunning) {
        const { id, latitud, longitud } = (address ? this.addresses.flat().find(a => a.id == address?.id) : { latitud: null, longitud: null });

        jornada = { start: this.$moment().tz('Europe/Madrid').format(), vehicle: vehicle?.id, km: { from: kms } , coords_start: {
          latitude,
          longitude
        }, address_coords: { id, latitud, longitud } };

        time.push(jornada);
      } else {
        const { id, latitud, longitud } = (address ? this.addresses.flat().find(a => a.id == address?.id) : { latitud: null, longitud: null });

        jornada = this.gmao.time.at(-1);
        
        jornada.stop = this.$moment().format();
        jornada.km = jornada.km || {};
        jornada.km.to = kms || null;
        jornada.address_coords = { id, latitud, longitud };
        jornada.coords_end = {
          latitude,
          longitude
        };
        // this.selectingComida = true;
      }
      this.setJornadas(jornada);
      this.selectingVehicle = false;
      this.selectingComida = false;
    },

    setJornadas(jornada) {
      // console.log('P:: ', structuredClone(jornada));
      this.disableButton = true;

      let axiosPayload = {};
      try {
        axiosPayload = {
          data: {
            jornadas: [
              {
                id_jornada: jornada.id,
                inicio: this.$moment(jornada.start).format("HH:mm:ss"),
                fin: jornada.stop ? this.$moment(jornada.stop).tz('Europe/Madrid').format("HH:mm:ss") : null,
                fecha: this.$moment(jornada.start).format('YYYY-MM-DD'),
                fecha_fin: jornada.stop ? this.$moment(jornada.stop).tz('Europe/Madrid').format('YYYY-MM-DD') : null,
                fecha_tz: this.$moment(jornada.start).format(),
                fecha_fin_tz: jornada.stop ? this.$moment(jornada.stop).format() : null,
                descontado: this.selectedTiempoComida,
                id_tecnico: this.gmao.user.id,
                vehicle: jornada?.vehicle || null,
                km_from: jornada?.km?.from,
                km_to: jornada?.km?.to,
                coords_inicio: jornada.coords_start,
                coords_end: jornada.coords_end,
                address_coords: jornada.address_coords
              }
            ]
          },
          headers: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
          vehicle_fin: jornada.stop || null,
        };
      } catch (error) {
        // TODO: ErrorController
        console.error(error);
      }

      console.log('FIN DE JORNADA CON VEHICULOS');

      this.$axios.post(`/v2/users/actions.php?call=setJornada&token=${this.gmao.user.token}&v2=1`,
        { data: {...axiosPayload.data} },
        axiosPayload.headers,
      ).then(async ({ data }) => {
        await this.getTechJornada();        
        const newJornada = this.gmao.time.at(-1);
        newJornada && this.handleJornadaOfflineVehiculo(newJornada, axiosPayload);

        if (data && typeof data == 'object' && data?.status) {
          this.$emit('set', data);
        } else {
          this.$openToastObject(
            this.$t('Ha ocurrido un error'),
            this.$t('No se ha podido imputar la jornada. Compruebe la conexión'),
            'danger'
          );
        }
      }).catch(async (e) => {
        if (e.code == 'ERR_NETWORK' && this.offline.status) {
          this.handleJornadaOfflineVehiculo(jornada, axiosPayload);
        }
      }).finally(() => {
        this.disableButton = false;
      });
    },

    async handleJornadaOfflineVehiculo(jornada, axiosPayload) {
      try {
        
        let query = '';
        let queryVehiculo = '';
        const jornadaOffline = axiosPayload.data.jornadas[0];
        const unixID = this.$moment().unix();

        /** XXX:
         * Sobreescribimos id_jornada por si nos viene del api
         */
        if (jornada?.id) jornadaOffline.id_jornada = jornada.id;
        const idJornada = jornadaOffline?.id_jornada || unixID;

        const jornadaFin = 'fin' in jornadaOffline && jornadaOffline.fin?.length;

        console.log('Offline handle:: ', jornada, jornadaOffline);

        /** NOTE: 
         * En este caso parentTempId apunta a jornada y no a axiosPayload
         */
        axiosPayload = {
          ...axiosPayload,
          callFunction: 'setJornada',
          props: {
            layer: !jornadaFin ? 1 : 2,
            group: 'jornada_horas',
            tempId: unixID,
            parentTempId: !jornadaFin ? undefined : jornada.id,
            dependsOn: ['jornada_horas'],
            dependencies: [{keyInDependent: 'id_jornada', keyInParent: 'id'}]
          },
        }

        /** REVIEW: TODO: Comprobar si hay conexion o no
         * Porque si hay, no es necesario subir el axiosPayload a la cola
         *  Si la conexión falla – SI
         *  Si no tiene conextión – SI
         */
        if (this.offline.status) this.offlineModule.syncController.addChange(axiosPayload);

        const kmFrom = jornadaOffline.km_from;
        const kmTo = jornadaOffline.km_to;
        const fechaIni = jornadaOffline.fecha ? `'${jornadaOffline.fecha}'` : "NULL";
        const inicio = jornadaOffline.inicio ? `'${jornadaOffline.inicio}'` : "NULL";
        
        const fechaFin = jornadaOffline.fecha_fin ? `'${jornadaOffline.fecha_fin}'` : "NULL";
        const fin = jornadaOffline.fin ? `'${jornadaOffline.fin}'` : "NULL";
        const fechaFinTz = jornadaOffline.fecha_fin_tz ? `'${jornadaOffline.fecha_fin_tz}'` : "NULL";
        
        const desde = fechaIni; // Usaremos el mismo valor de fechaFin para hasta
        const fechaTz = jornadaOffline.fecha_tz ? `'${jornadaOffline.fecha_tz}'` : "NULL";
        const hasta = fechaFin; // Usaremos el mismo valor de fechaFin para hasta
        const hastaTz = fechaFinTz; // Usamos el mismo valor de fechaFinTz para hastaTz
        const duracion = jornadaOffline.fecha_fin_tz 
          ? Math.abs(new Date(jornadaOffline.fecha_fin_tz).getTime() - new Date(jornadaOffline.fecha_tz).getTime()) - jornadaOffline.descontado
          : 0;
        const descontado = jornadaOffline.descontado;
        const coordsInicio = jornadaOffline.coords_inicio ? `${jornadaOffline.coords_inicio}` : "NULL";
        const coordsEnd = jornadaOffline.coords_end ? `${jornadaOffline.coords_end}` : "NULL";

        const kmDif = +kmTo && (+kmTo - +kmFrom) || 0;

        query = `
          INSERT OR REPLACE INTO jornada_horas
            (id, id_tecnico, fecha, inicio, desde, desde_tz, fecha_fin, fin, hasta, hasta_tz, duracion, geo_inicio, geo_fin, tiempo_comida, id_direccion, syncObject, last_modified)
            VALUES (
              ${idJornada},
              ${jornadaOffline?.id_tecnico || ''},
              ${fechaIni},
              ${inicio},
              ${desde},
              ${fechaTz},
              ${fechaFin},
              ${fin},
              ${hasta},
              ${hastaTz},
              ${duracion},
              ${JSON.stringify(coordsInicio)},
              ${JSON.stringify(coordsEnd)},
              ${descontado},
              ${jornadaOffline.address_coords?.id || 'null'},
              '${JSON.stringify(axiosPayload)}',
              '${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
          );
        `;

        queryVehiculo = `
          INSERT OR REPLACE INTO vehiculo_horas
            (id, fecha_inicio, hora_inicio, fecha_fin, hora_fin, geo_inicio, geo_fin, id_tecnico, id_vehiculo, km_inicio, km_fin, km_diferencia, syncObject, last_modified)
            VALUES (
              ${idJornada},
              ${fechaIni},
              ${inicio},
              ${fechaFin},
              ${fin},
              ${JSON.stringify(coordsInicio)},
              ${JSON.stringify(coordsEnd)},
              ${jornadaOffline?.id_tecnico || 'null'},
              ${jornadaOffline.vehicle || 'null'},
              ${kmFrom},
              ${kmTo || 0},
              ${kmDif},
              '${JSON.stringify(axiosPayload)}',
              '${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
          );
        `;
        
        await this.offlineModule.openDBConnection();
        const setJornadaPromise = new Promise((resolve) => {
          resolve(
            this.offlineActionsController.SQLiteQuery(
              'setJornada_vehiculo_workorder',
              'jornada_horas',
              [idJornada, query, queryVehiculo],
              { method: 'post' },
              this.offlineModule?.db
            )
          );
        });
        setJornadaPromise.then(() => {
          
          setTimeout(async () => {
            // sqlite.closeConnection('gmaoTecnicos').then(() => {
              if (this.offline.jornada_horas) {
                this.getTechJornada();
                this.$emit('set', this.offline.jornada_horas);
              } else {
                this.$openToastObject(
                  this.$t('Ha ocurrido un error'),
                  this.$t('No se ha podido imputar la jornada. Compruebe la conexión'),
                  'danger'
                );
              }
              if (!this.offline.status) await this.offlineModule.closeDBConnection();
            // });
          }, 500);
        });
      } catch (err) {
        // TODO: ErrorController
          console.error(err);
          this.$Sentry.captureException(err);
      }
    },

    onLoadDirecciones(event) {
      if (!this.loading && this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFiltersDirecciones(false, statusCall).then(() => {
          event.target.complete();
        });
      } else event.target.complete();
    },

    applyFiltersDirecciones(reset = true) {
      if (reset) {
        this.addresses = [];
        this.current_page = 1;
      }
      return this.getDirecciones();
    },

    getDirecciones() {
      this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getDireccionesModal',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            v2: 1,
            page: this.current_page,
            search: this.addressSearchAjax,
          },
        })
        .then(({ data }) => {
          this.addresses.push(data.data);

          this.current_page = data.current_page;
          this.last_page = data.last_page;
          this.total = data.total;
          this.disableButton = false;

        }).catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {

            try {
              const query = `
                SELECT *
                FROM direcciones
                ${this.addressSearchAjax?.length && this.addressSearchAjax ? `WHERE nombre LIKE "%${this.addressSearchAjax}%" OR direccion LIKE "%${this.addressSearchAjax}%"`: ''}
                `;

              const getDireccionesPromise = new Promise((resolve) => {
                  resolve(this.offlineActionsController.SQLiteQuery('getDirecciones_new_workorder', 'direcciones', query, e.config, this.offlineModule?.db));
              });
              await getDireccionesPromise.then(() => {
                this.addresses = [];
                setTimeout(() => {
                  this.addresses = [this.offline.addresses];
                  // sqlite.closeConnection('gmaoTecnicos');
                }, 500);
              });
            } catch (err) {
              this.$Sentry.captureException(err);
            }
          }
        });
    }
  }
}
</script>

<style>
@keyframes blink {
  from, to { opacity: 0; }
  50% { opacity: 1; }
}

@-moz-keyframes blink {
  from, to { opacity: 0; }
  50% { opacity: 1; }
}

@-webkit-keyframes blink {
  from, to { opacity: 0; }
  50% { opacity: 1; }
}

@-ms-keyframes blink {
  from, to { opacity: 0; }
  50% { opacity: 1; }
}

@-o-keyframes blink {
  from, to { opacity: 0; }
  50% { opacity: 1; }
}
</style>