// SQLiteDBConnection
import { SQLiteDBConnection } from '@capacitor-community/sqlite';

// OFFLINE CONTROLLERS
import setController from '@/utils/OfflineModule/setQueries';
import getController from '@/utils/OfflineModule/getQueries';

// STORES
import { useOfflineStore } from '@/stores/offline';

export class OfflineActionsController {
    public offline: any = useOfflineStore();

    constructor() {}

    /**
     * Ejecuta una consulta SQLite con un método específico (GET o POST)
     * @param functionName Nombre de la función que se ejecuta
     * @param table Nombre de la tabla
     * @param query Consulta SQL a ejecutar
     * @param config Configuración adicional (debe incluir el método)
     * @param dbEX Conexión SQLite
     * @returns Promise con los resultados procesados
     */
    async SQLiteQuery(
        functionName:string,
        table: string,
        query: string,
        config: any,
        dbEX: SQLiteDBConnection
    ): Promise<any> {

        const data = { functionName, table, query, config, dbEX };

        try {
            const { method } = config;

            // Valida el método
            if (!['get', 'post'].includes(method)) {
                // TODO: ErrorController
                throw new Error(`Unsupported method: ${method}`);
            }
            

            switch (method) {
                case 'get':
                    return await getController(data, this.offline);

                case 'post':
                    return setController(data, this.offline);

                default:
                    throw new Error(`Unsupported method: ${method}`);
            }

        } catch (e:any) {
            // TODO: ErrorController
            console.log(`Error SQLite Query: ${e}`);
            // throw new Error(`Error SQLite Query: ${e}`)
        }
    }
}