<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>
          <ion-title>{{ $t('Seleccionar modelo') }}</ion-title>
        </ion-toolbar>
      </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-searchbar
        :placeholder="$t('Buscar modelos...')"
        v-model="modeloSearch"
        class="defaultSearchBar"
        @ionInput="applyFilters(true)"
        :debounce="1000"
      />

      <template v-if="modelos?.length">
        <ion-list style="border-radius: 15px; margin-bottom: 100px">
          <template v-if="modelos.flat().length">
            <ion-item
              v-for="(m, key) in modelos.flat()"
              :key="`modalelo-${key}`"
              @click="$emit('done', m)"
            >
              <ion-label class="ion-text-wrap">
                <h3>
                  {{ `${m?.familia?.nombre ? `${m?.familia?.nombre}` : `(${$t('Familia')})` }` }}
                  {{`${m?.subfamilia?.nombre ? `- ${m?.subfamilia?.nombre}` : `(${$t('SubFamilia')})`}`}}
                  {{`${m?.marca ? `- ${m?.marca}` : `(${$t('Marca')})`}`}}
                  {{`${m?.modelo ? `- ${m?.modelo}` : `(${$t('Modelo')})`}`}}
                </h3>
                <h3 v-if="!m?.familia?.nombre && !m?.subfamilia?.nombre && !m?.marca && !m?.modelo">
                  {{ $t('Sin nombre') }}
                </h3>
              </ion-label>
            </ion-item>
          </template>
          <template v-else>
            <ion-item
              lines="none"
              style="margin: 0; color: var(--ion-color-primary)"
              @click="$emit('newModelo', modeloSearch)"
            >
              <ion-icon slot="start" :icon="add"></ion-icon>

              <ion-label>
                <h3>Añadir "{{ modeloSearch }}" a la lista de modelos</h3>
              </ion-label>
            </ion-item>
          </template>
        </ion-list>

        <ion-infinite-scroll
          threshold="100px"
          id="infinite-scroll"
          @ionInfinite="onLoad($event)"
        >
          <ion-infinite-scroll-content
            loading-spinner="bubbles"
            :loading-text="$t('Cargando más modelos...')"
          >
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </template>


    </ion-content>

  </ion-modal>
</template>

<script>
import { ref, getCurrentInstance } from 'vue';

// Stores
import { useGmaoStore } from '@/stores/gmao';
import { useOfflineStore } from '@/stores/offline';

import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonIcon,
} from '@ionic/vue';

import { add } from 'ionicons/icons';

export default {
  name: 'MSetModeloNewActivo',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    offlineToolkit: {
      type: Object,
      required: false,
    },

  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSearchbar,
    IonIcon,
  },

  setup() {
    const gmao = useGmaoStore();
    const offline = useOfflineStore();

    const app = getCurrentInstance();

    return {
      modeloSearch: ref(null),
      modelos: ref([]),

      current_page: ref(1),
      last_page: ref(null),
      total: ref(null),
      loading: ref(null),

      // Stores
      gmao,
      offline,

      // OTHER
      app,
      add,
    };
  },

  watch: {
    modelValue(ev) {
      if (ev) this.applyFilters(true);
    },
  },

  methods: {

    /** PAGINATION */
    onLoad(event) {
      if (!this.loading && this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFilters(false).then(() => {
          event.target.complete();
        });
      } else event.target.complete();
    },
    async applyFilters(reset = true) {
      if (reset) {
        this.modelos = [];
        this.current_page = 1;
      }
      return this.getModelos();
    },

    async getModelos() {
      this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getModelos',
            token: this.gmao.user.token,
            search: this.modeloSearch,
            page: this.current_page,
            v2: 1,
          },
        })
        .then(({ data }) => {
          this.modelos.push(data.data);

          this.current_page = data.current_page;
          this.last_page = data.last_page;
          this.total = data.total;

        })
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const query = `
                SELECT *
                FROM maquina_modelos
              `;
              const getModelosPromise = new Promise((resolve) => {
                resolve(
                  this.offlineToolkit.offlineActionsController.SQLiteQuery(
                    'getModelos_workorder',
                    'maquina_modelos',
                    query,
                    e.config,
                    this.offlineToolkit.offlineModule?.db
                  )
                );
              });
              getModelosPromise.then(() => {
                setTimeout(() => {
                  this.modelos = this.offline.modelos;
                  // sqlite.closeConnection('gmaoTecnicos');
                }, 500);
              });
            } catch (err) {
              // this.$Sentry.withScope(function(scope) {

              //   scope.setTag("offline", "Workorder: GET Maquina Modelos");

              //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

              // });
                this.$Sentry.captureException(err);
            }
          }
        });
    },

  },
};
</script>