// STORES
import { useOfflineStore } from '@/stores/offline';
import { useGmaoStore } from '@/stores/gmao';
import { useWorkOrdersStore } from '@/stores/workorders';

// Axios
import axios from 'axios';


// Moment
import moment from 'moment';

export class ApiController {

  public offline: any = useOfflineStore();
  public gmao: any = useGmaoStore();
  public workorders: any = useWorkOrdersStore();

  constructor() {}

  /**
   * Obtiene los esquemas de la base de datos desde el API.
   */
  async getSchemasDB(): Promise<any> {
    try {
      const { data } = await axios.get('/v2/users/actions.php', {
        params: {
          call: 'getSchemasDB',
          token: this.gmao.user.token,
          offline: 1,
          v2: 1,
        },
      });
      return data;
    } catch (error) {
      console.error('Error fetching schemas:', error);
      throw new Error('Get Schemas Error');
    }
  }

  async getSistemas(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getSistemasTecnico',
                  token: this.gmao.user.token,
                  offline: 1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              return data;
          })
          .catch((error:any) => {
              throw Error('Get Sistemas', error);
          });
  }

  async getDocumentosSQL(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getDocumentosSQL',
                  token: this.gmao.user.token,
                  offline: 1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              return data;
          })
          .catch((error:any) => {
              throw Error('Get Documentos', error);
          });
  }

  async getAlmacenArticulos(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getAlmacenArticulosSQL',
                  token: this.gmao.user.token,
                  offline: 1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              return data;
          })
          .catch((error:any) => {
              throw Error('Get AlmacenArticulos', error);
          });
  }

  async getModelos(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getModelos',
                  token: this.gmao.user.token,
                  page: -1,
                  offline: 1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              return data;
          }).catch((error:any) => {
              console.error(error);
          });
  }

  async getTecnicos(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getTecnicos',
                  token: this.gmao.user.token,
                  page: -1,
                  offline: 1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              return data;
          })
          .catch((error:any) => {
              console.error(error);
          });
  }

  async getAlmacenes(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getAlmacenes',
                  token: this.gmao.user.token,
                  page: -1,
                  offline: 1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              this.gmao.warehouse = data.data;
              return data;
          })
          .catch((error:any) => {
              console.error(error);
          });
  }

  async getPartes(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getPartes',
                  u: this.gmao.user.id,
                  token: this.gmao.user.token,
                  order_column: 'fecha',
                  order_cardinality: 'DESC',
                  offline_date: 1,
                  limit_maquinas: 1,
                  page: -1,
                  offline: 1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              // this.workorders?.workorders.push(data);

              // Cargar imagenes para offline
              // if (!+process?.env?.VUE_APP_FORZAR_LOGIN_UNICO) {
              //   this.workorders.offlineWorkorders[0].forEach((wo) => {
              //     if (this.workorders.imagenes) {
              //       this.workorders.imagenes.forEach((im) => {
              //         getBase64FromUrl(im.src).then((data) => {
              //           im.base64 = data;
              //         });
              //       });
              //     }
              //   });
              // }
              return data;
          })
          .catch((error:any) => {
            // TODO: ErrorController
            console.error('getPartes: ', error);
          });
  }

  // async getBase64FromUrl(url): Promise<any> {
  //   const data = await fetch(url);
  //   const blob = await data.blob();
  //   return new Promise((resolve) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(blob);
  //     reader.onloadend = () => {
  //       const base64data = reader.result;
  //       resolve(base64data);
  //     };
  //   });
  // },

  async getQuestions(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getPreguntas',
                  token: this.gmao.user.token,
                  value: this.gmao.user.id,
                  page: -1,
                  offline: 1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              return data;
          })
          .catch((error:any) => {
              console.error('GetPreguntas: ', error);
          });
  }

  async getReplies(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getRespuestas',
                  token: this.gmao.user.token,
                  field: 'id_pregunta',
                  page: -1,
                  offline: 1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              return data;
          })
          .catch((error:any) => {
              console.error('GetPreguntas: ', error);
          });
  }

  async getGamas(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getGamas',
                  token: this.gmao.user.token,
                  page: -1,
                  offline: 1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              return data;
          })
          .catch((error:any) => {
              console.error('GetGamas: ', error);
          });
  }

  async getWoTypes(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getWoTypes',
                  token: this.gmao.user.token,
                  page: -1,
                  offline: 1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              return data;
          })
          .catch((error:any) => {
              console.error('GetProyectoParteTipos: ', error);
          });
  }

  async getProyectoParteTipos(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getProyectoParteTipos',
                  token: this.gmao.user.token,
                  page: -1,
                  offline: 1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              return data;
          })
          .catch((error:any) => {
              console.error('GetProyectoParteTipos: ', error);
          });
  }

  async getMaterials(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getMateriales',
                  token: this.gmao.user.token,
                  value: this.gmao.user.id,
                  almacen: (this.gmao.warehouse.map((w:any) => w.id) || []).toString() || '',
                  page: -1,
                  offline: 1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              // * Si quitamos page:-1 esto es necesario

              // const materiales = {
              //   all: data.all.data,
              //   active: data.active.data,
              //   model: data.model.data,
              // };
              // TODO: Fix this object values
              return data;
          })
          .catch((error:any) => {
              console.error('GetMaterials: ', error);
          });
  }

  async getSolutionTexts(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getTextosPredefinidos',
                  u: this.gmao.user.id,
                  token: this.gmao.user.token,
                  offline: 1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              this.workorders.textosPredefinidos = data;
              return data;
          })
          .catch((error:any) => {
              console.error('GetTextos: ', error);
          });
  }

  async getLotesMaterial(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getLotesMaterial',
                  token: this.gmao.user.token,
                  page: -1,
                  offline: 1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              return data;
          })
          .catch((error:any) => {
              console.error('GetLotes: ', error);
          });
  }

  async getLotesAlmacenesSQL(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getLotesAlmacenesSQL',
                  token: this.gmao.user.token,
                  page: -1,
                  offline: 1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              return data;
          })
          .catch((error:any) => {
              console.error('GetLotesAlmacenes: ', error);
          });
  }

  async getRepuestosMaquinaSQL(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getRepuestosMaquinaSQL',
                  u: this.gmao.user.id,
                  token: this.gmao.user.token,
                  offline: 1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              return data;
          })
          .catch((error:any) => {
              console.error('GetRepuestos: ', error);
          });
  }

  async getHourTypes(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getHourTypes',
                  token: this.gmao.user.token,
                  page: -1,
                  offline: 1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              this.workorders.tiposHora = [];
              this.workorders.tiposHora.push(data);
              return data;
          })
          .catch((error:any) => {
              console.error('GetTiposHora: ', error);
          });
  }

  async getDirecciones(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getDirecciones',
                  u: this.gmao.user.id,
                  token: this.gmao.user.token,
                  offline: 1,
                  offline_date: 1,
                  page: -1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              return data;
          })
          .catch((error:any) => {
              console.error(error);
          });
  }

  async getMaquinas(): Promise<any> {
      return axios
          .get('/v2/users/actions.php', {
              params: {
                  call: 'getMaquinas',
                  token: this.gmao.user.token,
                  page: -1,
                  offline: 1,
                  v2: 1,
              },
          })
          .then(({ data }:any) => {
              return data;
          })
          .catch((error:any) => {
              console.error(error);
          });
  }

  async getJornadasTecnico(): Promise<any> {
      return axios.get('/v2/users/actions.php', {
          params: {
              call: 'getAllTechWorkingDays',
              token: this.gmao.user.token,
              offline: 1,
              page: -1,
              v2: 1
          }
      }).then(({ data }:any) => {
        if ('data' in data) data = data.data;
        
        const dataToSql = structuredClone(data);
        dataToSql.map((d:any) => {
            delete(d.desde_formatted);
            delete(d.hasta_formatted);

            return d;
        });
        const dataToTime = data && data?.map((d:any) => {
              const start = d?.desde_formatted ? moment(d?.desde_formatted).format() : moment(`${d.fecha} ${d.inicio}`).tz('Europe/Madrid').format();
              const stop = d?.desde_formatted ?
              (d.hasta_formatted ? moment(d?.hasta_formatted).format() : undefined) :
              (d.fecha_fin ? moment(`${d.fecha_fin} ${d.fin}`).tz('Europe/Madrid').format() : undefined);
  
              const timeTemp:any = {
                  id: d?.id,
                  start,
                  stop,
                  // duracion: d.temp_duracion,
                  km: { from: null },
                  coords_start: {
                      latitude: d?.geo_inicio?.latitude || null,
                      longitude: d?.geo_inicio?.longitude || null,
                  },
                  address_coords: {}
              }
              Object.assign(timeTemp, {...d});

              delete(timeTemp.desde_formatted);
              delete(timeTemp.hasta_formatted);
              

              if (+this.gmao.user.modulos.modulo_vehiculos && d?.jornada_vehiculo) timeTemp.vehicle = d?.jornada_vehiculo?.id_vehiculo

              return timeTemp;
          }) || [];

          this.gmao.time = dataToTime;
          
          return dataToSql;
      }).catch((e:any) => {
          console.error('Error al traer jornadas: ', e);
          
      });
  }

  async getJornadas(): Promise<any> {
      const today = moment();
      const startOfWeek = today.startOf('week').add(1, 'days').unix();
      const endOfWeek = today.endOf('week').add(1, 'days').unix();

      return axios.get('/v2/users/actions.php', {
          params: {
              call: 'getWorkedDays',
              u: this.gmao.user.id,
              token: this.gmao.user.token,
              v2: 1,
              start: startOfWeek,
              end: endOfWeek,
              page: -1,
              offline: 1,
          },
          })
          .then(({ data }: any) => {
              this.offline.workedDays = data;

              return [];
          });
  }

  async getVehicles(): Promise<any> {
      return axios.get('/v2/users/actions.php', {
      params: {
          call: 'getVehiculos',
          token: this.gmao.user.token,
          value: this.gmao.user.id,
          page: -1,
          v2: 1
      }
      }).then(({ data }: any) => {
          return data;
      });
  }

  async getIsWorkingDay(): Promise<any> {
      return axios
      .get('/v2/users/actions.php', {
          params: {
              call: 'getIsWorkingDay',
              u: this.gmao.user.id,
              token: this.gmao.user.token,
              v2: 1,
              offline:1
          },
      })
      .then(({ data }: any) => {
          return data;
      })
  }

  // Agrega otros métodos de API aquí siguiendo la misma estructura
}
