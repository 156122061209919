// ErrorController.ts
import { toastController, ToastOptions } from '@ionic/vue';

interface ErrorItem {
    error: Error;
    timestamp: Date;
    sentToSentry: boolean;
    showMessageToUser: string;
}

export class ErrorController {
    private errorQueue: ErrorItem[] = [];
    private hasCapturedErrors: boolean = false;

    private toast: {
        create(options: ToastOptions): Promise<HTMLIonToastElement>;
        dismiss(data?: any, role?: string, id?: string): Promise<boolean>;
        getTop(): Promise<HTMLIonToastElement>;
    } = toastController;

    constructor() {
        this.initQueue();
    }

    async initQueue() {
        /**
         * NOTE: Inicializaremos diferentes componentes que gestionan/muestran errores
         *      - Sentry
         *      - Toast...
         */
    }

    captureError(error: Error, showMessageToUser: string, skipToShow = false) {
        const errorItem: ErrorItem = {
            error,
            timestamp: new Date(),
            sentToSentry: false,
            showMessageToUser
        };
        this.errorQueue.push(errorItem);
        this.hasCapturedErrors = true;
        if (skipToShow) this.displayErrorsToUser()
        // console.log('Cola: ', this.errorQueue);
    }

    private prepareErrorMessage(errorItem: ErrorItem): string {
        return `Error: ${errorItem.showMessageToUser}`;
    }

    async sendErrorsToSentry(isThereConnection: boolean) {
        if (!isThereConnection) {
            // this.$Sentry.captureException(err);
            console.log("OFFLINE:: los errores se enviarán cuando se recupere la conexión.");
            return;
        }

        while (this.errorQueue.length > 0) {
            const errorItem = this.errorQueue.shift();
            if (errorItem && !errorItem.sentToSentry) {
                try {

                    console.log(`Enviando error a Sentry: ${errorItem.error.message}`);
                    errorItem.sentToSentry = true;
                    // Enviar a Sentry
                    // await new Promise(resolve => setTimeout(resolve, 1000)); // Ejemplo de espera
                } catch (error) {
                    // ErrorController -> captureError (¿correo a admin?)
                    console.error("Error al enviar a Sentry:", error);
                }
            }
        }
    }

    displayErrorsToUser() {
        this.errorQueue.forEach(async (errorItem) => {
            if (!errorItem.sentToSentry) {
                console.error(this.prepareErrorMessage(errorItem));
                const toast = await this.toast.create({
                    header: 'Ha ocurrido un error',
                    message: errorItem.showMessageToUser,
                    position: 'top',
                    color: 'danger',
                    duration: 5000
                });
                await toast.present();
            } else {
                // TODO: Enviar el error a Sentry
            }
        });
    }
}
